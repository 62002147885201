/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  id: 0,
  itemId: 0,
  documentDetail: {
    price_group_id: 0,
    currency_id: 0,
    item_id: 0,
    uom_id: 0,
    uom_rate: 0,
    sale_price: 0,
    valid_from: Date.now(),
    valid_to: Date.now(),
    uom_select2: { value: 0, label: '' },
  },
  documentIsLoading: false,
  itemPriceIsVisible: false,

  uomOptions: [],
  uomaIsLoading: false
};
