/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  brands: [],
  documents: [],
  fetchIsLoading: false,
  sorts: {
    doc_date: 'descend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 100,
  selectedDocuments: [],
  selectedBrand: 0,
  workspaceIsVisible: false,

  managePhotoIsVisible: false,
  photo: { value: 0, image: null },
  photoIsLoading: false,
  uploadIsLoading: false
};
