import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  auditUser01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  auditUser01SetUser: ['userId'],
  auditUser01FetchAuditUser01: ['userId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  auditUser01FetchAuditUser01Loading: ['boolean'],
  auditUser01FetchAuditUser01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],

  auditUser01SetExpandedRows: ['expandedRows']
});

export const AuditUser01Types = Types;
export default Creators;
