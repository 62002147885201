import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cartDetailResetTimestamp: null,
  cartDetailSetHdrId: ['hdrId', 'itemId'],

  cartDetailInitHeader: ['divisionId'],
  cartDetailShowHeader: ['hdrId'],
  cartDetailShowHeaderSuccess: ['documentHeader'],

  cartDetailUpdateHeader: ['formikBag', 'documentHeader'],
  cartDetailCreateHeader: ['formikBag', 'documentHeader'],
  cartDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  cartDetailShowDocumentLoading: ['boolean'],
  cartDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  cartDetailShowDetails: ['hdrId'],
  cartDetailShowDetailsSuccess: ['documentDetails'],

  cartDetailSetDetailVisible: ['boolean'],
  cartDetailSetDocumentDetail: ['documentDetail'],

  cartDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  cartDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  cartDetailDeleteDetail: ['hdrId', 'documentDetail'],

  cartDetailFetchSalesmanOptions: ['search'],
  cartDetailFetchSalesmanOptionLoading: ['boolean'],
  cartDetailFetchSalesmanOptionSuccess: ['options'],

  cartDetailFetchDeliveryPointOptions: ['search'],
  cartDetailFetchDeliveryPointOptionLoading: ['boolean'],
  cartDetailFetchDeliveryPointOptionSuccess: ['options'],
  cartDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  cartDetailFetchCreditTermOptions: ['search'],
  cartDetailFetchCreditTermOptionLoading: ['boolean'],
  cartDetailFetchCreditTermOptionSuccess: ['options'],

  cartDetailFetchCurrencyOptions: ['search'],
  cartDetailFetchCurrencyOptionLoading: ['boolean'],
  cartDetailFetchCurrencyOptionSuccess: ['options'],
  cartDetailChangeCurrency: ['formikBag', 'currencyId'],

  cartDetailFetchItemOptions: ['search'],
  cartDetailFetchItemOptionLoading: ['boolean'],
  cartDetailFetchItemOptionSuccess: ['options'],
  cartDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  cartDetailFetchUomOptions: ['itemId', 'search'],
  cartDetailFetchUomOptionLoading: ['boolean'],
  cartDetailFetchUomOptionSuccess: ['options'],
  cartDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId']
});

export const CartDetailTypes = Types;
export default Creators;
