import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import WhseJob140101Actions from '../../Stores/WhseJob140101/Actions';

class WhseJob140101Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnPrintDocument = this.useOnPrintDocument.bind(this);
    this.useOnPrintHandlingUnit = this.useOnPrintHandlingUnit.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'pallet_qty' }),
        // sort field
        dataIndex: 'pallet_qty',
        sorter: (a, b) => a.pallet_qty - b.pallet_qty,
        // filter field
        key: 'pallet_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.pallet_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'case_qty' }),
        // sort field
        dataIndex: 'case_qty',
        sorter: (a, b) => a.case_qty - b.case_qty,
        // filter field
        key: 'case_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        sorter: (a, b) => a.gross_weight - b.gross_weight,
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        sorter: (a, b) => a.cubic_meter - b.cubic_meter,
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnPrintDocument() {
    const { selectedDocuments, printWhseJob140101, curSiteFlowId } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    printWhseJob140101(curSiteFlowId, hdrIds);
  }

  useOnPrintHandlingUnit() {
    const { selectedDocuments, printHandlingUnit, curSiteFlowId } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    printHandlingUnit(curSiteFlowId, hdrIds);
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, printIsLoading } = this.props;

    // summarise the table
    const calcRow = selectedDocuments.reduce((ttlObj, object) => {
      return {
        pallet_qty:
          ('pallet_qty' in ttlObj ? ttlObj.pallet_qty : 0) + parseFloat(object.pallet_qty),
        case_qty: ('case_qty' in ttlObj ? ttlObj.case_qty : 0) + parseFloat(object.case_qty),
        gross_weight:
          ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) + parseFloat(object.gross_weight),
        cubic_meter:
          ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) + parseFloat(object.cubic_meter)
      };
    }, {});

    // override the normal table, and add footer below table
    const tableWithFooter = props => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <table {...props}>
          {props.children}
          {Object.keys(calcRow).length > 0 && (
            <tfoot className="ant-table-tbody">
              <tr className="ant-table-row">
                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={3}>
                  {intl.formatMessage({ id: 'total' })}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.pallet_qty)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.case_qty)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.gross_weight)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.cubic_meter)}
                </td>
                <td />
              </tr>
            </tfoot>
          )}
        </table>
      );
    };

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'print_gds_rcpt_warehouse_job' })}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="pallet_label"
              disabled={selectedDocuments.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintHandlingUnit}
            >
              {intl.formatMessage({ id: 'pallet_label' })}
            </Button>,
            <Button
              type="primary"
              key="print"
              disabled={selectedDocuments.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintDocument}
            >
              {intl.formatMessage({ id: 'print' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                components={{ table: tableWithFooter }}
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={printIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

WhseJob140101Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  printIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,

  printWhseJob140101: PropTypes.func,
  printHandlingUnit: PropTypes.func
};

WhseJob140101Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  printIsLoading: false,

  curSiteFlowId: 0,

  printWhseJob140101() {},
  printHandlingUnit() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.whseJob140101.workspaceIsVisible,

  selectedDocuments: state.whseJob140101.selectedDocuments,
  newDocuments: state.whseJob140101.newDocuments,

  printIsLoading: state.whseJob140101.printIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(WhseJob140101Actions.whseJob140101SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(WhseJob140101Actions.whseJob140101RemoveSelectedDocuments(selectedDocuments)),

  printWhseJob140101: (curSiteFlowId, hdrIds) =>
    dispatch(WhseJob140101Actions.whseJob140101PrintWhseJob140101(curSiteFlowId, hdrIds)),

  printHandlingUnit: (curSiteFlowId, hdrIds) =>
    dispatch(WhseJob140101Actions.whseJob140101PrintHandlingUnit(curSiteFlowId, hdrIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WhseJob140101Dialog));
