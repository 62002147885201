import * as Yup from 'yup';

import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Row, Typography } from 'antd';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FormikButton, FormikInput, FormikSelect } from '../../Components/Formik';

import DeliveryPointActions from '../../Stores/DeliveryPoint/Actions';

const { Text } = Typography;

class DeliveryPointForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchState = this.handleSearchState.bind(this);
    this.handleSearchArea = this.handleSearchArea.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.handleChangeArea = this.handleChangeArea.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchState(form, value) {
    const { fetchStateOptions } = this.props;
    fetchStateOptions(value);
  }

  handleSearchArea(form, value) {
    const { fetchAreaOptions } = this.props;
    const { values } = form;
    fetchAreaOptions(values.state_select2.value, value);
  }

  handleChangeState(form, value) {
    // params: form, value, option
    const { changeState } = this.props;
    changeState(form, value);
  }

  handleChangeArea(form, value) {
    // params: form, value, option
    const { changeArea } = this.props;
    changeArea(form, value);
  }

  render() {
    const {
      documentDetail,
      debtorId,
      createAddress,
      updateAddress,
      stateIsLoading,
      stateOptions,
      areaIsLoading,
      areaOptions,
      intl
    } = this.props;

    const disabled = false;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          const processedValues = {
            id: values.id,
            unit_no: values.unit_no,
            street_name: values.street_name,
            building_name: values.building_name,
            state_id: values.state_select2.value,
            area_id: values.area_select2.value,
            postcode: values.postcode,
            attention: values.attention,
            phone_01: values.phone_01
          };

          if (values.id > 0) {
            updateAddress(formikBag, processedValues);
          } else {
            createAddress(formikBag, debtorId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          unit_no: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          street_name: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          postcode: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          state_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'code_is_required' }))
          }),
          area_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'code_is_required' }))
          }),
          phone_01: Yup.string().required(intl.formatMessage({ id: 'code_is_required' }))
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'unit_no' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={3}>
                <Field name="unit_no" component={FormikInput} disabled={disabled} />
              </Col>
              <Col span={17} />
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'street_name' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="street_name" component={FormikInput} disabled={disabled} />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'building_name' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="building_name" component={FormikInput} disabled={disabled} />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'state_name' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="state_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchState}
                  notFoundContent={null}
                  loading={stateIsLoading}
                  options={stateOptions}
                  disabled={disabled}
                  onChange={this.handleChangeState}
                />
              </Col>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'area' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="area_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchArea}
                  notFoundContent={null}
                  loading={areaIsLoading}
                  options={areaOptions}
                  disabled={disabled}
                  onChange={this.handleChangeArea}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'postcode' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="postcode" component={FormikInput} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'attention' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="attention" component={FormikInput} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'phone_number' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="phone_01" component={FormikInput} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>
            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={handleReset}
                  icon="undo"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon="save"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

DeliveryPointForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  debtorId: PropTypes.number,
  stateIsLoading: PropTypes.bool,
  stateOptions: PropTypes.arrayOf(PropTypes.object),
  areaIsLoading: PropTypes.bool,
  areaOptions: PropTypes.arrayOf(PropTypes.object),

  documentDetail: PropTypes.shape({}),
  createAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  fetchStateOptions: PropTypes.func,
  fetchAreaOptions: PropTypes.func,
  changeState: PropTypes.func,
  changeArea: PropTypes.func
};

DeliveryPointForm.defaultProps = {
  intl: {},
  debtorId: 0,
  stateIsLoading: false,
  stateOptions: [],
  areaIsLoading: false,
  areaOptions: [],
  documentDetail: {},
  createAddress() {},
  updateAddress() {},
  fetchStateOptions() {},
  fetchAreaOptions() {},
  changeState() {},
  changeArea() {}
};

const mapStateToProps = state => ({
  debtorId: state.deliveryPoint.debtorId,
  documentDetail: state.deliveryPoint.documentDetail,

  stateIsLoading: state.deliveryPoint.stateIsLoading,
  stateOptions: state.deliveryPoint.stateOptions,
  areaIsLoading: state.deliveryPoint.areaIsLoading,
  areaOptions: state.deliveryPoint.areaOptions
});

const mapDispatchToProps = dispatch => ({
  createAddress: (formikBag, debtorId, documentDetail) =>
    dispatch(DeliveryPointActions.deliveryPointCreate(formikBag, debtorId, documentDetail)),
  updateAddress: (formikBag, documentDetail) =>
    dispatch(DeliveryPointActions.deliveryPointUpdate(formikBag, documentDetail)),
  fetchStateOptions: search =>
    dispatch(DeliveryPointActions.deliveryPointFetchStateOptions(search)),
  fetchAreaOptions: (stateId, search) =>
    dispatch(DeliveryPointActions.deliveryPointFetchAreaOptions(stateId, search)),
  changeState: (formikBag, stateId) =>
    dispatch(DeliveryPointActions.deliveryPointChangeState(formikBag, stateId)),
  changeArea: (formikBag, areaId) =>
    dispatch(DeliveryPointActions.deliveryPointChangeArea(formikBag, areaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeliveryPointForm));
