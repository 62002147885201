/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { ItemGroup01List01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemGroup01List01ResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const itemGroup01List01FetchItemGroup01IndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const itemGroup01List01FetchItemGroup01ListAllSuccess = (
  state,
  { brands }
) => ({
  ...state,
  brands
});

export const itemGroup01List01FetchItemGroup01IndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const itemGroup01List01AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const itemGroup01List01SetSelectedBrand = (state, { selectedBrand }) => ({
  ...state,
  selectedBrand
});

export const itemGroup01List01ResetSelectedBrand = (state) => ({
  ...state,
  selectedBrand: 0
});


export const itemGroup01List01RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const itemGroup01List01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const itemGroup01List01SetManagePhotoVisible = (state, { document, boolean }) => ({
  ...state,
  selectedDocument: document,
  managePhotoIsVisible: boolean
});

export const itemGroup01List01ManagePhotoLoading = (state, { boolean }) => ({
  ...state,
  photoIsLoading: boolean
});

export const itemGroup01List01ManagePhotoSuccess = (state, { document }) => ({
  ...state,
  selectedDocument: document
});

export const itemGroup01List01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_RESET_TIMESTAMP]: itemGroup01List01ResetTimestamp,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_FETCH_ITEM_GROUP01_LIST_LOADING]: itemGroup01List01FetchItemGroup01IndexLoading,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_FETCH_ITEM_GROUP01_LIST_SUCCESS]: itemGroup01List01FetchItemGroup01IndexSuccess,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_FETCH_ITEM_GROUP01_LIST_ALL_SUCCESS]: itemGroup01List01FetchItemGroup01ListAllSuccess,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_ADD_SELECTED_DOCUMENTS]: itemGroup01List01AddSelectedDocuments,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_REMOVE_SELECTED_DOCUMENTS]: itemGroup01List01RemoveSelectedDocuments,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_SET_WORKSPACE_VISIBLE]: itemGroup01List01SetWorkspaceVisible,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_SET_SELECTED_BRAND]: itemGroup01List01SetSelectedBrand,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_RESET_SELECTED_BRAND]: itemGroup01List01ResetSelectedBrand,

  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_SET_MANAGE_PHOTO_VISIBLE]: itemGroup01List01SetManagePhotoVisible,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_MANAGE_PHOTO_LOADING]: itemGroup01List01ManagePhotoLoading,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_MANAGE_PHOTO_SUCCESS]: itemGroup01List01ManagePhotoSuccess,
  [ItemGroup01List01Types.ITEM_GROUP01_LIST01_UPLOAD_LOADING]: itemGroup01List01UploadLoading
});
