import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cycleCountDetailResetTimestamp: null,
  cycleCountDetailSetHdrId: ['hdrId'],

  cycleCountDetailInitHeader: ['siteFlowId'],
  cycleCountDetailShowHeader: ['hdrId'],
  cycleCountDetailShowHeaderSuccess: ['documentHeader'],

  cycleCountDetailUpdateHeader: ['formikBag', 'documentHeader'],
  cycleCountDetailCreateHeader: ['formikBag', 'documentHeader'],
  cycleCountDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  cycleCountDetailShowDocumentLoading: ['boolean'],
  cycleCountDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  cycleCountDetailShowDetails: ['hdrId'],
  cycleCountDetailShowDetailsSuccess: ['documentDetails'],

  cycleCountDetailSetDetailVisible: ['boolean'],
  cycleCountDetailSetJobDetailVisible: ['boolean'],
  cycleCountDetailSetDocumentDetail: ['documentDetail'],

  cycleCountDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  cycleCountDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  cycleCountDetailDeleteDetail: ['hdrId', 'documentDetail'],

  cycleCountDetailSetWhseJobType: ['whseJobType'],

  cycleCountDetailFetchCompanyOptions: ['search'],
  cycleCountDetailFetchCompanyOptionLoading: ['boolean'],
  cycleCountDetailFetchCompanyOptionSuccess: ['options'],

  cycleCountDetailFetchItemOptions: ['search'],
  cycleCountDetailFetchItemOptionLoading: ['boolean'],
  cycleCountDetailFetchItemOptionSuccess: ['options'],
  cycleCountDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  cycleCountDetailFetchItemBatchOptions: ['itemId', 'search'],
  cycleCountDetailFetchItemBatchOptionLoading: ['boolean'],
  cycleCountDetailFetchItemBatchOptionSuccess: ['options'],
  cycleCountDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  cycleCountDetailFetchUomOptions: ['itemId', 'search'],
  cycleCountDetailFetchUomOptionLoading: ['boolean'],
  cycleCountDetailFetchUomOptionSuccess: ['options'],
  cycleCountDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  cycleCountDetailFetchStorageBinOptions: ['siteFlowId', 'search'],
  cycleCountDetailFetchStorageBinOptionLoading: ['boolean'],
  cycleCountDetailFetchStorageBinOptionSuccess: ['options'],

  cycleCountDetailFetchHandlingUnitOptions: ['siteFlowId', 'search'],
  cycleCountDetailFetchHandlingUnitOptionLoading: ['boolean'],
  cycleCountDetailFetchHandlingUnitOptionSuccess: ['options'],

  cycleCountDetailFetchStorageRowOptions: ['siteFlowId', 'search'],
  cycleCountDetailFetchStorageRowOptionLoading: ['boolean'],
  cycleCountDetailFetchStorageRowOptionSuccess: ['options'],

  cycleCountDetailFetchStorageBayOptions: ['siteFlowId', 'search'],
  cycleCountDetailFetchStorageBayOptionLoading: ['boolean'],
  cycleCountDetailFetchStorageBayOptionSuccess: ['options'],

  cycleCountDetailCreateJobDetail: ['formikBag', 'hdrId', 'desc', 'rowIds', 'bayIds', 'levels'],
  cycleCountDetailDeleteJobDetail: ['hdrId', 'jobNo']
});

export const CycleCountDetailTypes = Types;
export default Creators;
