import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Card, Row, Col, Button, Alert } from 'antd'
import PromoDiscListActions from '../../Stores/PromoDiscIndex/Actions'
import ManagePhotoDialog from '../PromoIndex/ManagePhotoDialog'

class BannerForm extends Component {

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        
    }

    componentWillUnmount() {
        
    }

    render() {
        const { intl, documentHeader, setManagePhotoVisible } = this.props
        return documentHeader ? (
            <>
                <Card type="inner" title={intl.formatMessage({ id: 'banner' })}>
                    <Row type="flex" justify="center" gutter={[8, 8]}>
                        <Col span={24}>
                            {documentHeader.hasOwnProperty('asset_url') 
                                ? (<img
                                    width="100%"
                                    src={documentHeader.asset_url !== null || documentHeader.asset_url !== ''
                                            ? documentHeader.asset_url 
                                            : "https://via.placeholder.com/1600x640?text=No+Banner"
                                        }
                                />)
                                : (<Alert type="warning" message="Banner image will be uploaded once the form is submitted."/>)
                            }
                            
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" gutter={[8, 8]}>
                        <Col span={3}>
                            <Button
                                type="primary"
                                onClick={() => setManagePhotoVisible(documentHeader, true)}
                                icon="upload"
                            >
                                {intl.formatMessage({ id: 'upload' })}
                            </Button>
                        </Col>
                        <Col span={21}>
                        </Col>
                    </Row>
                </Card>
                <ManagePhotoDialog/>
            </>
        ) : null
    }
}

const mapStateToProps = state => ({
    documentHeader: state.promoDetail.documentHeader,
});

const mapDispatchToProps = dispatch => ({
    setManagePhotoVisible: (document, boolean) =>
        dispatch(PromoDiscListActions.promoDiscListSetManagePhotoVisible(document, boolean)),
});

export default connect(
mapStateToProps,
mapDispatchToProps
)(injectIntl(BannerForm));
