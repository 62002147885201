import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  advShipDetailResetTimestamp: null,
  advShipDetailSetHdrId: ['hdrId'],

  advShipDetailInitHeader: ['divisionId'],
  advShipDetailShowHeader: ['hdrId'],
  advShipDetailShowHeaderSuccess: ['documentHeader'],

  advShipDetailUpdateHeader: ['formikBag', 'documentHeader'],
  advShipDetailCreateHeader: ['formikBag', 'documentHeader'],
  advShipDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  advShipDetailShowDocumentLoading: ['boolean'],
  advShipDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  advShipDetailShowDetails: ['hdrId'],
  advShipDetailShowDetailsSuccess: ['documentDetails'],

  advShipDetailSetDetailVisible: ['boolean'],
  advShipDetailSetDocumentDetail: ['documentDetail'],

  advShipDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  advShipDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  advShipDetailDeleteDetail: ['hdrId', 'documentDetail'],

  advShipDetailFetchPurchaserOptions: ['search'],
  advShipDetailFetchPurchaserOptionLoading: ['boolean'],
  advShipDetailFetchPurchaserOptionSuccess: ['options'],

  advShipDetailFetchBizPartnerOptions: ['search'],
  advShipDetailFetchBizPartnerOptionLoading: ['boolean'],
  advShipDetailFetchBizPartnerOptionSuccess: ['options'],
  advShipDetailChangeBizPartner: ['formikBag', 'bizPartnerId'],

  advShipDetailFetchCreditTermOptions: ['search'],
  advShipDetailFetchCreditTermOptionLoading: ['boolean'],
  advShipDetailFetchCreditTermOptionSuccess: ['options'],

  advShipDetailFetchCurrencyOptions: ['search'],
  advShipDetailFetchCurrencyOptionLoading: ['boolean'],
  advShipDetailFetchCurrencyOptionSuccess: ['options'],
  advShipDetailChangeCurrency: ['formikBag', 'currencyId'],

  advShipDetailFetchItemOptions: ['search'],
  advShipDetailFetchItemOptionLoading: ['boolean'],
  advShipDetailFetchItemOptionSuccess: ['options'],
  advShipDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  advShipDetailFetchUomOptions: ['itemId', 'search'],
  advShipDetailFetchUomOptionLoading: ['boolean'],
  advShipDetailFetchUomOptionSuccess: ['options'],
  advShipDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId']
});

export const AdvShipDetailTypes = Types;
export default Creators;
