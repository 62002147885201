/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PalletLabelProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const palletLabelProcessFetchPalletLabelProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const palletLabelProcessFetchPalletLabelProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PalletLabelProcessTypes.PALLET_LABEL_PROCESS_FETCH_PALLET_LABEL_PROCESS_LOADING]: palletLabelProcessFetchPalletLabelProcessLoading,
  [PalletLabelProcessTypes.PALLET_LABEL_PROCESS_FETCH_PALLET_LABEL_PROCESS_SUCCESS]: palletLabelProcessFetchPalletLabelProcessSuccess
});
