import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    divisionDetailResetTimestamp: null,
    divisionDetailInitHeader: ['login_type'],
    divisionDetailSetHdrId: ['hdrId'],
    divisionDetailShowHeader: ['hdrId'],
    divisionDetailShowDocumentLoading: ['boolean'],
    divisionDetailShowHeaderSuccess: ['documentHeader'],
    
    divisionDetailCreateHeader: ['formikBag', 'documentHeader'],
    divisionDetailUpdateHeader: ['formikBag', 'documentHeader'],
    divisionDetailUpdateHeaderSuccess: ['documentHeader'],

    divisionDetailFetchCompanyOptions: ['search'],
    divisionDetailFetchCompanyOptionLoading: ['boolean'],
    divisionDetailFetchCompanyOptionSuccess: ['options'],
    
    divisionDetailShowSyncSetting: ['hdrId'],
    divisionDetailShowSyncSettingLoading: ['boolean'],
    divisionDetailShowSyncSettingSuccess: ['syncSetting'],
    divisionDetailUpdateSyncSetting: ['formikBag', 'syncSetting'],
    divisionDetailUpdateSyncSettingSuccess: ['syncSetting'],
});

export const DivisionDetailTypes = Types;
export default Creators;