/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { UserDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const userDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const userDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
});

export const userDetailShowDocumentLoading = (state, { boolean }) => ({
    ...state,
    documentIsLoading: boolean
  });
  
export const userDetailShowHeaderSuccess = (state, { documentHeader }) => ({
    ...state,
    documentHeader
});

export const userDetailUpdateHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const userDetailFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const userDetailFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});


/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [UserDetailTypes.USER_DETAIL_RESET_TIMESTAMP]: userDetailResetTimestamp,
    [UserDetailTypes.USER_DETAIL_SET_HDR_ID]: userDetailSetHdrId,
    [UserDetailTypes.USER_DETAIL_SHOW_HEADER_SUCCESS]: userDetailShowHeaderSuccess,
    [UserDetailTypes.USER_DETAIL_SHOW_DOCUMENT_LOADING]: userDetailShowDocumentLoading,
    [UserDetailTypes.USER_DETAIL_UPDATE_HEADER_SUCCESS]: userDetailUpdateHeaderSuccess,
    
    [UserDetailTypes.USER_DETAIL_FETCH_DEBTOR_OPTION_LOADING]: userDetailFetchDebtorOptionLoading,
    [UserDetailTypes.USER_DETAIL_FETCH_DEBTOR_OPTION_SUCCESS]: userDetailFetchDebtorOptionSuccess,

});