import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { Row, Col, Table, Button, Badge } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import GdsRcpt02Actions from '../../Stores/GdsRcpt02/Actions';

import GdsRcpt02Dialog from './GdsRcpt02Dialog';

class GdsRcpt02Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchGdsRcpt02,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchGdsRcpt02(curSiteFlowId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'division_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'division_code'),
        // filter field
        key: 'division',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'division' }),
          'division',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.division_code}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.delivery_point_code}</b>
            <br />
            {record.delivery_point_company_name_01}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.doc_code}</div>
            <div style={{ fontWeight: 'bold' }}>
              {record.sls_rtn_hdr_code.length > 0 ? record.sls_rtn_hdr_code : <br />}
            </div>
            <div>{record.ref_code_01.length > 0 ? record.ref_code_01 : <br />}</div>
            <div>{record.ref_code_02.length > 0 ? record.ref_code_02 : <br />}</div>
            <div>{record.ref_code_03.length > 0 ? record.ref_code_03 : <br />}</div>
            <div>{record.ref_code_04.length > 0 ? record.ref_code_04 : <br />}</div>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_doc_status'),
        // filter field
        key: 'str_doc_status',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'doc_status' }),
          'str_doc_status',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.str_doc_status}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },

      {
        align: 'right',
        title: intl.formatMessage({ id: 'case_qty' }),
        // sort field
        dataIndex: 'case_qty',
        ...AntDesignTable.getColumnSortProps(sorts, 'case_qty'),
        // filter field
        key: 'case_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.case_qty)}
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        ...AntDesignTable.getColumnSortProps(sorts, 'gross_weight'),
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.gross_weight)}
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        ...AntDesignTable.getColumnSortProps(sorts, 'cubic_meter'),
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.cubic_meter)}
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon="edit" onClick={() => goToDocument(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: record => ({
              // Column configuration not to be checked
              disabled: record.str_doc_status !== 'COMPLETE'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
              </Row>
            </>
          )}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px'
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon="laptop"
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <GdsRcpt02Dialog />
      </>
    );
  }
}

GdsRcpt02Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchGdsRcpt02: PropTypes.func
};

GdsRcpt02Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchGdsRcpt02() {}
};

const mapStateToProps = state => ({
  timestamp: state.gdsRcpt02.timestamp,
  workspaceIsVisible: state.gdsRcpt02.workspaceIsVisible,

  documents: state.gdsRcpt02.documents,
  selectedDocuments: state.gdsRcpt02.selectedDocuments,

  sorts: state.gdsRcpt02.sorts,
  filters: state.gdsRcpt02.filters,

  currentPage: state.gdsRcpt02.currentPage,
  pageSize: state.gdsRcpt02.pageSize,
  total: state.gdsRcpt02.total,
  fetchIsLoading: state.gdsRcpt02.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(GdsRcpt02Actions.gdsRcpt02ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(GdsRcpt02Actions.gdsRcpt02GoToDocument(hdrId)),
  setWorkspaceVisible: boolean => dispatch(GdsRcpt02Actions.gdsRcpt02SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcpt02Actions.gdsRcpt02AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcpt02Actions.gdsRcpt02RemoveSelectedDocuments(selectedDocuments)),

  fetchGdsRcpt02: (divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      GdsRcpt02Actions.gdsRcpt02FetchGdsRcpt02(divisionId, currentPage, sorts, filters, pageSize)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GdsRcpt02Table));
