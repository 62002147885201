import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import PalletLabelList01Actions from '../../Stores/PalletLabelList01/Actions';

class PalletLabelList01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnPrintDocument = this.useOnPrintDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'barcode' }),
        // sort field
        dataIndex: 'barcode',
        sorter: (a, b) => `${a.barcode}`.localeCompare(b.barcode),
        // filter field
        key: 'barcode',
        render: (text, record) => <>{record.barcode}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_at' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{record.created_at}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'print' }),
        // sort field
        dataIndex: 'print_count',
        sorter: (a, b) => a.print_count - b.print_count,
        // filter field
        key: 'print_count',
        render: (text, record) => <>{record.print_count}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'first_printed_at' }),
        // sort field
        dataIndex: 'first_printed_at',
        sorter: (a, b) => new Date(a.first_printed_at) - new Date(b.first_printed_at),
        // filter field
        key: 'first_printed_at',
        render: (text, record) => <>{record.first_printed_at}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'last_printed_at' }),
        // sort field
        dataIndex: 'last_printed_at',
        sorter: (a, b) => new Date(a.last_printed_at) - new Date(b.last_printed_at),
        // filter field
        key: 'last_printed_at',
        render: (text, record) => <>{record.last_printed_at}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnPrintDocument() {
    const { selectedDocuments, printPalletLabelList01, curSiteFlowId } = this.props;

    const ids = selectedDocuments.map(value => {
      return value.id;
    });

    printPalletLabelList01(curSiteFlowId, ids);
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, printIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'manage_pallet_labels' })}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="print"
              disabled={selectedDocuments.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintDocument}
            >
              {intl.formatMessage({ id: 'print' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={printIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_labels' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

PalletLabelList01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  printIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,

  printPalletLabelList01: PropTypes.func
};

PalletLabelList01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  printIsLoading: false,

  curSiteFlowId: 0,

  printPalletLabelList01() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.palletLabelList01.workspaceIsVisible,

  selectedDocuments: state.palletLabelList01.selectedDocuments,

  printIsLoading: state.palletLabelList01.printIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(PalletLabelList01Actions.palletLabelList01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(PalletLabelList01Actions.palletLabelList01RemoveSelectedDocuments(selectedDocuments)),

  printPalletLabelList01: (siteFlowId, ids) =>
    dispatch(PalletLabelList01Actions.palletLabelList01PrintPalletLabelList01(siteFlowId, ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PalletLabelList01Dialog));
