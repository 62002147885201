import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userProcessFetchUserProcess: ['siteFlowId'],
  userProcessFetchUserProcessLoading: ['boolean'],
  userProcessFetchUserProcessSuccess: ['process']
});

export const UserProcessTypes = Types;
export default Creators;
