/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ReservedStockReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const reservedStockReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const reservedStockReportInitReservedStockSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const reservedStockReportReservedStockSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const reservedStockReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const reservedStockReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const reservedStockReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const reservedStockReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const reservedStockReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const reservedStockReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const reservedStockReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const reservedStockReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const reservedStockReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const reservedStockReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const reservedStockReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const reservedStockReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const reservedStockReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const reservedStockReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const reservedStockReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const reservedStockReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_REPORT_LOADING]: reservedStockReportReportLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_INIT_RESERVED_STOCK_SUCCESS]: reservedStockReportInitReservedStockSuccess,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_RESERVED_STOCK_SUCCESS]: reservedStockReportReservedStockSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_OPTION_LOADING]: reservedStockReportFetchItemOptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_OPTION_SUCCESS]: reservedStockReportFetchItemOptionSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: reservedStockReportFetchItemGroup01OptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: reservedStockReportFetchItemGroup01OptionSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: reservedStockReportFetchItemGroup02OptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: reservedStockReportFetchItemGroup02OptionSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: reservedStockReportFetchItemGroup03OptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: reservedStockReportFetchItemGroup03OptionSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: reservedStockReportFetchStorageBinOptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: reservedStockReportFetchStorageBinOptionSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: reservedStockReportFetchStorageRowOptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: reservedStockReportFetchStorageRowOptionSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: reservedStockReportFetchStorageBayOptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: reservedStockReportFetchStorageBayOptionSuccess,

  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTION_LOADING]: reservedStockReportFetchLocationOptionLoading,
  [ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: reservedStockReportFetchLocationOptionSuccess
});
