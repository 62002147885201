import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    companyDetailResetTimestamp: null,
    companyDetailInitHeader: ['login_type'],
    companyDetailSetHdrId: ['hdrId'],
    companyDetailShowHeader: ['hdrId'],
    companyDetailShowDocumentLoading: ['boolean'],
    companyDetailShowHeaderSuccess: ['documentHeader'],
    
    companyDetailCreateHeader: ['formikBag', 'documentHeader'],
    companyDetailUpdateHeader: ['formikBag', 'documentHeader'],
    companyDetailUpdateHeaderSuccess: ['documentHeader'],
});

export const CompanyDetailTypes = Types;
export default Creators;