/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { OutbOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const outbOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const outbOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const outbOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const outbOrdDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const outbOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const outbOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const outbOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const outbOrdDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const outbOrdDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const outbOrdDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const outbOrdDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const outbOrdDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const outbOrdDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const outbOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const outbOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const outbOrdDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const outbOrdDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const outbOrdDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const outbOrdDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const outbOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const outbOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_RESET_TIMESTAMP]: outbOrdDetailResetTimestamp,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_SET_HDR_ID]: outbOrdDetailSetHdrId,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_SHOW_HEADER_SUCCESS]: outbOrdDetailShowHeaderSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: outbOrdDetailUpdateDocumentSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: outbOrdDetailShowDocumentLoading,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: outbOrdDetailShowDetailsSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_SET_DETAIL_VISIBLE]: outbOrdDetailSetDetailVisible,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_SET_DOCUMENT_DETAIL]: outbOrdDetailSetDocumentDetail,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_UPDATE_DETAILS]: outbOrdDetailUpdateDetails,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_CREATE_DETAIL]: outbOrdDetailCreateDetail,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: outbOrdDetailFetchSalesmanOptionLoading,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: outbOrdDetailFetchSalesmanOptionSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: outbOrdDetailFetchDeliveryPointOptionLoading,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: outbOrdDetailFetchDeliveryPointOptionSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: outbOrdDetailFetchCreditTermOptionLoading,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: outbOrdDetailFetchCreditTermOptionSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: outbOrdDetailFetchCurrencyOptionLoading,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: outbOrdDetailFetchCurrencyOptionSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_ITEM_OPTION_LOADING]: outbOrdDetailFetchItemOptionLoading,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: outbOrdDetailFetchItemOptionSuccess,

  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_UOM_OPTION_LOADING]: outbOrdDetailFetchUomOptionLoading,
  [OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_UOM_OPTION_SUCCESS]: outbOrdDetailFetchUomOptionSuccess
});
