import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { PropTypes } from 'prop-types';

const LineChartComponent = props => {
  const { data, xDataKey, maxValue, lineDataKey } = props;

  return (
    <ResponsiveContainer width="99%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey} />
        <YAxis domain={[0, maxValue]} tickCount={10} allowDataOverflow />
        <Tooltip />
        <Line type="monotone" dataKey={lineDataKey} stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types

  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  xDataKey: PropTypes.string,
  maxValue: PropTypes.number,
  lineDataKey: PropTypes.string
};

LineChartComponent.defaultProps = {
  data: [],
  xDataKey: '',
  maxValue: 0,
  lineDataKey: ''
};

export default LineChartComponent;
