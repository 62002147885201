import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrd01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd01GoToDocument: ['hdrId'],
  outbOrd01FetchOutbOrd01: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd01FetchOutbOrd01Loading: ['boolean'],
  outbOrd01FetchOutbOrd01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  outbOrd01AddSelectedDocuments: ['selectedDocuments'],
  outbOrd01RemoveSelectedDocuments: ['selectedDocuments'],
  outbOrd01SetWorkspaceVisible: ['boolean'],
  outbOrd01CreateOutbOrd01: ['hdrIds'],
  outbOrd01CreateOutbOrd01Loading: ['boolean'],
  outbOrd01CreateOutbOrd01Success: ['newDocuments'],

  outbOrd01SetExpandedRows: ['expandedRows']
});

export const OutbOrd01Types = Types;
export default Creators;
