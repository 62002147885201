import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cycleCountAnalysisReportInitCycleCountAnalysis: ['siteFlowId'],
  cycleCountAnalysisReportInitCycleCountAnalysisSuccess: ['criteria'],
  cycleCountAnalysisReportCycleCountAnalysis: ['formikBag', 'siteFlowId', 'criteria'],
  cycleCountAnalysisReportCycleCountAnalysisSuccess: ['criteria', 'reportData'],
  cycleCountAnalysisReportReportLoading: ['boolean'],

  cycleCountAnalysisReportFetchItemOptions: ['search'],
  cycleCountAnalysisReportFetchItemOptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchItemOptionSuccess: ['options'],

  cycleCountAnalysisReportFetchItemGroup01Options: ['search'],
  cycleCountAnalysisReportFetchItemGroup01OptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchItemGroup01OptionSuccess: ['options'],

  cycleCountAnalysisReportFetchItemGroup02Options: ['search'],
  cycleCountAnalysisReportFetchItemGroup02OptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchItemGroup02OptionSuccess: ['options'],

  cycleCountAnalysisReportFetchItemGroup03Options: ['search'],
  cycleCountAnalysisReportFetchItemGroup03OptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchItemGroup03OptionSuccess: ['options'],

  cycleCountAnalysisReportFetchStorageBinOptions: ['siteFlowId', 'search'],
  cycleCountAnalysisReportFetchStorageBinOptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchStorageBinOptionSuccess: ['options'],

  cycleCountAnalysisReportFetchStorageRowOptions: ['siteFlowId', 'search'],
  cycleCountAnalysisReportFetchStorageRowOptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchStorageRowOptionSuccess: ['options'],

  cycleCountAnalysisReportFetchStorageBayOptions: ['siteFlowId', 'search'],
  cycleCountAnalysisReportFetchStorageBayOptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchStorageBayOptionSuccess: ['options'],

  cycleCountAnalysisReportFetchLocationOptions: ['siteFlowId', 'search'],
  cycleCountAnalysisReportFetchLocationOptionLoading: ['boolean'],
  cycleCountAnalysisReportFetchLocationOptionSuccess: ['options']
});

export const CycleCountAnalysisReportTypes = Types;
export default Creators;
