import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  promoSync01ShowSyncSetting: ['divisionId'],
  promoSync01ShowSyncSettingLoading: ['boolean'],
  promoSync01ShowSyncSettingSuccess: ['syncSetting'],
  promoSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  promoSync01ShowBatchJobStatus: null,
  promoSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  promoSync01SyncNow: ['formikBag', 'divisionId'],
  promoSync01SyncLoading: ['boolean']
});

export const PromoSync01Types = Types;
export default Creators;
