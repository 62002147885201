import { put, call } from 'redux-saga/effects';
import { notification } from 'antd';
// import ApiService from '../Services/ApiService';
// import AppActions from '../Stores/App/Actions';
import StorageBinProcessActions from '../Stores/StorageBinProcess/Actions';

// const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* storageBinProcessFetchStorageBinProcess() {
  try {
    yield put(StorageBinProcessActions.storageBinProcessFetchStorageBinProcessLoading(true));

    const result = [
      { proc_type: 'STORAGE_BIN_LIST_01', icon: 'fa fa-cubes' },
      { proc_type: 'STORAGE_BIN_EXCEL_01', icon: 'fa fa-file-excel-o' }
    ];
    yield put(StorageBinProcessActions.storageBinProcessFetchStorageBinProcessSuccess(result));

    /*
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `site/indexItemFlow/${siteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    
    if (result.isSuccess === true) {
      yield put(StorageBinProcessActions.storageBinProcessFetchStorageBinProcessSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
    */
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageBinProcessActions.storageBinProcessFetchStorageBinProcessLoading(false));
  }
}
