/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { UserList02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const userList02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const userList02FetchUserList02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const userList02FetchUserList02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const userList02AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const userList02RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const userList02SetManageDivisionVisible = (state, { document, boolean }) => ({
  ...state,
  selectedDocument: document,
  manageDivisionIsVisible: boolean
});

export const userList02ManageDivisionLoading = (state, { boolean }) => ({
  ...state,
  assignIsLoading: boolean
});

export const userList02ManageDivisionSuccess = (state, { divisions }) => ({
  ...state,
  selectedDocument: {
    ...state.selectedDocument,
    divisions
  }
});

export const userList02FetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const userList02FetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const userList02SetDivisionOption = (state, { option }) => ({
  ...state,
  divisionOption: option
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UserList02Types.USER_LIST02_RESET_TIMESTAMP]: userList02ResetTimestamp,
  [UserList02Types.USER_LIST02_FETCH_USER_LIST02_LOADING]: userList02FetchUserList02Loading,
  [UserList02Types.USER_LIST02_FETCH_USER_LIST02_SUCCESS]: userList02FetchUserList02Success,
  [UserList02Types.USER_LIST02_ADD_SELECTED_DOCUMENTS]: userList02AddSelectedDocuments,
  [UserList02Types.USER_LIST02_REMOVE_SELECTED_DOCUMENTS]: userList02RemoveSelectedDocuments,
  [UserList02Types.USER_LIST02_SET_MANAGE_DIVISION_VISIBLE]: userList02SetManageDivisionVisible,
  [UserList02Types.USER_LIST02_MANAGE_DIVISION_LOADING]: userList02ManageDivisionLoading,
  [UserList02Types.USER_LIST02_MANAGE_DIVISION_SUCCESS]: userList02ManageDivisionSuccess,

  [UserList02Types.USER_LIST02_FETCH_DIVISION_OPTION_LOADING]: userList02FetchDivisionOptionLoading,
  [UserList02Types.USER_LIST02_FETCH_DIVISION_OPTION_SUCCESS]: userList02FetchDivisionOptionSuccess,
  [UserList02Types.USER_LIST02_SET_DIVISION_OPTION]: userList02SetDivisionOption
});
