import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Alert, Card, Row, Col, Table, Button, Select } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import PromoIndexActions from '../../Stores/PromoIndex/Actions';

const { Option } = Select;

class PromoAllIndexScreen extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectFocus = this.handleSelectFocus.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, resetTimestamp } = this.props;
    if (curDivisionId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchAllPromotions,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchAllPromotions(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, promotions, goToDocument, setManagePhotoVisible, goToAudit } = this.props;
    return [
      {
        width: 60,
        align: 'left',
        title: intl.formatMessage({ id: 'order_by' }),
        // sort field
        dataIndex: 'sequence',
        ...AntDesignTable.getColumnSortProps(sorts, 'sequence'),
        // filter field
        key: 'sequence',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'order_by' }),
          'sequence',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => 
          <Select
            showSearch
            value={record.sequence}
            style={{width: 75}}
            optionFilterProp="children"
            onFocus={this.handleSelectFocus}
            onChange={this.handleSelectChange}
            filterOption={(input, option) => 
              option.props.children.toString().indexOf(input.toString()) >= 0
            }
          >
            {promotions.map((promotions) => 
              <Option value={promotions.sequence} key={promotions.sequence}>{promotions.sequence}</Option>
            )}
          </Select>
      },
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'type' }),
        // sort field
        dataIndex: 'type',
        ...AntDesignTable.getColumnSortProps(sorts, 'type'),
        // filter field
        key: 'type',
        filters,
        render: (text, record) => (
          <>
          {record.type == 0 ?
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{intl.formatMessage({ id: 'discount_percent' })}</span> :
          record.type == 1 ?
          <span style={{backgroundColor: '#001529', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{intl.formatMessage({ id: 'discount_fixed' })}</span> :
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>other</span>}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.code
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc_01' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.desc_01
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_02'),
        // filter field
        key: 'desc_02',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc_02' }),
          'desc_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.desc_02
      },
      {
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon="edit"
              size="small"
              onClick={() => goToDocument(record.id)}
            />
            <Button
              type="dashed"
              size="small"
              icon="picture"
              onClick={() => setManagePhotoVisible(record, true)}
            />
            <Button type="dashed" icon="audit" size="small" onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  handleSelectChange(id) {
    const { reorderPromo } = this.props
    
    reorderPromo(id)
  }

  handleSelectFocus() {
    const { setSelectedPromo } = this.props
    setSelectedPromo(parseInt(document.activeElement.children[0].children[0].innerHTML))
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      promotions,
      fetchIsLoading,
    } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'all_promotions' })}>
        <Alert type="warning" message="Changing the order of the promotions will effect the promotion banner carousel in the BizOrder app."/>
        <Table
          size="small"
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={promotions}
          loading={fetchIsLoading}
          bordered
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </Card>
    );
  }
}

PromoAllIndexScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,

  promotions: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchAllPromotions: PropTypes.func,
  reorderPromo: PropTypes.func,
  setSelectedPromo: PropTypes.func,
};

PromoAllIndexScreen.defaultProps = {
  intl: {},
  promotions: [],
  
  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,

  resetTimestamp() {},
  goToDocument() {},

  fetchAllPromotions() {},
  reorderPromo() {},
  setSelectedPromo() {},
};

const mapStateToProps = state => ({
  timestamp: state.promoIndex.timestamp,

  promotions: state.promoIndex.promotions,

  sorts: state.itemGroup01List01.sorts,
  filters: state.itemGroup01List01.filters,

  currentPage: state.promoIndex.currentPage,
  pageSize: state.promoIndex.pageSize,
  total: state.promoIndex.total,
  fetchIsLoading: state.promoIndex.fetchIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PromoIndexActions.promoIndexResetTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  fetchAllPromotions: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PromoIndexActions.promoIndexFetchAllPromotions(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  reorderPromo: (promoId) => dispatch(PromoIndexActions.promoIndexReorderPromotions(promoId)),
  setSelectedPromo: (selectedPromo) => dispatch(PromoIndexActions.promoIndexSetSelectedPromotion(selectedPromo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PromoAllIndexScreen));
