import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';

import DeliveryPointActions from '../Stores/DeliveryPoint/Actions';
import DebtorList01Actions from '../Stores/DebtorList01/Actions';

const getAppStore = state => state.app;

export function* deliveryPointCreate({ formikBag, debtorId, documentDetail }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);
    const postData = {
      data: { ...documentDetail, debtor_id: debtorId, code: '' }
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(DeliveryPointActions.deliveryPointSetVisible(false));
      yield put(DebtorList01Actions.debtorList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* deliveryPointUpdate({ formikBag, documentDetail }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);
    const postData = {
      data: { ...documentDetail }
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `deliveryPoint/updateModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(DeliveryPointActions.deliveryPointSetVisible(false));
      yield put(DebtorList01Actions.debtorList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* deliveryPointFetchStateOptions({ search }) {
  try {
    yield put(DeliveryPointActions.deliveryPointFetchStateOptionsLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: {}
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `state/select2`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.desc_01}`
      }));

      yield put(DeliveryPointActions.deliveryPointFetchStateOptionsSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointActions.deliveryPointFetchStateOptionsLoading(false));
  }
}

export function* deliveryPointFetchAreaOptions({ stateId, search }) {
  try {
    yield put(DeliveryPointActions.deliveryPointFetchAreaOptionsLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: {
        state_id: stateId
      },
      pageSize: 100
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `area/select2`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(DeliveryPointActions.deliveryPointFetchAreaOptionsSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointActions.deliveryPointFetchAreaOptionsLoading(false));
  }
}

export function* deliveryPointChangeState({ formikBag, stateId }) {
  formikBag.setSubmitting(true);
  formikBag.setFieldValue('area_select2', {
    value: 0,
    label: ''
  });
  yield put(DeliveryPointActions.deliveryPointFetchAreaOptions(stateId, ''));
  formikBag.setSubmitting(false);
}

export function* deliveryPointChangeArea({ formikBag, areaId }) {
  formikBag.setSubmitting(true);
  formikBag.setSubmitting(false);
}
