import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob170201ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob170201GoToDocument: ['hdrId'],
  whseJob170201FetchWhseJob170201: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob170201FetchWhseJob170201Loading: ['boolean'],
  whseJob170201FetchWhseJob170201Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob170201AddSelectedDocuments: ['selectedDocuments'],
  whseJob170201RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob170201SetWorkspaceVisible: ['boolean'],
  whseJob170201PrintWhseJob170201: ['siteFlowId', 'hdrIds'],
  whseJob170201PrintWhseJob170201Loading: ['boolean'],

  whseJob170201SetExpandedRows: ['expandedRows']
});

export const WhseJob170201Types = Types;
export default Creators;
