import { takeLatest, all } from 'redux-saga/effects';

import { AdvShipDetailTypes } from '../Stores/AdvShipDetail/Actions';
import { AdvShipIndexTypes } from '../Stores/AdvShipIndex/Actions';
import { AdvShipSync01Types } from '../Stores/AdvShipSync01/Actions';
import { AppTypes } from '../Stores/App/Actions';
import { AuditRes01Types } from '../Stores/AuditRes01/Actions';
import { AuditUser01Types } from '../Stores/AuditUser01/Actions';
import { BinTrf02Types } from '../Stores/BinTrf02/Actions';
import { BinTrfDetailTypes } from '../Stores/BinTrfDetail/Actions';
import { BinTrfIndexTypes } from '../Stores/BinTrfIndex/Actions';
import { BinTrfProcessTypes } from '../Stores/BinTrfProcess/Actions';
import { CartDetailTypes } from '../Stores/CartDetail/Actions';
import { CartIndexTypes } from '../Stores/CartIndex/Actions';
import { CompanyDetailTypes } from '../Stores/CompanyDetail/Actions';
import { CompanyListTypes } from '../Stores/CompanyIndex/Actions';
import { CountAdj01Types } from '../Stores/CountAdj01/Actions';
import { CountAdjAnalysisReportTypes } from '../Stores/CountAdjAnalysisReport/Actions';
import { CountAdjDetailTypes } from '../Stores/CountAdjDetail/Actions';
import { CountAdjIndexTypes } from '../Stores/CountAdjIndex/Actions';
import { CycleCount02DetailTypes } from '../Stores/CycleCount02Detail/Actions';
import { CycleCount02Types } from '../Stores/CycleCount02/Actions';
import { CycleCount03Types } from '../Stores/CycleCount03/Actions';
import { CycleCountAnalysisReportTypes } from '../Stores/CycleCountAnalysisReport/Actions';
import { CycleCountDetailTypes } from '../Stores/CycleCountDetail/Actions';
import { CycleCountIndexTypes } from '../Stores/CycleCountIndex/Actions';
import { DashboardTypes } from '../Stores/Dashboard/Actions';
import { DebtorDetailTypes } from '../Stores/DebtorDetail/Actions';
import { DebtorList01Types } from '../Stores/DebtorList01/Actions';
import { DebtorProcessTypes } from '../Stores/DebtorProcess/Actions';
import { DebtorSync01Types } from '../Stores/DebtorSync01/Actions';
import { DeliveryPointList01Types } from '../Stores/DeliveryPointList01/Actions';
import { DeliveryPointProcessTypes } from '../Stores/DeliveryPointProcess/Actions';
import { DeliveryPointTypes } from '../Stores/DeliveryPoint/Actions';
import { DivisionDetailTypes } from '../Stores/DivisionDetail/Actions';
import { DivisionListTypes } from '../Stores/DivisionIndex/Actions';
import { GdsDelProcessTypes } from '../Stores/GdsDelProcess/Actions';
import { GdsRcpt01Types } from '../Stores/GdsRcpt01/Actions';
import { GdsRcpt0201Types } from '../Stores/GdsRcpt0201/Actions';
import { GdsRcpt02Types } from '../Stores/GdsRcpt02/Actions';
import { GdsRcptDetailTypes } from '../Stores/GdsRcptDetail/Actions';
import { GdsRcptIndexTypes } from '../Stores/GdsRcptIndex/Actions';
import { GdsRcptProcessTypes } from '../Stores/GdsRcptProcess/Actions';
import { InbOrd01Types } from '../Stores/InbOrd01/Actions';
import { InbOrd02Types } from '../Stores/InbOrd02/Actions';
import { InbOrdDetailTypes } from '../Stores/InbOrdDetail/Actions';
import { InbOrdIndexTypes } from '../Stores/InbOrdIndex/Actions';
import { InvAuditProcessTypes } from '../Stores/InvAuditProcess/Actions';
import { InvDoc0101Types } from '../Stores/InvDoc0101/Actions';
import { InvDoc0102Types } from '../Stores/InvDoc0102/Actions';
import { InvDoc01Types } from '../Stores/InvDoc01/Actions';
import { ItemDetailTypes } from '../Stores/ItemDetail/Actions';
import { ItemExcel01Types } from '../Stores/ItemExcel01/Actions';
import { ItemGroup01List01Types } from '../Stores/ItemGroup01List01/Actions';
import { ItemGroup02List01Types } from '../Stores/ItemGroup02List01/Actions';
import { ItemGroup03List01Types } from '../Stores/ItemGroup03List01/Actions';
import { ItemList01Types } from '../Stores/ItemList01/Actions';
import { ItemPriceTypes } from '../Stores/ItemPrice/Actions';
import { ItemProcessTypes } from '../Stores/ItemProcess/Actions';
import { ItemSync01Types } from '../Stores/ItemSync01/Actions';
import { OutbOrd01Types } from '../Stores/OutbOrd01/Actions';
import { OutbOrdAnalysisReportTypes } from '../Stores/OutbOrdAnalysisReport/Actions';
import { OutbOrdDetailTypes } from '../Stores/OutbOrdDetail/Actions';
import { OutbOrdIndexTypes } from '../Stores/OutbOrdIndex/Actions';
import { PalletLabelList01Types } from '../Stores/PalletLabelList01/Actions';
import { PalletLabelProcessTypes } from '../Stores/PalletLabelProcess/Actions';
import { PickFaceStrategyExcel01Types } from '../Stores/PickFaceStrategyExcel01/Actions';
import { PickFaceStrategyList01Types } from '../Stores/PickFaceStrategyList01/Actions';
import { PickFaceStrategyProcessTypes } from '../Stores/PickFaceStrategyProcess/Actions';
import { PickList01Types } from '../Stores/PickList01/Actions';
import { PickListDetailTypes } from '../Stores/PickListDetail/Actions';
import { PickListIndexTypes } from '../Stores/PickListIndex/Actions';
import { PromoIndexTypes } from '../Stores/PromoIndex/Actions';
import { PromoDetailTypes } from '../Stores/PromoDetail/Actions';
import { PromoDiscListTypes } from '../Stores/PromoDiscIndex/Actions';
import { PromoFocListTypes } from '../Stores/PromoFocIndex/Actions';
import { PromoProcessTypes } from '../Stores/PromoProcess/Actions';
import { PromoSync01Types } from '../Stores/PromoSync01/Actions';
import { PurchaseProcessTypes } from '../Stores/PurchaseProcess/Actions';
import { PutAway01Types } from '../Stores/PutAway01/Actions';
import { PutAwayDetailTypes } from '../Stores/PutAwayDetail/Actions';
import { PutAwayIndexTypes } from '../Stores/PutAwayIndex/Actions';
import { ReservedStockReportTypes } from '../Stores/ReservedStockReport/Actions';
import { RoleExcel01Types } from '../Stores/RoleExcel01/Actions';
import { RoleList01Types } from '../Stores/RoleList01/Actions';
import { RoleProcessTypes } from '../Stores/RoleProcess/Actions';
import { RtnRcptDetailTypes } from '../Stores/RtnRcptDetail/Actions';
import { RtnRcptIndexTypes } from '../Stores/RtnRcptIndex/Actions';
import { RtnRcptProcessTypes } from '../Stores/RtnRcptProcess/Actions';
import { RtnRcptSync01Types } from '../Stores/RtnRcptSync01/Actions';
import { SalesProcessTypes } from '../Stores/SalesProcess/Actions';
import { SlsInvDetailTypes } from '../Stores/SlsInvDetail/Actions';
import { SlsInvIndexTypes } from '../Stores/SlsInvIndex/Actions';
import { SlsInvSync01Types } from '../Stores/SlsInvSync01/Actions';
import { SlsInvSync02Types } from '../Stores/SlsInvSync02/Actions';
import { SlsOrdDetailTypes } from '../Stores/SlsOrdDetail/Actions';
import { SlsOrdIndexTypes } from '../Stores/SlsOrdIndex/Actions';
import { SlsOrdSync01Types } from '../Stores/SlsOrdSync01/Actions';
import { SlsOrdSync02Types } from '../Stores/SlsOrdSync02/Actions';
import { SlsOrdSync03Types } from '../Stores/SlsOrdSync03/Actions';
import { SlsRtnDetailTypes } from '../Stores/SlsRtnDetail/Actions';
import { SlsRtnIndexTypes } from '../Stores/SlsRtnIndex/Actions';
import { SlsRtnProcessTypes } from '../Stores/SlsRtnProcess/Actions';
import { SlsRtnSync01Types } from '../Stores/SlsRtnSync01/Actions';
import { StartupTypes } from '../Stores/Startup/Actions';
import { StockBalanceReportTypes } from '../Stores/StockBalanceReport/Actions';
import { StockCardReportTypes } from '../Stores/StockCardReport/Actions';
import { StorageBinExcel01Types } from '../Stores/StorageBinExcel01/Actions';
import { StorageBinList01Types } from '../Stores/StorageBinList01/Actions';
import { StorageBinProcessTypes } from '../Stores/StorageBinProcess/Actions';
import { UserDetailTypes } from '../Stores/UserDetail/Actions';
import { UserExcel01Types } from '../Stores/UserExcel01/Actions';
import { UserExcel03Types } from '../Stores/UserExcel03/Actions';
import { UserList01Types } from '../Stores/UserList01/Actions';
import { UserList02Types } from '../Stores/UserList02/Actions';
import { UserList03Types } from '../Stores/UserList03/Actions';
import { UserProcessTypes } from '../Stores/UserProcess/Actions';
import { WarehouseMapTypes } from '../Stores/WarehouseMap/Actions';
import { WhseJob030102Types } from '../Stores/WhseJob030102/Actions';
import { WhseJob0301Types } from '../Stores/WhseJob0301/Actions';
import { WhseJob03DetailTypes } from '../Stores/WhseJob03Detail/Actions';
import { WhseJob140101Types } from '../Stores/WhseJob140101/Actions';
import { WhseJob1401Types } from '../Stores/WhseJob1401/Actions';
import { WhseJob14DetailTypes } from '../Stores/WhseJob14Detail/Actions';
import { WhseJob150101Types } from '../Stores/WhseJob150101/Actions';
import { WhseJob1501Types } from '../Stores/WhseJob1501/Actions';
import { WhseJob15DetailTypes } from '../Stores/WhseJob15Detail/Actions';
import { WhseJob160102Types } from '../Stores/WhseJob160102/Actions';
import { WhseJob1601Types } from '../Stores/WhseJob1601/Actions';
import { WhseJob16DetailTypes } from '../Stores/WhseJob16Detail/Actions';
import { WhseJob170201Types } from '../Stores/WhseJob170201/Actions';
import { WhseJob1702Types } from '../Stores/WhseJob1702/Actions';
import { WhseJob17DetailTypes } from '../Stores/WhseJob17Detail/Actions';
import { AuditLogTypes } from '../Stores/AuditLog/Actions';
 
import { startup } from './StartupSaga';
import {
  appAuthenticate,
  appAuthenticateSuccess,
  appTokenExpired,
  appPasswordExpired,
  appChangePassword,
  appChangePasswordSuccess,
  appHistoryGoBack,
  appLogout,
  appLogoutSuccess,
  appFetchSiteFlowOptions,
  appFetchDivisionOptions
} from './AppSaga';

import {
  companyListFetchCompanyList,
  companyListGoToAudit,
  companyListNewDocument,
  companyListGoToDocument
} from './CompanyIndexSaga';

import {
  companyDetailInitHeader,
  companyDetailShowHeader,
  companyDetailCreateHeader,
  companyDetailUpdateHeader
} from './CompanyDetailSaga';

import {
  dashboardFetchChartOneData,
  dashboardFetchChartTwoData,
  dashboardFetchChartThreeData,
  dashboardFetchChartFourData,
  dashboardFetchChartFiveData,
  dashboardFetchChartSixData,
  dashboardFetchSummaryChartData,
  dashboardUpdateModel,
  dashboardCreateModel,
  dashboardRetrieveModel,
  dashboardFetchId
} from './DashboardSaga';

import {
  divisionListFetchDivisionList,
  divisionListGoToAudit,
  divisionListNewDocument,
  divisionListGoToDocument
} from './DivisionIndexSaga';

import {
  divisionDetailInitHeader,
  divisionDetailShowHeader,
  divisionDetailCreateHeader,
  divisionDetailUpdateHeader,
  divisionDetailFetchCompanyOptions,
  divisionDetailShowSyncSetting,
  divisionDetailUpdateSyncSetting
} from './DivisionDetailSaga';

import {
  deliveryPointCreate,
  deliveryPointUpdate,
  deliveryPointFetchStateOptions,
  deliveryPointFetchAreaOptions,
  deliveryPointChangeState,
  deliveryPointChangeArea
} from './DeliveryPointSaga';

import { salesProcessFetchSalesProcess } from './SalesProcessSaga';
import {
  outbOrd01FetchOutbOrd01,
  outbOrd01CreateOutbOrd01,
  outbOrd01GoToDocument
} from './OutbOrd01Saga';
import { gdsDelProcessFetchGdsDelProcess } from './GdsDelProcessSaga';
import { gdsRcptProcessFetchGdsRcptProcess } from './GdsRcptProcessSaga';
import {
  gdsRcpt01FetchGdsRcpt01,
  gdsRcpt01FetchToStorageBinOptions,
  gdsRcpt01CreateGdsRcpt01,
  gdsRcpt01GoToDocument
} from './GdsRcpt01Saga';
import {
  pickList01FetchPickList01,
  pickList01FetchToStorageBinOptions,
  pickList01CreatePickList01,
  pickList01GoToDocument
} from './PickList01Saga';
import {
  pickListDetailInitHeader,
  pickListDetailShowHeader,
  pickListDetailTransitionToStatus,
  pickListDetailUpdateHeader,
  pickListDetailCreateHeader,
  pickListDetailShowDetails,
  pickListDetailFetchCompanyOptions,
  pickListDetailFetchFrStorageBinOptions,
  pickListDetailFetchQuantBalOptions,
  pickListDetailFetchUomOptions,
  pickListDetailChangeUom,
  pickListDetailFetchToStorageBinOptions,
  pickListDetailChangeQuantBal,
  pickListDetailUpdateDetails,
  pickListDetailDeleteDetail,
  pickListDetailCreateDetail
} from './PickListDetailSaga';

import {
  advShipDetailInitHeader,
  advShipDetailShowHeader,
  advShipDetailTransitionToStatus,
  advShipDetailUpdateHeader,
  advShipDetailCreateHeader,
  advShipDetailShowDetails,
  advShipDetailFetchPurchaserOptions,
  advShipDetailFetchBizPartnerOptions,
  advShipDetailFetchCreditTermOptions,
  advShipDetailFetchCurrencyOptions,
  advShipDetailChangeBizPartner,
  advShipDetailChangeCurrency,
  advShipDetailFetchItemOptions,
  advShipDetailChangeItem,
  advShipDetailChangeUom,
  advShipDetailFetchUomOptions,
  advShipDetailUpdateDetails,
  advShipDetailCreateDetail,
  advShipDetailDeleteDetail
} from './AdvShipDetailSaga';

import {
  outbOrdDetailInitHeader,
  outbOrdDetailShowHeader,
  outbOrdDetailTransitionToStatus,
  outbOrdDetailUpdateHeader,
  outbOrdDetailCreateHeader,
  outbOrdDetailShowDetails,
  outbOrdDetailFetchSalesmanOptions,
  outbOrdDetailFetchDeliveryPointOptions,
  outbOrdDetailFetchCreditTermOptions,
  outbOrdDetailFetchCurrencyOptions,
  outbOrdDetailChangeDeliveryPoint,
  outbOrdDetailChangeCurrency,
  outbOrdDetailFetchItemOptions,
  outbOrdDetailChangeItem,
  outbOrdDetailChangeUom,
  outbOrdDetailFetchUomOptions,
  outbOrdDetailUpdateDetails,
  outbOrdDetailCreateDetail,
  outbOrdDetailDeleteDetail
} from './OutbOrdDetailSaga';

import {
  inbOrdDetailInitHeader,
  inbOrdDetailShowHeader,
  inbOrdDetailTransitionToStatus,
  inbOrdDetailUpdateHeader,
  inbOrdDetailCreateHeader,
  inbOrdDetailShowDetails,
  inbOrdDetailFetchPurchaserOptions,
  inbOrdDetailFetchBizPartnerOptions,
  inbOrdDetailFetchCreditTermOptions,
  inbOrdDetailFetchCurrencyOptions,
  inbOrdDetailChangeBizPartner,
  inbOrdDetailChangeCurrency,
  inbOrdDetailFetchItemOptions,
  inbOrdDetailChangeItem,
  inbOrdDetailChangeUom,
  inbOrdDetailFetchUomOptions,
  inbOrdDetailUpdateDetails,
  inbOrdDetailCreateDetail,
  inbOrdDetailDeleteDetail
} from './InbOrdDetailSaga';

import {
  whseJob0301FetchWhseJob0301,
  whseJob0301CreateWhseJob0301,
  whseJob0301GoToDocument
} from './WhseJob0301Saga';

import {
  whseJob030102FetchWhseJob030102,
  whseJob030102PrintWhseJob030102,
  whseJob030102GoToWhseJobDocument
} from './WhseJob030102Saga';

import {
  invDoc01FetchInvDoc01,
  invDoc01CreateInvDoc01,
  invDoc01GoToOrderDoc
} from './InvDoc01Saga';

import {
  putAway01FetchPutAway01,
  putAway01CreatePutAway01,
  putAway01GoToDocument
} from './PutAway01Saga';

import { invDoc0101FetchInvDoc0101, invDoc0101PrintInvDoc0101 } from './InvDoc0101Saga';

import { invDoc0102FetchInvDoc0102, invDoc0102PrintInvDoc0102 } from './InvDoc0102Saga';

import {
  pickListIndexFetchPickListIndex,
  pickListIndexGoToDocument,
  pickListIndexGoToAudit
} from './PickListIndexSaga';

import {
  whseJob1401FetchWhseJob1401,
  whseJob1401CreateWhseJob1401,
  whseJob1401GoToDocument
} from './WhseJob1401Saga';

import {
  whseJob140101FetchWhseJob140101,
  whseJob140101PrintWhseJob140101,
  whseJob140101PrintHandlingUnit,
  whseJob140101GoToDocument
} from './WhseJob140101Saga';

import {
  gdsRcptDetailInitHeader,
  gdsRcptDetailShowHeader,
  gdsRcptDetailTransitionToStatus,
  gdsRcptDetailUpdateHeader,
  gdsRcptDetailCreateHeader,
  gdsRcptDetailShowDetails,
  gdsRcptDetailFetchCompanyOptions,
  gdsRcptDetailFetchItemOptions,
  gdsRcptDetailChangeItem,
  gdsRcptDetailFetchItemBatchOptions,
  gdsRcptDetailChangeItemBatch,
  gdsRcptDetailFetchUomOptions,
  gdsRcptDetailChangeUom,
  gdsRcptDetailFetchItemCond01Options,
  gdsRcptDetailFetchToStorageBinOptions,
  gdsRcptDetailFetchToHandlingUnitOptions,
  gdsRcptDetailUpdateDetails,
  gdsRcptDetailDeleteDetail,
  gdsRcptDetailCreateDetail
} from './GdsRcptDetailSaga';

import {
  whseJob1501FetchWhseJob1501,
  whseJob1501CreateWhseJob1501,
  whseJob1501GoToDocument
} from './WhseJob1501Saga';

import {
  whseJob150101FetchWhseJob150101,
  whseJob150101PrintWhseJob150101,
  whseJob150101GoToDocument
} from './WhseJob150101Saga';

import { rtnRcptProcessFetchRtnRcptProcess } from './RtnRcptProcessSaga';

import {
  gdsRcpt02FetchGdsRcpt02,
  gdsRcpt02CreateGdsRcpt02,
  gdsRcpt02GoToDocument
} from './GdsRcpt02Saga';

import {
  gdsRcpt0201FetchGdsRcpt0201,
  gdsRcpt0201PrintGdsRcpt0201,
  gdsRcpt0201GoToDocument
} from './GdsRcpt0201Saga';

import {
  cycleCount03FetchCycleCount03,
  cycleCount03PrintCycleCount03,
  cycleCount03GoToDocument,
  cycleCount03NewDocument
} from './CycleCount03Saga';

import { invAuditProcessFetchInvAuditProcess } from './InvAuditProcessSaga';

import {
  whseJob160102FetchWhseJob160102,
  whseJob160102PrintWhseJob160102,
  whseJob160102GoToDocument
} from './WhseJob160102Saga';

import {
  cycleCountDetailInitHeader,
  cycleCountDetailShowHeader,
  cycleCountDetailTransitionToStatus,
  cycleCountDetailUpdateHeader,
  cycleCountDetailCreateHeader,
  cycleCountDetailShowDetails,
  cycleCountDetailFetchCompanyOptions,
  cycleCountDetailFetchItemOptions,
  cycleCountDetailChangeItem,
  cycleCountDetailFetchItemBatchOptions,
  cycleCountDetailChangeItemBatch,
  cycleCountDetailFetchUomOptions,
  cycleCountDetailChangeUom,
  cycleCountDetailFetchStorageBinOptions,
  cycleCountDetailFetchHandlingUnitOptions,
  cycleCountDetailUpdateDetails,
  cycleCountDetailDeleteDetail,
  cycleCountDetailCreateDetail,
  cycleCountDetailFetchStorageRowOptions,
  cycleCountDetailFetchStorageBayOptions,
  cycleCountDetailCreateJobDetail,
  cycleCountDetailDeleteJobDetail
} from './CycleCountDetailSaga';

import {
  whseJob1601FetchWhseJob1601,
  whseJob1601CreateWhseJob1601,
  whseJob1601GoToDocument
} from './WhseJob1601Saga';

import {
  cycleCount02DetailShowDetails,
  cycleCount02DetailUpdateRecount,
  cycleCount02DetailAutoConfirm,
  cycleCount02DetailCreateCycleCount02
} from './CycleCount02DetailSaga';

import { cycleCount02FetchCycleCount02, cycleCount02GoToDocument } from './CycleCount02Saga';

import {
  countAdj01FetchCountAdj01,
  countAdj01CreateCountAdj01,
  countAdj01GoToDocument
} from './CountAdj01Saga';

import {
  stockBalanceReportInitStockBalance,
  stockBalanceReportStockBalance,
  stockBalanceReportFetchItemOptions,
  stockBalanceReportFetchItemGroup01Options,
  stockBalanceReportFetchItemGroup02Options,
  stockBalanceReportFetchItemGroup03Options,
  stockBalanceReportFetchStorageBinOptions,
  stockBalanceReportFetchStorageRowOptions,
  stockBalanceReportFetchStorageBayOptions,
  stockBalanceReportFetchLocationOptions
} from './StockBalanceReportSaga';

import { warehouseMapFetchStorageBinList02 } from './WarehouseMapSaga';

import { debtorProcessFetchDebtorProcess } from './DebtorProcessSaga';
import {
  debtorList01FetchDebtorList01,
  debtorList01NewDocument,
  debtorList01GoToDocument,
  debtorList01ChangeStatus,
  debtorList01RemoveDivision,
  debtorList01AddDivision,
  debtorList01FetchDivisionOptions,
  debtorList01GoToAudit
} from './DebtorList01Saga';
import {
  debtorSync01ShowBatchJobStatus,
  debtorSync01ShowSyncSetting,
  debtorSync01SyncNow,
  debtorSync01UpdateSyncSetting
} from './DebtorSync01Saga';
import {
  debtorDetailInitHeader,
  debtorDetailShowHeader,
  debtorDetailTransitionToStatus,
  debtorDetailUpdateHeader,
  debtorDetailCreateHeader,
  debtorDetailDeleteHeader
} from './DebtorDetailSaga';

import { itemProcessFetchItemProcess } from './ItemProcessSaga';
import {
  itemList01FetchItemList01,
  itemList01GoToDocument,
  itemList01NewDocument,
  itemList01GoToAudit,
  itemList01UploadPhoto,
  itemList01RemovePhoto,
  itemList01UpdateItem
} from './ItemList01Saga';

import {
  itemDetailInitHeader,
  itemDetailShowHeader,
  itemDetailCreateHeader,
  itemDetailUpdateHeader,
  itemDetailFetchBrandOptions,
  itemDetailFetchCategoryOptions,
  itemDetailFetchManufacturerOptions
  // itemDetailFetchDebtorOptions
} from './ItemDetailSaga';

import {
  itemExcel01ShowBatchJobStatus,
  itemExcel01UploadExcel,
  itemExcel01DownloadExcel
} from './ItemExcel01Saga';

import {
  itemPriceCreate,
  itemPriceUpdate,
  itemPriceFetchUomOptions,
  itemPriceChangeUom,
  itemPriceDelete
} from './ItemPriceSaga';

import { storageBinProcessFetchStorageBinProcess } from './StorageBinProcessSaga';
import { storageBinList01FetchStorageBinList01 } from './StorageBinList01Saga';

import {
  itemSync01ShowBatchJobStatus,
  itemSync01ShowSyncSetting,
  itemSync01SyncNow,
  itemSync01SyncImage,
  itemSync01ResetImages,
  itemSync01UpdateSyncSetting
} from './ItemSync01Saga';

import {
  itemGroup01List01FetchItemGroup01ListAll,
  itemGroup01List01FetchItemGroup01List,
  itemGroup01List01UploadPhoto,
  itemGroup01List01RemovePhoto,
  itemGroup01List01ReorderBrands
} from './ItemGroup01IndexSaga';
import {
  itemGroup02List01FetchItemGroup02List,
  itemGroup02List01UploadPhoto,
  itemGroup02List01RemovePhoto
} from './ItemGroup02IndexSaga';
import {
  itemGroup03List01FetchItemGroup03List,
  itemGroup03List01UploadPhoto,
  itemGroup03List01RemovePhoto
} from './ItemGroup03IndexSaga';

import {
  storageBinExcel01ShowBatchJobStatus,
  storageBinExcel01UploadExcel,
  storageBinExcel01DownloadExcel
} from './StorageBinExcel01Saga';

import { palletLabelProcessFetchPalletLabelProcess } from './PalletLabelProcessSaga';
import {
  palletLabelList01FetchPalletLabelList01,
  palletLabelList01PrintPalletLabelList01
} from './PalletLabelList01Saga';

import { pickFaceStrategyProcessFetchPickFaceStrategyProcess } from './PickFaceStrategyProcessSaga';

import { pickFaceStrategyList01FetchPickFaceStrategyList01 } from './PickFaceStrategyList01Saga';

import {
  pickFaceStrategyExcel01ShowBatchJobStatus,
  pickFaceStrategyExcel01UploadExcel,
  pickFaceStrategyExcel01DownloadExcel
} from './PickFaceStrategyExcel01Saga';

import { userProcessFetchUserProcess } from './UserProcessSaga';

import {
  userList01FetchUserList01,
  userList01RemoveRole,
  userList01AddRole,
  userList01FetchRoleOptions,
  userList01GoToAudit,
  userList01NewDocument,
  userList01GoToDocument,
  userList01RemoveDivision,
  userList01AddDivision,
  userList01FetchDivisionOptions
} from './UserList01Saga';

import {
  userList02FetchUserList02,
  userList02RemoveDivision,
  userList02AddDivision,
  userList02FetchDivisionOptions,
  userList02GoToAudit,
  userList02NewDocument,
  userList02GoToDocument
} from './UserList02Saga';

import {
  userList03FetchUserList03,
  userList03GoToAudit,
  userList03NewDocument,
  userList03GoToDocument
} from './UserList03Saga';

import {
  userExcel01ShowBatchJobStatus,
  userExcel01UploadExcel,
  userExcel01DownloadExcel
} from './UserExcel01Saga';

import {
  userExcel03ShowBatchJobStatus,
  userExcel03UploadExcel,
  userExcel03DownloadExcel
} from './UserExcel03Saga';

import {
  userDetailInitHeader,
  userDetailShowHeader,
  userDetailCreateHeader,
  userDetailUpdateHeader,
  userDetailFetchDebtorOptions,
  userDetailGoToChangePassword,
  userDetailChangePassword
} from './UserDetailSaga';

import { roleProcessFetchRoleProcess } from './RoleProcessSaga';

import { roleList01FetchRoleList01 } from './RoleList01Saga';

import {
  roleExcel01ShowBatchJobStatus,
  roleExcel01UploadExcel,
  roleExcel01DownloadExcel
} from './RoleExcel01Saga';

import { slsRtnProcessFetchSlsRtnProcess } from './SlsRtnProcessSaga';

import {
  inbOrd02FetchInbOrd02,
  inbOrd02CreateInbOrd02,
  inbOrd02GoToDocument
} from './InbOrd02Saga';

import {
  outbOrdAnalysisReportInitOutbOrdAnalysis,
  outbOrdAnalysisReportOutbOrdAnalysis,
  outbOrdAnalysisReportFetchItemOptions,
  outbOrdAnalysisReportFetchItemGroup01Options,
  outbOrdAnalysisReportFetchItemGroup02Options,
  outbOrdAnalysisReportFetchItemGroup03Options,
  outbOrdAnalysisReportFetchDivisionOptions,
  outbOrdAnalysisReportFetchDeliveryPointOptions
} from './OutbOrdAnalysisReportSaga';

import { purchaseProcessFetchPurchaseProcess } from './PurchaseProcessSaga';

import {
  inbOrd01FetchInbOrd01,
  inbOrd01CreateInbOrd01,
  inbOrd01GoToDocument
} from './InbOrd01Saga';

import {
  whseJob03DetailInitHeader,
  whseJob03DetailShowHeader,
  whseJob03DetailTransitionToStatus,
  whseJob03DetailUpdateHeader,
  whseJob03DetailCreateHeader,
  whseJob03DetailShowDetails,
  whseJob03DetailFetchCompanyOptions,
  whseJob03DetailFetchFrStorageBinOptions,
  whseJob03DetailFetchQuantBalOptions,
  whseJob03DetailFetchUomOptions,
  whseJob03DetailChangeUom,
  whseJob03DetailFetchToStorageBinOptions,
  whseJob03DetailChangeQuantBal,
  whseJob03DetailFetchWorkerOptions,
  whseJob03DetailUpdateDetails,
  whseJob03DetailDeleteDetail,
  whseJob03DetailCreateDetail
} from './WhseJob03DetailSaga';

import {
  whseJob14DetailInitHeader,
  whseJob14DetailShowHeader,
  whseJob14DetailTransitionToStatus,
  whseJob14DetailUpdateHeader,
  whseJob14DetailCreateHeader,
  whseJob14DetailShowDetails,
  whseJob14DetailFetchCompanyOptions,
  whseJob14DetailFetchItemOptions,
  whseJob14DetailChangeItem,
  whseJob14DetailFetchItemBatchOptions,
  whseJob14DetailChangeItemBatch,
  whseJob14DetailFetchUomOptions,
  whseJob14DetailChangeUom,
  whseJob14DetailFetchItemCond01Options,
  whseJob14DetailFetchToStorageBinOptions,
  whseJob14DetailFetchToHandlingUnitOptions,
  whseJob14DetailFetchWorkerOptions,
  whseJob14DetailUpdateDetails,
  whseJob14DetailDeleteDetail,
  whseJob14DetailCreateDetail
} from './WhseJob14DetailSaga';

import {
  putAwayIndexFetchPutAwayIndex,
  putAwayIndexGoToDocument,
  putAwayIndexGoToAudit
} from './PutAwayIndexSaga';

import {
  putAwayDetailInitHeader,
  putAwayDetailShowHeader,
  putAwayDetailTransitionToStatus,
  putAwayDetailUpdateHeader,
  putAwayDetailCreateHeader,
  putAwayDetailShowDetails,
  putAwayDetailFetchFrStorageBinOptions,
  putAwayDetailFetchQuantBalOptions,
  putAwayDetailChangeQuantBal,
  putAwayDetailFetchUomOptions,
  putAwayDetailChangeUom,
  putAwayDetailFetchItemCond01Options,
  putAwayDetailFetchToStorageBinOptions,
  putAwayDetailUpdateDetails,
  putAwayDetailDeleteDetail,
  putAwayDetailCreateDetail
} from './PutAwayDetailSaga';

import {
  whseJob15DetailInitHeader,
  whseJob15DetailShowHeader,
  whseJob15DetailTransitionToStatus,
  whseJob15DetailUpdateHeader,
  whseJob15DetailCreateHeader,
  whseJob15DetailShowDetails,
  whseJob15DetailFetchFrStorageBinOptions,
  whseJob15DetailFetchQuantBalOptions,
  whseJob15DetailChangeQuantBal,
  whseJob15DetailFetchUomOptions,
  whseJob15DetailChangeUom,
  whseJob15DetailFetchItemCond01Options,
  whseJob15DetailFetchToStorageBinOptions,
  whseJob15DetailFetchWorkerOptions,
  whseJob15DetailUpdateDetails,
  whseJob15DetailDeleteDetail,
  whseJob15DetailCreateDetail
} from './WhseJob15DetailSaga';

import {
  advShipIndexFetchAdvShipIndex,
  advShipIndexGoToDocument,
  advShipIndexGoToAudit
} from './AdvShipIndexSaga';

import {
  cartIndexFetchCartIndex,
  cartIndexGoToDocument,
  cartIndexGoToAudit
} from './CartIndexSaga';

import {
  cartDetailInitHeader,
  cartDetailShowHeader,
  cartDetailTransitionToStatus,
  cartDetailUpdateHeader,
  cartDetailCreateHeader,
  cartDetailShowDetails,
  cartDetailFetchSalesmanOptions,
  cartDetailFetchDeliveryPointOptions,
  cartDetailFetchCreditTermOptions,
  cartDetailFetchCurrencyOptions,
  cartDetailChangeDeliveryPoint,
  cartDetailChangeCurrency,
  cartDetailFetchItemOptions,
  cartDetailChangeItem,
  cartDetailChangeUom,
  cartDetailFetchUomOptions,
  cartDetailUpdateDetails,
  cartDetailCreateDetail,
  cartDetailDeleteDetail
} from './CartDetailSaga';

import {
  slsOrdIndexFetchSlsOrdIndex,
  slsOrdIndexGoToDocument,
  slsOrdIndexGoToAudit
} from './SlsOrdIndexSaga';

import {
  slsOrdDetailInitHeader,
  slsOrdDetailShowHeader,
  slsOrdDetailTransitionToStatus,
  slsOrdDetailUpdateHeader,
  slsOrdDetailCreateHeader,
  slsOrdDetailShowDetails,
  slsOrdDetailFetchSalesmanOptions,
  slsOrdDetailFetchDeliveryPointOptions,
  slsOrdDetailFetchCreditTermOptions,
  slsOrdDetailFetchCurrencyOptions,
  slsOrdDetailChangeDeliveryPoint,
  slsOrdDetailChangeCurrency,
  slsOrdDetailFetchItemOptions,
  slsOrdDetailChangeItem,
  slsOrdDetailChangeUom,
  slsOrdDetailFetchUomOptions,
  slsOrdDetailUpdateDetails,
  slsOrdDetailCreateDetail,
  slsOrdDetailDeleteDetail
} from './SlsOrdDetailSaga';

import {
  slsInvIndexFetchSlsInvIndex,
  slsInvIndexGoToDocument,
  slsInvIndexGoToAudit
} from './SlsInvIndexSaga';

import {
  slsInvDetailInitHeader,
  slsInvDetailShowHeader,
  slsInvDetailTransitionToStatus,
  slsInvDetailUpdateHeader,
  slsInvDetailCreateHeader,
  slsInvDetailShowDetails,
  slsInvDetailFetchSalesmanOptions,
  slsInvDetailFetchDeliveryPointOptions,
  slsInvDetailFetchCreditTermOptions,
  slsInvDetailFetchCurrencyOptions,
  slsInvDetailChangeDeliveryPoint,
  slsInvDetailChangeCurrency,
  slsInvDetailFetchItemOptions,
  slsInvDetailChangeItem,
  slsInvDetailChangeUom,
  slsInvDetailFetchUomOptions,
  slsInvDetailUpdateDetails,
  slsInvDetailCreateDetail,
  slsInvDetailDeleteDetail
} from './SlsInvDetailSaga';

import {
  slsRtnIndexFetchSlsRtnIndex,
  slsRtnIndexGoToDocument,
  slsRtnIndexGoToAudit
} from './SlsRtnIndexSaga';

import {
  slsRtnDetailInitHeader,
  slsRtnDetailShowHeader,
  slsRtnDetailTransitionToStatus,
  slsRtnDetailUpdateHeader,
  slsRtnDetailCreateHeader,
  slsRtnDetailShowDetails,
  slsRtnDetailFetchSalesmanOptions,
  slsRtnDetailFetchDeliveryPointOptions,
  slsRtnDetailFetchCreditTermOptions,
  slsRtnDetailFetchCurrencyOptions,
  slsRtnDetailChangeDeliveryPoint,
  slsRtnDetailChangeCurrency,
  slsRtnDetailFetchItemOptions,
  slsRtnDetailChangeItem,
  slsRtnDetailChangeUom,
  slsRtnDetailFetchUomOptions,
  slsRtnDetailUpdateDetails,
  slsRtnDetailCreateDetail,
  slsRtnDetailDeleteDetail
} from './SlsRtnDetailSaga';

import {
  rtnRcptIndexFetchRtnRcptIndex,
  rtnRcptIndexGoToDocument,
  rtnRcptIndexGoToAudit
} from './RtnRcptIndexSaga';

import {
  rtnRcptDetailInitHeader,
  rtnRcptDetailShowHeader,
  rtnRcptDetailTransitionToStatus,
  rtnRcptDetailUpdateHeader,
  rtnRcptDetailCreateHeader,
  rtnRcptDetailShowDetails,
  rtnRcptDetailFetchSalesmanOptions,
  rtnRcptDetailFetchDeliveryPointOptions,
  rtnRcptDetailFetchCreditTermOptions,
  rtnRcptDetailFetchCurrencyOptions,
  rtnRcptDetailChangeDeliveryPoint,
  rtnRcptDetailChangeCurrency,
  rtnRcptDetailFetchItemOptions,
  rtnRcptDetailChangeItem,
  rtnRcptDetailChangeUom,
  rtnRcptDetailFetchUomOptions,
  rtnRcptDetailUpdateDetails,
  rtnRcptDetailCreateDetail,
  rtnRcptDetailDeleteDetail
} from './RtnRcptDetailSaga';

import {
  outbOrdIndexFetchOutbOrdIndex,
  outbOrdIndexGoToDocument,
  outbOrdIndexGoToAudit
} from './OutbOrdIndexSaga';

import {
  inbOrdIndexFetchInbOrdIndex,
  inbOrdIndexGoToDocument,
  inbOrdIndexGoToAudit
} from './InbOrdIndexSaga';

import {
  gdsRcptIndexFetchGdsRcptIndex,
  gdsRcptIndexGoToDocument,
  gdsRcptIndexGoToAudit
} from './GdsRcptIndexSaga';

import {
  cycleCountIndexFetchCycleCountIndex,
  cycleCountIndexGoToDocument,
  cycleCountIndexGoToAudit
} from './CycleCountIndexSaga';

import {
  countAdjIndexFetchCountAdjIndex,
  countAdjIndexGoToDocument,
  countAdjIndexNewDocument,
  countAdjIndexGoToAudit
} from './CountAdjIndexSaga';

import {
  countAdjDetailInitHeader,
  countAdjDetailShowHeader,
  countAdjDetailTransitionToStatus,
  countAdjDetailUpdateHeader,
  countAdjDetailCreateHeader,
  countAdjDetailShowDetails,
  countAdjDetailFetchCompanyOptions,
  countAdjDetailFetchItemOptions,
  countAdjDetailChangeItem,
  countAdjDetailFetchItemBatchOptions,
  countAdjDetailChangeItemBatch,
  countAdjDetailFetchUomOptions,
  countAdjDetailChangeUom,
  countAdjDetailFetchStorageBinOptions,
  countAdjDetailFetchHandlingUnitOptions,
  countAdjDetailUpdateDetails,
  countAdjDetailDeleteDetail,
  countAdjDetailCreateDetail,
  countAdjDetailFetchStorageRowOptions,
  countAdjDetailFetchStorageBayOptions,
  countAdjDetailFetchQuantBalOptions,
  countAdjDetailChangeQuantBal,
  countAdjDetailUploadExcel,
  countAdjDetailDownloadExcel
} from './CountAdjDetailSaga';

import {
  binTrfIndexFetchBinTrfIndex,
  binTrfIndexGoToDocument,
  binTrfIndexGoToAudit
} from './BinTrfIndexSaga';

import {
  binTrfDetailInitHeader,
  binTrfDetailShowHeader,
  binTrfDetailTransitionToStatus,
  binTrfDetailUpdateHeader,
  binTrfDetailCreateHeader,
  binTrfDetailShowDetails,
  binTrfDetailFetchItemOptions,
  binTrfDetailChangeItem,
  binTrfDetailFetchItemBatchOptions,
  binTrfDetailChangeItemBatch,
  binTrfDetailFetchUomOptions,
  binTrfDetailChangeUom,
  binTrfDetailFetchFrStorageBinOptions,
  binTrfDetailFetchToStorageBinOptions,
  binTrfDetailFetchHandlingUnitOptions,
  binTrfDetailUpdateDetails,
  binTrfDetailDeleteDetail,
  binTrfDetailCreateDetail,
  binTrfDetailFetchStorageRowOptions,
  binTrfDetailFetchStorageBayOptions,
  binTrfDetailFetchQuantBalOptions,
  binTrfDetailChangeQuantBal
} from './BinTrfDetailSaga';

import { binTrfProcessFetchBinTrfProcess } from './BinTrfProcessSaga';

import {
  binTrf02FetchBinTrf02,
  binTrf02PrintBinTrf02,
  binTrf02GoToDocument,
  binTrf02NewDocument
} from './BinTrf02Saga';

import {
  whseJob1702FetchWhseJob1702,
  whseJob1702CreateWhseJob1702,
  whseJob1702GoToDocument
} from './WhseJob1702Saga';

import {
  whseJob170201FetchWhseJob170201,
  whseJob170201PrintWhseJob170201,
  whseJob170201GoToDocument
} from './WhseJob170201Saga';

import {
  whseJob17DetailInitHeader,
  whseJob17DetailShowHeader,
  whseJob17DetailTransitionToStatus,
  whseJob17DetailUpdateHeader,
  whseJob17DetailCreateHeader,
  whseJob17DetailShowDetails,
  whseJob17DetailFetchItemOptions,
  whseJob17DetailChangeItem,
  whseJob17DetailFetchItemBatchOptions,
  whseJob17DetailChangeItemBatch,
  whseJob17DetailFetchUomOptions,
  whseJob17DetailChangeUom,
  whseJob17DetailFetchFrStorageBinOptions,
  whseJob17DetailFetchToStorageBinOptions,
  whseJob17DetailFetchHandlingUnitOptions,
  whseJob17DetailUpdateDetails,
  whseJob17DetailDeleteDetail,
  whseJob17DetailCreateDetail,
  whseJob17DetailFetchStorageRowOptions,
  whseJob17DetailFetchStorageBayOptions,
  whseJob17DetailFetchQuantBalOptions,
  whseJob17DetailChangeQuantBal,
  whseJob17DetailFetchWorkerOptions
} from './WhseJob17DetailSaga';

import {
  slsOrdSync01ShowBatchJobStatus,
  slsOrdSync01ShowSyncSetting,
  slsOrdSync01SyncNow,
  slsOrdSync01UpdateSyncSetting
} from './SlsOrdSync01Saga';

import {
  slsOrdSync02FetchSlsOrdIndex,
  slsOrdSync02FetchSlsOrdIndexSearch,
  slsOrdSync02GoToDocument,
  slsOrdSync02ShowBatchJobStatus,
  slsOrdSync02SyncNow
} from './SlsOrdSync02Saga';

import { slsOrdSync03ShowBatchJobStatus, slsOrdSync03SyncNow } from './SlsOrdSync03Saga';

import {
  slsInvSync01ShowBatchJobStatus,
  slsInvSync01ShowSyncSetting,
  slsInvSync01SyncNow,
  slsInvSync01UpdateSyncSetting
} from './SlsInvSync01Saga';

import {
  slsInvSync02FetchSlsOrdIndex,
  slsInvSync02FetchSlsOrdIndexSearch,
  slsInvSync02GoToDocument,
  slsInvSync02ShowBatchJobStatus,
  slsInvSync02SyncNow
} from './SlsInvSync02Saga';

import {
  slsRtnSync01ShowBatchJobStatus,
  slsRtnSync01ShowSyncSetting,
  slsRtnSync01SyncNow,
  slsRtnSync01UpdateSyncSetting
} from './SlsRtnSync01Saga';

import {
  rtnRcptSync01ShowBatchJobStatus,
  rtnRcptSync01ShowSyncSetting,
  rtnRcptSync01SyncNow,
  rtnRcptSync01UpdateSyncSetting
} from './RtnRcptSync01Saga';

import {
  advShipSync01ShowBatchJobStatus,
  advShipSync01ShowSyncSetting,
  advShipSync01SyncNow,
  advShipSync01UpdateSyncSetting
} from './AdvShipSync01Saga';

import {
  stockCardReportInitStockCard,
  stockCardReportStockCard,
  stockCardReportFetchItemOptions,
  stockCardReportFetchItemGroup01Options,
  stockCardReportFetchItemGroup02Options,
  stockCardReportFetchItemGroup03Options,
  stockCardReportFetchStorageBinOptions,
  stockCardReportFetchStorageRowOptions,
  stockCardReportFetchStorageBayOptions,
  stockCardReportFetchLocationOptions
} from './StockCardReportSaga';

import {
  reservedStockReportInitReservedStock,
  reservedStockReportReservedStock,
  reservedStockReportFetchItemOptions,
  reservedStockReportFetchItemGroup01Options,
  reservedStockReportFetchItemGroup02Options,
  reservedStockReportFetchItemGroup03Options,
  reservedStockReportFetchStorageBinOptions,
  reservedStockReportFetchStorageRowOptions,
  reservedStockReportFetchStorageBayOptions,
  reservedStockReportFetchLocationOptions
} from './ReservedStockReportSaga';

import {
  cycleCountAnalysisReportInitCycleCountAnalysis,
  cycleCountAnalysisReportCycleCountAnalysis,
  cycleCountAnalysisReportFetchItemOptions,
  cycleCountAnalysisReportFetchItemGroup01Options,
  cycleCountAnalysisReportFetchItemGroup02Options,
  cycleCountAnalysisReportFetchItemGroup03Options,
  cycleCountAnalysisReportFetchStorageBinOptions,
  cycleCountAnalysisReportFetchStorageRowOptions,
  cycleCountAnalysisReportFetchStorageBayOptions,
  cycleCountAnalysisReportFetchLocationOptions
} from './CycleCountAnalysisReportSaga';

import {
  countAdjAnalysisReportInitCountAdjAnalysis,
  countAdjAnalysisReportCountAdjAnalysis,
  countAdjAnalysisReportFetchItemOptions,
  countAdjAnalysisReportFetchItemGroup01Options,
  countAdjAnalysisReportFetchItemGroup02Options,
  countAdjAnalysisReportFetchItemGroup03Options,
  countAdjAnalysisReportFetchStorageBinOptions,
  countAdjAnalysisReportFetchStorageRowOptions,
  countAdjAnalysisReportFetchStorageBayOptions,
  countAdjAnalysisReportFetchLocationOptions
} from './CountAdjAnalysisReportSaga';

// DeliveryPointProcess
import { deliveryPointProcessFetchDeliveryPointProcess } from './DeliveryPointProcessSaga';

import { deliveryPointList01FetchDeliveryPointList01 } from './DeliveryPointList01Saga';

import { auditRes01FetchAuditRes01, auditRes01FetchAuditTypes } from './AuditRes01Saga';

import { auditUser01FetchAuditUser01 } from './AuditUser01Saga';

import { auditLogFetchAuditLog } from './AuditLogSaga';

import {
  whseJob16DetailInitHeader,
  whseJob16DetailShowHeader,
  whseJob16DetailTransitionToStatus,
  whseJob16DetailUpdateHeader,
  whseJob16DetailCreateHeader,
  whseJob16DetailShowDetails,
  whseJob16DetailFetchCompanyOptions,
  whseJob16DetailFetchItemOptions,
  whseJob16DetailChangeItem,
  whseJob16DetailFetchItemBatchOptions,
  whseJob16DetailChangeItemBatch,
  whseJob16DetailFetchUomOptions,
  whseJob16DetailChangeUom,
  whseJob16DetailFetchStorageBinOptions,
  whseJob16DetailFetchHandlingUnitOptions,
  whseJob16DetailUpdateDetails,
  whseJob16DetailDeleteDetail,
  whseJob16DetailCreateDetail,
  whseJob16DetailFetchStorageRowOptions,
  whseJob16DetailFetchStorageBayOptions,
  whseJob16DetailFetchWorkerOptions
} from './WhseJob16DetailSaga';

import {
  promoListFetchPromoDiscList,
  promoListFetchPromoFocList,
  promoListGoToDocument,
  promoListGoToBroadcast,
  promoListUploadPhoto,
  promoListRemovePhoto,
  promoListGoToAudit,
  promoListNewDocument,
  promoIndexFetchAllPromotions,
  promoIndexReorderPromotions
} from './PromoIndexSaga';

import {
  promoDetailBroadcastPromo,
  promoDetailFetchItemOptions,
  promoDetailFetchBrandOptions,
  promoDetailFetchCategoryOptions,
  promoDetailFetchManufacturerOptions,
  promoDetailFetchBroadcastDebtorOptions,
  promoDetailFetchBroadcastDebtorGroupOptions,
  promoDetailFetchDebtorOptions,
  promoDetailFetchDebtorGroupOptions,
  promoDetailCreateDetail,
  promoDetailUpdateDetails,
  promoDetailInitHeader,
  promoDetailCreateHeader,
  promoDetailShowHeader,
  promoDetailShowDetails,
  promoDetailShowDetailsMain,
  promoDetailShowDetailsAddOn,
  promoDetailUpdateHeader,
  promoDetailDeleteHeader,
  promoDetailDeleteDetail,
  promoDetailFetchVariantTypeOptions,
  promoDetailChangeItem,
  promoDetailChangeItemGroup,
  promoDetailFetchRuleTypeOptions,
  promoDetailCreateRule,
  promoDetailUpdateRule,
  promoDetailDeleteRule,
  promoDetailShowRules
} from './PromoDetailSaga';

import { promoProcessFetchPromoProcess } from './PromoProcessSaga';
import {
  promoSync01ShowBatchJobStatus,
  promoSync01ShowSyncSetting,
  promoSync01SyncNow,
  promoSync01UpdateSyncSetting
} from './PromoSync01Saga';

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    takeLatest(StartupTypes.STARTUP, startup),

    takeLatest(AppTypes.APP_AUTHENTICATE, appAuthenticate),
    takeLatest(AppTypes.APP_AUTHENTICATE_SUCCESS, appAuthenticateSuccess),
    takeLatest(AppTypes.APP_TOKEN_EXPIRED, appTokenExpired),
    takeLatest(AppTypes.APP_PASSWORD_EXPIRED, appPasswordExpired),
    takeLatest(AppTypes.APP_CHANGE_PASSWORD, appChangePassword),
    takeLatest(AppTypes.APP_CHANGE_PASSWORD_SUCCESS, appChangePasswordSuccess),
    takeLatest(AppTypes.APP_HISTORY_GO_BACK, appHistoryGoBack),
    takeLatest(AppTypes.APP_LOGOUT, appLogout),
    takeLatest(AppTypes.APP_LOGOUT_SUCCESS, appLogoutSuccess),
    takeLatest(AppTypes.APP_FETCH_SITE_FLOW_OPTIONS, appFetchSiteFlowOptions),
    takeLatest(AppTypes.APP_FETCH_DIVISION_OPTIONS, appFetchDivisionOptions),

    // company list
    takeLatest(CompanyListTypes.COMPANY_LIST_FETCH_COMPANY_LIST, companyListFetchCompanyList),
    takeLatest(CompanyListTypes.COMPANY_LIST_GO_TO_AUDIT, companyListGoToAudit),
    takeLatest(CompanyListTypes.COMPANY_LIST_NEW_DOCUMENT, companyListNewDocument),
    takeLatest(CompanyListTypes.COMPANY_LIST_GO_TO_DOCUMENT, companyListGoToDocument),

    // company detail
    takeLatest(CompanyDetailTypes.COMPANY_DETAIL_INIT_HEADER, companyDetailInitHeader),
    takeLatest(CompanyDetailTypes.COMPANY_DETAIL_SHOW_HEADER, companyDetailShowHeader),
    takeLatest(CompanyDetailTypes.COMPANY_DETAIL_CREATE_HEADER, companyDetailCreateHeader),
    takeLatest(CompanyDetailTypes.COMPANY_DETAIL_UPDATE_HEADER, companyDetailUpdateHeader),

    // dashboard detail
    takeLatest(DashboardTypes.DASHBOARD_FETCH_CHART_ONE_DATA, dashboardFetchChartOneData),
    takeLatest(DashboardTypes.DASHBOARD_FETCH_CHART_TWO_DATA, dashboardFetchChartTwoData),
    takeLatest(DashboardTypes.DASHBOARD_FETCH_CHART_THREE_DATA, dashboardFetchChartThreeData),
    takeLatest(DashboardTypes.DASHBOARD_FETCH_CHART_FOUR_DATA, dashboardFetchChartFourData),
    takeLatest(DashboardTypes.DASHBOARD_FETCH_CHART_FIVE_DATA, dashboardFetchChartFiveData),
    takeLatest(DashboardTypes.DASHBOARD_FETCH_CHART_SIX_DATA, dashboardFetchChartSixData),
    takeLatest(DashboardTypes.DASHBOARD_FETCH_SUMMARY_CHART_DATA, dashboardFetchSummaryChartData),
    takeLatest(DashboardTypes.DASHBOARD_UPDATE_MODEL, dashboardUpdateModel),
    takeLatest(DashboardTypes.DASHBOARD_CREATE_MODEL, dashboardCreateModel),
    takeLatest(DashboardTypes.DASHBOARD_RETRIEVE_MODEL, dashboardRetrieveModel),
    takeLatest(DashboardTypes.DASHBOARD_FETCH_ID, dashboardFetchId),

    // division list
    takeLatest(DivisionListTypes.DIVISION_LIST_FETCH_DIVISION_LIST, divisionListFetchDivisionList),
    takeLatest(DivisionListTypes.DIVISION_LIST_GO_TO_AUDIT, divisionListGoToAudit),
    takeLatest(DivisionListTypes.DIVISION_LIST_NEW_DOCUMENT, divisionListNewDocument),
    takeLatest(DivisionListTypes.DIVISION_LIST_GO_TO_DOCUMENT, divisionListGoToDocument),

    // division detail
    takeLatest(DivisionDetailTypes.DIVISION_DETAIL_INIT_HEADER, divisionDetailInitHeader),
    takeLatest(DivisionDetailTypes.DIVISION_DETAIL_SHOW_HEADER, divisionDetailShowHeader),
    takeLatest(DivisionDetailTypes.DIVISION_DETAIL_CREATE_HEADER, divisionDetailCreateHeader),
    takeLatest(DivisionDetailTypes.DIVISION_DETAIL_UPDATE_HEADER, divisionDetailUpdateHeader),
    takeLatest(
      DivisionDetailTypes.DIVISION_DETAIL_FETCH_COMPANY_OPTIONS,
      divisionDetailFetchCompanyOptions
    ),
    takeLatest(
      DivisionDetailTypes.DIVISION_DETAIL_SHOW_SYNC_SETTING,
      divisionDetailShowSyncSetting
    ),
    takeLatest(
      DivisionDetailTypes.DIVISION_DETAIL_UPDATE_SYNC_SETTING,
      divisionDetailUpdateSyncSetting
    ),

    takeLatest(SalesProcessTypes.SALES_PROCESS_FETCH_SALES_PROCESS, salesProcessFetchSalesProcess),

    takeLatest(OutbOrd01Types.OUTB_ORD01_FETCH_OUTB_ORD01, outbOrd01FetchOutbOrd01),
    takeLatest(OutbOrd01Types.OUTB_ORD01_CREATE_OUTB_ORD01, outbOrd01CreateOutbOrd01),
    takeLatest(OutbOrd01Types.OUTB_ORD01_GO_TO_DOCUMENT, outbOrd01GoToDocument),

    takeLatest(
      GdsDelProcessTypes.GDS_DEL_PROCESS_FETCH_GDS_DEL_PROCESS,
      gdsDelProcessFetchGdsDelProcess
    ),

    takeLatest(
      GdsRcptProcessTypes.GDS_RCPT_PROCESS_FETCH_GDS_RCPT_PROCESS,
      gdsRcptProcessFetchGdsRcptProcess
    ),

    // gdsRcpt01
    takeLatest(GdsRcpt01Types.GDS_RCPT01_GO_TO_DOCUMENT, gdsRcpt01GoToDocument),
    takeLatest(GdsRcpt01Types.GDS_RCPT01_FETCH_GDS_RCPT01, gdsRcpt01FetchGdsRcpt01),
    takeLatest(
      GdsRcpt01Types.GDS_RCPT01_FETCH_TO_STORAGE_BIN_OPTIONS,
      gdsRcpt01FetchToStorageBinOptions
    ),
    takeLatest(GdsRcpt01Types.GDS_RCPT01_CREATE_GDS_RCPT01, gdsRcpt01CreateGdsRcpt01),

    takeLatest(PickList01Types.PICK_LIST01_GO_TO_DOCUMENT, pickList01GoToDocument),
    takeLatest(PickList01Types.PICK_LIST01_FETCH_PICK_LIST01, pickList01FetchPickList01),
    takeLatest(
      PickList01Types.PICK_LIST01_FETCH_TO_STORAGE_BIN_OPTIONS,
      pickList01FetchToStorageBinOptions
    ),
    takeLatest(PickList01Types.PICK_LIST01_CREATE_PICK_LIST01, pickList01CreatePickList01),

    // pickListDetail
    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_INIT_HEADER, pickListDetailInitHeader),
    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_SHOW_HEADER, pickListDetailShowHeader),

    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_UPDATE_HEADER, pickListDetailUpdateHeader),
    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_CREATE_HEADER, pickListDetailCreateHeader),

    takeLatest(
      PickListDetailTypes.PICK_LIST_DETAIL_TRANSITION_TO_STATUS,
      pickListDetailTransitionToStatus
    ),

    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_SHOW_DETAILS, pickListDetailShowDetails),

    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_UPDATE_DETAILS, pickListDetailUpdateDetails),
    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_DELETE_DETAIL, pickListDetailDeleteDetail),
    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_CREATE_DETAIL, pickListDetailCreateDetail),

    takeLatest(
      PickListDetailTypes.PICK_LIST_DETAIL_FETCH_COMPANY_OPTIONS,
      pickListDetailFetchCompanyOptions
    ),

    takeLatest(
      PickListDetailTypes.PICK_LIST_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
      pickListDetailFetchFrStorageBinOptions
    ),

    takeLatest(
      PickListDetailTypes.PICK_LIST_DETAIL_FETCH_QUANT_BAL_OPTIONS,
      pickListDetailFetchQuantBalOptions
    ),
    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_CHANGE_QUANT_BAL, pickListDetailChangeQuantBal),

    takeLatest(
      PickListDetailTypes.PICK_LIST_DETAIL_FETCH_UOM_OPTIONS,
      pickListDetailFetchUomOptions
    ),
    takeLatest(PickListDetailTypes.PICK_LIST_DETAIL_CHANGE_UOM, pickListDetailChangeUom),

    takeLatest(
      PickListDetailTypes.PICK_LIST_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      pickListDetailFetchToStorageBinOptions
    ),

    // PickListIndex
    takeLatest(PickListIndexTypes.PICK_LIST_INDEX_GO_TO_DOCUMENT, pickListIndexGoToDocument),
    takeLatest(PickListIndexTypes.PICK_LIST_INDEX_GO_TO_AUDIT, pickListIndexGoToAudit),
    takeLatest(
      PickListIndexTypes.PICK_LIST_INDEX_FETCH_PICK_LIST_INDEX,
      pickListIndexFetchPickListIndex
    ),

    // advShipDetail
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_INIT_HEADER, advShipDetailInitHeader),
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_SHOW_HEADER, advShipDetailShowHeader),

    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_UPDATE_HEADER, advShipDetailUpdateHeader),
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_CREATE_HEADER, advShipDetailCreateHeader),

    takeLatest(
      AdvShipDetailTypes.ADV_SHIP_DETAIL_TRANSITION_TO_STATUS,
      advShipDetailTransitionToStatus
    ),

    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_SHOW_DETAILS, advShipDetailShowDetails),

    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_UPDATE_DETAILS, advShipDetailUpdateDetails),
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_CREATE_DETAIL, advShipDetailCreateDetail),
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_DELETE_DETAIL, advShipDetailDeleteDetail),

    takeLatest(
      AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_PURCHASER_OPTIONS,
      advShipDetailFetchPurchaserOptions
    ),

    takeLatest(
      AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_BIZ_PARTNER_OPTIONS,
      advShipDetailFetchBizPartnerOptions
    ),
    takeLatest(
      AdvShipDetailTypes.ADV_SHIP_DETAIL_CHANGE_BIZ_PARTNER,
      advShipDetailChangeBizPartner
    ),

    takeLatest(
      AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      advShipDetailFetchCreditTermOptions
    ),

    takeLatest(
      AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_CURRENCY_OPTIONS,
      advShipDetailFetchCurrencyOptions
    ),
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_CHANGE_CURRENCY, advShipDetailChangeCurrency),

    takeLatest(
      AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_ITEM_OPTIONS,
      advShipDetailFetchItemOptions
    ),
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_CHANGE_ITEM, advShipDetailChangeItem),

    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_CHANGE_UOM, advShipDetailChangeUom),
    takeLatest(AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_UOM_OPTIONS, advShipDetailFetchUomOptions),

    // outbOrdDetail
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_INIT_HEADER, outbOrdDetailInitHeader),
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_SHOW_HEADER, outbOrdDetailShowHeader),

    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_UPDATE_HEADER, outbOrdDetailUpdateHeader),
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_CREATE_HEADER, outbOrdDetailCreateHeader),

    takeLatest(
      OutbOrdDetailTypes.OUTB_ORD_DETAIL_TRANSITION_TO_STATUS,
      outbOrdDetailTransitionToStatus
    ),

    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_SHOW_DETAILS, outbOrdDetailShowDetails),

    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_UPDATE_DETAILS, outbOrdDetailUpdateDetails),
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_CREATE_DETAIL, outbOrdDetailCreateDetail),
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_DELETE_DETAIL, outbOrdDetailDeleteDetail),

    takeLatest(
      OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_SALESMAN_OPTIONS,
      outbOrdDetailFetchSalesmanOptions
    ),

    takeLatest(
      OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTIONS,
      outbOrdDetailFetchDeliveryPointOptions
    ),
    takeLatest(
      OutbOrdDetailTypes.OUTB_ORD_DETAIL_CHANGE_DELIVERY_POINT,
      outbOrdDetailChangeDeliveryPoint
    ),

    takeLatest(
      OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      outbOrdDetailFetchCreditTermOptions
    ),

    takeLatest(
      OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_CURRENCY_OPTIONS,
      outbOrdDetailFetchCurrencyOptions
    ),
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_CHANGE_CURRENCY, outbOrdDetailChangeCurrency),

    takeLatest(
      OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_ITEM_OPTIONS,
      outbOrdDetailFetchItemOptions
    ),
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_CHANGE_ITEM, outbOrdDetailChangeItem),

    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_CHANGE_UOM, outbOrdDetailChangeUom),
    takeLatest(OutbOrdDetailTypes.OUTB_ORD_DETAIL_FETCH_UOM_OPTIONS, outbOrdDetailFetchUomOptions),

    // inbOrdDetail
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_INIT_HEADER, inbOrdDetailInitHeader),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_SHOW_HEADER, inbOrdDetailShowHeader),

    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_UPDATE_HEADER, inbOrdDetailUpdateHeader),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_CREATE_HEADER, inbOrdDetailCreateHeader),

    takeLatest(
      InbOrdDetailTypes.INB_ORD_DETAIL_TRANSITION_TO_STATUS,
      inbOrdDetailTransitionToStatus
    ),

    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_SHOW_DETAILS, inbOrdDetailShowDetails),

    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_UPDATE_DETAILS, inbOrdDetailUpdateDetails),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_CREATE_DETAIL, inbOrdDetailCreateDetail),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_DELETE_DETAIL, inbOrdDetailDeleteDetail),

    takeLatest(
      InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_PURCHASER_OPTIONS,
      inbOrdDetailFetchPurchaserOptions
    ),

    takeLatest(
      InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_BIZ_PARTNER_OPTIONS,
      inbOrdDetailFetchBizPartnerOptions
    ),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_CHANGE_BIZ_PARTNER, inbOrdDetailChangeBizPartner),

    takeLatest(
      InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      inbOrdDetailFetchCreditTermOptions
    ),

    takeLatest(
      InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_CURRENCY_OPTIONS,
      inbOrdDetailFetchCurrencyOptions
    ),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_CHANGE_CURRENCY, inbOrdDetailChangeCurrency),

    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_ITEM_OPTIONS, inbOrdDetailFetchItemOptions),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_CHANGE_ITEM, inbOrdDetailChangeItem),

    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_CHANGE_UOM, inbOrdDetailChangeUom),
    takeLatest(InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_UOM_OPTIONS, inbOrdDetailFetchUomOptions),

    // WhseJob0301
    takeLatest(WhseJob0301Types.WHSE_JOB0301_GO_TO_DOCUMENT, whseJob0301GoToDocument),
    takeLatest(WhseJob0301Types.WHSE_JOB0301_FETCH_WHSE_JOB0301, whseJob0301FetchWhseJob0301),
    takeLatest(WhseJob0301Types.WHSE_JOB0301_CREATE_WHSE_JOB0301, whseJob0301CreateWhseJob0301),

    // WhseJob030102
    takeLatest(
      WhseJob030102Types.WHSE_JOB030102_FETCH_WHSE_JOB030102,
      whseJob030102FetchWhseJob030102
    ),
    takeLatest(
      WhseJob030102Types.WHSE_JOB030102_PRINT_WHSE_JOB030102,
      whseJob030102PrintWhseJob030102
    ),
    takeLatest(
      WhseJob030102Types.WHSE_JOB030102_GO_TO_WHSE_JOB_DOCUMENT,
      whseJob030102GoToWhseJobDocument
    ),

    // InvDoc01
    takeLatest(InvDoc01Types.INV_DOC01_FETCH_INV_DOC01, invDoc01FetchInvDoc01),
    takeLatest(InvDoc01Types.INV_DOC01_CREATE_INV_DOC01, invDoc01CreateInvDoc01),
    takeLatest(InvDoc01Types.INV_DOC01_GO_TO_ORDER_DOC, invDoc01GoToOrderDoc),

    // InvDoc0101
    takeLatest(InvDoc0101Types.INV_DOC0101_FETCH_INV_DOC0101, invDoc0101FetchInvDoc0101),
    takeLatest(InvDoc0101Types.INV_DOC0101_PRINT_INV_DOC0101, invDoc0101PrintInvDoc0101),

    // InvDoc0102
    takeLatest(InvDoc0102Types.INV_DOC0102_FETCH_INV_DOC0102, invDoc0102FetchInvDoc0102),
    takeLatest(InvDoc0102Types.INV_DOC0102_PRINT_INV_DOC0102, invDoc0102PrintInvDoc0102),

    // WhseJob1401
    takeLatest(WhseJob1401Types.WHSE_JOB1401_GO_TO_DOCUMENT, whseJob1401GoToDocument),
    takeLatest(WhseJob1401Types.WHSE_JOB1401_FETCH_WHSE_JOB1401, whseJob1401FetchWhseJob1401),
    takeLatest(WhseJob1401Types.WHSE_JOB1401_CREATE_WHSE_JOB1401, whseJob1401CreateWhseJob1401),

    // WhseJob140101
    takeLatest(
      WhseJob140101Types.WHSE_JOB140101_FETCH_WHSE_JOB140101,
      whseJob140101FetchWhseJob140101
    ),
    takeLatest(
      WhseJob140101Types.WHSE_JOB140101_PRINT_WHSE_JOB140101,
      whseJob140101PrintWhseJob140101
    ),
    takeLatest(
      WhseJob140101Types.WHSE_JOB140101_PRINT_HANDLING_UNIT,
      whseJob140101PrintHandlingUnit
    ),
    takeLatest(WhseJob140101Types.WHSE_JOB140101_GO_TO_DOCUMENT, whseJob140101GoToDocument),

    // gdsRcptDetail
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_INIT_HEADER, gdsRcptDetailInitHeader),
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_SHOW_HEADER, gdsRcptDetailShowHeader),

    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_UPDATE_HEADER, gdsRcptDetailUpdateHeader),
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CREATE_HEADER, gdsRcptDetailCreateHeader),

    takeLatest(
      GdsRcptDetailTypes.GDS_RCPT_DETAIL_TRANSITION_TO_STATUS,
      gdsRcptDetailTransitionToStatus
    ),

    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_SHOW_DETAILS, gdsRcptDetailShowDetails),

    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_UPDATE_DETAILS, gdsRcptDetailUpdateDetails),
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_DELETE_DETAIL, gdsRcptDetailDeleteDetail),
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CREATE_DETAIL, gdsRcptDetailCreateDetail),

    takeLatest(
      GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_COMPANY_OPTIONS,
      gdsRcptDetailFetchCompanyOptions
    ),

    takeLatest(
      GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_OPTIONS,
      gdsRcptDetailFetchItemOptions
    ),
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CHANGE_ITEM, gdsRcptDetailChangeItem),

    takeLatest(
      GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
      gdsRcptDetailFetchItemBatchOptions
    ),
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CHANGE_ITEM_BATCH, gdsRcptDetailChangeItemBatch),

    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_UOM_OPTIONS, gdsRcptDetailFetchUomOptions),
    takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CHANGE_UOM, gdsRcptDetailChangeUom),

    takeLatest(
      GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_COND01_OPTIONS,
      gdsRcptDetailFetchItemCond01Options
    ),

    takeLatest(
      GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      gdsRcptDetailFetchToStorageBinOptions
    ),

    takeLatest(
      GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_HANDLING_UNIT_OPTIONS,
      gdsRcptDetailFetchToHandlingUnitOptions
    ),

    // putAway01
    takeLatest(PutAway01Types.PUT_AWAY01_GO_TO_DOCUMENT, putAway01GoToDocument),
    takeLatest(PutAway01Types.PUT_AWAY01_FETCH_PUT_AWAY01, putAway01FetchPutAway01),
    takeLatest(PutAway01Types.PUT_AWAY01_CREATE_PUT_AWAY01, putAway01CreatePutAway01),

    // WhseJob1501
    takeLatest(WhseJob1501Types.WHSE_JOB1501_GO_TO_DOCUMENT, whseJob1501GoToDocument),
    takeLatest(WhseJob1501Types.WHSE_JOB1501_FETCH_WHSE_JOB1501, whseJob1501FetchWhseJob1501),
    takeLatest(WhseJob1501Types.WHSE_JOB1501_CREATE_WHSE_JOB1501, whseJob1501CreateWhseJob1501),

    // WhseJob150101
    takeLatest(
      WhseJob150101Types.WHSE_JOB150101_FETCH_WHSE_JOB150101,
      whseJob150101FetchWhseJob150101
    ),
    takeLatest(
      WhseJob150101Types.WHSE_JOB150101_PRINT_WHSE_JOB150101,
      whseJob150101PrintWhseJob150101
    ),
    takeLatest(WhseJob150101Types.WHSE_JOB150101_GO_TO_DOCUMENT, whseJob150101GoToDocument),

    // rtnRcptProcess
    takeLatest(
      RtnRcptProcessTypes.RTN_RCPT_PROCESS_FETCH_RTN_RCPT_PROCESS,
      rtnRcptProcessFetchRtnRcptProcess
    ),

    // gdsRcpt02
    takeLatest(GdsRcpt02Types.GDS_RCPT02_GO_TO_DOCUMENT, gdsRcpt02GoToDocument),
    takeLatest(GdsRcpt02Types.GDS_RCPT02_FETCH_GDS_RCPT02, gdsRcpt02FetchGdsRcpt02),
    takeLatest(GdsRcpt02Types.GDS_RCPT02_CREATE_GDS_RCPT02, gdsRcpt02CreateGdsRcpt02),

    // gdsRcpt0201
    takeLatest(GdsRcpt0201Types.GDS_RCPT0201_GO_TO_DOCUMENT, gdsRcpt0201GoToDocument),
    takeLatest(GdsRcpt0201Types.GDS_RCPT0201_FETCH_GDS_RCPT0201, gdsRcpt0201FetchGdsRcpt0201),
    takeLatest(GdsRcpt0201Types.GDS_RCPT0201_PRINT_GDS_RCPT0201, gdsRcpt0201PrintGdsRcpt0201),

    // invAuditProcess
    takeLatest(
      InvAuditProcessTypes.INV_AUDIT_PROCESS_FETCH_INV_AUDIT_PROCESS,
      invAuditProcessFetchInvAuditProcess
    ),

    // cycleCount03
    takeLatest(CycleCount03Types.CYCLE_COUNT03_GO_TO_DOCUMENT, cycleCount03GoToDocument),
    takeLatest(CycleCount03Types.CYCLE_COUNT03_NEW_DOCUMENT, cycleCount03NewDocument),
    takeLatest(CycleCount03Types.CYCLE_COUNT03_FETCH_CYCLE_COUNT03, cycleCount03FetchCycleCount03),
    takeLatest(CycleCount03Types.CYCLE_COUNT03_PRINT_CYCLE_COUNT03, cycleCount03PrintCycleCount03),

    // cycleCountDetail
    takeLatest(CycleCountDetailTypes.CYCLE_COUNT_DETAIL_INIT_HEADER, cycleCountDetailInitHeader),
    takeLatest(CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SHOW_HEADER, cycleCountDetailShowHeader),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_UPDATE_HEADER,
      cycleCountDetailUpdateHeader
    ),
    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_CREATE_HEADER,
      cycleCountDetailCreateHeader
    ),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_TRANSITION_TO_STATUS,
      cycleCountDetailTransitionToStatus
    ),

    takeLatest(CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SHOW_DETAILS, cycleCountDetailShowDetails),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_UPDATE_DETAILS,
      cycleCountDetailUpdateDetails
    ),
    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_DELETE_DETAIL,
      cycleCountDetailDeleteDetail
    ),
    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_CREATE_DETAIL,
      cycleCountDetailCreateDetail
    ),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_COMPANY_OPTIONS,
      cycleCountDetailFetchCompanyOptions
    ),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_ITEM_OPTIONS,
      cycleCountDetailFetchItemOptions
    ),
    takeLatest(CycleCountDetailTypes.CYCLE_COUNT_DETAIL_CHANGE_ITEM, cycleCountDetailChangeItem),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
      cycleCountDetailFetchItemBatchOptions
    ),
    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_CHANGE_ITEM_BATCH,
      cycleCountDetailChangeItemBatch
    ),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_UOM_OPTIONS,
      cycleCountDetailFetchUomOptions
    ),
    takeLatest(CycleCountDetailTypes.CYCLE_COUNT_DETAIL_CHANGE_UOM, cycleCountDetailChangeUom),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_BIN_OPTIONS,
      cycleCountDetailFetchStorageBinOptions
    ),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
      cycleCountDetailFetchHandlingUnitOptions
    ),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
      cycleCountDetailFetchStorageRowOptions
    ),
    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
      cycleCountDetailFetchStorageBayOptions
    ),

    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_DELETE_JOB_DETAIL,
      cycleCountDetailDeleteJobDetail
    ),
    takeLatest(
      CycleCountDetailTypes.CYCLE_COUNT_DETAIL_CREATE_JOB_DETAIL,
      cycleCountDetailCreateJobDetail
    ),

    // WhseJob1601
    takeLatest(WhseJob1601Types.WHSE_JOB1601_GO_TO_DOCUMENT, whseJob1601GoToDocument),
    takeLatest(WhseJob1601Types.WHSE_JOB1601_FETCH_WHSE_JOB1601, whseJob1601FetchWhseJob1601),
    takeLatest(WhseJob1601Types.WHSE_JOB1601_CREATE_WHSE_JOB1601, whseJob1601CreateWhseJob1601),

    // WhseJob160102
    takeLatest(
      WhseJob160102Types.WHSE_JOB160102_FETCH_WHSE_JOB160102,
      whseJob160102FetchWhseJob160102
    ),
    takeLatest(
      WhseJob160102Types.WHSE_JOB160102_PRINT_WHSE_JOB160102,
      whseJob160102PrintWhseJob160102
    ),
    takeLatest(WhseJob160102Types.WHSE_JOB160102_GO_TO_DOCUMENT, whseJob160102GoToDocument),

    // CycleCount02
    takeLatest(CycleCount02Types.CYCLE_COUNT02_FETCH_CYCLE_COUNT02, cycleCount02FetchCycleCount02),
    takeLatest(CycleCount02Types.CYCLE_COUNT02_GO_TO_DOCUMENT, cycleCount02GoToDocument),

    // CycleCount02Detail
    takeLatest(
      CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SHOW_DETAILS,
      cycleCount02DetailShowDetails
    ),
    takeLatest(
      CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_UPDATE_RECOUNT,
      cycleCount02DetailUpdateRecount
    ),
    takeLatest(
      CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_AUTO_CONFIRM,
      cycleCount02DetailAutoConfirm
    ),
    takeLatest(
      CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_CREATE_CYCLE_COUNT02,
      cycleCount02DetailCreateCycleCount02
    ),

    // CountAdj01
    takeLatest(CountAdj01Types.COUNT_ADJ01_GO_TO_DOCUMENT, countAdj01GoToDocument),
    takeLatest(CountAdj01Types.COUNT_ADJ01_FETCH_COUNT_ADJ01, countAdj01FetchCountAdj01),
    takeLatest(CountAdj01Types.COUNT_ADJ01_CREATE_COUNT_ADJ01, countAdj01CreateCountAdj01),

    // StockBalanceReport
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_INIT_STOCK_BALANCE,
      stockBalanceReportInitStockBalance
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_STOCK_BALANCE,
      stockBalanceReportStockBalance
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_OPTIONS,
      stockBalanceReportFetchItemOptions
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
      stockBalanceReportFetchItemGroup01Options
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
      stockBalanceReportFetchItemGroup02Options
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
      stockBalanceReportFetchItemGroup03Options
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_BIN_OPTIONS,
      stockBalanceReportFetchStorageBinOptions
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_ROW_OPTIONS,
      stockBalanceReportFetchStorageRowOptions
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_BAY_OPTIONS,
      stockBalanceReportFetchStorageBayOptions
    ),
    takeLatest(
      StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_LOCATION_OPTIONS,
      stockBalanceReportFetchLocationOptions
    ),

    // Warehouse Map
    takeLatest(
      WarehouseMapTypes.WAREHOUSE_MAP_FETCH_STORAGE_BIN_LIST02,
      warehouseMapFetchStorageBinList02
    ),

    // debtorProcess
    takeLatest(
      DebtorProcessTypes.DEBTOR_PROCESS_FETCH_DEBTOR_PROCESS,
      debtorProcessFetchDebtorProcess
    ),

    // debtorList01
    takeLatest(DebtorList01Types.DEBTOR_LIST01_FETCH_DEBTOR_LIST01, debtorList01FetchDebtorList01),
    takeLatest(DebtorList01Types.DEBTOR_LIST01_REMOVE_DIVISION, debtorList01RemoveDivision),
    takeLatest(DebtorList01Types.DEBTOR_LIST01_ADD_DIVISION, debtorList01AddDivision),
    takeLatest(
      DebtorList01Types.DEBTOR_LIST01_FETCH_DIVISION_OPTIONS,
      debtorList01FetchDivisionOptions
    ),
    takeLatest(DebtorList01Types.DEBTOR_LIST01_NEW_DOCUMENT, debtorList01NewDocument),
    takeLatest(DebtorList01Types.DEBTOR_LIST01_CHANGE_STATUS, debtorList01ChangeStatus),
    takeLatest(DebtorList01Types.DEBTOR_LIST01_GO_TO_DOCUMENT, debtorList01GoToDocument),
    takeLatest(DebtorList01Types.DEBTOR_LIST01_GO_TO_AUDIT, debtorList01GoToAudit),

    // debtorDetail
    takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_INIT_HEADER, debtorDetailInitHeader),
    takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_SHOW_HEADER, debtorDetailShowHeader),

    takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_UPDATE_HEADER, debtorDetailUpdateHeader),
    takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_CREATE_HEADER, debtorDetailCreateHeader),
    takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_DELETE_HEADER, debtorDetailDeleteHeader),

    takeLatest(
      DebtorDetailTypes.DEBTOR_DETAIL_TRANSITION_TO_STATUS,
      debtorDetailTransitionToStatus
    ),

    // takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_SHOW_DETAILS, debtorDetailShowDetails),

    // takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_UPDATE_DETAILS, debtorDetailUpdateDetails),
    // takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_CREATE_DETAIL, debtorDetailCreateDetail),
    // takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_DELETE_DETAIL, debtorDetailDeleteDetail),

    // debtorSync01
    takeLatest(
      DebtorSync01Types.DEBTOR_SYNC01_SHOW_BATCH_JOB_STATUS,
      debtorSync01ShowBatchJobStatus
    ),
    takeLatest(DebtorSync01Types.DEBTOR_SYNC01_SHOW_SYNC_SETTING, debtorSync01ShowSyncSetting),
    takeLatest(DebtorSync01Types.DEBTOR_SYNC01_SYNC_NOW, debtorSync01SyncNow),
    takeLatest(DebtorSync01Types.DEBTOR_SYNC01_UPDATE_SYNC_SETTING, debtorSync01UpdateSyncSetting),

    // itemProcess
    takeLatest(ItemProcessTypes.ITEM_PROCESS_FETCH_ITEM_PROCESS, itemProcessFetchItemProcess),

    // itemList01
    takeLatest(ItemList01Types.ITEM_LIST01_FETCH_ITEM_LIST01, itemList01FetchItemList01),
    takeLatest(ItemList01Types.ITEM_LIST01_GO_TO_DOCUMENT, itemList01GoToDocument),
    takeLatest(ItemList01Types.ITEM_LIST01_NEW_DOCUMENT, itemList01NewDocument),
    takeLatest(ItemList01Types.ITEM_LIST01_GO_TO_AUDIT, itemList01GoToAudit),
    takeLatest(ItemList01Types.ITEM_LIST01_UPLOAD_PHOTO, itemList01UploadPhoto),
    takeLatest(ItemList01Types.ITEM_LIST01_REMOVE_PHOTO, itemList01RemovePhoto),
    takeLatest(ItemList01Types.ITEM_LIST01_UPDATE_ITEM, itemList01UpdateItem),

    // itemPrice
    takeLatest(ItemPriceTypes.ITEM_PRICE_CREATE, itemPriceCreate),
    takeLatest(ItemPriceTypes.ITEM_PRICE_UPDATE, itemPriceUpdate),
    takeLatest(ItemPriceTypes.ITEM_PRICE_DELETE, itemPriceDelete),
    takeLatest(ItemPriceTypes.ITEM_PRICE_FETCH_UOM_OPTIONS, itemPriceFetchUomOptions),
    takeLatest(ItemPriceTypes.ITEM_PRICE_CHANGE_UOM, itemPriceChangeUom),

    // itemDetail
    takeLatest(ItemDetailTypes.ITEM_DETAIL_INIT_HEADER, itemDetailInitHeader),
    takeLatest(ItemDetailTypes.ITEM_DETAIL_SHOW_HEADER, itemDetailShowHeader),

    takeLatest(ItemDetailTypes.ITEM_DETAIL_CREATE_HEADER, itemDetailCreateHeader),
    takeLatest(ItemDetailTypes.ITEM_DETAIL_UPDATE_HEADER, itemDetailUpdateHeader),

    takeLatest(ItemDetailTypes.ITEM_DETAIL_FETCH_BRAND_OPTIONS, itemDetailFetchBrandOptions),
    takeLatest(ItemDetailTypes.ITEM_DETAIL_FETCH_CATEGORY_OPTIONS, itemDetailFetchCategoryOptions),
    takeLatest(
      ItemDetailTypes.ITEM_DETAIL_FETCH_MANUFACTURER_OPTIONS,
      itemDetailFetchManufacturerOptions
    ),

    // itemExcel01
    takeLatest(ItemExcel01Types.ITEM_EXCEL01_SHOW_BATCH_JOB_STATUS, itemExcel01ShowBatchJobStatus),
    takeLatest(ItemExcel01Types.ITEM_EXCEL01_UPLOAD_EXCEL, itemExcel01UploadExcel),
    takeLatest(ItemExcel01Types.ITEM_EXCEL01_DOWNLOAD_EXCEL, itemExcel01DownloadExcel),

    // ItemSync01
    takeLatest(ItemSync01Types.ITEM_SYNC01_SHOW_BATCH_JOB_STATUS, itemSync01ShowBatchJobStatus),
    takeLatest(ItemSync01Types.ITEM_SYNC01_SHOW_SYNC_SETTING, itemSync01ShowSyncSetting),
    takeLatest(ItemSync01Types.ITEM_SYNC01_SYNC_NOW, itemSync01SyncNow),
    takeLatest(ItemSync01Types.ITEM_SYNC01_SYNC_IMAGE, itemSync01SyncImage),
    takeLatest(ItemSync01Types.ITEM_SYNC01_RESET_IMAGE, itemSync01ResetImages),
    takeLatest(ItemSync01Types.ITEM_SYNC01_UPDATE_SYNC_SETTING, itemSync01UpdateSyncSetting),

    // storageBinProcess
    takeLatest(
      StorageBinProcessTypes.STORAGE_BIN_PROCESS_FETCH_STORAGE_BIN_PROCESS,
      storageBinProcessFetchStorageBinProcess
    ),

    // storageBinList01
    takeLatest(
      StorageBinList01Types.STORAGE_BIN_LIST01_FETCH_STORAGE_BIN_LIST01,
      storageBinList01FetchStorageBinList01
    ),

    // storageBinExcel01
    takeLatest(
      StorageBinExcel01Types.STORAGE_BIN_EXCEL01_SHOW_BATCH_JOB_STATUS,
      storageBinExcel01ShowBatchJobStatus
    ),
    takeLatest(
      StorageBinExcel01Types.STORAGE_BIN_EXCEL01_UPLOAD_EXCEL,
      storageBinExcel01UploadExcel
    ),
    takeLatest(
      StorageBinExcel01Types.STORAGE_BIN_EXCEL01_DOWNLOAD_EXCEL,
      storageBinExcel01DownloadExcel
    ),

    // palletLabelProcess
    takeLatest(
      PalletLabelProcessTypes.PALLET_LABEL_PROCESS_FETCH_PALLET_LABEL_PROCESS,
      palletLabelProcessFetchPalletLabelProcess
    ),

    // palletLabelList01
    takeLatest(
      PalletLabelList01Types.PALLET_LABEL_LIST01_FETCH_PALLET_LABEL_LIST01,
      palletLabelList01FetchPalletLabelList01
    ),
    takeLatest(
      PalletLabelList01Types.PALLET_LABEL_LIST01_PRINT_PALLET_LABEL_LIST01,
      palletLabelList01PrintPalletLabelList01
    ),

    // pickFaceStrategyProcess
    takeLatest(
      PickFaceStrategyProcessTypes.PICK_FACE_STRATEGY_PROCESS_FETCH_PICK_FACE_STRATEGY_PROCESS,
      pickFaceStrategyProcessFetchPickFaceStrategyProcess
    ),

    // pickFaceStrategyList01
    takeLatest(
      PickFaceStrategyList01Types.PICK_FACE_STRATEGY_LIST01_FETCH_PICK_FACE_STRATEGY_LIST01,
      pickFaceStrategyList01FetchPickFaceStrategyList01
    ),

    // pickFaceStrategyExcel01
    takeLatest(
      PickFaceStrategyExcel01Types.PICK_FACE_STRATEGY_EXCEL01_SHOW_BATCH_JOB_STATUS,
      pickFaceStrategyExcel01ShowBatchJobStatus
    ),
    takeLatest(
      PickFaceStrategyExcel01Types.PICK_FACE_STRATEGY_EXCEL01_UPLOAD_EXCEL,
      pickFaceStrategyExcel01UploadExcel
    ),
    takeLatest(
      PickFaceStrategyExcel01Types.PICK_FACE_STRATEGY_EXCEL01_DOWNLOAD_EXCEL,
      pickFaceStrategyExcel01DownloadExcel
    ),

    // userProcess
    takeLatest(UserProcessTypes.USER_PROCESS_FETCH_USER_PROCESS, userProcessFetchUserProcess),

    // userList01
    takeLatest(UserList01Types.USER_LIST01_FETCH_USER_LIST01, userList01FetchUserList01),
    takeLatest(UserList01Types.USER_LIST01_REMOVE_ROLE, userList01RemoveRole),
    takeLatest(UserList01Types.USER_LIST01_ADD_ROLE, userList01AddRole),
    takeLatest(UserList01Types.USER_LIST01_FETCH_ROLE_OPTIONS, userList01FetchRoleOptions),
    takeLatest(UserList01Types.USER_LIST01_GO_TO_AUDIT, userList01GoToAudit),
    takeLatest(UserList01Types.USER_LIST01_NEW_DOCUMENT, userList01NewDocument),
    takeLatest(UserList01Types.USER_LIST01_GO_TO_DOCUMENT, userList01GoToDocument),
    takeLatest(UserList01Types.USER_LIST01_REMOVE_DIVISION, userList01RemoveDivision),
    takeLatest(UserList01Types.USER_LIST01_ADD_DIVISION, userList01AddDivision),
    takeLatest(UserList01Types.USER_LIST01_FETCH_DIVISION_OPTIONS, userList01FetchDivisionOptions),

    // userList02
    takeLatest(UserList02Types.USER_LIST02_FETCH_USER_LIST02, userList02FetchUserList02),
    takeLatest(UserList02Types.USER_LIST02_REMOVE_DIVISION, userList02RemoveDivision),
    takeLatest(UserList02Types.USER_LIST02_ADD_DIVISION, userList02AddDivision),
    takeLatest(UserList02Types.USER_LIST02_FETCH_DIVISION_OPTIONS, userList02FetchDivisionOptions),
    takeLatest(UserList02Types.USER_LIST02_GO_TO_AUDIT, userList02GoToAudit),
    takeLatest(UserList02Types.USER_LIST02_NEW_DOCUMENT, userList02NewDocument),
    takeLatest(UserList02Types.USER_LIST02_GO_TO_DOCUMENT, userList02GoToDocument),

    // userList03
    takeLatest(UserList03Types.USER_LIST03_FETCH_USER_LIST03, userList03FetchUserList03),
    takeLatest(UserList03Types.USER_LIST03_GO_TO_AUDIT, userList03GoToAudit),
    takeLatest(UserList03Types.USER_LIST03_NEW_DOCUMENT, userList03NewDocument),
    takeLatest(UserList03Types.USER_LIST03_GO_TO_DOCUMENT, userList03GoToDocument),

    // userDetail
    takeLatest(UserDetailTypes.USER_DETAIL_INIT_HEADER, userDetailInitHeader),
    takeLatest(UserDetailTypes.USER_DETAIL_SHOW_HEADER, userDetailShowHeader),

    takeLatest(UserDetailTypes.USER_DETAIL_CREATE_HEADER, userDetailCreateHeader),
    takeLatest(UserDetailTypes.USER_DETAIL_UPDATE_HEADER, userDetailUpdateHeader),

    takeLatest(UserDetailTypes.USER_DETAIL_FETCH_DEBTOR_OPTIONS, userDetailFetchDebtorOptions),

    takeLatest(UserDetailTypes.USER_DETAIL_GO_TO_CHANGE_PASSWORD, userDetailGoToChangePassword),
    takeLatest(UserDetailTypes.USER_DETAIL_CHANGE_PASSWORD, userDetailChangePassword),

    // userExcel01
    takeLatest(UserExcel01Types.USER_EXCEL01_SHOW_BATCH_JOB_STATUS, userExcel01ShowBatchJobStatus),
    takeLatest(UserExcel01Types.USER_EXCEL01_UPLOAD_EXCEL, userExcel01UploadExcel),
    takeLatest(UserExcel01Types.USER_EXCEL01_DOWNLOAD_EXCEL, userExcel01DownloadExcel),

    // userExcel03
    takeLatest(UserExcel03Types.USER_EXCEL03_SHOW_BATCH_JOB_STATUS, userExcel03ShowBatchJobStatus),
    takeLatest(UserExcel03Types.USER_EXCEL03_UPLOAD_EXCEL, userExcel03UploadExcel),
    takeLatest(UserExcel03Types.USER_EXCEL03_DOWNLOAD_EXCEL, userExcel03DownloadExcel),

    // roleProcess
    takeLatest(RoleProcessTypes.ROLE_PROCESS_FETCH_ROLE_PROCESS, roleProcessFetchRoleProcess),

    // roleList01
    takeLatest(RoleList01Types.ROLE_LIST01_FETCH_ROLE_LIST01, roleList01FetchRoleList01),

    // roleExcel01
    takeLatest(RoleExcel01Types.ROLE_EXCEL01_SHOW_BATCH_JOB_STATUS, roleExcel01ShowBatchJobStatus),
    takeLatest(RoleExcel01Types.ROLE_EXCEL01_UPLOAD_EXCEL, roleExcel01UploadExcel),
    takeLatest(RoleExcel01Types.ROLE_EXCEL01_DOWNLOAD_EXCEL, roleExcel01DownloadExcel),

    // slsRtnProcess
    takeLatest(
      SlsRtnProcessTypes.SLS_RTN_PROCESS_FETCH_SLS_RTN_PROCESS,
      slsRtnProcessFetchSlsRtnProcess
    ),

    // inbOrd02
    takeLatest(InbOrd02Types.INB_ORD02_FETCH_INB_ORD02, inbOrd02FetchInbOrd02),
    takeLatest(InbOrd02Types.INB_ORD02_CREATE_INB_ORD02, inbOrd02CreateInbOrd02),
    takeLatest(InbOrd02Types.INB_ORD02_GO_TO_DOCUMENT, inbOrd02GoToDocument),

    // OutbOrdAnalysisReport
    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_INIT_OUTB_ORD_ANALYSIS,
      outbOrdAnalysisReportInitOutbOrdAnalysis
    ),
    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_OUTB_ORD_ANALYSIS,
      outbOrdAnalysisReportOutbOrdAnalysis
    ),
    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_OPTIONS,
      outbOrdAnalysisReportFetchItemOptions
    ),
    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
      outbOrdAnalysisReportFetchItemGroup01Options
    ),
    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
      outbOrdAnalysisReportFetchItemGroup02Options
    ),
    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
      outbOrdAnalysisReportFetchItemGroup03Options
    ),

    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DIVISION_OPTIONS,
      outbOrdAnalysisReportFetchDivisionOptions
    ),
    takeLatest(
      OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DELIVERY_POINT_OPTIONS,
      outbOrdAnalysisReportFetchDeliveryPointOptions
    ),

    // PurchaseProcess
    takeLatest(
      PurchaseProcessTypes.PURCHASE_PROCESS_FETCH_PURCHASE_PROCESS,
      purchaseProcessFetchPurchaseProcess
    ),

    // InbOrd01
    takeLatest(InbOrd01Types.INB_ORD01_FETCH_INB_ORD01, inbOrd01FetchInbOrd01),
    takeLatest(InbOrd01Types.INB_ORD01_CREATE_INB_ORD01, inbOrd01CreateInbOrd01),
    takeLatest(InbOrd01Types.INB_ORD01_GO_TO_DOCUMENT, inbOrd01GoToDocument),

    // whseJob03Detail
    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_INIT_HEADER, whseJob03DetailInitHeader),
    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SHOW_HEADER, whseJob03DetailShowHeader),

    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_UPDATE_HEADER, whseJob03DetailUpdateHeader),
    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_CREATE_HEADER, whseJob03DetailCreateHeader),

    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_TRANSITION_TO_STATUS,
      whseJob03DetailTransitionToStatus
    ),

    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SHOW_DETAILS, whseJob03DetailShowDetails),

    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_UPDATE_DETAILS, whseJob03DetailUpdateDetails),
    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_DELETE_DETAIL, whseJob03DetailDeleteDetail),
    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_CREATE_DETAIL, whseJob03DetailCreateDetail),

    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_COMPANY_OPTIONS,
      whseJob03DetailFetchCompanyOptions
    ),

    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
      whseJob03DetailFetchFrStorageBinOptions
    ),

    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_QUANT_BAL_OPTIONS,
      whseJob03DetailFetchQuantBalOptions
    ),
    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_CHANGE_QUANT_BAL,
      whseJob03DetailChangeQuantBal
    ),

    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_UOM_OPTIONS,
      whseJob03DetailFetchUomOptions
    ),
    takeLatest(WhseJob03DetailTypes.WHSE_JOB03_DETAIL_CHANGE_UOM, whseJob03DetailChangeUom),

    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      whseJob03DetailFetchToStorageBinOptions
    ),
    takeLatest(
      WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_WORKER_OPTIONS,
      whseJob03DetailFetchWorkerOptions
    ),

    // whseJob14Detail
    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_INIT_HEADER, whseJob14DetailInitHeader),
    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SHOW_HEADER, whseJob14DetailShowHeader),

    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_UPDATE_HEADER, whseJob14DetailUpdateHeader),
    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_CREATE_HEADER, whseJob14DetailCreateHeader),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_TRANSITION_TO_STATUS,
      whseJob14DetailTransitionToStatus
    ),

    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SHOW_DETAILS, whseJob14DetailShowDetails),

    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_UPDATE_DETAILS, whseJob14DetailUpdateDetails),
    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_DELETE_DETAIL, whseJob14DetailDeleteDetail),
    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_CREATE_DETAIL, whseJob14DetailCreateDetail),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_COMPANY_OPTIONS,
      whseJob14DetailFetchCompanyOptions
    ),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_OPTIONS,
      whseJob14DetailFetchItemOptions
    ),
    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_CHANGE_ITEM, whseJob14DetailChangeItem),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
      whseJob14DetailFetchItemBatchOptions
    ),
    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_CHANGE_ITEM_BATCH,
      whseJob14DetailChangeItemBatch
    ),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_UOM_OPTIONS,
      whseJob14DetailFetchUomOptions
    ),
    takeLatest(WhseJob14DetailTypes.WHSE_JOB14_DETAIL_CHANGE_UOM, whseJob14DetailChangeUom),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_COND01_OPTIONS,
      whseJob14DetailFetchItemCond01Options
    ),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      whseJob14DetailFetchToStorageBinOptions
    ),

    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_TO_HANDLING_UNIT_OPTIONS,
      whseJob14DetailFetchToHandlingUnitOptions
    ),
    takeLatest(
      WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_WORKER_OPTIONS,
      whseJob14DetailFetchWorkerOptions
    ),

    // PutAwayIndex
    takeLatest(PutAwayIndexTypes.PUT_AWAY_INDEX_GO_TO_DOCUMENT, putAwayIndexGoToDocument),
    takeLatest(PutAwayIndexTypes.PUT_AWAY_INDEX_GO_TO_AUDIT, putAwayIndexGoToAudit),
    takeLatest(
      PutAwayIndexTypes.PUT_AWAY_INDEX_FETCH_PUT_AWAY_INDEX,
      putAwayIndexFetchPutAwayIndex
    ),

    // putAwayDetail
    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_INIT_HEADER, putAwayDetailInitHeader),
    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_SHOW_HEADER, putAwayDetailShowHeader),

    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_UPDATE_HEADER, putAwayDetailUpdateHeader),
    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_CREATE_HEADER, putAwayDetailCreateHeader),

    takeLatest(
      PutAwayDetailTypes.PUT_AWAY_DETAIL_TRANSITION_TO_STATUS,
      putAwayDetailTransitionToStatus
    ),

    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_SHOW_DETAILS, putAwayDetailShowDetails),

    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_UPDATE_DETAILS, putAwayDetailUpdateDetails),
    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_DELETE_DETAIL, putAwayDetailDeleteDetail),
    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_CREATE_DETAIL, putAwayDetailCreateDetail),

    takeLatest(
      PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
      putAwayDetailFetchFrStorageBinOptions
    ),

    takeLatest(
      PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_QUANT_BAL_OPTIONS,
      putAwayDetailFetchQuantBalOptions
    ),
    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_CHANGE_QUANT_BAL, putAwayDetailChangeQuantBal),

    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_UOM_OPTIONS, putAwayDetailFetchUomOptions),
    takeLatest(PutAwayDetailTypes.PUT_AWAY_DETAIL_CHANGE_UOM, putAwayDetailChangeUom),

    takeLatest(
      PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_ITEM_COND01_OPTIONS,
      putAwayDetailFetchItemCond01Options
    ),

    takeLatest(
      PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      putAwayDetailFetchToStorageBinOptions
    ),

    // whseJob15Detail
    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_INIT_HEADER, whseJob15DetailInitHeader),
    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SHOW_HEADER, whseJob15DetailShowHeader),

    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_UPDATE_HEADER, whseJob15DetailUpdateHeader),
    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_CREATE_HEADER, whseJob15DetailCreateHeader),

    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_TRANSITION_TO_STATUS,
      whseJob15DetailTransitionToStatus
    ),

    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SHOW_DETAILS, whseJob15DetailShowDetails),

    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_UPDATE_DETAILS, whseJob15DetailUpdateDetails),
    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_DELETE_DETAIL, whseJob15DetailDeleteDetail),
    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_CREATE_DETAIL, whseJob15DetailCreateDetail),

    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
      whseJob15DetailFetchFrStorageBinOptions
    ),

    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_QUANT_BAL_OPTIONS,
      whseJob15DetailFetchQuantBalOptions
    ),
    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_CHANGE_QUANT_BAL,
      whseJob15DetailChangeQuantBal
    ),

    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_UOM_OPTIONS,
      whseJob15DetailFetchUomOptions
    ),
    takeLatest(WhseJob15DetailTypes.WHSE_JOB15_DETAIL_CHANGE_UOM, whseJob15DetailChangeUom),

    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_ITEM_COND01_OPTIONS,
      whseJob15DetailFetchItemCond01Options
    ),

    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      whseJob15DetailFetchToStorageBinOptions
    ),
    takeLatest(
      WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_WORKER_OPTIONS,
      whseJob15DetailFetchWorkerOptions
    ),

    // AdvShipIndex
    takeLatest(AdvShipIndexTypes.ADV_SHIP_INDEX_GO_TO_DOCUMENT, advShipIndexGoToDocument),
    takeLatest(AdvShipIndexTypes.ADV_SHIP_INDEX_GO_TO_AUDIT, advShipIndexGoToAudit),
    takeLatest(
      AdvShipIndexTypes.ADV_SHIP_INDEX_FETCH_ADV_SHIP_INDEX,
      advShipIndexFetchAdvShipIndex
    ),

    // CartIndex
    takeLatest(CartIndexTypes.CART_INDEX_FETCH_CART_INDEX, cartIndexFetchCartIndex),
    takeLatest(CartIndexTypes.CART_INDEX_GO_TO_DOCUMENT, cartIndexGoToDocument),
    takeLatest(CartIndexTypes.CART_INDEX_GO_TO_AUDIT, cartIndexGoToAudit),

    // cartDetail
    takeLatest(CartDetailTypes.CART_DETAIL_INIT_HEADER, cartDetailInitHeader),
    takeLatest(CartDetailTypes.CART_DETAIL_SHOW_HEADER, cartDetailShowHeader),
    takeLatest(CartDetailTypes.CART_DETAIL_UPDATE_HEADER, cartDetailUpdateHeader),
    takeLatest(CartDetailTypes.CART_DETAIL_CREATE_HEADER, cartDetailCreateHeader),

    takeLatest(CartDetailTypes.CART_DETAIL_TRANSITION_TO_STATUS, cartDetailTransitionToStatus),

    takeLatest(CartDetailTypes.CART_DETAIL_SHOW_DETAILS, cartDetailShowDetails),

    takeLatest(CartDetailTypes.CART_DETAIL_UPDATE_DETAILS, cartDetailUpdateDetails),
    takeLatest(CartDetailTypes.CART_DETAIL_CREATE_DETAIL, cartDetailCreateDetail),
    takeLatest(CartDetailTypes.CART_DETAIL_DELETE_DETAIL, cartDetailDeleteDetail),

    takeLatest(CartDetailTypes.CART_DETAIL_FETCH_SALESMAN_OPTIONS, cartDetailFetchSalesmanOptions),

    takeLatest(
      CartDetailTypes.CART_DETAIL_FETCH_DELIVERY_POINT_OPTIONS,
      cartDetailFetchDeliveryPointOptions
    ),
    takeLatest(CartDetailTypes.CART_DETAIL_CHANGE_DELIVERY_POINT, cartDetailChangeDeliveryPoint),

    takeLatest(
      CartDetailTypes.CART_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      cartDetailFetchCreditTermOptions
    ),

    takeLatest(CartDetailTypes.CART_DETAIL_FETCH_CURRENCY_OPTIONS, cartDetailFetchCurrencyOptions),
    takeLatest(CartDetailTypes.CART_DETAIL_CHANGE_CURRENCY, cartDetailChangeCurrency),

    takeLatest(CartDetailTypes.CART_DETAIL_FETCH_ITEM_OPTIONS, cartDetailFetchItemOptions),
    takeLatest(CartDetailTypes.CART_DETAIL_CHANGE_ITEM, cartDetailChangeItem),

    takeLatest(CartDetailTypes.CART_DETAIL_CHANGE_UOM, cartDetailChangeUom),
    takeLatest(CartDetailTypes.CART_DETAIL_FETCH_UOM_OPTIONS, cartDetailFetchUomOptions),

    // SlsOrdIndex
    takeLatest(SlsOrdIndexTypes.SLS_ORD_INDEX_FETCH_SLS_ORD_INDEX, slsOrdIndexFetchSlsOrdIndex),
    takeLatest(SlsOrdIndexTypes.SLS_ORD_INDEX_GO_TO_DOCUMENT, slsOrdIndexGoToDocument),
    takeLatest(SlsOrdIndexTypes.SLS_ORD_INDEX_GO_TO_AUDIT, slsOrdIndexGoToAudit),

    // slsOrdDetail
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_INIT_HEADER, slsOrdDetailInitHeader),
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_SHOW_HEADER, slsOrdDetailShowHeader),

    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_UPDATE_HEADER, slsOrdDetailUpdateHeader),
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_CREATE_HEADER, slsOrdDetailCreateHeader),

    takeLatest(
      SlsOrdDetailTypes.SLS_ORD_DETAIL_TRANSITION_TO_STATUS,
      slsOrdDetailTransitionToStatus
    ),

    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_SHOW_DETAILS, slsOrdDetailShowDetails),

    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_UPDATE_DETAILS, slsOrdDetailUpdateDetails),
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_CREATE_DETAIL, slsOrdDetailCreateDetail),
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_DELETE_DETAIL, slsOrdDetailDeleteDetail),

    takeLatest(
      SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_SALESMAN_OPTIONS,
      slsOrdDetailFetchSalesmanOptions
    ),

    takeLatest(
      SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTIONS,
      slsOrdDetailFetchDeliveryPointOptions
    ),
    takeLatest(
      SlsOrdDetailTypes.SLS_ORD_DETAIL_CHANGE_DELIVERY_POINT,
      slsOrdDetailChangeDeliveryPoint
    ),

    takeLatest(
      SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      slsOrdDetailFetchCreditTermOptions
    ),

    takeLatest(
      SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_CURRENCY_OPTIONS,
      slsOrdDetailFetchCurrencyOptions
    ),
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_CHANGE_CURRENCY, slsOrdDetailChangeCurrency),

    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_ITEM_OPTIONS, slsOrdDetailFetchItemOptions),
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_CHANGE_ITEM, slsOrdDetailChangeItem),

    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_CHANGE_UOM, slsOrdDetailChangeUom),
    takeLatest(SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_UOM_OPTIONS, slsOrdDetailFetchUomOptions),

    // SlsInvIndex
    takeLatest(SlsInvIndexTypes.SLS_INV_INDEX_FETCH_SLS_INV_INDEX, slsInvIndexFetchSlsInvIndex),
    takeLatest(SlsInvIndexTypes.SLS_INV_INDEX_GO_TO_DOCUMENT, slsInvIndexGoToDocument),
    takeLatest(SlsInvIndexTypes.SLS_INV_INDEX_GO_TO_AUDIT, slsInvIndexGoToAudit),

    // slsInvDetail
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_INIT_HEADER, slsInvDetailInitHeader),
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_SHOW_HEADER, slsInvDetailShowHeader),

    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_UPDATE_HEADER, slsInvDetailUpdateHeader),
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_CREATE_HEADER, slsInvDetailCreateHeader),

    takeLatest(
      SlsInvDetailTypes.SLS_INV_DETAIL_TRANSITION_TO_STATUS,
      slsInvDetailTransitionToStatus
    ),

    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_SHOW_DETAILS, slsInvDetailShowDetails),

    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_UPDATE_DETAILS, slsInvDetailUpdateDetails),
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_CREATE_DETAIL, slsInvDetailCreateDetail),
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_DELETE_DETAIL, slsInvDetailDeleteDetail),

    takeLatest(
      SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_SALESMAN_OPTIONS,
      slsInvDetailFetchSalesmanOptions
    ),

    takeLatest(
      SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_DELIVERY_POINT_OPTIONS,
      slsInvDetailFetchDeliveryPointOptions
    ),
    takeLatest(
      SlsInvDetailTypes.SLS_INV_DETAIL_CHANGE_DELIVERY_POINT,
      slsInvDetailChangeDeliveryPoint
    ),

    takeLatest(
      SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      slsInvDetailFetchCreditTermOptions
    ),

    takeLatest(
      SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_CURRENCY_OPTIONS,
      slsInvDetailFetchCurrencyOptions
    ),
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_CHANGE_CURRENCY, slsInvDetailChangeCurrency),

    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_ITEM_OPTIONS, slsInvDetailFetchItemOptions),
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_CHANGE_ITEM, slsInvDetailChangeItem),

    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_CHANGE_UOM, slsInvDetailChangeUom),
    takeLatest(SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_UOM_OPTIONS, slsInvDetailFetchUomOptions),

    // SlsRtnIndex
    takeLatest(SlsRtnIndexTypes.SLS_RTN_INDEX_FETCH_SLS_RTN_INDEX, slsRtnIndexFetchSlsRtnIndex),
    takeLatest(SlsRtnIndexTypes.SLS_RTN_INDEX_GO_TO_DOCUMENT, slsRtnIndexGoToDocument),
    takeLatest(SlsRtnIndexTypes.SLS_RTN_INDEX_GO_TO_AUDIT, slsRtnIndexGoToAudit),

    // slsRtnDetail
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_INIT_HEADER, slsRtnDetailInitHeader),
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_SHOW_HEADER, slsRtnDetailShowHeader),

    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_UPDATE_HEADER, slsRtnDetailUpdateHeader),
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_CREATE_HEADER, slsRtnDetailCreateHeader),

    takeLatest(
      SlsRtnDetailTypes.SLS_RTN_DETAIL_TRANSITION_TO_STATUS,
      slsRtnDetailTransitionToStatus
    ),

    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_SHOW_DETAILS, slsRtnDetailShowDetails),

    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_UPDATE_DETAILS, slsRtnDetailUpdateDetails),
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_CREATE_DETAIL, slsRtnDetailCreateDetail),
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_DELETE_DETAIL, slsRtnDetailDeleteDetail),

    takeLatest(
      SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_SALESMAN_OPTIONS,
      slsRtnDetailFetchSalesmanOptions
    ),

    takeLatest(
      SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_DELIVERY_POINT_OPTIONS,
      slsRtnDetailFetchDeliveryPointOptions
    ),
    takeLatest(
      SlsRtnDetailTypes.SLS_RTN_DETAIL_CHANGE_DELIVERY_POINT,
      slsRtnDetailChangeDeliveryPoint
    ),

    takeLatest(
      SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      slsRtnDetailFetchCreditTermOptions
    ),

    takeLatest(
      SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_CURRENCY_OPTIONS,
      slsRtnDetailFetchCurrencyOptions
    ),
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_CHANGE_CURRENCY, slsRtnDetailChangeCurrency),

    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_ITEM_OPTIONS, slsRtnDetailFetchItemOptions),
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_CHANGE_ITEM, slsRtnDetailChangeItem),

    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_CHANGE_UOM, slsRtnDetailChangeUom),
    takeLatest(SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_UOM_OPTIONS, slsRtnDetailFetchUomOptions),

    // RtnRcptIndex
    takeLatest(
      RtnRcptIndexTypes.RTN_RCPT_INDEX_FETCH_RTN_RCPT_INDEX,
      rtnRcptIndexFetchRtnRcptIndex
    ),
    takeLatest(RtnRcptIndexTypes.RTN_RCPT_INDEX_GO_TO_DOCUMENT, rtnRcptIndexGoToDocument),
    takeLatest(RtnRcptIndexTypes.RTN_RCPT_INDEX_GO_TO_AUDIT, rtnRcptIndexGoToAudit),

    // rtnRcptDetail
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_INIT_HEADER, rtnRcptDetailInitHeader),
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_SHOW_HEADER, rtnRcptDetailShowHeader),

    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_UPDATE_HEADER, rtnRcptDetailUpdateHeader),
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_CREATE_HEADER, rtnRcptDetailCreateHeader),

    takeLatest(
      RtnRcptDetailTypes.RTN_RCPT_DETAIL_TRANSITION_TO_STATUS,
      rtnRcptDetailTransitionToStatus
    ),

    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_SHOW_DETAILS, rtnRcptDetailShowDetails),

    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_UPDATE_DETAILS, rtnRcptDetailUpdateDetails),
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_CREATE_DETAIL, rtnRcptDetailCreateDetail),
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_DELETE_DETAIL, rtnRcptDetailDeleteDetail),

    takeLatest(
      RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_SALESMAN_OPTIONS,
      rtnRcptDetailFetchSalesmanOptions
    ),

    takeLatest(
      RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_DELIVERY_POINT_OPTIONS,
      rtnRcptDetailFetchDeliveryPointOptions
    ),
    takeLatest(
      RtnRcptDetailTypes.RTN_RCPT_DETAIL_CHANGE_DELIVERY_POINT,
      rtnRcptDetailChangeDeliveryPoint
    ),

    takeLatest(
      RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
      rtnRcptDetailFetchCreditTermOptions
    ),

    takeLatest(
      RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_CURRENCY_OPTIONS,
      rtnRcptDetailFetchCurrencyOptions
    ),
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_CHANGE_CURRENCY, rtnRcptDetailChangeCurrency),

    takeLatest(
      RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_ITEM_OPTIONS,
      rtnRcptDetailFetchItemOptions
    ),
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_CHANGE_ITEM, rtnRcptDetailChangeItem),

    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_CHANGE_UOM, rtnRcptDetailChangeUom),
    takeLatest(RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_UOM_OPTIONS, rtnRcptDetailFetchUomOptions),

    // OutbOrdIndex
    takeLatest(
      OutbOrdIndexTypes.OUTB_ORD_INDEX_FETCH_OUTB_ORD_INDEX,
      outbOrdIndexFetchOutbOrdIndex
    ),
    takeLatest(OutbOrdIndexTypes.OUTB_ORD_INDEX_GO_TO_DOCUMENT, outbOrdIndexGoToDocument),
    takeLatest(OutbOrdIndexTypes.OUTB_ORD_INDEX_GO_TO_AUDIT, outbOrdIndexGoToAudit),

    // InbOrdIndex
    takeLatest(InbOrdIndexTypes.INB_ORD_INDEX_FETCH_INB_ORD_INDEX, inbOrdIndexFetchInbOrdIndex),
    takeLatest(InbOrdIndexTypes.INB_ORD_INDEX_GO_TO_DOCUMENT, inbOrdIndexGoToDocument),
    takeLatest(InbOrdIndexTypes.INB_ORD_INDEX_GO_TO_AUDIT, inbOrdIndexGoToAudit),

    // GdsRcptIndex
    takeLatest(GdsRcptIndexTypes.GDS_RCPT_INDEX_GO_TO_DOCUMENT, gdsRcptIndexGoToDocument),
    takeLatest(GdsRcptIndexTypes.GDS_RCPT_INDEX_GO_TO_AUDIT, gdsRcptIndexGoToAudit),
    takeLatest(
      GdsRcptIndexTypes.GDS_RCPT_INDEX_FETCH_GDS_RCPT_INDEX,
      gdsRcptIndexFetchGdsRcptIndex
    ),

    // CycleCountIndex
    takeLatest(CycleCountIndexTypes.CYCLE_COUNT_INDEX_GO_TO_DOCUMENT, cycleCountIndexGoToDocument),
    takeLatest(CycleCountIndexTypes.CYCLE_COUNT_INDEX_GO_TO_AUDIT, cycleCountIndexGoToAudit),
    takeLatest(
      CycleCountIndexTypes.CYCLE_COUNT_INDEX_FETCH_CYCLE_COUNT_INDEX,
      cycleCountIndexFetchCycleCountIndex
    ),

    // CountAdjIndex
    takeLatest(CountAdjIndexTypes.COUNT_ADJ_INDEX_GO_TO_DOCUMENT, countAdjIndexGoToDocument),
    takeLatest(CountAdjIndexTypes.COUNT_ADJ_INDEX_GO_TO_AUDIT, countAdjIndexGoToAudit),
    takeLatest(
      CountAdjIndexTypes.COUNT_ADJ_INDEX_FETCH_COUNT_ADJ_INDEX,
      countAdjIndexFetchCountAdjIndex
    ),
    takeLatest(CountAdjIndexTypes.COUNT_ADJ_INDEX_NEW_DOCUMENT, countAdjIndexNewDocument),

    // countAdjDetail
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_INIT_HEADER, countAdjDetailInitHeader),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_SHOW_HEADER, countAdjDetailShowHeader),

    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPDATE_HEADER, countAdjDetailUpdateHeader),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CREATE_HEADER, countAdjDetailCreateHeader),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_TRANSITION_TO_STATUS,
      countAdjDetailTransitionToStatus
    ),

    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_SHOW_DETAILS, countAdjDetailShowDetails),

    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPDATE_DETAILS, countAdjDetailUpdateDetails),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_DELETE_DETAIL, countAdjDetailDeleteDetail),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CREATE_DETAIL, countAdjDetailCreateDetail),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_COMPANY_OPTIONS,
      countAdjDetailFetchCompanyOptions
    ),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_OPTIONS,
      countAdjDetailFetchItemOptions
    ),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_ITEM, countAdjDetailChangeItem),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
      countAdjDetailFetchItemBatchOptions
    ),
    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_ITEM_BATCH,
      countAdjDetailChangeItemBatch
    ),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_UOM_OPTIONS,
      countAdjDetailFetchUomOptions
    ),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_UOM, countAdjDetailChangeUom),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BIN_OPTIONS,
      countAdjDetailFetchStorageBinOptions
    ),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
      countAdjDetailFetchHandlingUnitOptions
    ),

    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
      countAdjDetailFetchStorageRowOptions
    ),
    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
      countAdjDetailFetchStorageBayOptions
    ),
    takeLatest(
      CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_QUANT_BAL_OPTIONS,
      countAdjDetailFetchQuantBalOptions
    ),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_QUANT_BAL, countAdjDetailChangeQuantBal),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPLOAD_EXCEL, countAdjDetailUploadExcel),
    takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_DOWNLOAD_EXCEL, countAdjDetailDownloadExcel),

    // BinTrfIndex
    takeLatest(BinTrfIndexTypes.BIN_TRF_INDEX_GO_TO_DOCUMENT, binTrfIndexGoToDocument),
    takeLatest(BinTrfIndexTypes.BIN_TRF_INDEX_GO_TO_AUDIT, binTrfIndexGoToAudit),
    takeLatest(BinTrfIndexTypes.BIN_TRF_INDEX_FETCH_BIN_TRF_INDEX, binTrfIndexFetchBinTrfIndex),

    // BinTrfDetail
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_INIT_HEADER, binTrfDetailInitHeader),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_SHOW_HEADER, binTrfDetailShowHeader),

    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_UPDATE_HEADER, binTrfDetailUpdateHeader),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CREATE_HEADER, binTrfDetailCreateHeader),

    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_TRANSITION_TO_STATUS,
      binTrfDetailTransitionToStatus
    ),

    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_SHOW_DETAILS, binTrfDetailShowDetails),

    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_UPDATE_DETAILS, binTrfDetailUpdateDetails),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_DELETE_DETAIL, binTrfDetailDeleteDetail),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CREATE_DETAIL, binTrfDetailCreateDetail),

    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_OPTIONS, binTrfDetailFetchItemOptions),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_ITEM, binTrfDetailChangeItem),

    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
      binTrfDetailFetchItemBatchOptions
    ),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_ITEM_BATCH, binTrfDetailChangeItemBatch),

    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_UOM_OPTIONS, binTrfDetailFetchUomOptions),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_UOM, binTrfDetailChangeUom),

    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
      binTrfDetailFetchFrStorageBinOptions
    ),

    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      binTrfDetailFetchToStorageBinOptions
    ),

    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
      binTrfDetailFetchHandlingUnitOptions
    ),

    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
      binTrfDetailFetchStorageRowOptions
    ),
    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
      binTrfDetailFetchStorageBayOptions
    ),
    takeLatest(
      BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_QUANT_BAL_OPTIONS,
      binTrfDetailFetchQuantBalOptions
    ),
    takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_QUANT_BAL, binTrfDetailChangeQuantBal),

    // BinTrfProcess
    takeLatest(
      BinTrfProcessTypes.BIN_TRF_PROCESS_FETCH_BIN_TRF_PROCESS,
      binTrfProcessFetchBinTrfProcess
    ),

    // binTrf02
    takeLatest(BinTrf02Types.BIN_TRF02_GO_TO_DOCUMENT, binTrf02GoToDocument),
    takeLatest(BinTrf02Types.BIN_TRF02_FETCH_BIN_TRF02, binTrf02FetchBinTrf02),
    takeLatest(BinTrf02Types.BIN_TRF02_PRINT_BIN_TRF02, binTrf02PrintBinTrf02),
    takeLatest(BinTrf02Types.BIN_TRF02_NEW_DOCUMENT, binTrf02NewDocument),

    // WhseJob1702
    takeLatest(WhseJob1702Types.WHSE_JOB1702_GO_TO_DOCUMENT, whseJob1702GoToDocument),
    takeLatest(WhseJob1702Types.WHSE_JOB1702_FETCH_WHSE_JOB1702, whseJob1702FetchWhseJob1702),
    takeLatest(WhseJob1702Types.WHSE_JOB1702_CREATE_WHSE_JOB1702, whseJob1702CreateWhseJob1702),

    // WhseJob170201
    takeLatest(
      WhseJob170201Types.WHSE_JOB170201_FETCH_WHSE_JOB170201,
      whseJob170201FetchWhseJob170201
    ),
    takeLatest(
      WhseJob170201Types.WHSE_JOB170201_PRINT_WHSE_JOB170201,
      whseJob170201PrintWhseJob170201
    ),
    takeLatest(WhseJob170201Types.WHSE_JOB170201_GO_TO_DOCUMENT, whseJob170201GoToDocument),

    // WhseJob17Detail
    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_INIT_HEADER, whseJob17DetailInitHeader),
    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SHOW_HEADER, whseJob17DetailShowHeader),

    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_UPDATE_HEADER, whseJob17DetailUpdateHeader),
    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CREATE_HEADER, whseJob17DetailCreateHeader),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_TRANSITION_TO_STATUS,
      whseJob17DetailTransitionToStatus
    ),

    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SHOW_DETAILS, whseJob17DetailShowDetails),

    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_UPDATE_DETAILS, whseJob17DetailUpdateDetails),
    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_DELETE_DETAIL, whseJob17DetailDeleteDetail),
    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CREATE_DETAIL, whseJob17DetailCreateDetail),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_OPTIONS,
      whseJob17DetailFetchItemOptions
    ),
    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_ITEM, whseJob17DetailChangeItem),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
      whseJob17DetailFetchItemBatchOptions
    ),
    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_ITEM_BATCH,
      whseJob17DetailChangeItemBatch
    ),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_UOM_OPTIONS,
      whseJob17DetailFetchUomOptions
    ),
    takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_UOM, whseJob17DetailChangeUom),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
      whseJob17DetailFetchFrStorageBinOptions
    ),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
      whseJob17DetailFetchToStorageBinOptions
    ),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
      whseJob17DetailFetchHandlingUnitOptions
    ),

    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
      whseJob17DetailFetchStorageRowOptions
    ),
    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
      whseJob17DetailFetchStorageBayOptions
    ),
    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_QUANT_BAL_OPTIONS,
      whseJob17DetailFetchQuantBalOptions
    ),
    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_QUANT_BAL,
      whseJob17DetailChangeQuantBal
    ),
    takeLatest(
      WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_WORKER_OPTIONS,
      whseJob17DetailFetchWorkerOptions
    ),

    // SlsOrdSync01
    takeLatest(
      SlsOrdSync01Types.SLS_ORD_SYNC01_SHOW_BATCH_JOB_STATUS,
      slsOrdSync01ShowBatchJobStatus
    ),
    takeLatest(SlsOrdSync01Types.SLS_ORD_SYNC01_SHOW_SYNC_SETTING, slsOrdSync01ShowSyncSetting),
    takeLatest(SlsOrdSync01Types.SLS_ORD_SYNC01_SYNC_NOW, slsOrdSync01SyncNow),
    takeLatest(SlsOrdSync01Types.SLS_ORD_SYNC01_UPDATE_SYNC_SETTING, slsOrdSync01UpdateSyncSetting),

    // SlsOrdSync02
    takeLatest(SlsOrdSync02Types.SLS_ORD_SYNC02_FETCH_SLS_ORD_INDEX, slsOrdSync02FetchSlsOrdIndex),
    takeLatest(
      SlsOrdSync02Types.SLS_ORD_SYNC02_FETCH_SLS_ORD_INDEX_SEARCH,
      slsOrdSync02FetchSlsOrdIndexSearch
    ),

    takeLatest(SlsOrdSync02Types.SLS_ORD_SYNC02_GO_TO_DOCUMENT, slsOrdSync02GoToDocument),
    takeLatest(
      SlsOrdSync02Types.SLS_ORD_SYNC02_SHOW_BATCH_JOB_STATUS,
      slsOrdSync02ShowBatchJobStatus
    ),
    takeLatest(SlsOrdSync02Types.SLS_ORD_SYNC02_SYNC_NOW, slsOrdSync02SyncNow),

    // SlsOrdSync03
    takeLatest(
      SlsOrdSync03Types.SLS_ORD_SYNC03_SHOW_BATCH_JOB_STATUS,
      slsOrdSync03ShowBatchJobStatus
    ),
    takeLatest(SlsOrdSync03Types.SLS_ORD_SYNC03_SYNC_NOW, slsOrdSync03SyncNow),

    // SlsInvSync01
    takeLatest(
      SlsInvSync01Types.SLS_INV_SYNC01_SHOW_BATCH_JOB_STATUS,
      slsInvSync01ShowBatchJobStatus
    ),
    takeLatest(SlsInvSync01Types.SLS_INV_SYNC01_SHOW_SYNC_SETTING, slsInvSync01ShowSyncSetting),
    takeLatest(SlsInvSync01Types.SLS_INV_SYNC01_SYNC_NOW, slsInvSync01SyncNow),
    takeLatest(SlsInvSync01Types.SLS_INV_SYNC01_UPDATE_SYNC_SETTING, slsInvSync01UpdateSyncSetting),

    // SlsInvSync02
    takeLatest(SlsInvSync02Types.SLS_INV_SYNC02_FETCH_SLS_ORD_INDEX, slsInvSync02FetchSlsOrdIndex),
    takeLatest(
      SlsInvSync02Types.SLS_INV_SYNC02_FETCH_SLS_ORD_INDEX_SEARCH,
      slsInvSync02FetchSlsOrdIndexSearch
    ),

    takeLatest(SlsInvSync02Types.SLS_INV_SYNC02_GO_TO_DOCUMENT, slsInvSync02GoToDocument),
    takeLatest(
      SlsInvSync02Types.SLS_INV_SYNC02_SHOW_BATCH_JOB_STATUS,
      slsInvSync02ShowBatchJobStatus
    ),
    takeLatest(SlsInvSync02Types.SLS_INV_SYNC02_SYNC_NOW, slsInvSync02SyncNow),

    // SlsRtnSync01
    takeLatest(
      SlsRtnSync01Types.SLS_RTN_SYNC01_SHOW_BATCH_JOB_STATUS,
      slsRtnSync01ShowBatchJobStatus
    ),
    takeLatest(SlsRtnSync01Types.SLS_RTN_SYNC01_SHOW_SYNC_SETTING, slsRtnSync01ShowSyncSetting),
    takeLatest(SlsRtnSync01Types.SLS_RTN_SYNC01_SYNC_NOW, slsRtnSync01SyncNow),
    takeLatest(SlsRtnSync01Types.SLS_RTN_SYNC01_UPDATE_SYNC_SETTING, slsRtnSync01UpdateSyncSetting),

    // RtnRcptSync01
    takeLatest(
      RtnRcptSync01Types.RTN_RCPT_SYNC01_SHOW_BATCH_JOB_STATUS,
      rtnRcptSync01ShowBatchJobStatus
    ),
    takeLatest(RtnRcptSync01Types.RTN_RCPT_SYNC01_SHOW_SYNC_SETTING, rtnRcptSync01ShowSyncSetting),
    takeLatest(RtnRcptSync01Types.RTN_RCPT_SYNC01_SYNC_NOW, rtnRcptSync01SyncNow),
    takeLatest(
      RtnRcptSync01Types.RTN_RCPT_SYNC01_UPDATE_SYNC_SETTING,
      rtnRcptSync01UpdateSyncSetting
    ),

    // AdvShipSync01
    takeLatest(
      AdvShipSync01Types.ADV_SHIP_SYNC01_SHOW_BATCH_JOB_STATUS,
      advShipSync01ShowBatchJobStatus
    ),
    takeLatest(AdvShipSync01Types.ADV_SHIP_SYNC01_SHOW_SYNC_SETTING, advShipSync01ShowSyncSetting),
    takeLatest(AdvShipSync01Types.ADV_SHIP_SYNC01_SYNC_NOW, advShipSync01SyncNow),
    takeLatest(
      AdvShipSync01Types.ADV_SHIP_SYNC01_UPDATE_SYNC_SETTING,
      advShipSync01UpdateSyncSetting
    ),

    // StockCardReport
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_INIT_STOCK_CARD,
      stockCardReportInitStockCard
    ),
    takeLatest(StockCardReportTypes.STOCK_CARD_REPORT_STOCK_CARD, stockCardReportStockCard),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_OPTIONS,
      stockCardReportFetchItemOptions
    ),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
      stockCardReportFetchItemGroup01Options
    ),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
      stockCardReportFetchItemGroup02Options
    ),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
      stockCardReportFetchItemGroup03Options
    ),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_BIN_OPTIONS,
      stockCardReportFetchStorageBinOptions
    ),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_ROW_OPTIONS,
      stockCardReportFetchStorageRowOptions
    ),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_BAY_OPTIONS,
      stockCardReportFetchStorageBayOptions
    ),
    takeLatest(
      StockCardReportTypes.STOCK_CARD_REPORT_FETCH_LOCATION_OPTIONS,
      stockCardReportFetchLocationOptions
    ),

    // ReservedStockReport
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_INIT_RESERVED_STOCK,
      reservedStockReportInitReservedStock
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_RESERVED_STOCK,
      reservedStockReportReservedStock
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_OPTIONS,
      reservedStockReportFetchItemOptions
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
      reservedStockReportFetchItemGroup01Options
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
      reservedStockReportFetchItemGroup02Options
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
      reservedStockReportFetchItemGroup03Options
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTIONS,
      reservedStockReportFetchStorageBinOptions
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTIONS,
      reservedStockReportFetchStorageRowOptions
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTIONS,
      reservedStockReportFetchStorageBayOptions
    ),
    takeLatest(
      ReservedStockReportTypes.RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTIONS,
      reservedStockReportFetchLocationOptions
    ),

    // CycleCountAnalysisReport
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_INIT_CYCLE_COUNT_ANALYSIS,
      cycleCountAnalysisReportInitCycleCountAnalysis
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_CYCLE_COUNT_ANALYSIS,
      cycleCountAnalysisReportCycleCountAnalysis
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_OPTIONS,
      cycleCountAnalysisReportFetchItemOptions
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
      cycleCountAnalysisReportFetchItemGroup01Options
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
      cycleCountAnalysisReportFetchItemGroup02Options
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
      cycleCountAnalysisReportFetchItemGroup03Options
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTIONS,
      cycleCountAnalysisReportFetchStorageBinOptions
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTIONS,
      cycleCountAnalysisReportFetchStorageRowOptions
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTIONS,
      cycleCountAnalysisReportFetchStorageBayOptions
    ),
    takeLatest(
      CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_LOCATION_OPTIONS,
      cycleCountAnalysisReportFetchLocationOptions
    ),

    // CountAdjAnalysisReport
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_INIT_COUNT_ADJ_ANALYSIS,
      countAdjAnalysisReportInitCountAdjAnalysis
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_COUNT_ADJ_ANALYSIS,
      countAdjAnalysisReportCountAdjAnalysis
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_OPTIONS,
      countAdjAnalysisReportFetchItemOptions
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
      countAdjAnalysisReportFetchItemGroup01Options
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
      countAdjAnalysisReportFetchItemGroup02Options
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
      countAdjAnalysisReportFetchItemGroup03Options
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTIONS,
      countAdjAnalysisReportFetchStorageBinOptions
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTIONS,
      countAdjAnalysisReportFetchStorageRowOptions
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTIONS,
      countAdjAnalysisReportFetchStorageBayOptions
    ),
    takeLatest(
      CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_LOCATION_OPTIONS,
      countAdjAnalysisReportFetchLocationOptions
    ),

    // deliveryPointProcess
    takeLatest(
      DeliveryPointProcessTypes.DELIVERY_POINT_PROCESS_FETCH_DELIVERY_POINT_PROCESS,
      deliveryPointProcessFetchDeliveryPointProcess
    ),

    // deliveryPointList01
    takeLatest(
      DeliveryPointList01Types.DELIVERY_POINT_LIST01_FETCH_DELIVERY_POINT_LIST01,
      deliveryPointList01FetchDeliveryPointList01
    ),

    // AuditRes01
    takeLatest(AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_TYPES, auditRes01FetchAuditTypes),
    takeLatest(AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_RES01, auditRes01FetchAuditRes01),

    // AuditUser01
    takeLatest(AuditUser01Types.AUDIT_USER01_FETCH_AUDIT_USER01, auditUser01FetchAuditUser01),

    // AuditLog
    takeLatest(AuditLogTypes.AUDIT_LOG_FETCH_AUDIT_LOG, auditLogFetchAuditLog),

    // whseJob16Detail
    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_INIT_HEADER, whseJob16DetailInitHeader),
    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SHOW_HEADER, whseJob16DetailShowHeader),

    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_UPDATE_HEADER, whseJob16DetailUpdateHeader),
    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CREATE_HEADER, whseJob16DetailCreateHeader),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_TRANSITION_TO_STATUS,
      whseJob16DetailTransitionToStatus
    ),

    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SHOW_DETAILS, whseJob16DetailShowDetails),

    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_UPDATE_DETAILS, whseJob16DetailUpdateDetails),
    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_DELETE_DETAIL, whseJob16DetailDeleteDetail),
    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CREATE_DETAIL, whseJob16DetailCreateDetail),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_COMPANY_OPTIONS,
      whseJob16DetailFetchCompanyOptions
    ),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_OPTIONS,
      whseJob16DetailFetchItemOptions
    ),
    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CHANGE_ITEM, whseJob16DetailChangeItem),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
      whseJob16DetailFetchItemBatchOptions
    ),
    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CHANGE_ITEM_BATCH,
      whseJob16DetailChangeItemBatch
    ),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_UOM_OPTIONS,
      whseJob16DetailFetchUomOptions
    ),
    takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CHANGE_UOM, whseJob16DetailChangeUom),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BIN_OPTIONS,
      whseJob16DetailFetchStorageBinOptions
    ),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
      whseJob16DetailFetchHandlingUnitOptions
    ),

    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
      whseJob16DetailFetchStorageRowOptions
    ),
    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
      whseJob16DetailFetchStorageBayOptions
    ),
    takeLatest(
      WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_WORKER_OPTIONS,
      whseJob16DetailFetchWorkerOptions
    ),

    takeLatest(DeliveryPointTypes.DELIVERY_POINT_CREATE, deliveryPointCreate),
    takeLatest(DeliveryPointTypes.DELIVERY_POINT_UPDATE, deliveryPointUpdate),

    takeLatest(
      DeliveryPointTypes.DELIVERY_POINT_FETCH_STATE_OPTIONS,
      deliveryPointFetchStateOptions
    ),
    takeLatest(DeliveryPointTypes.DELIVERY_POINT_FETCH_AREA_OPTIONS, deliveryPointFetchAreaOptions),
    takeLatest(DeliveryPointTypes.DELIVERY_POINT_CHANGE_STATE, deliveryPointChangeState),
    takeLatest(DeliveryPointTypes.DELIVERY_POINT_CHANGE_AREA, deliveryPointChangeArea),
    takeLatest(
      ItemGroup01List01Types.ITEM_GROUP01_LIST01_FETCH_ITEM_GROUP01_LIST,
      itemGroup01List01FetchItemGroup01List
    ),
    takeLatest(
      ItemGroup01List01Types.ITEM_GROUP01_LIST01_FETCH_ITEM_GROUP01_LIST_ALL,
      itemGroup01List01FetchItemGroup01ListAll
    ),
    takeLatest(
      ItemGroup01List01Types.ITEM_GROUP01_LIST01_UPLOAD_PHOTO,
      itemGroup01List01UploadPhoto
    ),
    takeLatest(
      ItemGroup01List01Types.ITEM_GROUP01_LIST01_REMOVE_PHOTO,
      itemGroup01List01RemovePhoto
    ),
    takeLatest(
      ItemGroup01List01Types.ITEM_GROUP01_LIST01_REORDER_BRANDS,
      itemGroup01List01ReorderBrands
    ),
    takeLatest(
      ItemGroup02List01Types.ITEM_GROUP02_LIST01_FETCH_ITEM_GROUP02_LIST,
      itemGroup02List01FetchItemGroup02List
    ),
    takeLatest(
      ItemGroup02List01Types.ITEM_GROUP02_LIST01_UPLOAD_PHOTO,
      itemGroup02List01UploadPhoto
    ),
    takeLatest(
      ItemGroup02List01Types.ITEM_GROUP02_LIST01_REMOVE_PHOTO,
      itemGroup02List01RemovePhoto
    ),
    takeLatest(
      ItemGroup03List01Types.ITEM_GROUP03_LIST01_FETCH_ITEM_GROUP03_LIST,
      itemGroup03List01FetchItemGroup03List
    ),
    takeLatest(
      ItemGroup03List01Types.ITEM_GROUP03_LIST01_UPLOAD_PHOTO,
      itemGroup03List01UploadPhoto
    ),
    takeLatest(
      ItemGroup03List01Types.ITEM_GROUP03_LIST01_REMOVE_PHOTO,
      itemGroup03List01RemovePhoto
    ),
    takeLatest(PromoIndexTypes.PROMO_INDEX_FETCH_ALL_PROMOTIONS, promoIndexFetchAllPromotions),
    takeLatest(PromoIndexTypes.PROMO_INDEX_REORDER_PROMOTIONS, promoIndexReorderPromotions),
    takeLatest(PromoDiscListTypes.PROMO_DISC_LIST_FETCH_PROMO_LIST, promoListFetchPromoDiscList),
    takeLatest(PromoDiscListTypes.PROMO_DISC_LIST_UPLOAD_PHOTO, promoListUploadPhoto),
    takeLatest(PromoDiscListTypes.PROMO_DISC_LIST_REMOVE_PHOTO, promoListRemovePhoto),
    takeLatest(PromoDiscListTypes.PROMO_DISC_LIST_GO_TO_AUDIT, promoListGoToAudit),
    takeLatest(PromoDiscListTypes.PROMO_DISC_LIST_NEW_DOCUMENT, promoListNewDocument),
    takeLatest(PromoDiscListTypes.PROMO_DISC_LIST_GO_TO_DOCUMENT, promoListGoToDocument),
    takeLatest(PromoDiscListTypes.PROMO_DISC_LIST_GO_TO_BROADCAST, promoListGoToBroadcast),
    takeLatest(PromoFocListTypes.PROMO_FOC_LIST_FETCH_PROMO_LIST, promoListFetchPromoFocList),
    takeLatest(PromoFocListTypes.PROMO_FOC_LIST_GO_TO_AUDIT, promoListGoToAudit),
    takeLatest(PromoFocListTypes.PROMO_FOC_LIST_NEW_DOCUMENT, promoListNewDocument),
    takeLatest(PromoFocListTypes.PROMO_FOC_LIST_GO_TO_DOCUMENT, promoListGoToDocument),

    takeLatest(PromoDetailTypes.PROMO_DETAIL_INIT_HEADER, promoDetailInitHeader),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_SHOW_HEADER, promoDetailShowHeader),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_CREATE_HEADER, promoDetailCreateHeader),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_UPDATE_HEADER, promoDetailUpdateHeader),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_DELETE_HEADER, promoDetailDeleteHeader),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_FETCH_ITEM_OPTIONS, promoDetailFetchItemOptions),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_FETCH_BRAND_OPTIONS, promoDetailFetchBrandOptions),
    takeLatest(
      PromoDetailTypes.PROMO_DETAIL_FETCH_CATEGORY_OPTIONS,
      promoDetailFetchCategoryOptions
    ),
    takeLatest(
      PromoDetailTypes.PROMO_DETAIL_FETCH_MANUFACTURER_OPTIONS,
      promoDetailFetchManufacturerOptions
    ),
    takeLatest(
      PromoDetailTypes.PROMO_DETAIL_FETCH_BROADCAST_DEBTOR_OPTIONS,
      promoDetailFetchBroadcastDebtorOptions
    ),
    takeLatest(
      PromoDetailTypes.PROMO_DETAIL_FETCH_BROADCAST_DEBTOR_GROUP_OPTIONS,
      promoDetailFetchBroadcastDebtorGroupOptions
    ),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_FETCH_DEBTOR_OPTIONS, promoDetailFetchDebtorOptions),
    takeLatest(
      PromoDetailTypes.PROMO_DETAIL_FETCH_DEBTOR_GROUP_OPTIONS,
      promoDetailFetchDebtorGroupOptions
    ),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_SHOW_DETAILS, promoDetailShowDetails),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_SHOW_DETAILS_MAIN, promoDetailShowDetailsMain),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_SHOW_DETAILS_ADD_ON, promoDetailShowDetailsAddOn),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_CREATE_DETAIL, promoDetailCreateDetail),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_UPDATE_DETAILS, promoDetailUpdateDetails),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_DELETE_DETAIL, promoDetailDeleteDetail),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_BROADCAST_PROMO, promoDetailBroadcastPromo),
    takeLatest(
      PromoDetailTypes.PROMO_DETAIL_FETCH_VARIANT_TYPE_OPTIONS,
      promoDetailFetchVariantTypeOptions
    ),
    takeLatest(
      PromoDetailTypes.PROMO_DETAIL_FETCH_RULE_TYPE_OPTIONS,
      promoDetailFetchRuleTypeOptions
    ),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_CHANGE_ITEM, promoDetailChangeItem),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_CHANGE_ITEM_GROUP, promoDetailChangeItemGroup),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_CREATE_RULE, promoDetailCreateRule),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_UPDATE_RULE, promoDetailUpdateRule),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_DELETE_RULE, promoDetailDeleteRule),
    takeLatest(PromoDetailTypes.PROMO_DETAIL_SHOW_RULES, promoDetailShowRules),

    // PromoProcess
    takeLatest(PromoProcessTypes.PROMO_PROCESS_FETCH_PROMO_PROCESS, promoProcessFetchPromoProcess),
    // PromoSync01
    takeLatest(PromoSync01Types.PROMO_SYNC01_SHOW_BATCH_JOB_STATUS, promoSync01ShowBatchJobStatus),
    takeLatest(PromoSync01Types.PROMO_SYNC01_SHOW_SYNC_SETTING, promoSync01ShowSyncSetting),
    takeLatest(PromoSync01Types.PROMO_SYNC01_SYNC_NOW, promoSync01SyncNow),
    takeLatest(PromoSync01Types.PROMO_SYNC01_UPDATE_SYNC_SETTING, promoSync01UpdateSyncSetting)
  ]);
}
