/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const itemDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const itemDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const itemDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const itemDetailUpdateHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const itemDetailFetchBrandOptionLoading = (state, { boolean }) => ({
  ...state,
  brandIsLoading: boolean
});

export const itemDetailFetchBrandOptionSuccess = (state, { options }) => ({
  ...state,
  brandOptions: options
});

export const itemDetailFetchCategoryOptionLoading = (state, { boolean }) => ({
  ...state,
  categoryIsLoading: boolean
});

export const itemDetailFetchCategoryOptionSuccess = (state, { options }) => ({
  ...state,
  categoryOptions: options
});

export const itemDetailFetchManufacturerOptionLoading = (state, { boolean }) => ({
  ...state,
  manufacturerIsLoading: boolean
});

export const itemDetailFetchManufacturerOptionSuccess = (state, { options }) => ({
  ...state,
  manufacturerOptions: options
});

// export const itemDetailFetchDebtorOptionLoading = (state, { boolean }) => ({
//   ...state,
//   debtorIsLoading: boolean
// });

// export const itemDetailFetchDebtorOptionSuccess = (state, { options }) => ({
//   ...state,
//   debtorOptions: options
// });

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemDetailTypes.ITEM_DETAIL_RESET_TIMESTAMP]: itemDetailResetTimestamp,
  [ItemDetailTypes.ITEM_DETAIL_SET_HDR_ID]: itemDetailSetHdrId,
  [ItemDetailTypes.ITEM_DETAIL_SHOW_HEADER_SUCCESS]: itemDetailShowHeaderSuccess,
  [ItemDetailTypes.ITEM_DETAIL_SHOW_DOCUMENT_LOADING]: itemDetailShowDocumentLoading,
  [ItemDetailTypes.ITEM_DETAIL_UPDATE_HEADER_SUCCESS]: itemDetailUpdateHeaderSuccess,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_BRAND_OPTION_LOADING]: itemDetailFetchBrandOptionLoading,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_BRAND_OPTION_SUCCESS]: itemDetailFetchBrandOptionSuccess,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_CATEGORY_OPTION_LOADING]: itemDetailFetchCategoryOptionLoading,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_CATEGORY_OPTION_SUCCESS]: itemDetailFetchCategoryOptionSuccess,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_MANUFACTURER_OPTION_LOADING]: itemDetailFetchManufacturerOptionLoading,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_MANUFACTURER_OPTION_SUCCESS]: itemDetailFetchManufacturerOptionSuccess

  // [ItemDetailTypes.ITEM_DETAIL_FETCH_DEBTOR_OPTION_LOADING]: itemDetailFetchDebtorOptionLoading,
  // [ItemDetailTypes.ITEM_DETAIL_FETCH_DEBTOR_OPTION_SUCCESS]: itemDetailFetchDebtorOptionSuccess,
});
