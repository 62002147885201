import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob15DetailResetTimestamp: null,
  whseJob15DetailSetHdrId: ['hdrId'],

  whseJob15DetailInitHeader: ['siteFlowId'],
  whseJob15DetailShowHeader: ['hdrId'],
  whseJob15DetailShowHeaderSuccess: ['documentHeader'],

  whseJob15DetailUpdateHeader: ['formikBag', 'documentHeader'],
  whseJob15DetailCreateHeader: ['formikBag', 'documentHeader'],
  whseJob15DetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  whseJob15DetailShowDocumentLoading: ['boolean'],
  whseJob15DetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  whseJob15DetailShowDetails: ['hdrId'],
  whseJob15DetailShowDetailsSuccess: ['documentDetails'],

  whseJob15DetailSetDetailVisible: ['boolean'],
  whseJob15DetailSetDocumentDetail: ['documentDetail'],

  whseJob15DetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  whseJob15DetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  whseJob15DetailDeleteDetail: ['hdrId', 'documentDetail'],

  whseJob15DetailSetWhseJobType: ['whseJobType'],

  whseJob15DetailFetchFrStorageBinOptions: ['siteFlowId', 'search'],
  whseJob15DetailFetchFrStorageBinOptionLoading: ['boolean'],
  whseJob15DetailFetchFrStorageBinOptionSuccess: ['options'],

  whseJob15DetailFetchQuantBalOptions: ['storageBinId', 'search'],
  whseJob15DetailFetchQuantBalOptionLoading: ['boolean'],
  whseJob15DetailFetchQuantBalOptionSuccess: ['options'],
  whseJob15DetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  whseJob15DetailFetchUomOptions: ['itemId', 'search'],
  whseJob15DetailFetchUomOptionLoading: ['boolean'],
  whseJob15DetailFetchUomOptionSuccess: ['options'],
  whseJob15DetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  whseJob15DetailFetchItemCond01Options: ['search'],
  whseJob15DetailFetchItemCond01OptionLoading: ['boolean'],
  whseJob15DetailFetchItemCond01OptionSuccess: ['options'],

  whseJob15DetailFetchToStorageBinOptions: ['siteFlowId', 'search'],
  whseJob15DetailFetchToStorageBinOptionLoading: ['boolean'],
  whseJob15DetailFetchToStorageBinOptionSuccess: ['options'],

  whseJob15DetailFetchWorkerOptions: ['search'],
  whseJob15DetailFetchWorkerOptionLoading: ['boolean'],
  whseJob15DetailFetchWorkerOptionSuccess: ['options']
});

export const WhseJob15DetailTypes = Types;
export default Creators;
