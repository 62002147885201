import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Card, Button, Row, Col } from 'antd';
import ChartHeader from './ChartHeader';

import DashboardActions from '../../Stores/Dashboard/Actions';

class DashboardScreen extends React.PureComponent {
  constructor() {
    super();
    this.updateSettings = this.updateSettings.bind(this);
  }

  componentDidMount() {
    const { fetchId, userId, FetchSummaryChartData } = this.props;

    fetchId(userId);

    FetchSummaryChartData();
  }

  updateSettings() {
    const {
      updateModel,
      createModel,
      userId,
      dashboardId,
      chartOneConfig,
      chartTwoConfig,
      chartThreeConfig,
      chartFourConfig,
      chartFiveConfig,
      chartSixConfig
    } = this.props;

    const tmp = {
      dashboardId,
      userId,
      chartOneConfig,
      chartTwoConfig,
      chartThreeConfig,
      chartFourConfig,
      chartFiveConfig,
      chartSixConfig
    };

    if (dashboardId > 0) {
      updateModel(tmp);
    } else {
      createModel(tmp);
    }
  }

  render() {
    const {
      savingStatus,
      retrieveModel,
      idDoneLoading,
      dashboardId,
      summaryChart,
      summaryChartIsLoading
    } = this.props;

    if (idDoneLoading) {
      retrieveModel(dashboardId);
    }

    return (
      <Card title="Dashboard">
        <Button
          type="primary"
          onClick={this.updateSettings}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          loading={savingStatus}
        >
          Save
        </Button>

        <Row>
          <Col span={6} style={{ height: 200, width: '25%' }}>
            <Card loading={summaryChartIsLoading}>
              <p>Total Sales This Month</p>
              {summaryChart.monthlySales}
            </Card>
          </Col>
          <Col span={6} style={{ height: 200, width: '25%' }}>
            <Card loading={summaryChartIsLoading}>
              <p>Total Sales This Year</p>
              {summaryChart.yearlySales}
            </Card>
          </Col>
          <Col span={6} style={{ height: 200, width: '25%' }}>
            <Card loading={summaryChartIsLoading}>
              <p>Total Orders This Month</p>
              {summaryChart.totalOrder}
            </Card>
          </Col>
          <Col span={6} style={{ height: 200, width: '25%' }}>
            <Card loading={summaryChartIsLoading}>
              <p>Total Outstanding Orders</p>
              {summaryChart.outstandingOrder}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <ChartHeader index={1} />
          </Col>
          <Col span={12}>
            <ChartHeader index={2} />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <ChartHeader index={3} />
          </Col>
          <Col span={12}>
            <ChartHeader index={4} />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <ChartHeader index={5} />
          </Col>
          <Col span={12}>
            <ChartHeader index={6} />
          </Col>
        </Row>
      </Card>
    );
  }
}

DashboardScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // intl: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  retrieveModel: PropTypes.func,
  fetchId: PropTypes.func,
  savingStatus: PropTypes.bool,
  idDoneLoading: PropTypes.bool,
  userId: PropTypes.number,
  dashboardId: PropTypes.number,
  chartOneConfig: PropTypes.shape({
    chart: PropTypes.string,
    sortBy: PropTypes.string,
    title: PropTypes.string,
    rawDate: PropTypes.object
  }),
  chartTwoConfig: PropTypes.shape({
    chart: PropTypes.string,
    sortBy: PropTypes.string,
    title: PropTypes.string,
    rawDate: PropTypes.object
  }),
  chartThreeConfig: PropTypes.shape({
    chart: PropTypes.string,
    sortBy: PropTypes.string,
    title: PropTypes.string,
    rawDate: PropTypes.object
  }),
  chartFourConfig: PropTypes.shape({
    chart: PropTypes.string,
    sortBy: PropTypes.string,
    title: PropTypes.string,
    rawDate: PropTypes.object
  }),
  chartFiveConfig: PropTypes.shape({
    chart: PropTypes.string,
    sortBy: PropTypes.string,
    title: PropTypes.string,
    rawDate: PropTypes.object
  }),
  chartSixConfig: PropTypes.shape({
    chart: PropTypes.string,
    sortBy: PropTypes.string,
    title: PropTypes.string,
    rawDate: PropTypes.object
  }),
  summaryChart: PropTypes.shape({
    monthlySales: PropTypes.number,
    yearlySales: PropTypes.number,
    totalOrder: PropTypes.number,
    outstandingOrder: PropTypes.number
  }),
  FetchSummaryChartData: PropTypes.func,
  summaryChartIsLoading: PropTypes.bool
};

DashboardScreen.defaultProps = {
  createModel: {},
  updateModel: {},
  retrieveModel: {},
  fetchId: {},
  savingStatus: false,
  idDoneLoading: false,
  userId: 0,
  dashboardId: 0,
  chartOneConfig: PropTypes.shape({
    chart: '',
    sortBy: '',
    title: '',
    rawDate: {}
  }),
  chartTwoConfig: PropTypes.shape({
    chart: '',
    sortBy: '',
    title: '',
    rawDate: {}
  }),
  chartThreeConfig: PropTypes.shape({
    chart: '',
    sortBy: '',
    title: '',
    rawDate: {}
  }),
  chartFourConfig: PropTypes.shape({
    chart: '',
    sortBy: '',
    title: '',
    rawDate: {}
  }),
  chartFiveConfig: PropTypes.shape({
    chart: '',
    sortBy: '',
    title: '',
    rawDate: {}
  }),
  chartSixConfig: PropTypes.shape({
    chart: '',
    sortBy: '',
    title: '',
    rawDate: {}
  }),
  summaryChart: {
    monthlySales: 0,
    yearlySales: 0,
    totalOrder: 0,
    outstandingOrder: 0
  },
  FetchSummaryChartData: {},
  summaryChartIsLoading: false
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  userId: state.app.user.id,
  idDoneLoading: state.dashboardDetail.idDoneLoading,
  dashboardId: state.dashboardDetail.id,
  savingStatus: state.dashboardDetail.savingStatus,
  chartOneConfig: state.dashboardDetail.chartOneConfig,
  chartTwoConfig: state.dashboardDetail.chartTwoConfig,
  chartThreeConfig: state.dashboardDetail.chartThreeConfig,
  chartFourConfig: state.dashboardDetail.chartFourConfig,
  chartFiveConfig: state.dashboardDetail.chartFiveConfig,
  chartSixConfig: state.dashboardDetail.chartSixConfig,
  summaryChart: state.dashboardDetail.summaryChart,
  summaryChartIsLoading: state.dashboardDetail.summaryChartIsLoading
});

const mapDispatchToProps = dispatch => ({
  createModel: setting => {
    dispatch(DashboardActions.dashboardCreateModel(setting));
  },
  updateModel: setting => {
    dispatch(DashboardActions.dashboardUpdateModel(setting));
  },
  fetchId: userId => {
    dispatch(DashboardActions.dashboardFetchId(userId));
  },
  retrieveModel: dashboardId => {
    dispatch(DashboardActions.dashboardRetrieveModel(dashboardId));
  },
  FetchSummaryChartData: () => {
    dispatch(DashboardActions.dashboardFetchSummaryChartData());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DashboardScreen));
