import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  binTrfIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  binTrfIndexGoToDocument: ['hdrId'],
  binTrfIndexGoToAudit: ['hdrId'],
  binTrfIndexFetchBinTrfIndex: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  binTrfIndexFetchBinTrfIndexLoading: ['boolean'],
  binTrfIndexFetchBinTrfIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  binTrfIndexAddSelectedDocuments: ['selectedDocuments'],
  binTrfIndexRemoveSelectedDocuments: ['selectedDocuments'],
  binTrfIndexSetWorkspaceVisible: ['boolean'],
  binTrfIndexPrintBinTrfIndex: ['siteFlowId', 'hdrIds'],
  binTrfIndexPrintBinTrfIndexLoading: ['boolean']
});

export const BinTrfIndexTypes = Types;
export default Creators;
