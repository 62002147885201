import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcpt02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt02GoToDocument: ['hdrId'],
  gdsRcpt02FetchGdsRcpt02: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt02FetchGdsRcpt02Loading: ['boolean'],
  gdsRcpt02FetchGdsRcpt02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsRcpt02AddSelectedDocuments: ['selectedDocuments'],
  gdsRcpt02RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcpt02SetWorkspaceVisible: ['boolean'],
  gdsRcpt02CreateGdsRcpt02: ['hdrIds'],
  gdsRcpt02CreateGdsRcpt02Loading: ['boolean'],
  gdsRcpt02CreateGdsRcpt02Success: ['newDocuments']
});

export const GdsRcpt02Types = Types;
export default Creators;
