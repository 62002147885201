import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdSync03ShowBatchJobStatus: null,
  slsOrdSync03ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsOrdSync03SyncNow: ['formikBag', 'divisionId'],
  slsOrdSync03SyncLoading: ['boolean']
});

export const SlsOrdSync03Types = Types;
export default Creators;
