import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageBinList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  storageBinList01GoToDocument: ['hdrId'],
  storageBinList01FetchStorageBinList01: [
    'siteFlowId',
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  storageBinList01FetchStorageBinList01Loading: ['boolean'],
  storageBinList01FetchStorageBinList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  storageBinList01AddSelectedDocuments: ['selectedDocuments'],
  storageBinList01RemoveSelectedDocuments: ['selectedDocuments'],
  storageBinList01SetWorkspaceVisible: ['boolean']
});

export const StorageBinList01Types = Types;
export default Creators;
