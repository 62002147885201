import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import treeChanges from 'tree-changes';

import { Card, Modal, Breadcrumb } from 'antd';
// import BannerForm from './BannerForm';
import HeaderForm from './HeaderForm';
import BroadcastForm from './BroadcastForm';
// import DetailForm from './DetailForm';
// import DetailAddOnForm from './DetailAddOnForm';
// import RuleTable from './RuleTable';
// import RuleForm from './RuleForm';
import PromoDetailActions from '../../Stores/PromoDetail/Actions';
import PromoDiscIndexActions from '../../Stores/PromoDiscIndex/Actions'

class BroadcastPromoScreen extends React.PureComponent {
    constructor() {
        super();
    }

    componentDidMount() {
        const { match, setHdrId } = this.props;

        setHdrId(parseInt(match.params.id, 10))
    }

    componentDidUpdate(prevProps) {
        const { changed } = treeChanges(prevProps, this.props);
        if (changed('timestamp')) {
            this.forceUpdate()
        }
    }

    componentWillUnmount() {}

    render() {
        const { intl, match, detailIsVisible, detailAddOnIsVisible, ruleIsVisible, documentHeader, appPath } = this.props;

        var title = intl.formatMessage({ id: 'broadcast_promo'});
        
        return (
        <Card title={title}>
            {/* <Breadcrumb></Breadcrumb>
            <BannerForm /> */}
            <HeaderForm match={match} />
            <BroadcastForm match={match} />
            {/*
            <Modal
            visible={detailIsVisible}
            title={intl.formatMessage({ id: 'promo_detail' })}
            // style={{top:20}}
            width="90%"
            centered
            forceRender
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            >
            <DetailForm match={match} promoType={documentHeader.type} />
            </Modal>
            
            <Modal
            visible={detailAddOnIsVisible}
            title={intl.formatMessage({ id: 'promo_detail_add_on' })}
            // style={{top:20}}
            width="90%"
            centered
            forceRender
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            >
            <DetailAddOnForm match={match} promoType={documentHeader.type} />
            </Modal>

            <RuleTable match={match} />
            <Modal
            visible={ruleIsVisible}
            title={intl.formatMessage({ id: 'promo_rule' })}
            // style={{top:20}}
            width="90%"
            centered
            forceRender
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            >
            <RuleForm match={match} />
            </Modal> */}
        </Card>
        );
    }
}

BroadcastPromoScreen.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    intl: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    match: PropTypes.object,
    setHdrId: PropTypes.func,
    setDetailVisible: PropTypes.func,
    setDetailAddOnVisible: PropTypes.func,
    setRuleVisible: PropTypes.func,
    documentIsLoading: PropTypes.bool,
    detailIsVisible: PropTypes.bool,
    detailAddOnIsVisible: PropTypes.bool,
    ruleIsVisible: PropTypes.bool,
    documentHeader: PropTypes.shape({
        doc_flows: PropTypes.arrayOf(PropTypes.object)
    }),
    appPath: PropTypes.string
};

BroadcastPromoScreen.defaultProps = {
    intl: {},
    match: {},
    setHdrId() {},
    setDetailVisible() {},
    setDetailAddOnVisible() {},
    setRuleVisible() {},
    documentIsLoading: false,
    detailIsVisible: false,
    detailAddOnIsVisible: false,
    ruleIsVisible: false,
    documentHeader: { doc_flows: [] },
    appPath: ''
};

const mapStateToProps = state => ({
    documentIsLoading: state.promoDetail.documentIsLoading,
    detailIsVisible: state.promoDetail.detailIsVisible,
    detailAddOnIsVisible: state.promoDetail.detailAddOnIsVisible,
    ruleIsVisible: state.promoDetail.ruleIsVisible,
    documentHeader: state.promoDetail.documentHeader,
    appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
    setHdrId: hdrId => dispatch(PromoDetailActions.promoDetailSetHdrId(hdrId)),
    setDetailVisible: boolean => dispatch(PromoDetailActions.promoDetailSetDetailVisible(boolean)),
    setDetailAddOnVisible: boolean => dispatch(PromoDetailActions.promoDetailSetDetailAddOnVisible(boolean)),
    setRuleVisible: boolean => dispatch(PromoDetailActions.promoDetailSetRuleVisible(boolean)),
    resetUploadHold: (boolean, fileList) => dispatch(PromoDiscIndexActions.promoDiscListUploadPhotoHold(boolean, fileList))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(BroadcastPromoScreen));
