import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import DebtorProcessActions from '../../Stores/DebtorProcess/Actions';

import DebtorList01Table from '../DebtorList01/DebtorList01Table';
import DebtorSync01Form from '../DebtorSync01/DebtorSync01Form';

const { TabPane } = Tabs;

class DebtorProcessDeletedScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchDebtorProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchDebtorProcess(curSiteFlowId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchDebtorProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchDebtorProcess(curSiteFlowId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          {process.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                >
                  <Link
                    style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                    to={`${appPath}/debtorProcess/index/${value.proc_type}`}
                  >
                    <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                  </Link>
                </Tooltip>
              }
              key={value.proc_type}
            />
          ))}
        </Tabs>

        {match.params.proc_type === 'DEBTOR_LIST_01' && <DebtorList01Table match={match} />}
        {match.params.proc_type === 'DEBTOR_SYNC_01' && <DebtorSync01Form match={match} />}
      </Card>
    );
  }
}

DebtorProcessDeletedScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number,
  fetchDebtorProcess: PropTypes.func
};

DebtorProcessDeletedScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curSiteFlowId: 0,
  fetchDebtorProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.debtorProcess.process,
  processIsLoading: state.debtorProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchDebtorProcess: siteFlowId =>
    dispatch(DebtorProcessActions.debtorProcessFetchDebtorProcess(siteFlowId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DebtorProcessDeletedScreen));
