import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdSync02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'criteria', 'pageSize'],
  slsOrdSync02FetchSlsOrdIndex: ['divisionId', 'currentPage', 'sorts', 'filters', 'criteria', 'pageSize'],
  slsOrdSync02FetchSlsOrdIndexLoading: ['boolean'],
  slsOrdSync02FetchSlsOrdIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  slsOrdSync02AddSelectedDocuments: ['selectedDocuments'],
  slsOrdSync02RemoveSelectedDocuments: ['selectedDocuments'],
  slsOrdSync02GoToDocument: ['hdrId'],

  slsOrdSync02FetchSlsOrdIndexSearch: ['formikBag', 'criteria', 'divisionId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdSync02FetchSlsOrdIndexSearchSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize', 'criteria'],

  slsOrdSync02ShowBatchJobStatus: null,
  slsOrdSync02ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsOrdSync02SyncNow: ['divisionId', 'selectedDocuments'],
  slsOrdSync02SyncLoading: ['boolean']
});

export const SlsOrdSync02Types = Types;
export default Creators;
