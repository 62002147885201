import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  warehouseMapResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  warehouseMapSavePageSize: ['pageSize'],
  warehouseMapFetchStorageBinList02: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  warehouseMapFetchStorageBinList02Loading: ['boolean'],
  warehouseMapFetchStorageBinList02Success: [
    'rowLevels',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  warehouseMapSelectStorageBin: ['storageBin', 'quantBals'],
  warehouseMapSetWorkspaceVisible: ['boolean']
});

export const WarehouseMapTypes = Types;
export default Creators;
