/* eslint-disable react/jsx-indent-props */
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Row, Col, Table, Button, Badge, Tag, Menu, Icon, Dropdown, Popconfirm, Modal } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import DebtorList01Actions from '../../Stores/DebtorList01/Actions';
import DeliveryPointActions from '../../Stores/DeliveryPoint/Actions';
import ManageDivisionDialog from '../DebtorList01/ManageDivisionDialog';
import DeliveryPointForm from '../DebtorList01/DeliveryPointForm';

class DebtorList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);
    this.useCreateAddress = this.useCreateAddress.bind(this);
    this.useOnSelectDeliveryPoint = this.useOnSelectDeliveryPoint.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0 || curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      curSiteFlowId,
      resetTimestamp,
      fetchDebtorList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0 || curDivisionId > 0) {
        fetchDebtorList01(curDivisionId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const {
      intl,
      curDivisionId,
      currentPage,
      sorts,
      filters,
      pageSize,
      goToDocument,
      changeStatus,
      goToAudit
    } = this.props;

    function menu(record) { return (
      <Menu>
        <Menu.Item key="0">
          <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_restore_this_line' })}
              onConfirm={() => changeStatus(record.id, 100, curDivisionId, currentPage, sorts, filters, pageSize)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <a ><Icon type="delete" /> Undo</a>
          </Popconfirm>
        </Menu.Item>        
        <Menu.Divider />

        <Menu.Item key="1">
          <a onClick={() => goToAudit(record.id)}><Icon type="audit" /> Audit</a>
        </Menu.Item>
      </Menu>
    )};

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'company_name_01'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'company_name_01' }),
        // sort field
        dataIndex: 'company_name_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'company_name_01'),
        // filter field
        key: 'company_name_01',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'company_name_01' }),
          'company_name_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.company_name_01}</>
      },
      {
        width: 250,
        align: 'left',
        title: intl.formatMessage({ id: 'billing_address' }),
        // sort field
        dataIndex: 'address',
        ...AntDesignTable.getColumnSortProps(sorts, 'address'),
        // filter field
        key: 'address',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'address' }),
          'address',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <div dangerouslySetInnerHTML={{ __html: record.address_html }}></div>
          </>
        )
      },
      // {
      //   width: 150,
      //   align: 'left',
      //   title: intl.formatMessage({ id: 'area' }),
      //   // sort field
      //   dataIndex: 'area_code',
      //   ...AntDesignTable.getColumnSortProps(sorts, 'area_code'),
      //   // filter field
      //   key: 'area_code',
      //   ...AntDesignTable.getColumnSearchProps(
      //     filters,
      //     intl.formatMessage({ id: 'area' }),
      //     'area_code',
      //     this.handleSearch,
      //     this.handleReset
      //   ),
      //   render: (text, record) => <>{record.area_code}</>
      // },
      {
        width: 80,
        //fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'divisions',
        // filter field
        key: 'divisions',
        render: (text, record) => (
          <div>
            {record.divisions.map(value => (
              <Tag key={value.id}>{value.code}</Tag>
            ))}
          </div>
        )
      },
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_status'),
        // filter field
        key: 'str_status',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'status' }),
          'str_status',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
          {record.str_status == `DELETED` ?
          <span style={{backgroundColor: '#FF0000', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{record.str_status}</span> :
          <span style={{backgroundColor: '#707B7C', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{record.str_status}</span>}
          </>
        )
      },
      {
        width: 60,
        // fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Actions <Icon type="down" />
              </a>
            </Dropdown>
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  useCreateAddress(debtorId) {
    const { deliveryPointSetVisible, deliveryPointInitDocumentDetail } = this.props;

    deliveryPointInitDocumentDetail(debtorId);
    deliveryPointSetVisible(true);
  }

  useOnSelectDeliveryPoint(record) {
    const { deliveryPointSetVisible, deliveryPointSetDocumentDetail } = this.props;

    deliveryPointSetDocumentDetail(record);
    deliveryPointSetVisible(true);
  }

  handleCancel() {
    const { deliveryPointSetVisible } = this.props;

    deliveryPointSetVisible(false);
  }


  render() {
    const {
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      expandedRows,
      setExpandedRows,
      intl,
      deliveryPointIsVisible,
      debtorId
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          // rowSelection={{
          //   selectedRowKeys: selectedDocuments.map(value => value.id),
          //   onChange: () => {
          //     // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          //   },
          //   onSelect: (record, selected) => {
          //     // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
          //     if (selected === true) {
          //       addSelectedDocuments([record]);
          //     } else {
          //       removeSelectedDocuments([record]);
          //     }
          //   },
          //   onSelectAll: (selected, selectedRows, changeRows) => {
          //     // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
          //     if (selected === true) {
          //       addSelectedDocuments(changeRows);
          //     } else {
          //       removeSelectedDocuments(changeRows);
          //     }
          //   },
          //   onSelectInvert: selectedRows => {
          //     // console.log('onSelectInvert selectedRows: ', selectedRows);
          //     removeSelectedDocuments(selectedRows);
          //   },
          //   getCheckboxProps: () => ({
          //     // Column configuration not to be checked
          //     disabled: true
          //     // name: record.name,
          //   })
          // }}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px'
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon="laptop"
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>
        <Modal
          visible={deliveryPointIsVisible}
          title={intl.formatMessage({ id: 'delivery_point' })}
          // style={{top:20}}
          width="90%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <DeliveryPointForm debtorId={debtorId} />
        </Modal>
        <ManageDivisionDialog />
      </>
    );
  }
}

DebtorList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  newDocument: PropTypes.func,
  goToDocument: PropTypes.func,
  changeStatus: PropTypes.func,
  goToAudit: PropTypes.func,

  debtorId: PropTypes.number,
  deliveryPointSetVisible: PropTypes.func,
  deliveryPointInitDocumentDetail: PropTypes.func,
  deliveryPointSetDocumentDetail: PropTypes.func,
  deliveryPointSetDebtorId: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  setManageDivisionVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  curDivisionId: PropTypes.number,
  fetchDebtorList01: PropTypes.func,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func
};

DebtorList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  newDocument() {},
  goToDocument() {},
  changeStatus() {},
  goToAudit() {},

  debtorId: 0,
  deliveryPointSetVisible() {},
  deliveryPointInitDocumentDetail() {},
  deliveryPointSetDocumentDetail() {},
  deliveryPointSetDebtorId() {},

  setWorkspaceVisible() {},

  setManageDivisionVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  curDivisionId: 0,
  fetchDebtorList01() {},

  expandedRows: [],
  setExpandedRows() {}
};

const mapStateToProps = state => ({
  timestamp: state.debtorList01.timestamp,
  workspaceIsVisible: state.debtorList01.workspaceIsVisible,

  documents: state.debtorList01.documents,
  selectedDocuments: state.debtorList01.selectedDocuments,

  debtorId: state.deliveryPoint.debtorId,
  deliveryPointIsVisible: state.deliveryPoint.deliveryPointIsVisible,

  sorts: state.debtorList01.sorts,
  filters: state.debtorList01.filters,

  currentPage: state.debtorList01.currentPage,
  pageSize: state.debtorList01.pageSize,
  total: state.debtorList01.total,
  fetchIsLoading: state.debtorList01.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId,
  curDivisionId: state.app.curDivisionId,

  expandedRows: state.debtorList01.expandedRows,

  process: state.debtorProcess.process,
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(DebtorList01Actions.debtorList01ResetTimestamp(currentPage, sorts, filters, pageSize)),
  newDocument: hdrId => dispatch(DebtorList01Actions.debtorList01NewDocument(hdrId)),
  goToDocument: hdrId => dispatch(DebtorList01Actions.debtorList01GoToDocument(hdrId)),
  changeStatus: (hdrId, status, currentPage, sorts, filters, pageSize) =>
    dispatch(DebtorList01Actions.debtorList01ChangeStatus(hdrId, status, currentPage, sorts, filters, pageSize)),
  goToAudit: hdrId => dispatch(DebtorList01Actions.debtorList01GoToAudit(hdrId)),

  deliveryPointSetVisible: boolean =>
    dispatch(DeliveryPointActions.deliveryPointSetVisible(boolean)),
  deliveryPointInitDocumentDetail: debtorId =>
    dispatch(DeliveryPointActions.deliveryPointInitDocumentDetail(debtorId)),
  deliveryPointSetDocumentDetail: documentDetail =>
    dispatch(DeliveryPointActions.deliveryPointSetDocumentDetail(documentDetail)),
  deliveryPointSetDebtorId: debtorId =>
    dispatch(DeliveryPointActions.deliveryPointSetDebtorId(debtorId)),

  setWorkspaceVisible: boolean =>
    dispatch(DebtorList01Actions.debtorList01SetWorkspaceVisible(boolean)),

  setManageDivisionVisible: (document, boolean) =>
    dispatch(DebtorList01Actions.debtorList01SetManageDivisionVisible(document, boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(DebtorList01Actions.debtorList01AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(DebtorList01Actions.debtorList01RemoveSelectedDocuments(selectedDocuments)),

  fetchDebtorList01: (divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      DebtorList01Actions.debtorList01FetchDebtorList01(
        divisionId,
        currentPage,
        sorts,
        filters,
        pageSize,
        'Deleted'
      )
    ),

  setExpandedRows: expandedRows =>
    dispatch(DebtorList01Actions.debtorList01SetExpandedRows(expandedRows))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DebtorList01Table));
