import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcpt01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt01GoToDocument: ['hdrId'],
  gdsRcpt01FetchGdsRcpt01: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt01FetchGdsRcpt01Loading: ['boolean'],
  gdsRcpt01FetchGdsRcpt01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsRcpt01AddSelectedDocuments: ['selectedDocuments'],
  gdsRcpt01RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcpt01SetWorkspaceVisible: ['boolean'],
  gdsRcpt01CreateGdsRcpt01: ['hdrIds', 'toStorageBinId'],
  gdsRcpt01CreateGdsRcpt01Loading: ['boolean'],
  gdsRcpt01CreateGdsRcpt01Success: ['newDocuments'],

  gdsRcpt01FetchToStorageBinOptions: ['siteFlowId', 'search'],
  gdsRcpt01FetchToStorageBinOptionLoading: ['boolean'],
  gdsRcpt01FetchToStorageBinOptionSuccess: ['options'],
  gdsRcpt01SetToStorageBinOption: ['option']
});

export const GdsRcpt01Types = Types;
export default Creators;
