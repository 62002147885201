import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import ItemList01Actions from '../Stores/ItemList01/Actions';

const getAppStore = state => state.app;
const getItemList01SelectedDocumentStore = state => state.itemList01.selectedDocument;
const getItemList01DocumentsStore = state => state.itemList01.documents;

export function* itemList01FetchItemList01({
  divisionId,
  currentPage,
  sorts,
  filters,
  pageSize,
  status
}) {
  try {
    yield put(ItemList01Actions.itemList01FetchItemList01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize,
      status
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `item/indexDivision/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        ItemList01Actions.itemList01FetchItemList01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemList01Actions.itemList01FetchItemList01Loading(false));
  }
}

export function* itemList01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/itemDetail/update/${hdrId}`));
}

export function* itemList01NewDocument({ promoType }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/itemDetail/create/0`));
}

export function* itemList01GoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/ITEM/${hdrId}/Item`));
}

export function* itemList01UploadPhoto({ itemId, fileList }) {
  try {
    yield put(ItemList01Actions.itemList01UploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    fileList.forEach(file => {
      if (Math.sign(file.uid) == -1) {
      } else {
        postData.append('files[]', file.originFileObj, file.name);
      }
    });

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/uploadPhotos/${itemId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      const itemList01SelectedDocument = yield select(getItemList01SelectedDocumentStore);

      const { photos: oldPhotos } = itemList01SelectedDocument;

      const { photos: retPhotos, deleted_photos: retDeletedPhotos } = result.data;

      const processed = UtilService.processResources(oldPhotos, retPhotos, retDeletedPhotos);

      yield put(ItemList01Actions.itemList01ManagePhotoSuccess(processed.resources));

      yield put(ItemList01Actions.itemList01ResetTimestamp());

      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemList01Actions.itemList01UploadLoading(false));
  }
}

export function* itemList01RemovePhoto({ itemId, itemPhotoId }) {
  try {
    yield put(ItemList01Actions.itemList01ManagePhotoLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    itemPhotoId = itemPhotoId * -1;
    const postData = {
      data: [{ item_photo_id: itemPhotoId }]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/deletePhotos/${itemId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const itemList01SelectedDocument = yield select(getItemList01SelectedDocumentStore);

      const { photos: oldPhotos } = itemList01SelectedDocument;

      const { photos: retPhotos, deleted_photos: retDeletedPhotos } = result.data;

      const processed = UtilService.processResources(oldPhotos, retPhotos, retDeletedPhotos);

      yield put(ItemList01Actions.itemList01ManagePhotoSuccess(processed.resources));

      yield put(ItemList01Actions.itemList01ResetTimestamp());

      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemList01Actions.itemList01ManagePhotoLoading(false));
  }
}

export function* itemList01UpdateItem({ id, status, currentPage, sorts, filters, pageSize }) {
  try {
    yield put(ItemList01Actions.itemList01FetchItemList01Loading(true));
    const app = yield select(getAppStore);

    const postData = {
      data: { id: id, str_status: status }
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'item/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const itemList01Documents = yield select(getItemList01DocumentsStore);

      const {
        model: retDocumentHeader
      } = result.data;

      const processed = UtilService.processResources(
        itemList01Documents,
        [retDocumentHeader],
        []
      );

      yield put(
        ItemList01Actions.itemList01FetchItemList01Success(
          processed.resources, currentPage, sorts, filters, pageSize)
      );

    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemList01Actions.itemList01FetchItemList01Loading(false));
    yield put(ItemList01Actions.itemList01ResetTimestamp());
  }
}
