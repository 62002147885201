import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import StockBalanceReportActions from '../Stores/StockBalanceReport/Actions';

const getAppStore = state => state.app;

export function* stockBalanceReportInitStockBalance({ siteFlowId }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initStockBalance/${siteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StockBalanceReportActions.stockBalanceReportInitStockBalanceSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportReportLoading(false));
  }
}

export function* stockBalanceReportStockBalance({ formikBag, siteFlowId, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(StockBalanceReportActions.stockBalanceReportReportLoading(true));

    const objCriteria = {};
    if ('date' in criteria) {
      objCriteria.date = criteria.date;
    }
    if ('is_show_zero_balance' in criteria) {
      objCriteria.is_show_zero_balance = criteria.is_show_zero_balance;
    }
    if ('expiry_days' in criteria) {
      objCriteria.expiry_days = criteria.expiry_days;
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/stockBalance/${siteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        StockBalanceReportActions.stockBalanceReportStockBalanceSuccess(criteria, result.data.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StockBalanceReportActions.stockBalanceReportReportLoading(false));
  }
}

export function* stockBalanceReportFetchItemOptions({ search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemOptionLoading(false));
  }
}

export function* stockBalanceReportFetchItemGroup01Options({ search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup01OptionLoading(false));
  }
}

export function* stockBalanceReportFetchItemGroup02Options({ search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup02OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup02OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup02OptionLoading(false));
  }
}

export function* stockBalanceReportFetchItemGroup03Options({ search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup03OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup03OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchItemGroup03OptionLoading(false));
  }
}

export function* stockBalanceReportFetchStorageBinOptions({ siteFlowId, search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchStorageBinOptionLoading(false));
  }
}

export function* stockBalanceReportFetchStorageRowOptions({ siteFlowId, search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchStorageRowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchStorageRowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchStorageRowOptionLoading(false));
  }
}

export function* stockBalanceReportFetchStorageBayOptions({ siteFlowId, search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchStorageBayOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchStorageBayOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchStorageBayOptionLoading(false));
  }
}

export function* stockBalanceReportFetchLocationOptions({ siteFlowId, search }) {
  try {
    yield put(StockBalanceReportActions.stockBalanceReportFetchLocationOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockBalanceReportActions.stockBalanceReportFetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockBalanceReportActions.stockBalanceReportFetchLocationOptionLoading(false));
  }
}
