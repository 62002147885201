/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob14DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob14DetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const whseJob14DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const whseJob14DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const whseJob14DetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const whseJob14DetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const whseJob14DetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const whseJob14DetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const whseJob14DetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const whseJob14DetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const whseJob14DetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const whseJob14DetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const whseJob14DetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const whseJob14DetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const whseJob14DetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const whseJob14DetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const whseJob14DetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const whseJob14DetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const whseJob14DetailFetchItemCond01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemCond01IsLoading: boolean
});

export const whseJob14DetailFetchItemCond01OptionSuccess = (state, { options }) => ({
  ...state,
  itemCond01Options: options
});

export const whseJob14DetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const whseJob14DetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const whseJob14DetailFetchToHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  toHandlingUnitIsLoading: boolean
});

export const whseJob14DetailFetchToHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  toHandlingUnitOptions: options
});

// force refresh the formik form
export const whseJob14DetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const whseJob14DetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const whseJob14DetailFetchWorkerOptionLoading = (state, { boolean }) => ({
  ...state,
  workerIsLoading: boolean
});

export const whseJob14DetailFetchWorkerOptionSuccess = (state, { options }) => ({
  ...state,
  workerOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_RESET_TIMESTAMP]: whseJob14DetailResetTimestamp,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SET_HDR_ID]: whseJob14DetailSetHdrId,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SHOW_HEADER_SUCCESS]: whseJob14DetailShowHeaderSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_UPDATE_DOCUMENT_SUCCESS]: whseJob14DetailUpdateDocumentSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SHOW_DOCUMENT_LOADING]: whseJob14DetailShowDocumentLoading,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SHOW_DETAILS_SUCCESS]: whseJob14DetailShowDetailsSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SET_DETAIL_VISIBLE]: whseJob14DetailSetDetailVisible,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SET_DOCUMENT_DETAIL]: whseJob14DetailSetDocumentDetail,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_UPDATE_DETAILS]: whseJob14DetailUpdateDetails,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_CREATE_DETAIL]: whseJob14DetailCreateDetail,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_SET_WHSE_JOB_TYPE]: whseJob14DetailSetWhseJobType,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_COMPANY_OPTION_LOADING]: whseJob14DetailFetchCompanyOptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: whseJob14DetailFetchCompanyOptionSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_OPTION_LOADING]: whseJob14DetailFetchItemOptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: whseJob14DetailFetchItemOptionSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: whseJob14DetailFetchItemBatchOptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: whseJob14DetailFetchItemBatchOptionSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_UOM_OPTION_LOADING]: whseJob14DetailFetchUomOptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_UOM_OPTION_SUCCESS]: whseJob14DetailFetchUomOptionSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_COND01_OPTION_LOADING]: whseJob14DetailFetchItemCond01OptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_ITEM_COND01_OPTION_SUCCESS]: whseJob14DetailFetchItemCond01OptionSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: whseJob14DetailFetchToStorageBinOptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: whseJob14DetailFetchToStorageBinOptionSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_LOADING]: whseJob14DetailFetchToHandlingUnitOptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_SUCCESS]: whseJob14DetailFetchToHandlingUnitOptionSuccess,

  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_WORKER_OPTION_LOADING]: whseJob14DetailFetchWorkerOptionLoading,
  [WhseJob14DetailTypes.WHSE_JOB14_DETAIL_FETCH_WORKER_OPTION_SUCCESS]: whseJob14DetailFetchWorkerOptionSuccess
});
