import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemPriceSetVisible: ['boolean'],
  itemPriceSetItemId: ['itemId'],
  itemPriceInitDocumentDetail: ['itemId'],
  itemPriceSetDocumentDetail: ['documentDetail'],
  itemPriceCreate: ['formikBag', 'itemId', 'documentDetail'],
  itemPriceUpdate: ['formikBag', 'documentDetail'],
  itemPriceFetchUomOptions: ['itemId', 'search'],
  itemPriceFetchUomOptionsLoading: ['boolean'],
  itemPriceFetchUomOptionsSuccess: ['options'],
  itemPriceChangeUom: ['formikBag', 'uomId'],
  itemPriceDelete: ['id']
});

export const ItemPriceTypes = Types;
export default Creators;
