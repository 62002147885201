/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsOrdSync03Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdSync03ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const slsOrdSync03SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsOrdSync03Types.SLS_ORD_SYNC03_SHOW_BATCH_JOB_STATUS_SUCCESS]: slsOrdSync03ShowBatchJobStatusSuccess,
  [SlsOrdSync03Types.SLS_ORD_SYNC03_SYNC_LOADING]: slsOrdSync03SyncLoading
});
