import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userExcel03ShowBatchJobStatus: null,
  userExcel03ShowBatchJobStatusSuccess: ['batchJobStatus'],
  userExcel03UploadExcel: ['siteFlowId', 'divisionId', 'file'],
  userExcel03UploadLoading: ['boolean'],
  userExcel03DownloadExcel: ['siteFlowId', 'divisionId']
});

export const UserExcel03Types = Types;
export default Creators;
