/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { RtnRcptIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const rtnRcptIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const rtnRcptIndexFetchRtnRcptIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const rtnRcptIndexFetchRtnRcptIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const rtnRcptIndexAddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const rtnRcptIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const rtnRcptIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [RtnRcptIndexTypes.RTN_RCPT_INDEX_RESET_TIMESTAMP]: rtnRcptIndexResetTimestamp,
  [RtnRcptIndexTypes.RTN_RCPT_INDEX_FETCH_RTN_RCPT_INDEX_LOADING]: rtnRcptIndexFetchRtnRcptIndexLoading,
  [RtnRcptIndexTypes.RTN_RCPT_INDEX_FETCH_RTN_RCPT_INDEX_SUCCESS]: rtnRcptIndexFetchRtnRcptIndexSuccess,
  [RtnRcptIndexTypes.RTN_RCPT_INDEX_ADD_SELECTED_DOCUMENTS]: rtnRcptIndexAddSelectedDocuments,
  [RtnRcptIndexTypes.RTN_RCPT_INDEX_REMOVE_SELECTED_DOCUMENTS]: rtnRcptIndexRemoveSelectedDocuments,
  [RtnRcptIndexTypes.RTN_RCPT_INDEX_SET_WORKSPACE_VISIBLE]: rtnRcptIndexSetWorkspaceVisible
});
