import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Row, Col, Table, Button, Select } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import ItemGroup01List01Actions from '../../Stores/ItemGroup01List01/Actions';
import ManageItemGroup01PhotoDialog from './ManageItemGroup01PhotoDialog';

const { Option } = Select;

class ItemGroup01IndexScreen extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectFocus = this.handleSelectFocus.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;
    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchItemGroup01List,
      fetchItemGroup01ListAll,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchItemGroup01List(curDivisionId, currentPage, sorts, filters, pageSize);
        fetchItemGroup01ListAll(curDivisionId, 1, [], filters, 0);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, brands, goToDocument, setManagePhotoVisible, goToAudit } = this.props;
    return [
      {
        width: 60,
        align: 'left',
        title: intl.formatMessage({ id: 'order_by' }),
        // sort field
        dataIndex: 'sequence',
        ...AntDesignTable.getColumnSortProps(sorts, 'sequence'),
        // filter field
        key: 'sequence',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'order_by' }),
          'sequence',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => 
          <Select
            showSearch
            value={record.sequence}
            style={{width: 75}}
            optionFilterProp="children"
            onFocus={this.handleSelectFocus}
            onChange={this.handleSelectChange}
            filterOption={(input, option) => 
              option.props.children.toString().indexOf(input.toString()) >= 0
            }
          >
            {brands.map((brands) => 
              <Option value={brands.sequence} key={brands.sequence}>{brands.sequence}</Option>
            )}
          </Select>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.code
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc_01' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.desc_01
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_02'),
        // filter field
        key: 'desc_02',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc_02' }),
          'desc_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.desc_02
      },
      {
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon="edit"
              size="small"
              onClick={() => goToDocument(record.id)}
            />
            <Button
              type="dashed"
              size="small"
              icon="picture"
              onClick={() => setManagePhotoVisible(record, true)}
            />
            <Button type="dashed" icon="audit" size="small" onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  handleSelectChange(id) {
    const { reorderItemGroup01List } = this.props
    
    reorderItemGroup01List(id)
  }

  handleSelectFocus() {
    const { setSelectedBrand } = this.props
    setSelectedBrand(parseInt(document.activeElement.children[0].children[0].innerHTML))
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;
    return (
      <Card title={intl.formatMessage({ id: 'brands' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          // rowSelection={{
          //   selectedRowKeys: selectedDocuments.map(value => value.id),
          //   onChange: () => {
          //     // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          //   },
          //   onSelect: (record, selected) => {
          //     // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
          //     if (selected === true) {
          //       addSelectedDocuments([record]);
          //     } else {
          //       removeSelectedDocuments([record]);
          //     }
          //   },
          //   onSelectAll: (selected, selectedRows, changeRows) => {
          //     // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
          //     if (selected === true) {
          //       addSelectedDocuments(changeRows);
          //     } else {
          //       removeSelectedDocuments(changeRows);
          //     }
          //   },
          //   onSelectInvert: selectedRows => {
          //     // console.log('onSelectInvert selectedRows: ', selectedRows);
          //     removeSelectedDocuments(selectedRows);
          //   },
          //   getCheckboxProps: () => ({
          //     // Column configuration not to be checked
          //     disabled: true
          //     // name: record.name,
          //   })
          // }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
        <ManageItemGroup01PhotoDialog />
      </Card>
    );
  }
}

ItemGroup01IndexScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  setManagePhotoVisible: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  brands: PropTypes.arrayOf(PropTypes.object),
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchItemGroup01List: PropTypes.func,
  fetchItemGroup01ListAll: PropTypes.func,
  setSelectedBrand: PropTypes.func,
  reorderItemGroup01List: PropTypes.func,
};

ItemGroup01IndexScreen.defaultProps = {
  intl: {},
  documents: [],
  brands: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,

  resetTimestamp() {},
  goToDocument() {},
  setManagePhotoVisible() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  fetchItemGroup01List() {},
  fetchItemGroup01ListAll() {},
  setSelectedBrand() {},
  reorderItemGroup01List() {},
};

const mapStateToProps = state => ({
  timestamp: state.itemGroup01List01.timestamp,
  workspaceIsVisible: state.itemGroup01List01.workspaceIsVisible,

  documents: state.itemGroup01List01.documents,
  selectedDocuments: state.itemGroup01List01.selectedDocuments,
  brands: state.itemGroup01List01.brands,

  sorts: state.itemGroup01List01.sorts,
  filters: state.itemGroup01List01.filters,

  currentPage: state.itemGroup01List01.currentPage,
  pageSize: state.itemGroup01List01.pageSize,
  total: state.itemGroup01List01.total,
  fetchIsLoading: state.itemGroup01List01.fetchIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemGroup01List01Actions.itemGroup01List01ResetTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  goToDocument: hdrId => dispatch(ItemGroup01List01Actions.itemGroup01List01GoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(ItemGroup01List01Actions.itemGroup01List01GoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(ItemGroup01List01Actions.itemGroup01List01SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(ItemGroup01List01Actions.itemGroup01List01AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(ItemGroup01List01Actions.itemGroup01List01RemoveSelectedDocuments(selectedDocuments)),

  fetchItemGroup01List: (divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemGroup01List01Actions.itemGroup01List01FetchItemGroup01List(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

    fetchItemGroup01ListAll: (divisionId, currentPage, sorts, filters, pageSize) =>
      dispatch(
        ItemGroup01List01Actions.itemGroup01List01FetchItemGroup01ListAll(
          currentPage,
          sorts,
          filters,
          pageSize
        )
      ),

  setManagePhotoVisible: (document, boolean) =>
    dispatch(ItemGroup01List01Actions.itemGroup01List01SetManagePhotoVisible(document, boolean)),

  setSelectedBrand: (brandId) => dispatch(ItemGroup01List01Actions.itemGroup01List01SetSelectedBrand(brandId)),
  reorderItemGroup01List: (brandId) => dispatch(ItemGroup01List01Actions.itemGroup01List01ReorderBrands(brandId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ItemGroup01IndexScreen));
