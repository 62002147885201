import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  debtorSync01ShowSyncSetting: ['divisionId'],
  debtorSync01ShowSyncSettingLoading: ['boolean'],
  debtorSync01ShowSyncSettingSuccess: ['syncSetting'],
  debtorSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  debtorSync01ShowBatchJobStatus: null,
  debtorSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  debtorSync01SyncNow: ['formikBag', 'divisionId'],
  debtorSync01SyncLoading: ['boolean']
});

export const DebtorSync01Types = Types;
export default Creators;
