import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemDetailResetTimestamp: null,
  itemDetailInitHeader: [],
  itemDetailSetHdrId: ['hdrId'],
  itemDetailShowHeader: ['hdrId'],
  itemDetailShowDocumentLoading: ['boolean'],
  itemDetailShowHeaderSuccess: ['documentHeader'],

  itemDetailCreateHeader: ['formikBag', 'documentHeader', 'divisionId'],
  itemDetailUpdateHeader: ['formikBag', 'documentHeader'],
  itemDetailUpdateHeaderSuccess: ['documentHeader'],

  itemDetailFetchBrandOptions: ['search'],
  itemDetailFetchBrandOptionLoading: ['boolean'],
  itemDetailFetchBrandOptionSuccess: ['options'],

  itemDetailFetchCategoryOptions: ['search'],
  itemDetailFetchCategoryOptionLoading: ['boolean'],
  itemDetailFetchCategoryOptionSuccess: ['options'],

  itemDetailFetchManufacturerOptions: ['search'],
  itemDetailFetchManufacturerOptionLoading: ['boolean'],
  itemDetailFetchManufacturerOptionSuccess: ['options']

  // itemDetailFetchDebtorOptions: ['search'],
  // itemDetailFetchDebtorOptionLoading: ['boolean'],
  // itemDetailFetchDebtorOptionSuccess: ['options'],
});

export const ItemDetailTypes = Types;
export default Creators;
