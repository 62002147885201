import React from 'react';
import { PropTypes } from 'prop-types';
import { BarChart, LineChart, PieChart } from '.';

class Chart extends React.PureComponent {
  render() {
    const { chartType, data, maxValue } = this.props;
    let chart;

    if (chartType === 'bar') {
      chart = <BarChart data={data} xDataKey="doc_date" maxValue={maxValue} barDataKey="sales" />;
    } else if (chartType === 'line') {
      chart = <LineChart data={data} xDataKey="doc_date" maxValue={maxValue} lineDataKey="sales" />;
    } else if (chartType === 'pie') {
      chart = <PieChart data={data} xDataKey="sales" />;
    }

    return chart;
  }
}

Chart.propTypes = {
  chartType: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  maxValue: PropTypes.number
};

Chart.defaultProps = {
  chartType: '',
  data: [],
  maxValue: 0
};

export default Chart;
