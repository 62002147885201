import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemList01GoToDocument: ['hdrId'],
  itemList01NewDocument: [],
  itemList01GoToAudit: ['hdrId'],
  itemList01FetchItemList01: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize', 'status'],
  itemList01FetchItemList01Loading: ['boolean'],
  itemList01FetchItemList01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  itemList01AddSelectedDocuments: ['selectedDocuments'],
  itemList01RemoveSelectedDocuments: ['selectedDocuments'],
  itemList01SetWorkspaceVisible: ['boolean'],
  itemList01SetManagePhotoVisible: ['document', 'boolean'],
  itemList01RemovePhoto: ['itemId', 'itemPhotoId'],
  itemList01UploadPhoto: ['itemId', 'fileList'],
  itemList01ManagePhotoSuccess: ['photos'],
  itemList01ManagePhotoLoading: ['boolean'],
  itemList01UploadLoading: ['boolean'],
  itemList01SetExpandedRows: ['expandedRows'],
  itemList01UpdateItem: ['id', 'status'],
  itemList01UpdateItemSuccess: ['document']
}); 

export const ItemList01Types = Types;
export default Creators;
