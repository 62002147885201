import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import AdvShipDetailScreen from '../Containers/AdvShipDetail/AdvShipDetailScreen';
import AdvShipIndexScreen from '../Containers/AdvShipIndex/AdvShipIndexScreen';
import AuditRes01Screen from '../Containers/AuditRes01/AuditRes01Screen';
import AuditUser01Screen from '../Containers/AuditUser01/AuditUser01Screen';
import BinTrfDetailScreen from '../Containers/BinTrfDetail/BinTrfDetailScreen';
import BinTrfIndexScreen from '../Containers/BinTrfIndex/BinTrfIndexScreen';
import BinTrfProcessScreen from '../Containers/BinTrfProcess/BinTrfProcessScreen';
import BroadcastPromoScreen from '../Containers/BroadcastPromo/BroadcastPromoScreen'
import CartDetailScreen from '../Containers/CartDetail/CartDetailScreen';
import CartIndexScreen from '../Containers/CartIndex/CartIndexScreen';
import ChangePasswordScreen from '../Containers/ChangePassword/ChangePasswordScreen';
import CompanyDetailScreen from '../Containers/CompanyDetail/CompanyDetailScreen';
import CompanyIndexScreen from '../Containers/CompanyIndex/CompanyIndexScreen';
import CountAdjAnalysisReportScreen from '../Containers/CountAdjAnalysisReport/CountAdjAnalysisReportScreen';
import CountAdjDetailScreen from '../Containers/CountAdjDetail/CountAdjDetailScreen';
import CountAdjIndexScreen from '../Containers/CountAdjIndex/CountAdjIndexScreen';
import CycleCount02DetailScreen from '../Containers/CycleCount02Detail/CycleCount02DetailScreen';
import CycleCountAnalysisReportScreen from '../Containers/CycleCountAnalysisReport/CycleCountAnalysisReportScreen';
import CycleCountDetailScreen from '../Containers/CycleCountDetail/CycleCountDetailScreen';
import CycleCountIndexScreen from '../Containers/CycleCountIndex/CycleCountIndexScreen';
import DashboardScreen from '../Containers/Dashboard/DashboardScreen';
import DebtorProcessScreen from '../Containers/DebtorProcess/DebtorProcessScreen';
import DebtorProcessDeletedScreen from '../Containers/DebtorProcessDelete/DebtorProcessScreen';
import DebtorDetailScreen from '../Containers/DebtorDetail/DebtorDetailScreen';
import DeliveryPointProcessScreen from '../Containers/DeliveryPointProcess/DeliveryPointProcessScreen';
import DivisionDetailScreen from '../Containers/DivisionDetail/DivisionDetailScreen';
import DivisionIndexScreen from '../Containers/DivisionIndex/DivisionIndexScreen';
import GdsDelProcessScreen from '../Containers/GdsDelProcess/GdsDelProcessScreen';
import GdsRcptDetailScreen from '../Containers/GdsRcptDetail/GdsRcptDetailScreen';
import GdsRcptIndexScreen from '../Containers/GdsRcptIndex/GdsRcptIndexScreen';
import GdsRcptProcessScreen from '../Containers/GdsRcptProcess/GdsRcptProcessScreen';
import InbOrdDetailScreen from '../Containers/InbOrdDetail/InbOrdDetailScreen';
import InbOrdIndexScreen from '../Containers/InbOrdIndex/InbOrdIndexScreen';
import InvAuditProcessScreen from '../Containers/InvAuditProcess/InvAuditProcessScreen';
import ItemDetailScreen from '../Containers/ItemDetail/ItemDetailScreen';
import ItemGroup01IndexScreen from '../Containers/ItemGroup01Index/ItemGroup01IndexScreen';
import ItemGroup02IndexScreen from '../Containers/ItemGroup02Index/ItemGroup02IndexScreen';
import ItemGroup03IndexScreen from '../Containers/ItemGroup03Index/ItemGroup03IndexScreen';
import ItemProcessScreen from '../Containers/ItemProcess/ItemProcessScreen';
import ItemProcessDeletedScreen from '../Containers/ItemProcessDelete/ItemProcessScreen';
import LoginScreen from '../Containers/Login/LoginScreen';
import OutbOrdAnalysisReportScreen from '../Containers/OutbOrdAnalysisReport/OutbOrdAnalysisReportScreen';
import OutbOrdDetailScreen from '../Containers/OutbOrdDetail/OutbOrdDetailScreen';
import OutbOrdIndexScreen from '../Containers/OutbOrdIndex/OutbOrdIndexScreen';
import PalletLabelProcessScreen from '../Containers/PalletLabelProcess/PalletLabelProcessScreen';
import PickFaceStrategyProcessScreen from '../Containers/PickFaceStrategyProcess/PickFaceStrategyProcessScreen';
import PickListDetailScreen from '../Containers/PickListDetail/PickListDetailScreen';
import PickListIndexScreen from '../Containers/PickListIndex/PickListIndexScreen';
import PrivacyPolicyScreen from '../Containers/Legal/PrivacyPolicyScreen';
import PromoAllIndexScreen from '../Containers/PromoIndex/PromoAllIndexScreen';
import PromoDetailScreen from '../Containers/PromoDetail/PromoDetailScreen';
import PromoDiscIndexScreen from '../Containers/PromoIndex/PromoDiscIndexScreen';
import PromoFocIndexScreen from '../Containers/PromoIndex/PromoFocIndexScreen';
import PromoProcessScreen from '../Containers/PromoProcess/PromoProcessScreen';
import PurchaseProcessScreen from '../Containers/PurchaseProcess/PurchaseProcessScreen';
import PutAwayDetailScreen from '../Containers/PutAwayDetail/PutAwayDetailScreen';
import PutAwayIndexScreen from '../Containers/PutAwayIndex/PutAwayIndexScreen';
import ReservedStockReportScreen from '../Containers/ReservedStockReport/ReservedStockReportScreen';
import RoleProcessScreen from '../Containers/RoleProcess/RoleProcessScreen';
import RtnRcptDetailScreen from '../Containers/RtnRcptDetail/RtnRcptDetailScreen';
import RtnRcptIndexScreen from '../Containers/RtnRcptIndex/RtnRcptIndexScreen';
import RtnRcptProcessScreen from '../Containers/RtnRcptProcess/RtnRcptProcessScreen';
import SalesProcessScreen from '../Containers/SalesProcess/SalesProcessScreen';
import SlsInvDetailScreen from '../Containers/SlsInvDetail/SlsInvDetailScreen';
import SlsInvIndexScreen from '../Containers/SlsInvIndex/SlsInvIndexScreen';
import SlsOrdDetailScreen from '../Containers/SlsOrdDetail/SlsOrdDetailScreen';
import SlsOrdIndexScreen from '../Containers/SlsOrdIndex/SlsOrdIndexScreen';
import SlsRtnDetailScreen from '../Containers/SlsRtnDetail/SlsRtnDetailScreen';
import SlsRtnIndexScreen from '../Containers/SlsRtnIndex/SlsRtnIndexScreen';
import SlsRtnProcessScreen from '../Containers/SlsRtnProcess/SlsRtnProcessScreen';
import StockBalanceReportScreen from '../Containers/StockBalanceReport/StockBalanceReportScreen';
import StockCardReportScreen from '../Containers/StockCardReport/StockCardReportScreen';
import StorageBinProcessScreen from '../Containers/StorageBinProcess/StorageBinProcessScreen';
import TermsConditionsScreen from '../Containers/Legal/TermsConditionScreen';
import UserDetailChangePasswordScreen from '../Containers/UserDetail/ChangePasswordScreen';
import UserDetailScreen from '../Containers/UserDetail/UserDetailScreen';
import UserProcessScreen from '../Containers/UserProcess/UserProcessScreen';
import WarehouseMapScreen from '../Containers/WarehouseMap/WarehouseMapScreen';
import WhseJob03DetailScreen from '../Containers/WhseJob03Detail/WhseJob03DetailScreen';
import WhseJob14DetailScreen from '../Containers/WhseJob14Detail/WhseJob14DetailScreen';
import WhseJob15DetailScreen from '../Containers/WhseJob15Detail/WhseJob15DetailScreen';
import WhseJob16DetailScreen from '../Containers/WhseJob16Detail/WhseJob16DetailScreen';
import WhseJob17DetailScreen from '../Containers/WhseJob17Detail/WhseJob17DetailScreen';

export default function AppNavigator(props) {
  const { appPath } = props;

  return (
    <Switch>
      <Route path={`${appPath}/advShipDetail/:action/:id`} component={AdvShipDetailScreen} exact />
      <Route path={`${appPath}/advShipIndex`} component={AdvShipIndexScreen} exact />
      <Route path={`${appPath}/auditResource/:res_type/:res_id/:audit_type`} component={AuditRes01Screen} exact />
      <Route path={`${appPath}/auditUser/:user_id`} component={AuditUser01Screen} exact />
      <Route path={`${appPath}/binTrfDetail/:action/:id`} component={BinTrfDetailScreen} exact />
      <Route path={`${appPath}/binTrfIndex`} component={BinTrfIndexScreen} exact />
      <Route path={`${appPath}/binTrfProcess/index/:proc_type`} component={BinTrfProcessScreen} exact />
      <Route path={`${appPath}/cartDetail/:action/:id`} component={CartDetailScreen} exact />
      <Route path={`${appPath}/cartIndex`} component={CartIndexScreen} exact />
      <Route path={`${appPath}/changePassword/:user_id`} component={UserDetailChangePasswordScreen} exact />
      <Route path={`${appPath}/changePassword`} component={ChangePasswordScreen} exact />
      <Route path={`${appPath}/company/index`} component={CompanyIndexScreen} exact />
      <Route path={`${appPath}/companyDetail/:action/:id`} component={CompanyDetailScreen} exact />
      <Route path={`${appPath}/countAdjAnalysisReport`} component={CountAdjAnalysisReportScreen} exact />
      <Route path={`${appPath}/countAdjDetail/:action/:id`} component={CountAdjDetailScreen} exact />
      <Route path={`${appPath}/countAdjIndex`} component={CountAdjIndexScreen} exact />
      <Route path={`${appPath}/cycleCount02Detail/:action/:id/:item_id?`} component={CycleCount02DetailScreen} exact />
      <Route path={`${appPath}/cycleCountAnalysisReport`} component={CycleCountAnalysisReportScreen} exact />
      <Route path={`${appPath}/cycleCountDetail/:action/:id/:item_id?`} component={CycleCountDetailScreen} exact />
      <Route path={`${appPath}/cycleCountIndex`} component={CycleCountIndexScreen} exact />
      <Route path={`${appPath}/dashboard`} component={DashboardScreen} exact />
      <Route path={`${appPath}/debtorDetail/:action/:id`} component={DebtorDetailScreen} exact /> 
      <Route path={`${appPath}/debtorProcess/index/:proc_type`} component={DebtorProcessScreen} exact />
      <Route path={`${appPath}/debtorProcessDeleted/index/:proc_type`} component={DebtorProcessDeletedScreen} exact />
      <Route path={`${appPath}/deliveryPointProcess/index/:proc_type`} component={DeliveryPointProcessScreen} exact />
      <Route path={`${appPath}/division/index`} component={DivisionIndexScreen} exact />
      <Route path={`${appPath}/divisionDetail/:action/:id`} component={DivisionDetailScreen} exact />
      <Route path={`${appPath}/gdsDelProcess/index/:proc_type`} component={GdsDelProcessScreen} exact />
      <Route path={`${appPath}/gdsRcptDetail/:action/:id/:item_id?`} component={GdsRcptDetailScreen} exact />
      <Route path={`${appPath}/gdsRcptIndex`} component={GdsRcptIndexScreen} exact />
      <Route path={`${appPath}/gdsRcptProcess/index/:proc_type`} component={GdsRcptProcessScreen} exact />
      <Route path={`${appPath}/inbOrdDetail/:action/:id/:item_id?`} component={InbOrdDetailScreen} exact />
      <Route path={`${appPath}/inbOrdIndex`} component={InbOrdIndexScreen} exact />
      <Route path={`${appPath}/invAuditProcess/index/:proc_type`} component={InvAuditProcessScreen} exact />
      <Route path={`${appPath}/itemDetail/:action/:id`} component={ItemDetailScreen} exact />
      <Route path={`${appPath}/itemGroup01index`} component={ItemGroup01IndexScreen} exact />
      <Route path={`${appPath}/itemGroup02index`} component={ItemGroup02IndexScreen} exact />
      <Route path={`${appPath}/itemGroup03index`} component={ItemGroup03IndexScreen} exact />
      <Route path={`${appPath}/itemProcess/index/:proc_type`} component={ItemProcessScreen} exact />
      <Route path={`${appPath}/itemProcessDeleted/index/:proc_type`} component={ItemProcessDeletedScreen} exact />
      <Route path={`${appPath}/legal/PRIVACY_POLICY`} component={PrivacyPolicyScreen} exact />
      <Route path={`${appPath}/legal/TERMS_AND_CONDITIONS`} component={TermsConditionsScreen} exact />
      <Route path={`${appPath}/login`} component={LoginScreen} exact />
      <Route path={`${appPath}/outbOrdAnalysisReport`} component={OutbOrdAnalysisReportScreen} exact />
      <Route path={`${appPath}/outbOrdDetail/:action/:id/:item_id?`} component={OutbOrdDetailScreen} exact />
      <Route path={`${appPath}/outbOrdIndex`} component={OutbOrdIndexScreen} exact />
      <Route path={`${appPath}/palletLabelProcess/index/:proc_type`} component={PalletLabelProcessScreen} exact />
      <Route path={`${appPath}/pickFaceStrategyProcess/index/:proc_type`} component={PickFaceStrategyProcessScreen} exact />
      <Route path={`${appPath}/pickListDetail/:action/:id`} component={PickListDetailScreen} exact />
      <Route path={`${appPath}/pickListIndex`} component={PickListIndexScreen} exact />
      <Route path={`${appPath}/promoBundleIndex`} component={PromoDiscIndexScreen} exact />
      <Route path={`${appPath}/promoDetail/broadcast/:id?`} component={BroadcastPromoScreen} exact />
      <Route path={`${appPath}/promoDetail/:action/:id?`} component={PromoDetailScreen} exact />
      <Route path={`${appPath}/promoAllIndex`} component={PromoAllIndexScreen} exact />
      <Route path={`${appPath}/promoDiscIndex`} component={PromoDiscIndexScreen} exact />
      <Route path={`${appPath}/promoFocIndex`} component={PromoFocIndexScreen} exact />
      <Route path={`${appPath}/promoProcess/index/:proc_type`} component={PromoProcessScreen} exact />
      <Route path={`${appPath}/purchaseProcess/index/:proc_type`} component={PurchaseProcessScreen} exact />
      <Route path={`${appPath}/putAwayDetail/:action/:id/:item_id?`} component={PutAwayDetailScreen} exact />
      <Route path={`${appPath}/putAwayIndex`} component={PutAwayIndexScreen} exact />
      <Route path={`${appPath}/reservedStockReport`} component={ReservedStockReportScreen} exact />
      <Route path={`${appPath}/roleProcess/index/:proc_type`} component={RoleProcessScreen} exact />
      <Route path={`${appPath}/rtnRcptDetail/:action/:id`} component={RtnRcptDetailScreen} exact />
      <Route path={`${appPath}/rtnRcptIndex`} component={RtnRcptIndexScreen} exact />
      <Route path={`${appPath}/rtnRcptProcess/index/:proc_type`} component={RtnRcptProcessScreen} exact />
      <Route path={`${appPath}/salesProcess/index/:proc_type`} component={SalesProcessScreen} exact />
      <Route path={`${appPath}/slsInvDetail/:action/:id`} component={SlsInvDetailScreen} exact />
      <Route path={`${appPath}/slsInvIndex`} component={SlsInvIndexScreen} exact />
      <Route path={`${appPath}/slsOrdDetail/:action/:id`} component={SlsOrdDetailScreen} exact />
      <Route path={`${appPath}/slsOrdIndex`} component={SlsOrdIndexScreen} exact />
      <Route path={`${appPath}/slsRtnDetail/:action/:id`} component={SlsRtnDetailScreen} exact />
      <Route path={`${appPath}/slsRtnIndex`} component={SlsRtnIndexScreen} exact />
      <Route path={`${appPath}/slsRtnProcess/index/:proc_type`} component={SlsRtnProcessScreen} exact />
      <Route path={`${appPath}/stockBalanceReport`} component={StockBalanceReportScreen} exact />
      <Route path={`${appPath}/stockCardReport`} component={StockCardReportScreen} exact />
      <Route path={`${appPath}/storageBinProcess/index/:proc_type`} component={StorageBinProcessScreen} exact />
      <Route path={`${appPath}/userDetail/:action/:id`} component={UserDetailScreen} exact />
      <Route path={`${appPath}/userProcess/index/:proc_type`} component={UserProcessScreen} exact />
      <Route path={`${appPath}/warehouseMap`} component={WarehouseMapScreen} exact />
      <Route path={`${appPath}/whseJob03Detail/:action/:id`} component={WhseJob03DetailScreen} exact />
      <Route path={`${appPath}/whseJob14Detail/:action/:id`} component={WhseJob14DetailScreen} exact />
      <Route path={`${appPath}/whseJob15Detail/:action/:id`} component={WhseJob15DetailScreen} exact />
      <Route path={`${appPath}/whseJob16Detail/:action/:id/:item_id?`} component={WhseJob16DetailScreen} exact />
      <Route path={`${appPath}/whseJob17Detail/:action/:id`} component={WhseJob17DetailScreen} exact />
      <Route path={`${appPath}/`} component={DashboardScreen} exact/>
    </Switch>
  );
}

AppNavigator.propTypes = {
  appPath: PropTypes.string
};

AppNavigator.defaultProps = {
  appPath: ''
};
