/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import configureStore from './CreateStore';
import rootSaga from '../Sagas';

import { reducer as AdvShipDetailReducer } from './AdvShipDetail/Reducers';
import { reducer as AdvShipIndexReducer } from './AdvShipIndex/Reducers';
import { reducer as AdvShipSync01Reducer } from './AdvShipSync01/Reducers';
import { reducer as AppReducer } from './App/Reducers';
import { reducer as AuditRes01Reducer } from './AuditRes01/Reducers';
import { reducer as AuditUser01Reducer } from './AuditUser01/Reducers';
import { reducer as BinTrf02Reducer } from './BinTrf02/Reducers';
import { reducer as BinTrfDetailReducer } from './BinTrfDetail/Reducers';
import { reducer as BinTrfIndexReducer } from './BinTrfIndex/Reducers';
import { reducer as BinTrfProcessReducer } from './BinTrfProcess/Reducers';
import { reducer as CartDetailReducer } from './CartDetail/Reducers';
import { reducer as CartIndexReducer } from './CartIndex/Reducers';
import { reducer as CompanyDetailReducer } from './CompanyDetail/Reducers';
import { reducer as CompanyListReducer } from './CompanyIndex/Reducers';
import { reducer as CountAdj01Reducer } from './CountAdj01/Reducers';
import { reducer as CountAdjAnalysisReportReducer } from './CountAdjAnalysisReport/Reducers';
import { reducer as CountAdjDetailReducer } from './CountAdjDetail/Reducers';
import { reducer as CountAdjIndexReducer } from './CountAdjIndex/Reducers';
import { reducer as CycleCount02DetailReducer } from './CycleCount02Detail/Reducers';
import { reducer as CycleCount02Reducer } from './CycleCount02/Reducers';
import { reducer as CycleCount03Reducer } from './CycleCount03/Reducers';
import { reducer as CycleCountAnalysisReportReducer } from './CycleCountAnalysisReport/Reducers';
import { reducer as CycleCountDetailReducer } from './CycleCountDetail/Reducers';
import { reducer as CycleCountIndexReducer } from './CycleCountIndex/Reducers';
import { reducer as DashboardReducer } from './Dashboard/Reducers';
import { reducer as DebtorDetailReducer } from './DebtorDetail/Reducers';
import { reducer as DebtorList01Reducer } from './DebtorList01/Reducers';
import { reducer as DebtorProcessReducer } from './DebtorProcess/Reducers';
import { reducer as DebtorSync01Reducer } from './DebtorSync01/Reducers';
import { reducer as DeliveryPointList01Reducer } from './DeliveryPointList01/Reducers';
import { reducer as DeliveryPointProcessReducer } from './DeliveryPointProcess/Reducers';
import { reducer as DeliveryPointReducer } from './DeliveryPoint/Reducers';
import { reducer as DivisionDetailReducer } from './DivisionDetail/Reducers';
import { reducer as DivisionListReducer } from './DivisionIndex/Reducers';
import { reducer as GdsDelProcessReducer } from './GdsDelProcess/Reducers';
import { reducer as GdsRcpt01Reducer } from './GdsRcpt01/Reducers';
import { reducer as GdsRcpt0201Reducer } from './GdsRcpt0201/Reducers';
import { reducer as GdsRcpt02Reducer } from './GdsRcpt02/Reducers';
import { reducer as GdsRcptDetailReducer } from './GdsRcptDetail/Reducers';
import { reducer as GdsRcptIndexReducer } from './GdsRcptIndex/Reducers';
import { reducer as GdsRcptProcessReducer } from './GdsRcptProcess/Reducers';
import { reducer as InbOrd01Reducer } from './InbOrd01/Reducers';
import { reducer as InbOrd02Reducer } from './InbOrd02/Reducers';
import { reducer as InbOrdDetailReducer } from './InbOrdDetail/Reducers';
import { reducer as InbOrdIndexReducer } from './InbOrdIndex/Reducers';
import { reducer as InvAuditProcessReducer } from './InvAuditProcess/Reducers';
import { reducer as InvDoc0101Reducer } from './InvDoc0101/Reducers';
import { reducer as InvDoc0102Reducer } from './InvDoc0102/Reducers';
import { reducer as InvDoc01Reducer } from './InvDoc01/Reducers';
import { reducer as ItemDetailReducer } from './ItemDetail/Reducers';
import { reducer as ItemExcel01Reducer } from './ItemExcel01/Reducers';
import { reducer as ItemGroup01List01Reducer } from './ItemGroup01List01/Reducers';
import { reducer as ItemGroup02List01Reducer } from './ItemGroup02List01/Reducers';
import { reducer as ItemGroup03List01Reducer } from './ItemGroup03List01/Reducers';
import { reducer as ItemList01Reducer } from './ItemList01/Reducers';
import { reducer as ItemPriceReducer } from './ItemPrice/Reducers';
import { reducer as ItemProcessReducer } from './ItemProcess/Reducers';
import { reducer as ItemSync01Reducer } from './ItemSync01/Reducers';
import { reducer as OutbOrd01Reducer } from './OutbOrd01/Reducers';
import { reducer as OutbOrdAnalysisReportReducer } from './OutbOrdAnalysisReport/Reducers';
import { reducer as OutbOrdDetailReducer } from './OutbOrdDetail/Reducers';
import { reducer as OutbOrdIndexReducer } from './OutbOrdIndex/Reducers';
import { reducer as PalletLabelList01Reducer } from './PalletLabelList01/Reducers';
import { reducer as PalletLabelProcessReducer } from './PalletLabelProcess/Reducers';
import { reducer as PickFaceStrategyExcel01Reducer } from './PickFaceStrategyExcel01/Reducers';
import { reducer as PickFaceStrategyList01Reducer } from './PickFaceStrategyList01/Reducers';
import { reducer as PickFaceStrategyProcessReducer } from './PickFaceStrategyProcess/Reducers';
import { reducer as PickList01Reducer } from './PickList01/Reducers';
import { reducer as PickListDetailReducer } from './PickListDetail/Reducers';
import { reducer as PickListIndexReducer } from './PickListIndex/Reducers';
import { reducer as PromoIndexReducer } from './PromoIndex/Reducers';
import { reducer as PromoDetailReducer } from './PromoDetail/Reducers';
import { reducer as PromoDiscListReducer } from './PromoDiscIndex/Reducers';
import { reducer as PromoFocListReducer } from './PromoFocIndex/Reducers';
import { reducer as PromoProcessReducer } from './PromoProcess/Reducers';
import { reducer as PromoSync01Reducer } from './PromoSync01/Reducers';
import { reducer as PurchaseProcessReducer } from './PurchaseProcess/Reducers';
import { reducer as PutAway01Reducer } from './PutAway01/Reducers';
import { reducer as PutAwayDetailReducer } from './PutAwayDetail/Reducers';
import { reducer as PutAwayIndexReducer } from './PutAwayIndex/Reducers';
import { reducer as ReservedStockReportReducer } from './ReservedStockReport/Reducers';
import { reducer as RoleExcel01Reducer } from './RoleExcel01/Reducers';
import { reducer as RoleList01Reducer } from './RoleList01/Reducers';
import { reducer as RoleProcessReducer } from './RoleProcess/Reducers';
import { reducer as RtnRcptDetailReducer } from './RtnRcptDetail/Reducers';
import { reducer as RtnRcptIndexReducer } from './RtnRcptIndex/Reducers';
import { reducer as RtnRcptProcessReducer } from './RtnRcptProcess/Reducers';
import { reducer as RtnRcptSync01Reducer } from './RtnRcptSync01/Reducers';
import { reducer as SalesProcessReducer } from './SalesProcess/Reducers';
import { reducer as SlsInvDetailReducer } from './SlsInvDetail/Reducers';
import { reducer as SlsInvIndexReducer } from './SlsInvIndex/Reducers';
import { reducer as SlsInvSync01Reducer } from './SlsInvSync01/Reducers';
import { reducer as SlsInvSync02Reducer } from './SlsInvSync02/Reducers';
import { reducer as SlsOrdDetailReducer } from './SlsOrdDetail/Reducers';
import { reducer as SlsOrdIndexReducer } from './SlsOrdIndex/Reducers';
import { reducer as SlsOrdSync01Reducer } from './SlsOrdSync01/Reducers';
import { reducer as SlsOrdSync02Reducer } from './SlsOrdSync02/Reducers';
import { reducer as SlsOrdSync03Reducer } from './SlsOrdSync03/Reducers';
import { reducer as SlsRtnDetailReducer } from './SlsRtnDetail/Reducers';
import { reducer as SlsRtnIndexReducer } from './SlsRtnIndex/Reducers';
import { reducer as SlsRtnProcessReducer } from './SlsRtnProcess/Reducers';
import { reducer as SlsRtnSync01Reducer } from './SlsRtnSync01/Reducers';
import { reducer as StockBalanceReportReducer } from './StockBalanceReport/Reducers';
import { reducer as StockCardReportReducer } from './StockCardReport/Reducers';
import { reducer as StorageBinExcel01Reducer } from './StorageBinExcel01/Reducers';
import { reducer as StorageBinList01Reducer } from './StorageBinList01/Reducers';
import { reducer as StorageBinProcessReducer } from './StorageBinProcess/Reducers';
import { reducer as UserDetailReducer } from './UserDetail/Reducers';
import { reducer as UserExcel01Reducer } from './UserExcel01/Reducers';
import { reducer as UserExcel03Reducer } from './UserExcel03/Reducers';
import { reducer as UserList01Reducer } from './UserList01/Reducers';
import { reducer as UserList02Reducer } from './UserList02/Reducers';
import { reducer as UserList03Reducer } from './UserList03/Reducers';
import { reducer as UserProcessReducer } from './UserProcess/Reducers';
import { reducer as WarehouseMapReducer } from './WarehouseMap/Reducers';
import { reducer as WhseJob030102Reducer } from './WhseJob030102/Reducers';
import { reducer as WhseJob0301Reducer } from './WhseJob0301/Reducers';
import { reducer as WhseJob03DetailReducer } from './WhseJob03Detail/Reducers';
import { reducer as WhseJob140101Reducer } from './WhseJob140101/Reducers';
import { reducer as WhseJob1401Reducer } from './WhseJob1401/Reducers';
import { reducer as WhseJob14DetailReducer } from './WhseJob14Detail/Reducers';
import { reducer as WhseJob150101Reducer } from './WhseJob150101/Reducers';
import { reducer as WhseJob1501Reducer } from './WhseJob1501/Reducers';
import { reducer as WhseJob15DetailReducer } from './WhseJob15Detail/Reducers';
import { reducer as WhseJob160102Reducer } from './WhseJob160102/Reducers';
import { reducer as WhseJob1601Reducer } from './WhseJob1601/Reducers';
import { reducer as WhseJob16DetailReducer } from './WhseJob16Detail/Reducers';
import { reducer as WhseJob170201Reducer } from './WhseJob170201/Reducers';
import { reducer as WhseJob1702Reducer } from './WhseJob1702/Reducers';
import { reducer as WhseJob17DetailReducer } from './WhseJob17Detail/Reducers';
import { reducer as AuditLogReducer } from './AuditLog/Reducers';

export default () => {
  const rootReducer = history =>
    combineReducers({
      router: connectRouter(history),
      /**
       * Register your reducers here.
       * @see https://redux.js.org/api-reference/combinereducers
       */
      app: AppReducer,
      companyList: CompanyListReducer,
      companyDetail: CompanyDetailReducer,
      dashboardDetail: DashboardReducer,
      divisionList: DivisionListReducer,
      divisionDetail: DivisionDetailReducer,
      debtorList01: DebtorList01Reducer,
      debtorDetail: DebtorDetailReducer,
      debtorSync01: DebtorSync01Reducer,
      debtorProcess: DebtorProcessReducer,
      salesProcess: SalesProcessReducer,
      // outbOrd01: OutbOrd01Reducer,
      // gdsDelProcess: GdsDelProcessReducer,
      // gdsRcptProcess: GdsRcptProcessReducer,
      // gdsRcpt01: GdsRcpt01Reducer,
      // pickList01: PickList01Reducer,
      // pickListDetail: PickListDetailReducer,
      // pickListIndex: PickListIndexReducer,
      // whseJob0301: WhseJob0301Reducer,
      // whseJob030102: WhseJob030102Reducer,
      // advShipDetail: AdvShipDetailReducer,
      // outbOrdDetail: OutbOrdDetailReducer,
      // inbOrdDetail: InbOrdDetailReducer,
      invDoc01: InvDoc01Reducer,
      invDoc0101: InvDoc0101Reducer,
      invDoc0102: InvDoc0102Reducer,
      // whseJob1401: WhseJob1401Reducer,
      // gdsRcptDetail: GdsRcptDetailReducer,
      // whseJob140101: WhseJob140101Reducer,
      // putAway01: PutAway01Reducer,
      // whseJob1501: WhseJob1501Reducer,
      // whseJob150101: WhseJob150101Reducer,
      // rtnRcptProcess: RtnRcptProcessReducer,
      // gdsRcpt02: GdsRcpt02Reducer,
      // gdsRcpt0201: GdsRcpt0201Reducer,
      // invAuditProcess: InvAuditProcessReducer,
      // cycleCount03: CycleCount03Reducer,
      // cycleCountDetail: CycleCountDetailReducer,
      // whseJob1601: WhseJob1601Reducer,
      // whseJob160102: WhseJob160102Reducer,
      // cycleCount02: CycleCount02Reducer,
      // cycleCount02Detail: CycleCount02DetailReducer,
      // countAdj01: CountAdj01Reducer,
      // stockBalanceReport: StockBalanceReportReducer,
      // warehouseMap: WarehouseMapReducer,
      itemProcess: ItemProcessReducer,
      itemList01: ItemList01Reducer,
      itemPrice: ItemPriceReducer,
      itemDetail: ItemDetailReducer,
      itemExcel01: ItemExcel01Reducer,
      itemSync01: ItemSync01Reducer,
      itemGroup01List01: ItemGroup01List01Reducer,
      itemGroup02List01: ItemGroup02List01Reducer,
      itemGroup03List01: ItemGroup03List01Reducer,
      // storageBinProcess: StorageBinProcessReducer,
      // storageBinList01: StorageBinList01Reducer,
      // storageBinExcel01: StorageBinExcel01Reducer,
      // palletLabelProcess: PalletLabelProcessReducer,
      // palletLabelList01: PalletLabelList01Reducer,
      // pickFaceStrategyProcess: PickFaceStrategyProcessReducer,
      // pickFaceStrategyList01: PickFaceStrategyList01Reducer,
      // pickFaceStrategyExcel01: PickFaceStrategyExcel01Reducer,
      promoIndex: PromoIndexReducer,
      promoDiscList: PromoDiscListReducer,
      promoFocList: PromoFocListReducer,
      promoDetail: PromoDetailReducer,
      promoProcess: PromoProcessReducer,
      promoSync01: PromoSync01Reducer,
      userProcess: UserProcessReducer,
      userList01: UserList01Reducer,
      userList02: UserList02Reducer,
      userList03: UserList03Reducer,
      userExcel01: UserExcel01Reducer,
      userExcel03: UserExcel03Reducer,
      userDetail: UserDetailReducer,
      roleProcess: RoleProcessReducer,
      roleList01: RoleList01Reducer,
      roleExcel01: RoleExcel01Reducer,
      // slsRtnProcess: SlsRtnProcessReducer,
      // inbOrd02: InbOrd02Reducer,
      // outbOrdAnalysisReport: OutbOrdAnalysisReportReducer,
      // purchaseProcess: PurchaseProcessReducer,
      // inbOrd01: InbOrd01Reducer,
      // whseJob03Detail: WhseJob03DetailReducer,
      // whseJob14Detail: WhseJob14DetailReducer,
      // putAwayIndex: PutAwayIndexReducer,
      // putAwayDetail: PutAwayDetailReducer,
      // whseJob15Detail: WhseJob15DetailReducer,
      // advShipIndex: AdvShipIndexReducer,
      cartIndex: CartIndexReducer,
      cartDetail: CartDetailReducer,
      slsOrdIndex: SlsOrdIndexReducer,
      slsOrdDetail: SlsOrdDetailReducer,
      slsInvIndex: SlsInvIndexReducer,
      slsInvDetail: SlsInvDetailReducer,
      // slsRtnIndex: SlsRtnIndexReducer,
      // slsRtnDetail: SlsRtnDetailReducer,
      // rtnRcptIndex: RtnRcptIndexReducer,
      // rtnRcptDetail: RtnRcptDetailReducer,
      // outbOrdIndex: OutbOrdIndexReducer,
      // inbOrdIndex: InbOrdIndexReducer,
      // gdsRcptIndex: GdsRcptIndexReducer,
      // cycleCountIndex: CycleCountIndexReducer,
      // countAdjIndex: CountAdjIndexReducer,
      // countAdjDetail: CountAdjDetailReducer,
      // binTrfIndex: BinTrfIndexReducer,
      // binTrfDetail: BinTrfDetailReducer,
      // binTrfProcess: BinTrfProcessReducer,
      // binTrf02: BinTrf02Reducer,
      // whseJob1702: WhseJob1702Reducer,
      // whseJob170201: WhseJob170201Reducer,
      // whseJob17Detail: WhseJob17DetailReducer,
      slsOrdSync01: SlsOrdSync01Reducer,
      slsOrdSync02: SlsOrdSync02Reducer,
      slsOrdSync03: SlsOrdSync03Reducer,
      slsInvSync01: SlsInvSync01Reducer,
      slsInvSync02: SlsInvSync02Reducer,
      // slsRtnSync01: SlsRtnSync01Reducer,
      // rtnRcptSync01: RtnRcptSync01Reducer,
      // advShipSync01: AdvShipSync01Reducer,
      // stockCardReport: StockCardReportReducer,
      // reservedStockReport: ReservedStockReportReducer,
      // cycleCountAnalysisReport: CycleCountAnalysisReportReducer,
      // countAdjAnalysisReport: CountAdjAnalysisReportReducer,
      // deliveryPointProcess: DeliveryPointProcessReducer,
      // deliveryPointList01: DeliveryPointList01Reducer,
      auditRes01: AuditRes01Reducer,
      auditUser01: AuditUser01Reducer,
      auditLog: AuditLogReducer,
      // whseJob16Detail: WhseJob16DetailReducer,
      deliveryPoint: DeliveryPointReducer
    });

  return configureStore(rootReducer, rootSaga);
};
