/**
 * The initial values for the redux state.
 */
export default {
    timestamp: 0,
    hdrId: 0,
    documentIsLoading: false,
    syncSetting: {
      url: '',
      page_size: 1000,
      username: '',
      password: '',
      item_brand_mapping: {'value':'', 'label': 'N/A'},
      item_category_mapping_select2: '',
      item_manufacturer_mapping_select2: '',
      debtor_category_mapping_select2: '',
      last_synced_at: '2000-01-01 00:00:00'
    },
    fetchIsLoading: false,
};