import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import InvAuditProcessActions from '../../Stores/InvAuditProcess/Actions';

import CycleCount03Table from '../CycleCount03/CycleCount03Table';
import WhseJob1601Table from '../WhseJob1601/WhseJob1601Table';
import WhseJob160102Table from '../WhseJob160102/WhseJob160102Table';
import CycleCount02Table from '../CycleCount02/CycleCount02Table';
import CountAdj01Table from '../CountAdj01/CountAdj01Table';

const { TabPane } = Tabs;

class InvAuditProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchInvAuditProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchInvAuditProcess(curSiteFlowId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchInvAuditProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchInvAuditProcess(curSiteFlowId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          {process.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                >
                  <Link
                    style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                    to={`${appPath}/invAuditProcess/index/${value.proc_type}`}
                  >
                    <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                  </Link>
                </Tooltip>
              }
              key={value.proc_type}
            />
          ))}
        </Tabs>

        {match.params.proc_type === 'CYCLE_COUNT_03' && <CycleCount03Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_16_01' && <WhseJob1601Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_16_01_02' && <WhseJob160102Table match={match} />}
        {match.params.proc_type === 'CYCLE_COUNT_02' && <CycleCount02Table match={match} />}
        {match.params.proc_type === 'COUNT_ADJ_01' && <CountAdj01Table match={match} />}
      </Card>
    );
  }
}

InvAuditProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number,
  fetchInvAuditProcess: PropTypes.func
};

InvAuditProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curSiteFlowId: 0,
  fetchInvAuditProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.invAuditProcess.process,
  processIsLoading: state.invAuditProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchInvAuditProcess: siteFlowId =>
    dispatch(InvAuditProcessActions.invAuditProcessFetchInvAuditProcess(siteFlowId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InvAuditProcessScreen));
