import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  roleExcel01ShowBatchJobStatus: null,
  roleExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  roleExcel01UploadExcel: ['siteFlowId', 'file'],
  roleExcel01UploadLoading: ['boolean'],
  roleExcel01DownloadExcel: ['siteFlowId']
});

export const RoleExcel01Types = Types;
export default Creators;
