/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DashboardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

const getMaximum = data => {
  if (data != null) {
    let mV = Math.max(...data.map(m => m.sales), 0);
    mV += (mV * 10) / 100;
    mV = Math.round(mV);
    return mV;
  }

  return 0;
};

export const dashboardFetchChartOneDataLoading = (state, { boolean }) => ({
  ...state,
  chart_1_DataIsLoading: boolean
});

export const dashboardFetchChartOneDataSuccess = (state, { data }) => ({
  ...state,
  chart_1_DocumentHeader: data,
  chart_1_MaxValue: getMaximum(data)
});

export const dashboardFetchChartTwoDataLoading = (state, { boolean }) => ({
  ...state,
  chart_2_DataIsLoading: boolean
});

export const dashboardFetchChartTwoDataSuccess = (state, { data }) => ({
  ...state,
  chart_2_DocumentHeader: data,
  chart_2_MaxValue: getMaximum(data)
});

export const dashboardFetchChartThreeDataLoading = (state, { boolean }) => ({
  ...state,
  chart_3_DataIsLoading: boolean
});

export const dashboardFetchChartThreeDataSuccess = (state, { data }) => ({
  ...state,
  chart_3_DocumentHeader: data,
  chart_3_MaxValue: getMaximum(data)
});

export const dashboardFetchChartFourDataLoading = (state, { boolean }) => ({
  ...state,
  chart_4_DataIsLoading: boolean
});

export const dashboardFetchChartFourDataSuccess = (state, { data }) => ({
  ...state,
  chart_4_DocumentHeader: data,
  chart_4_MaxValue: getMaximum(data)
});

export const dashboardFetchChartFiveDataLoading = (state, { boolean }) => ({
  ...state,
  chart_5_DataIsLoading: boolean
});

export const dashboardFetchChartFiveDataSuccess = (state, { data }) => ({
  ...state,
  chart_5_DocumentHeader: data,
  chart_5_MaxValue: getMaximum(data)
});

export const dashboardFetchChartSixDataLoading = (state, { boolean }) => ({
  ...state,
  chart_6_DataIsLoading: boolean
});

export const dashboardFetchChartSixDataSuccess = (state, { data }) => ({
  ...state,
  chart_6_DocumentHeader: data,
  chart_6_MaxValue: getMaximum(data)
});

export const dashboardFetchSummaryChartDataLoading = (state, { boolean }) => ({
  ...state,
  summaryChartIsLoading: boolean
});

export const dashboardFetchSummaryChartDataSuccess = (state, { data }) => ({
  ...state,
  summaryChart: data
});

export const dashboardUpdateModelLoading = (state, { boolean }) => ({
  ...state,
  savingStatus: boolean
});

export const dashboardCreateModelLoading = (state, { boolean }) => ({
  ...state,
  savingStatus: boolean
});

export const dashboardRetrieveModelLoading = (state, { boolean }) => ({
  ...state,
  loadingStatus: boolean
});

export const dashboardRetrieveModelSuccess = (state, { data }) => ({
  ...state,
  chartSettings: data
});

export const dashboardChartOneConfig = (state, { config }) => ({
  ...state,
  chartOneConfig: config
});

export const dashboardChartOneConfigLoading = (state, { boolean }) => ({
  ...state,
  chartOneConfigLoading: boolean
});

export const dashboardChartTwoConfig = (state, { config }) => ({
  ...state,
  chartTwoConfig: config
});

export const dashboardChartTwoConfigLoading = (state, { boolean }) => ({
  ...state,
  chartTwoConfigLoading: boolean
});

export const dashboardChartThreeConfig = (state, { config }) => ({
  ...state,
  chartThreeConfig: config
});

export const dashboardChartThreeConfigLoading = (state, { boolean }) => ({
  ...state,
  chartThreeConfigLoading: boolean
});

export const dashboardChartFourConfig = (state, { config }) => ({
  ...state,
  chartFourConfig: config
});

export const dashboardChartFourConfigLoading = (state, { boolean }) => ({
  ...state,
  chartFourConfigLoading: boolean
});

export const dashboardChartFiveConfig = (state, { config }) => ({
  ...state,
  chartFiveConfig: config
});

export const dashboardChartFiveConfigLoading = (state, { boolean }) => ({
  ...state,
  chartFiveConfigLoading: boolean
});

export const dashboardChartSixConfig = (state, { config }) => ({
  ...state,
  chartSixConfig: config
});

export const dashboardChartSixConfigLoading = (state, { boolean }) => ({
  ...state,
  chartSixConfigLoading: boolean
});

export const dashboardFetchIdLoading = (state, { boolean }) => ({
  ...state,
  idDoneLoading: boolean
});

export const dashboardFetchIdSuccess = (state, { id }) => ({
  ...state,
  id
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DashboardTypes.DASHBOARD_FETCH_CHART_ONE_DATA_LOADING]: dashboardFetchChartOneDataLoading,
  [DashboardTypes.DASHBOARD_FETCH_CHART_ONE_DATA_SUCCESS]: dashboardFetchChartOneDataSuccess,
  [DashboardTypes.DASHBOARD_FETCH_CHART_TWO_DATA_LOADING]: dashboardFetchChartTwoDataLoading,
  [DashboardTypes.DASHBOARD_FETCH_CHART_TWO_DATA_SUCCESS]: dashboardFetchChartTwoDataSuccess,
  [DashboardTypes.DASHBOARD_FETCH_CHART_THREE_DATA_LOADING]: dashboardFetchChartThreeDataLoading,
  [DashboardTypes.DASHBOARD_FETCH_CHART_THREE_DATA_SUCCESS]: dashboardFetchChartThreeDataSuccess,
  [DashboardTypes.DASHBOARD_FETCH_CHART_FOUR_DATA_LOADING]: dashboardFetchChartFourDataLoading,
  [DashboardTypes.DASHBOARD_FETCH_CHART_FOUR_DATA_SUCCESS]: dashboardFetchChartFourDataSuccess,
  [DashboardTypes.DASHBOARD_FETCH_CHART_FIVE_DATA_LOADING]: dashboardFetchChartFiveDataLoading,
  [DashboardTypes.DASHBOARD_FETCH_CHART_FIVE_DATA_SUCCESS]: dashboardFetchChartFiveDataSuccess,
  [DashboardTypes.DASHBOARD_FETCH_CHART_SIX_DATA_LOADING]: dashboardFetchChartSixDataLoading,
  [DashboardTypes.DASHBOARD_FETCH_CHART_SIX_DATA_SUCCESS]: dashboardFetchChartSixDataSuccess,
  [DashboardTypes.DASHBOARD_FETCH_SUMMARY_CHART_DATA_LOADING]: dashboardFetchSummaryChartDataLoading,
  [DashboardTypes.DASHBOARD_FETCH_SUMMARY_CHART_DATA_SUCCESS]: dashboardFetchSummaryChartDataSuccess,
  [DashboardTypes.DASHBOARD_UPDATE_MODEL_LOADING]: dashboardUpdateModelLoading,
  [DashboardTypes.DASHBOARD_CREATE_MODEL_LOADING]: dashboardCreateModelLoading,
  [DashboardTypes.DASHBOARD_RETRIEVE_MODEL_LOADING]: dashboardRetrieveModelLoading,
  [DashboardTypes.DASHBOARD_RETRIEVE_MODEL_SUCCESS]: dashboardRetrieveModelSuccess,
  [DashboardTypes.DASHBOARD_CHART_ONE_CONFIG]: dashboardChartOneConfig,
  [DashboardTypes.DASHBOARD_CHART_ONE_CONFIG_LOADING]: dashboardChartOneConfigLoading,
  [DashboardTypes.DASHBOARD_CHART_TWO_CONFIG]: dashboardChartTwoConfig,
  [DashboardTypes.DASHBOARD_CHART_TWO_CONFIG_LOADING]: dashboardChartTwoConfigLoading,
  [DashboardTypes.DASHBOARD_CHART_THREE_CONFIG]: dashboardChartThreeConfig,
  [DashboardTypes.DASHBOARD_CHART_THREE_CONFIG_LOADING]: dashboardChartThreeConfigLoading,
  [DashboardTypes.DASHBOARD_CHART_FOUR_CONFIG]: dashboardChartFourConfig,
  [DashboardTypes.DASHBOARD_CHART_FOUR_CONFIG_LOADING]: dashboardChartFourConfigLoading,
  [DashboardTypes.DASHBOARD_CHART_FIVE_CONFIG]: dashboardChartFiveConfig,
  [DashboardTypes.DASHBOARD_CHART_FIVE_CONFIG_LOADING]: dashboardChartFiveConfigLoading,
  [DashboardTypes.DASHBOARD_CHART_SIX_CONFIG]: dashboardChartSixConfig,
  [DashboardTypes.DASHBOARD_CHART_SIX_CONFIG_LOADING]: dashboardChartSixConfigLoading,
  [DashboardTypes.DASHBOARD_FETCH_ID_LOADING]: dashboardFetchIdLoading,
  [DashboardTypes.DASHBOARD_FETCH_ID_SUCCESS]: dashboardFetchIdSuccess
});
