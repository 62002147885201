import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import DashboardActions from '../Stores/Dashboard/Actions';

const getAppStore = state => state.app;

export function* dashboardFetchChartOneData(data) {
  const app = yield select(getAppStore);
  const { param } = data;

  try {
    let url;

    yield put(DashboardActions.dashboardFetchChartOneDataLoading(true));

    if (param.chart === 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyItemReport/${param.newDate}`;
      }
    }

    if (param.chart !== 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyReport/${param.newDate}`;
      }
    }

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      url,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchChartOneDataSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchChartOneDataLoading(false));
  }
}

export function* dashboardFetchChartTwoData(data) {
  const app = yield select(getAppStore);
  const { param } = data;

  try {
    let url;

    yield put(DashboardActions.dashboardFetchChartTwoDataLoading(true));

    if (param.chart === 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyItemReport/${param.newDate}`;
      }
    }

    if (param.chart !== 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyReport/${param.newDate}`;
      }
    }

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      url,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchChartTwoDataSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchChartTwoDataLoading(false));
  }
}

export function* dashboardFetchChartThreeData(data) {
  const app = yield select(getAppStore);
  const { param } = data;

  try {
    let url;

    yield put(DashboardActions.dashboardFetchChartThreeDataLoading(true));

    if (param.chart === 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyItemReport/${param.newDate}`;
      }
    }

    if (param.chart !== 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyReport/${param.newDate}`;
      }
    }

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      url,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchChartThreeDataSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchChartThreeDataLoading(false));
  }
}

export function* dashboardFetchChartFourData(data) {
  const app = yield select(getAppStore);
  const { param } = data;

  try {
    let url;

    yield put(DashboardActions.dashboardFetchChartFourDataLoading(true));

    if (param.chart === 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyItemReport/${param.newDate}`;
      }
    }

    if (param.chart !== 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyReport/${param.newDate}`;
      }
    }

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      url,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchChartFourDataSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchChartFourDataLoading(false));
  }
}

export function* dashboardFetchChartFiveData(data) {
  const app = yield select(getAppStore);
  const { param } = data;

  try {
    let url;

    yield put(DashboardActions.dashboardFetchChartFiveDataLoading(true));

    if (param.chart === 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyItemReport/${param.newDate}`;
      }
    }

    if (param.chart !== 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyReport/${param.newDate}`;
      }
    }

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      url,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchChartFiveDataSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchChartFiveDataLoading(false));
  }
}

export function* dashboardFetchChartSixData(data) {
  const app = yield select(getAppStore);
  const { param } = data;

  try {
    let url;

    yield put(DashboardActions.dashboardFetchChartSixDataLoading(true));

    if (param.chart === 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyItemReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyItemReport/${param.newDate}`;
      }
    }

    if (param.chart !== 'pie') {
      if (param.sortBy === 'week') {
        url = `dashboard/weeklyReport/${param.newDate}`;
      } else if (param.sortBy === 'month') {
        url = `dashboard/monthlyReport/${param.newDate}`;
      } else if (param.sortBy === 'year') {
        url = `dashboard/yearlyReport/${param.newDate}`;
      }
    }

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      url,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchChartSixDataSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchChartSixDataLoading(false));
  }
}

export function* dashboardFetchSummaryChartData() {
  const app = yield select(getAppStore);

  try {
    yield put(DashboardActions.dashboardFetchSummaryChartDataLoading(true));

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `dashboard/summaryReport`,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchSummaryChartDataSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchSummaryChartDataLoading(false));
  }
}

export function* dashboardUpdateModel(data) {
  try {
    yield put(DashboardActions.dashboardUpdateModelLoading(true));
    const app = yield select(getAppStore);
    const { param } = data;
    const postData = {
      setting: param
    };
    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `dashboard/updateModel`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: 'Setting successfully saved.',
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardUpdateModelLoading(false));
  }
}

export function* dashboardCreateModel(data) {
  try {
    yield put(DashboardActions.dashboardCreateModelLoading(true));
    const app = yield select(getAppStore);
    const { param } = data;
    const postData = {
      setting: param
    };
    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `dashboard/createModel`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: 'Setting successfully saved.',
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardCreateModelLoading(false));
  }
}

export function* dashboardRetrieveModel(data) {
  try {
    yield put(DashboardActions.dashboardRetrieveModelLoading(false));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `dashboard/retrieveModel/${data.dashboardId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardRetrieveModelSuccess(result.data));
      yield put(DashboardActions.dashboardRetrieveModelLoading(true));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardRetrieveModelLoading(false));
  }
}

export function* dashboardFetchId(data) {
  try {
    yield put(DashboardActions.dashboardFetchIdLoading(false));

    const app = yield select(getAppStore);

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `dashboard/initId/${data.id}`,
      app.token,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DashboardActions.dashboardFetchIdSuccess(result.data.id));
      yield put(DashboardActions.dashboardFetchIdLoading(true));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DashboardActions.dashboardFetchIdLoading(false));
  }
}
