import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import InvDoc01Actions from '../../Stores/InvDoc01/Actions';

class InvDoc01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'pick_qty' }),
        // sort field
        dataIndex: 'pick_unit_qty',
        sorter: (a, b) => a.case_qty - b.case_qty,
        // filter field
        key: 'pick_unit_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.pick_unit_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'order_qty' }),
        // sort field
        dataIndex: 'ord_unit_qty',
        sorter: (a, b) => a.ord_unit_qty - b.ord_unit_qty,
        // filter field
        key: 'ord_unit_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.ord_unit_qty)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCreateDocument() {
    const { selectedDocuments, createInvDoc01 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    createInvDoc01(hdrIds);
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  render() {
    const {
      intl,
      newDocuments,
      selectedDocuments,
      workspaceIsVisible,
      createIsLoading
    } = this.props;

    // summarise the table
    const calcRow = selectedDocuments.reduce((ttlObj, object) => {
      return {
        pick_unit_qty:
          ('pick_unit_qty' in ttlObj ? ttlObj.pick_unit_qty : 0) + parseFloat(object.pick_unit_qty),
        ord_unit_qty:
          ('ord_unit_qty' in ttlObj ? ttlObj.ord_unit_qty : 0) + parseFloat(object.ord_unit_qty)
      };
    }, {});

    // override the normal table, and add footer below table
    const tableWithFooter = props => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <table {...props}>
          {props.children}
          {Object.keys(calcRow).length > 0 && (
            <tfoot className="ant-table-tbody">
              <tr className="ant-table-row">
                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}>
                  {intl.formatMessage({ id: 'total' })}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.pick_unit_qty)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.ord_unit_qty)}
                </td>
                <td />
              </tr>
            </tfoot>
          )}
        </table>
      );
    };

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_invoice_or_do_from_pick_list' })}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                components={{ table: tableWithFooter }}
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_invoices_or_delivery_orders' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

InvDoc01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,

  createInvDoc01: PropTypes.func
};

InvDoc01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  curSiteFlowId: 0,

  createInvDoc01() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.invDoc01.workspaceIsVisible,

  selectedDocuments: state.invDoc01.selectedDocuments,
  newDocuments: state.invDoc01.newDocuments,

  createIsLoading: state.invDoc01.createIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean => dispatch(InvDoc01Actions.invDoc01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(InvDoc01Actions.invDoc01RemoveSelectedDocuments(selectedDocuments)),

  createInvDoc01: hdrIds => dispatch(InvDoc01Actions.invDoc01CreateInvDoc01(hdrIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InvDoc01Dialog));
