import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsDelProcessFetchGdsDelProcess: ['siteFlowId'],
  gdsDelProcessFetchGdsDelProcessLoading: ['boolean'],
  gdsDelProcessFetchGdsDelProcessSuccess: ['process']
});

export const GdsDelProcessTypes = Types;
export default Creators;
