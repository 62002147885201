import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickFaceStrategyList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickFaceStrategyList01GoToDocument: ['hdrId'],
  pickFaceStrategyList01FetchPickFaceStrategyList01: [
    'siteFlowId',
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  pickFaceStrategyList01FetchPickFaceStrategyList01Loading: ['boolean'],
  pickFaceStrategyList01FetchPickFaceStrategyList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  pickFaceStrategyList01AddSelectedDocuments: ['selectedDocuments'],
  pickFaceStrategyList01RemoveSelectedDocuments: ['selectedDocuments'],
  pickFaceStrategyList01SetWorkspaceVisible: ['boolean']
});

export const PickFaceStrategyList01Types = Types;
export default Creators;
