/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DivisionDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const divisionDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const divisionDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
});

export const divisionDetailShowDocumentLoading = (state, { boolean }) => ({
    ...state,
    documentIsLoading: boolean
  });
  
export const divisionDetailShowHeaderSuccess = (state, { documentHeader }) => ({
    ...state,
    documentHeader
});

export const divisionDetailUpdateHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const divisionDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const divisionDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const divisionDetailShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const divisionDetailUpdateSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [DivisionDetailTypes.DIVISION_DETAIL_RESET_TIMESTAMP]: divisionDetailResetTimestamp,
    [DivisionDetailTypes.DIVISION_DETAIL_SET_HDR_ID]: divisionDetailSetHdrId,
    [DivisionDetailTypes.DIVISION_DETAIL_SHOW_HEADER_SUCCESS]: divisionDetailShowHeaderSuccess,
    [DivisionDetailTypes.DIVISION_DETAIL_SHOW_DOCUMENT_LOADING]: divisionDetailShowDocumentLoading,
    [DivisionDetailTypes.DIVISION_DETAIL_UPDATE_HEADER_SUCCESS]: divisionDetailUpdateHeaderSuccess,

    [DivisionDetailTypes.DIVISION_DETAIL_FETCH_COMPANY_OPTION_LOADING]: divisionDetailFetchCompanyOptionLoading,
    [DivisionDetailTypes.DIVISION_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: divisionDetailFetchCompanyOptionSuccess,
    
    [DivisionDetailTypes.DIVISION_DETAIL_SHOW_SYNC_SETTING_SUCCESS]: divisionDetailShowSyncSettingSuccess,
    [DivisionDetailTypes.DIVISION_DETAIL_UPDATE_SYNC_SETTING_SUCCESS]: divisionDetailUpdateSyncSettingSuccess,

});