import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Card, Button, Row, Col, Typography, Radio } from 'antd';
import { FormikInput, FormikSelect, FormikButton, FormikRadio } from '../../Components/Formik';

import PromoDetailActions from '../../Stores/PromoDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class BroadcastForm extends React.PureComponent {
  constructor(props) {
    super(props)

    // this.handleRecipientOptionChange = this.handleRecipientOptionChange.bind(this)
  }
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { rules } = this.props
    if(prevProps.rules !== rules) {
      this.forceUpdate()
    }
  }

  componentWillUnmount() {}
  
  // handleRecipientOptionChange(value) {
  //   const { setRecipientOption } = this.props
    
  //   setRecipientOption(value)
  // }

  render() {
    const {
      intl,
      hdrId,
      broadcast,
      broadcastPromo,
      rules,
      documentIsLoading,
    } = this.props;

    const disabled = false;

    const priorityOptions = [
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'normal' })}
      </Radio.Button>,
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'urgent' })}
      </Radio.Button>
    ];
    
    return (
      <Formik
        enableReinitialize
        initialValues={broadcast}
        onSubmit={(values, formikBag) => {

          if (values.submit_action === 'broadcast') {
            const processedValues = {
              id: hdrId,
              title: values.title,
              content: values.content,
              priority: values.priority,
              // recipient_option: values.recipient_option,
              rules: rules,
              debtor_ids: [],
              debtor_group_ids: [],
              promotion_id: 0
            };

            broadcastPromo(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={ 
          Yup.object().shape({
            title: Yup.string().required(intl.formatMessage({ id: 'title_is_required' })),
            content: Yup.string().required(intl.formatMessage({ id: 'content_is_required' })),
            priority: Yup.number().min(0, intl.formatMessage({ id: 'priority_is_required' })),
          })
        }
      >
        {({
          values,
          dirty,
          isSubmitting,
          handleSubmit,
          handleReset,
          setFieldValue
        }) => {
          return (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'broadcast_promo' })}>
              {/* Title Input */}
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'title' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="title" component={FormikInput} disabled={disabled} />
                </Col>
              </Row>

              {/* Content Input */}
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'content' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="content" component={FormikInput} disabled={disabled} />
                </Col>
              </Row>

              {/* Priority Radio */}
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'priority' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="priority"
                    component={FormikRadio}
                    options={priorityOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              {/* Recipients Divider */}
              {/* <Row type="flex" justify="start" gutter={[8,8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'recipient_option' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="recipient_option"
                    render={({ field, form: { isSubmitting }}) => (
                      <Radio.Group 
                        defaultValue={values.recipient_option} 
                        onChange={(value) => {
                          this.handleRecipientOptionChange(value.target.value)
                        }}
                      >
                        <Radio.Button key={0} value={0}>
                          {intl.formatMessage({ id: 'all_recipients' })}
                        </Radio.Button>
                        <Radio.Button key={1} value={1}>
                          {intl.formatMessage({ id: 'selected_recipients' })}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  />
                </Col>
              </Row> */}
            </Card>

            <Card>
              <Row type="flex" justify="space-between" gutter={[16, 16]}>
                <Col>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="broadcast"
                    disabled={hdrId === 0}
                    component={FormikButton}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({ id: 'broadcast' })}
                    icon="notification"
                  />
                </Col>
              </Row>
            </Card>
          
          </Form>
          )}
        }
      </Formik>
    );
  }
}

BroadcastForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  transitionToStatus: PropTypes.func,
  broadcastPromo: PropTypes.func,
  hdrId: PropTypes.number,
  curDivisionId: PropTypes.number,
};

BroadcastForm.defaultProps = {
  intl: {},
  match: {},
  transitionToStatus() {},
  broadcastPromo() {},
  hdrId: 0,
  curDivisionId: 0,
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.promoDetail.timestamp,
  hdrId: state.promoDetail.hdrId,
  broadcast: state.promoDetail.broadcast,
  documentIsLoading: state.promoDetail.documentIsLoading,
  curDivisionId: state.app.curDivisionId,
  rules: state.promoDetail.rules,
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(PromoDetailActions.promoDetailResetTimestamp()),
  broadcastPromo: (formikBag, hdrId, broadcastValues) =>
    dispatch(PromoDetailActions.promoDetailBroadcastPromo(formikBag, hdrId, broadcastValues)),

  // setRecipientOption: option => dispatch(PromoDetailActions.promoDetailSetRecipientOption(option))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BroadcastForm));
