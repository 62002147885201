import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import WhseJob1702Actions from '../../Stores/WhseJob1702/Actions';

class WhseJob1702Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_row' }),
        // sort field
        dataIndex: 'storage_row_qty',
        sorter: (a, b) => a.storage_row_qty - b.storage_row_qty,
        // filter field
        key: 'storage_row_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.storage_row_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bay' }),
        // sort field
        dataIndex: 'storage_bay_qty',
        sorter: (a, b) => a.storage_bay_qty - b.storage_bay_qty,
        // filter field
        key: 'storage_bay_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.storage_bay_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_qty',
        sorter: (a, b) => a.storage_bin_qty - b.storage_bin_qty,
        // filter field
        key: 'storage_bin_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.storage_bin_qty)}
            </div>
          </>
        )
      },

      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnCreateDocument() {
    const { selectedDocuments, createWhseJob1702 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    createWhseJob1702(hdrIds);
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, createIsLoading } = this.props;

    // summarise the table
    const calcRow = selectedDocuments.reduce((ttlObj, object) => {
      return {
        storage_row_qty:
          ('storage_row_qty' in ttlObj ? ttlObj.storage_row_qty : 0) +
          parseFloat(object.storage_row_qty),
        storage_bay_qty:
          ('storage_bay_qty' in ttlObj ? ttlObj.storage_bay_qty : 0) +
          parseFloat(object.storage_bay_qty),
        storage_bin_qty:
          ('storage_bin_qty' in ttlObj ? ttlObj.storage_bin_qty : 0) +
          parseFloat(object.storage_bin_qty)
      };
    }, {});

    // override the normal table, and add footer below table
    const tableWithFooter = props => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <table {...props}>
          {props.children}
          {Object.keys(calcRow).length > 0 && (
            <tfoot className="ant-table-tbody">
              <tr className="ant-table-row">
                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}>
                  {intl.formatMessage({ id: 'total' })}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.storage_row_qty)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.storage_bay_qty)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.storage_bin_qty)}
                </td>
                <td />
              </tr>
            </tfoot>
          )}
        </table>
      );
    };

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_bin_transfer_warehouse_job' })}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                components={{ table: tableWithFooter }}
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

WhseJob1702Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,

  createWhseJob1702: PropTypes.func
};

WhseJob1702Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  curSiteFlowId: 0,

  createWhseJob1702() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.whseJob1702.workspaceIsVisible,

  selectedDocuments: state.whseJob1702.selectedDocuments,
  newDocuments: state.whseJob1702.newDocuments,

  createIsLoading: state.whseJob1702.createIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(WhseJob1702Actions.whseJob1702SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(WhseJob1702Actions.whseJob1702RemoveSelectedDocuments(selectedDocuments)),

  createWhseJob1702: hdrIds => dispatch(WhseJob1702Actions.whseJob1702CreateWhseJob1702(hdrIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WhseJob1702Dialog));
