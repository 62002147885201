/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AuditLogTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const auditLogResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const auditLogSetAuditable = (state, { auditableId }) => ({
  ...state,
  auditableId
});

export const auditLogFetchAuditLogLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const auditLogFetchAuditLogSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const auditLogSetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AuditLogTypes.AUDIT_LOG_RESET_TIMESTAMP]: auditLogResetTimestamp,
  [AuditLogTypes.AUDIT_LOG_SET_AUDITABLE]: auditLogSetAuditable,
  [AuditLogTypes.AUDIT_LOG_FETCH_AUDIT_LOG_LOADING]: auditLogFetchAuditLogLoading,
  [AuditLogTypes.AUDIT_LOG_FETCH_AUDIT_LOG_SUCCESS]: auditLogFetchAuditLogSuccess,
  [AuditLogTypes.AUDIT_LOG_SET_EXPANDED_ROWS]: auditLogSetExpandedRows
});
