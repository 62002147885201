import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob160102ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob160102GoToDocument: ['hdrId'],
  whseJob160102FetchWhseJob160102: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob160102FetchWhseJob160102Loading: ['boolean'],
  whseJob160102FetchWhseJob160102Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob160102AddSelectedDocuments: ['selectedDocuments'],
  whseJob160102RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob160102SetWorkspaceVisible: ['boolean'],
  whseJob160102PrintWhseJob160102: ['siteFlowId', 'hdrIds'],
  whseJob160102PrintWhseJob160102Loading: ['boolean'],

  whseJob160102SetExpandedRows: ['expandedRows']
});

export const WhseJob160102Types = Types;
export default Creators;
