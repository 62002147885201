/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { InbOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const inbOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const inbOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  purchaserOptions: [],
  bizPartnerOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const inbOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const inbOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const inbOrdDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const inbOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const inbOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const inbOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const inbOrdDetailFetchPurchaserOptionLoading = (state, { boolean }) => ({
  ...state,
  purchaserIsLoading: boolean
});

export const inbOrdDetailFetchPurchaserOptionSuccess = (state, { options }) => ({
  ...state,
  purchaserOptions: options
});

export const inbOrdDetailFetchBizPartnerOptionLoading = (state, { boolean }) => ({
  ...state,
  bizPartnerIsLoading: boolean
});

export const inbOrdDetailFetchBizPartnerOptionSuccess = (state, { options }) => ({
  ...state,
  bizPartnerOptions: options
});

export const inbOrdDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const inbOrdDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const inbOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const inbOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const inbOrdDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const inbOrdDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const inbOrdDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const inbOrdDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const inbOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const inbOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InbOrdDetailTypes.INB_ORD_DETAIL_RESET_TIMESTAMP]: inbOrdDetailResetTimestamp,
  [InbOrdDetailTypes.INB_ORD_DETAIL_SET_HDR_ID]: inbOrdDetailSetHdrId,

  [InbOrdDetailTypes.INB_ORD_DETAIL_SHOW_HEADER_SUCCESS]: inbOrdDetailShowHeaderSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: inbOrdDetailUpdateDocumentSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: inbOrdDetailShowDocumentLoading,

  [InbOrdDetailTypes.INB_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: inbOrdDetailShowDetailsSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_SET_DETAIL_VISIBLE]: inbOrdDetailSetDetailVisible,
  [InbOrdDetailTypes.INB_ORD_DETAIL_SET_DOCUMENT_DETAIL]: inbOrdDetailSetDocumentDetail,

  [InbOrdDetailTypes.INB_ORD_DETAIL_UPDATE_DETAILS]: inbOrdDetailUpdateDetails,
  [InbOrdDetailTypes.INB_ORD_DETAIL_CREATE_DETAIL]: inbOrdDetailCreateDetail,

  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_PURCHASER_OPTION_LOADING]: inbOrdDetailFetchPurchaserOptionLoading,
  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_PURCHASER_OPTION_SUCCESS]: inbOrdDetailFetchPurchaserOptionSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_BIZ_PARTNER_OPTION_LOADING]: inbOrdDetailFetchBizPartnerOptionLoading,
  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_BIZ_PARTNER_OPTION_SUCCESS]: inbOrdDetailFetchBizPartnerOptionSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: inbOrdDetailFetchCreditTermOptionLoading,
  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: inbOrdDetailFetchCreditTermOptionSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: inbOrdDetailFetchCurrencyOptionLoading,
  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: inbOrdDetailFetchCurrencyOptionSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_ITEM_OPTION_LOADING]: inbOrdDetailFetchItemOptionLoading,
  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: inbOrdDetailFetchItemOptionSuccess,

  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_UOM_OPTION_LOADING]: inbOrdDetailFetchUomOptionLoading,
  [InbOrdDetailTypes.INB_ORD_DETAIL_FETCH_UOM_OPTION_SUCCESS]: inbOrdDetailFetchUomOptionSuccess
});
