import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { Card, Row, Col, Table, Button, Progress } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import SlsOrdSync02Actions from '../../Stores/SlsOrdSync02/Actions';
import CriteriaForm from './CriteriaForm';

class SlsOrdSync02Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchSlsOrdIndex,
      currentPage,
      sorts,
      filters,
      criteria,
      pageSize,
      syncIsLoading,
      showBatchJobStatus
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchSlsOrdIndex(curDivisionId, currentPage, sorts, filters, criteria, pageSize);
      }
    }
    
    if (syncIsLoading) {
      showBatchJobStatus();
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'order_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.doc_code
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'order_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        // ...AntDesignTable.getColumnSearchProps(
        //   filters,
        //   intl.formatMessage({ id: 'doc_date' }),
        //   'doc_date',
        //   this.handleSearch,
        //   this.handleReset
        // ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'order_by' }),
        // sort field
        dataIndex: 'salesman_username',
        ...AntDesignTable.getColumnSortProps(sorts, 'salesman_username'),
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'salesman' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <span style={{fontWeight: 'bold', fontSize: '0.8em'}}>{record.debtor_code} - {record.debtor_company_name_01}</span>
            <br />
            {record.salesman_username}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'deliver_to' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_area_code'),
        // filter field
        key: 'delivery_point_area',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'delivery_point_area',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.delivery_point_unit_no} {record.delivery_point_building_name}
            <br />
            {record.delivery_point_street_name} {record.delivery_point_district_01} {record.delivery_point_district_02}
            <br />
            {record.delivery_point_postcode} {record.delivery_point_area_name} {record.delivery_point_state_name}
          </>
        )
      },
      // {
      //   width: 150,
      //   align: 'left',
      //   title: intl.formatMessage({ id: 'delivery_point' }),
      //   // sort field
      //   dataIndex: 'delivery_point_code',
      //   ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
      //   // filter field
      //   key: 'delivery_point',
      //   ...AntDesignTable.getColumnSearchProps(
      //     filters,
      //     intl.formatMessage({ id: 'delivery_point' }),
      //     'delivery_point',
      //     this.handleSearch,
      //     this.handleReset
      //   ),
      //   render: (text, record) => (
      //     <>
      //       <b>{record.delivery_point_code}</b>
      //       <br />
      //       {record.delivery_point_company_name_01}
      //     </>
      //   )
      // },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_doc_status'),
        // filter field
        key: 'status',
        filters: [
          { text: 'Draft', value: '3' },
          { text: 'Processing', value: '50' },
          // { text: 'Completed', value: '100' },
        ],
        // ...AntDesignTable.getColumnSearchProps(
        //   filters,
        //   intl.formatMessage({ id: 'doc_status' }),
        //   'str_doc_status',
        //   this.handleSearch,
        //   this.handleReset
        // ),
        render: (text, record) => (
          <>
          {record.str_doc_status == `DRAFT` ?
          <span style={{backgroundColor: '#1890ff', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>Draft</span> :
          record.str_doc_status == `WIP` ?
          <span style={{backgroundColor: '#001529', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>Processing</span> :
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>Completed</span>}
          </>
        )
      },
      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon="edit" onClick={() => goToDocument(record.id)} />
            <Button type="dashed" icon="audit" onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { criteria, resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, criteria, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      syncIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      batchJobStatus,
      syncNow,
      curDivisionId
    } = this.props;
    const hasSelected = selectedDocuments.length > 0;
    return (
      <Card>
        <Row type="flex" justify="center" gutter={[0, 8]} style={syncIsLoading ? {visibility: "visible"} : {visibility: "hidden"}}>
          <Col span={24}>
            <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CriteriaForm />
          </Col>
        </Row>
        <br />
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: record => ({
              // Column configuration not to be checked
              disabled: record.str_doc_status == 'WIP',
              name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={18}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  >
                  Refresh
                  </Button>
                  <Button
                    type="primary"
                    disabled={!hasSelected}
                    loading={fetchIsLoading}
                    onClick={() => syncNow(curDivisionId, selectedDocuments)}
                    icon="upload"
                    style={ {marginLeft : 5} }
                  >
                  Upload
                  </Button>
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected ? `Selected ${selectedDocuments.length} items` : ''}
                  </span>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
        </Card>
    );
  }
}

SlsOrdSync02Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchSlsOrdIndex: PropTypes.func,
  criteria: PropTypes.object,

  syncIsLoading: PropTypes.bool,
  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  showBatchJobStatus: PropTypes.func,
  syncNow: PropTypes.func,
};

SlsOrdSync02Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},
  criteria: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchSlsOrdIndex() {},
  
  syncIsLoading: false,
  showBatchJobStatus() {},
  syncNow() {},
};

const mapStateToProps = state => ({
  timestamp: state.slsOrdSync02.timestamp,
  // workspaceIsVisible: state.slsOrdSync02.workspaceIsVisible,

  documents: state.slsOrdSync02.documents,
  selectedDocuments: state.slsOrdSync02.selectedDocuments,

  sorts: state.slsOrdSync02.sorts,
  filters: state.slsOrdSync02.filters,
  criteria: state.slsOrdSync02.criteria,

  currentPage: state.slsOrdSync02.currentPage,
  pageSize: state.slsOrdSync02.pageSize,
  total: state.slsOrdSync02.total,
  fetchIsLoading: state.slsOrdSync02.fetchIsLoading,

  curDivisionId: state.app.curDivisionId,
  
  syncIsLoading: state.slsOrdSync02.syncIsLoading,
  batchJobStatus: state.slsOrdSync02.batchJobStatus,
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, criteria, pageSize) =>
    dispatch(SlsOrdSync02Actions.slsOrdSync02ResetTimestamp(currentPage, sorts, filters, criteria, pageSize)),
  goToDocument: hdrId => dispatch(SlsOrdSync02Actions.slsOrdSync02GoToDocument(hdrId)),

  // setWorkspaceVisible: boolean =>
  //   dispatch(SlsOrdIndexActions.slsOrdSync02SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(SlsOrdSync02Actions.slsOrdSync02AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(SlsOrdSync02Actions.slsOrdSync02RemoveSelectedDocuments(selectedDocuments)),

  fetchSlsOrdIndex: (divisionId, currentPage, sorts, filters, criteria, pageSize) =>
    dispatch(
      SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndex(
        divisionId,
        currentPage,
        sorts,
        filters,
        criteria,
        pageSize
      )
    ),

  showBatchJobStatus: () => dispatch(SlsOrdSync02Actions.slsOrdSync02ShowBatchJobStatus()),
  syncNow: (divisionId, selectedDocuments) =>
    dispatch(SlsOrdSync02Actions.slsOrdSync02SyncNow(divisionId, selectedDocuments))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SlsOrdSync02Table));
