import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  dashboardFetchChartOneData: ['param'],
  dashboardFetchChartOneDataLoading: ['boolean'],
  dashboardFetchChartOneDataSuccess: ['data'],

  dashboardFetchChartTwoData: ['param'],
  dashboardFetchChartTwoDataLoading: ['boolean'],
  dashboardFetchChartTwoDataSuccess: ['data'],

  dashboardFetchChartThreeData: ['param'],
  dashboardFetchChartThreeDataLoading: ['boolean'],
  dashboardFetchChartThreeDataSuccess: ['data'],

  dashboardFetchChartFourData: ['param'],
  dashboardFetchChartFourDataLoading: ['boolean'],
  dashboardFetchChartFourDataSuccess: ['data'],

  dashboardFetchChartFiveData: ['param'],
  dashboardFetchChartFiveDataLoading: ['boolean'],
  dashboardFetchChartFiveDataSuccess: ['data'],

  dashboardFetchChartSixData: ['param'],
  dashboardFetchChartSixDataLoading: ['boolean'],
  dashboardFetchChartSixDataSuccess: ['data'],

  dashboardFetchSummaryChartData: [],
  dashboardFetchSummaryChartDataLoading: ['boolean'],
  dashboardFetchSummaryChartDataSuccess: ['data'],

  dashboardUpdateModel: ['param'],
  dashboardUpdateModelLoading: ['boolean'],

  dashboardCreateModel: ['param'],
  dashboardCreateModelLoading: ['boolean'],

  dashboardRetrieveModel: ['dashboardId'],
  dashboardRetrieveModelLoading: ['boolean'],
  dashboardRetrieveModelSuccess: ['data'],

  dashboardChartOneConfig: ['config'],
  dashboardChartOneConfigLoading: ['boolean'],

  dashboardChartTwoConfig: ['config'],
  dashboardChartTwoConfigLoading: ['boolean'],

  dashboardChartThreeConfig: ['config'],
  dashboardChartThreeConfigLoading: ['boolean'],

  dashboardChartFourConfig: ['config'],
  dashboardChartFourConfigLoading: ['boolean'],

  dashboardChartFiveConfig: ['config'],
  dashboardChartFiveConfigLoading: ['boolean'],

  dashboardChartSixConfig: ['config'],
  dashboardChartSixConfigLoading: ['boolean'],

  dashboardFetchId: ['id'],
  dashboardFetchIdLoading: ['boolean'],
  dashboardFetchIdSuccess: ['id']
});

export const DashboardTypes = Types;
export default Creators;
