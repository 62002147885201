import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  debtorDetailResetTimestamp: null,
  debtorDetailSetHdrId: ['hdrId'],

  debtorDetailInitHeader: ['divisionId'],
  debtorDetailShowHeader: ['hdrId'],
  debtorDetailShowHeaderSuccess: ['documentHeader'],

  debtorDetailUpdateHeader: ['formikBag', 'documentHeader'],
  debtorDetailCreateHeader: ['formikBag', 'documentHeader', 'divisionId'],
  debtorDetailDeleteHeader: ['hdrId'],
  debtorDetailUpdateDocumentSuccess: ['documentHeader'],

  debtorDetailShowDocumentLoading: ['boolean'],
  debtorDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],
});

export const DebtorDetailTypes = Types;
export default Creators;