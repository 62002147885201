import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikText,
  FormikInputNumber,
  FormikTextNumber
} from '../../Components/Formik';
import WhseJob16DetailActions from '../../Stores/WhseJob16Detail/Actions';

const { Text } = Typography;

class WhseJobType01Form extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemBatch = this.handleSearchItemBatch.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchStorageBin = this.handleSearchStorageBin.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeItemBatch = this.handleChangeItemBatch.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchItemBatch(form, value) {
    const { fetchItemBatchOptions } = this.props;
    const { values } = form;

    fetchItemBatchOptions(values.item_select2.value, value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_select2.value, value);
  }

  handleSearchStorageBin(form, value) {
    const { curSiteFlowId, fetchStorageBinOptions } = this.props;

    fetchStorageBinOptions(curSiteFlowId, value);
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { hdrId, changeItem } = this.props;

    changeItem(form, hdrId, value);
  }

  handleChangeItemBatch(form, value) {
    // params: form, value, option
    const { hdrId, changeItemBatch } = this.props;
    const { values } = form;

    changeItemBatch(form, hdrId, values.item_select2.value, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      companyIsLoading,
      companyOptions,
      itemIsLoading,
      itemOptions,
      itemBatchIsLoading,
      itemBatchOptions,
      uomIsLoading,
      uomOptions,
      storageBinIsLoading,
      storageBinOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          company_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'storage_bin_is_required' }))
          }),
          item_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_is_required' }))
          }),
          item_batch_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_batch_is_required' }))
          }),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          }),
          qty: Yup.number().moreThan(0, intl.formatMessage({ id: 'qty_must_be_greater_than_0' }))
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'company' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="company_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleCompanySearch}
                  notFoundContent={null}
                  loading={companyIsLoading}
                  options={companyOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBin}
                  notFoundContent={null}
                  loading={storageBinIsLoading}
                  options={storageBinOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={disabled}
                  onChange={this.handleChangeItem}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_01" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_02" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_batch' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_batch_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemBatch}
                  notFoundContent={null}
                  loading={itemBatchIsLoading}
                  options={itemBatchOptions}
                  disabled={disabled}
                  onChange={this.handleChangeItemBatch}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="batch_serial_no" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="expiry_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="receipt_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'qty' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="qty" component={FormikInputNumber} disabled={disabled} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="uom_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUom}
                  notFoundContent={null}
                  loading={uomIsLoading}
                  options={uomOptions}
                  disabled={disabled}
                  onChange={this.handleChangeUom}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="uom_rate"
                  component={FormikTextNumber}
                  minimumfractiondigits={6}
                  maximumfractiondigits={6}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon="undo"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon="save"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

WhseJobType01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemBatchOptions: PropTypes.func,
  changeItemBatch: PropTypes.func,
  itemBatchIsLoading: PropTypes.bool,
  itemBatchOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchStorageBinOptions: PropTypes.func,
  storageBinIsLoading: PropTypes.bool,
  storageBinOptions: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number
};

WhseJobType01Form.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchItemOptions() {},
  changeItem() {},
  itemIsLoading: false,
  itemOptions: [],

  fetchItemBatchOptions() {},
  changeItemBatch() {},
  itemBatchIsLoading: false,
  itemBatchOptions: [],

  fetchUomOptions() {},
  changeUom() {},
  uomIsLoading: false,
  uomOptions: [],

  fetchStorageBinOptions() {},
  storageBinIsLoading: false,
  storageBinOptions: [],

  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  hdrId: state.whseJob16Detail.hdrId,
  documentHeader: state.whseJob16Detail.documentHeader,
  documentDetail: state.whseJob16Detail.documentDetail,
  documentIsLoading: state.whseJob16Detail.documentIsLoading,

  companyIsLoading: state.whseJob16Detail.companyIsLoading,
  companyOptions: state.whseJob16Detail.companyOptions,

  itemIsLoading: state.whseJob16Detail.itemIsLoading,
  itemOptions: state.whseJob16Detail.itemOptions,

  itemBatchIsLoading: state.whseJob16Detail.itemBatchIsLoading,
  itemBatchOptions: state.whseJob16Detail.itemBatchOptions,

  uomIsLoading: state.whseJob16Detail.uomIsLoading,
  uomOptions: state.whseJob16Detail.uomOptions,

  storageBinIsLoading: state.whseJob16Detail.storageBinIsLoading,
  storageBinOptions: state.whseJob16Detail.storageBinOptions,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(
      WhseJob16DetailActions.whseJob16DetailUpdateDetails(formikBag, hdrId, documentDetails)
    ),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(WhseJob16DetailActions.whseJob16DetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchCompanyOptions: search =>
    dispatch(WhseJob16DetailActions.whseJob16DetailFetchCompanyOptions(search)),

  fetchItemOptions: search =>
    dispatch(WhseJob16DetailActions.whseJob16DetailFetchItemOptions(search)),
  changeItem: (formikBag, hdrId, itemId) =>
    dispatch(WhseJob16DetailActions.whseJob16DetailChangeItem(formikBag, hdrId, itemId)),

  fetchItemBatchOptions: (itemId, search) =>
    dispatch(WhseJob16DetailActions.whseJob16DetailFetchItemBatchOptions(itemId, search)),
  changeItemBatch: (formikBag, hdrId, itemBatchId) =>
    dispatch(WhseJob16DetailActions.whseJob16DetailChangeItemBatch(formikBag, hdrId, itemBatchId)),

  fetchUomOptions: (itemId, search) =>
    dispatch(WhseJob16DetailActions.whseJob16DetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(WhseJob16DetailActions.whseJob16DetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(WhseJob16DetailActions.whseJob16DetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchStorageBinOptions: (siteFlowId, search) =>
    dispatch(WhseJob16DetailActions.whseJob16DetailFetchStorageBinOptions(siteFlowId, search))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WhseJobType01Form));
