import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  palletLabelList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  palletLabelList01GoToDocument: ['hdrId'],
  palletLabelList01FetchPalletLabelList01: [
    'siteFlowId',
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  palletLabelList01FetchPalletLabelList01Loading: ['boolean'],
  palletLabelList01FetchPalletLabelList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  palletLabelList01AddSelectedDocuments: ['selectedDocuments'],
  palletLabelList01RemoveSelectedDocuments: ['selectedDocuments'],
  palletLabelList01SetWorkspaceVisible: ['boolean'],
  palletLabelList01PrintPalletLabelList01: ['siteFlowId', 'ids'],
  palletLabelList01PrintPalletLabelList01Loading: ['boolean']
});

export const PalletLabelList01Types = Types;
export default Creators;
