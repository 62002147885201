import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  roleProcessFetchRoleProcess: ['siteFlowId'],
  roleProcessFetchRoleProcessLoading: ['boolean'],
  roleProcessFetchRoleProcessSuccess: ['process']
});

export const RoleProcessTypes = Types;
export default Creators;
