const processHeaderDetails = (
  oldDocumentHeader,
  oldDocumentDetails,
  retDocumentHeader,
  retDocumentDetails,
  retDeletedDetails
) => {
  // loop the old details, check if got any modification, and replace the old detail
  const newDocumentDetails = oldDocumentDetails.map(oldDetail => {
    let a = -1;
    const retDocumentDetail = retDocumentDetails.reduce((lastObj, data, index) => {
      if (oldDetail.id === data.id) {
        a = index;
        return data;
      }
      return lastObj;
    }, {});

    if (a >= 0) {
      let isModified = false;
      Object.entries(retDocumentDetail).forEach(entry => {
        const field = entry[0];
        const modifiedValue = entry[1];

        const oldValue = oldDetail[field];
        if (typeof oldValue === 'object') {
          if (JSON.stringify(oldValue) !== JSON.stringify(modifiedValue)) {
            // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
            isModified = true;
          }
        } else if (oldValue !== modifiedValue) {
          // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
          isModified = true;
        }
      });

      retDocumentDetails.splice(a, 1);

      if (isModified) {
        return { ...retDocumentDetail, is_modified: true };
      }
    }

    return { ...oldDetail, is_modified: false };
  });

  // loop the remaining new details, and add to model
  retDocumentDetails.map(value => {
    newDocumentDetails.splice(newDocumentDetails.length, 1, { ...value, is_modified: true });
    return { ...value, is_modified: true };
  });

  // loop the deleted details, and remove from model
  retDeletedDetails.map(value => {
    newDocumentDetails.forEach((newDetail, a) => {
      if (value.id === newDetail.id) {
        newDocumentDetails.splice(a, 1);
      }
    });
    return { ...value, is_deleted: true };
  });

  if(retDocumentHeader.actions[0].config !== null && retDocumentHeader.actions[0].config.hasOwnProperty('disc_fixed_price')) {
    retDocumentHeader['disc_fixed_price'] = retDocumentHeader.actions[0].config.disc_fixed_price
  }

  return {
    header: retDocumentHeader,
    details: newDocumentDetails
  };
};

const processResources = (oldResources, retResources, retDeletedResources) => {
  // loop the old details, check if got any modification, and replace the old detail
  const newResources = oldResources.map(oldDetail => {
    let a = -1;
    const retResource = retResources.reduce((lastObj, data, index) => {
      if (oldDetail.id === data.id) {
        a = index;
        return data;
      }
      return lastObj;
    }, {});
    console.log(a);
    if (a >= 0) {
      let isModified = false;
      Object.entries(retResource).forEach(entry => {
        const field = entry[0];
        const modifiedValue = entry[1];

        const oldValue = oldDetail[field];
        if (typeof oldValue === 'object') {
          if (JSON.stringify(oldValue) !== JSON.stringify(modifiedValue)) {
            // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
            isModified = true;
          }
        } else if (oldValue !== modifiedValue) {
          // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
          isModified = true;
        }
      });

      retResources.splice(a, 1);

      if (isModified) {
        return { ...retResource, is_modified: true };
      }
    }

    return { ...oldDetail, is_modified: false };
  });

  // loop the remaining new details, and add to model
  retResources.map(value => {
    newResources.splice(newResources.length, 1, { ...value, is_modified: true });
    return { ...value, is_modified: true };
  });

  // loop the deleted details, and remove from model
  retDeletedResources.map(value => {
    newResources.forEach((newDetail, a) => {
      if (value.id === newDetail.id) {
        newResources.splice(a, 1);
      }
    });
    return { ...value, is_deleted: true };
  });

  return {
    resources: newResources
  };
};

export default {
  processHeaderDetails,
  processResources
};
