/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CycleCount02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const cycleCount02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false,
  printIsLoading: false
});

export const cycleCount02FetchCycleCount02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const cycleCount02FetchCycleCount02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const cycleCount02AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const cycleCount02RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const cycleCount02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const cycleCount02SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CycleCount02Types.CYCLE_COUNT02_RESET_TIMESTAMP]: cycleCount02ResetTimestamp,
  [CycleCount02Types.CYCLE_COUNT02_FETCH_CYCLE_COUNT02_LOADING]: cycleCount02FetchCycleCount02Loading,
  [CycleCount02Types.CYCLE_COUNT02_FETCH_CYCLE_COUNT02_SUCCESS]: cycleCount02FetchCycleCount02Success,
  [CycleCount02Types.CYCLE_COUNT02_ADD_SELECTED_DOCUMENTS]: cycleCount02AddSelectedDocuments,
  [CycleCount02Types.CYCLE_COUNT02_REMOVE_SELECTED_DOCUMENTS]: cycleCount02RemoveSelectedDocuments,
  [CycleCount02Types.CYCLE_COUNT02_SET_WORKSPACE_VISIBLE]: cycleCount02SetWorkspaceVisible,

  [CycleCount02Types.CYCLE_COUNT02_SET_EXPANDED_ROWS]: cycleCount02SetExpandedRows
});
