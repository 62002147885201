import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Row, Col, Table, Button, Tag } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import UserList02Actions from '../../Stores/UserList02/Actions';

import ManageDivisionDialog from './ManageDivisionDialog';

class UserList02Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchUserList02,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchUserList02(curSiteFlowId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit, setManageDivisionVisible } = this.props;

    return [
      {
        width: 130,
        fixed: 'left',
        align: 'left',
        title: intl.formatMessage({ id: 'username' }),
        // sort field
        dataIndex: 'username',
        ...AntDesignTable.getColumnSortProps(sorts, 'username'),
        // filter field
        key: 'username',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'username' }),
          'username',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.username}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'email' }),
        // sort field
        dataIndex: 'email',
        ...AntDesignTable.getColumnSortProps(sorts, 'email'),
        // filter field
        key: 'email',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'email' }),
          'email',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.email}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_status'),
        // filter field
        key: 'str_status',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'status' }),
          'str_status',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.str_status}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'last_login' }),
        // sort field
        dataIndex: 'last_login',
        ...AntDesignTable.getColumnSortProps(sorts, 'last_login'),
        // filter field
        key: 'last_login',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'last_login' }),
          'last_login',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.last_login}</>
      },
      {
        width: 125,
        align: 'left',
        title: intl.formatMessage({ id: 'password_changed_at' }),
        // sort field
        dataIndex: 'password_changed_at',
        ...AntDesignTable.getColumnSortProps(sorts, 'password_changed_at'),
        // filter field
        key: 'password_changed_at',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'password_changed_at' }),
          'password_changed_at',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.password_changed_at}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'first_name' }),
        // sort field
        dataIndex: 'first_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'first_name'),
        // filter field
        key: 'first_name',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'first_name' }),
          'first_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.first_name}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'last_name' }),
        // sort field
        dataIndex: 'last_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'last_name'),
        // filter field
        key: 'last_name',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'last_name' }),
          'last_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.last_name}</>
      },
      {
        width: 155,
        //fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'divisions',
        // filter field
        key: 'divisions',
        render: (text, record) => (
          <div>
            {record.divisions.map(value => (
              <Tag key={value.id}>{value.code}</Tag>
            ))}
          </div>
        )
      },
      {
        width: 50,
        fixed: 'right',
        align: 'left',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon="edit" onClick={() => goToDocument(record.id)} />
            <Button
              type="dashed"
              icon="usergroup-add"
              onClick={() => setManageDivisionVisible(record, true)}
            />
            <Button type="dashed" icon="audit" onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  render() {
    const {
      intl,
      curSiteFlowId,
      newDocument,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={curSiteFlowId === 0}
                    loading={fetchIsLoading}
                    onClick={() => newDocument(curSiteFlowId)}
                    // icon="plus"
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
        <ManageDivisionDialog />
      </>
    );
  }
}

UserList02Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  newDocument: PropTypes.func,
  goToAudit: PropTypes.func,
  
  setManageDivisionVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchUserList02: PropTypes.func
};

UserList02Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  newDocument() {},
  goToAudit() {},

  setManageDivisionVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchUserList02() {}
};

const mapStateToProps = state => ({
  timestamp: state.userList02.timestamp,

  documents: state.userList02.documents,
  selectedDocuments: state.userList02.selectedDocuments,

  sorts: state.userList02.sorts,
  filters: state.userList02.filters,

  currentPage: state.userList02.currentPage,
  pageSize: state.userList02.pageSize,
  total: state.userList02.total,
  fetchIsLoading: state.userList02.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(UserList02Actions.userList02ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(UserList02Actions.userList02GoToDocument(hdrId)),
  newDocument: siteFlowId => dispatch(UserList02Actions.userList02NewDocument(siteFlowId)),
  goToAudit: userId => dispatch(UserList02Actions.userList02GoToAudit(userId)),

  setManageDivisionVisible: (document, boolean) =>
    dispatch(UserList02Actions.userList02SetManageDivisionVisible(document, boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(UserList02Actions.userList02AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(UserList02Actions.userList02RemoveSelectedDocuments(selectedDocuments)),

  fetchUserList02: (siteFlowId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      UserList02Actions.userList02FetchUserList02(siteFlowId, currentPage, sorts, filters, pageSize)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UserList02Table));
