import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    promoIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
    promoIndexFetchAllPromotions: ['currentPage', 'sorts', 'filters', 'pageSize'],
    promoIndexFetchAllPromotionsLoading: ['boolean'],
    promoIndexFetchAllPromotionsSuccess: ['promotions', 'currentPage', 'lastPage', 'total', 'pageSize',],
    promoIndexReorderPromotions: ['promoId'],
    promoIndexSetSelectedPromotion: ['selectedPromo']
});

export const PromoIndexTypes = Types;
export default Creators;
