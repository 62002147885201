import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Row, Col, Table, Button, Tag } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import CompanyListActions from '../../Stores/CompanyIndex/Actions';

class CompanyListTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchCompanyList,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchCompanyList(curSiteFlowId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit } = this.props;

    return [
      {
        width: 50,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'company_name_01' }),
        // sort field
        dataIndex: 'name_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'name_01'),
        // filter field
        key: 'name_01',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'company_name_01' }),
          'name_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.name_01}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'company_register_no' }),
        // sort field
        dataIndex: 'co_reg_no',
        ...AntDesignTable.getColumnSortProps(sorts, 'co_reg_no'),
        // filter field
        key: 'co_reg_no',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'company_register_no' }),
          'co_reg_no',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.co_reg_no}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'created_at' }),
        // sort field
        dataIndex: 'created_at',
        ...AntDesignTable.getColumnSortProps(sorts, 'created_at'),
        // filter field
        key: 'created_at',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'created_at' }),
          'created_at',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.created_at}</>
      },
      {
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" size="small" icon="edit" onClick={() => goToDocument(record.id)} />
            <Button type="dashed" size="small" icon="audit" onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  render() {
    const {
      intl,
      curSiteFlowId,
      newDocument,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'companies' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={curSiteFlowId === 0}
                    loading={fetchIsLoading}
                    onClick={() => newDocument(curSiteFlowId)}
                    // icon="plus"
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          // scroll={{ x: 950 }}
        />

      </Card>
    );
  }
}

CompanyListTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  newDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchCompanyList: PropTypes.func
};

CompanyListTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  newDocument() {},
  goToAudit() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchCompanyList() {}
};

const mapStateToProps = state => ({
  timestamp: state.companyList.timestamp,

  documents: state.companyList.documents,
  selectedDocuments: state.companyList.selectedDocuments,

  sorts: state.companyList.sorts,
  filters: state.companyList.filters,

  currentPage: state.companyList.currentPage,
  pageSize: state.companyList.pageSize,
  total: state.companyList.total,
  fetchIsLoading: state.companyList.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(CompanyListActions.companyListResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(CompanyListActions.companyListGoToDocument(hdrId)),
  newDocument: siteFlowId => dispatch(CompanyListActions.companyListNewDocument(siteFlowId)),
  goToAudit: companyId => dispatch(CompanyListActions.companyListGoToAudit(companyId)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(CompanyListActions.companyListAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(CompanyListActions.companyListRemoveSelectedDocuments(selectedDocuments)),

  fetchCompanyList: (siteFlowId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      CompanyListActions.companyListFetchCompanyList(siteFlowId, currentPage, sorts, filters, pageSize)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CompanyListTable));
