import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikTextNumber,
  FormikInputNumber
} from '../../Components/Formik';

import PromoDetailActions from '../../Stores/PromoDetail/Actions';

const { Text } = Typography;

class DetailMainForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchVariantType = this.handleSearchVariantType.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeVariantType = this.handleChangeVariantType.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  static getVariantType(id) {
    switch (id) {
      case 1:
        return 'item_group01';
      case 2:
        return 'item_group02';
      case 3:
        return 'item_group03';
      default:
        return 'item';
    }
  }

  handleSearchItem(form, value) {
    const { curDivisionId, fetchItemOptions } = this.props;

    fetchItemOptions(curDivisionId, value);
  }

  handleSearchVariantType(form, value) {
    const { fetchVariantTypeOptions } = this.props;
    const { values } = form;

    fetchVariantTypeOptions(values.variant_type_select2.value, value);
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { changeItem } = this.props;

    changeItem(form, value);
  }

  handleChangeVariantType(form, value) {
    // params: form, value, option
    const { hdrId, changeVariantType } = this.props;
    const { values } = form;

    changeVariantType(form, hdrId, values.variant_type_select2.value, value);
  }

  render() {
    const {
      intl,
      promoType,
      hdrId,
      documentHeader,
      documentDetail,
      itemIsLoading,
      itemOptions,
      updateDetails,
      createDetail,
      documentIsLoading,
      variantTypeOptions,
      variantTypeIsLoading
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = {
            id: values.id,
            is_main: true,
            variant_type: 'item',
            item_id: values.item_select2.value,
            item_group_01_id: 0,
            item_group_02_id: 0,
            item_group_03_id: 0,
            promotion_type: promoType,
            disc_perc_01: 0,
            disc_fixed_price: values.disc_fixed_price,
            min_qty: values.min_qty,
            max_qty: values.max_qty
          };

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          item_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_is_required' }))
          }),
          disc_fixed_price: Yup.number().min(0, intl.formatMessage({ id: 'item_is_required' })),
          min_qty: Yup.number().min(0, intl.formatMessage({ id: 'item_is_required' })),
          max_qty: Yup.number().min(0, intl.formatMessage({ id: 'item_is_required' }))
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => {
          return (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItem}
                    notFoundContent={null}
                    loading={itemIsLoading}
                    options={itemOptions}
                    disabled={disabled}
                    onChange={this.handleChangeItem}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'current_price' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="sale_price"
                    component={FormikInputNumber}
                    disabled={true}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'min_qty' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="min_qty" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'max_qty' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="max_qty" component={FormikInputNumber} disabled={disabled} />
                </Col>
              </Row>

              <Row type="flex" justify="end" gutter={[0, 16]}>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    disabled={disabled}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: values.id > 0 ? 'update' : 'create'
                    })}
                    icon="save"
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

DetailMainForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  curDivisionId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  fetchVariantTypeOptions: PropTypes.func,
  changeVariantType: PropTypes.func,
  variantTypeIsLoading: PropTypes.bool,
  variantTypeOptions: PropTypes.arrayOf(PropTypes.object)
};

DetailMainForm.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  curDivisionId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchItemOptions() {},
  fetchVariantTypeOptions() {},
  changeItem() {},
  changeUom() {},
  changeVariantType() {},

  itemIsLoading: false,
  itemOptions: [],
  uomIsLoading: false,
  uomOptions: [],

  variantTypeIsLoading: false,
  variantTypeOptions: []
};

const mapStateToProps = state => ({
  curDivisionId: state.app.curDivisionId,

  hdrId: state.promoDetail.hdrId,
  documentHeader: state.promoDetail.documentHeader,
  documentDetail: state.promoDetail.documentDetail,
  documentIsLoading: state.promoDetail.documentIsLoading,

  itemIsLoading: state.promoDetail.itemIsLoading,
  itemOptions: state.promoDetail.itemOptions,

  variantTypeIsLoading: state.promoDetail.variantTypeIsLoading,
  variantTypeOptions: state.promoDetail.variantTypeOptions
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(PromoDetailActions.promoDetailUpdateDetails(formikBag, hdrId, documentDetails)),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(PromoDetailActions.promoDetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchItemOptions: (divisionId, search) => dispatch(PromoDetailActions.promoDetailFetchItemOptions(divisionId, search)),
  changeItem: (formikBag, itemId) =>
    dispatch(PromoDetailActions.promoDetailChangeItem(formikBag, itemId)),

  fetchVariantTypeOptions: search =>
    dispatch(PromoDetailActions.promoDetailFetchVariantTypeOptions(search)),
  changeVariantType: (formikBag, hdrId) =>
    dispatch(PromoDetailActions.promoDetailChangeVariantType(formikBag, hdrId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DetailMainForm));
