import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsInvSync01ShowSyncSetting: ['divisionId'],
  slsInvSync01ShowSyncSettingLoading: ['boolean'],
  slsInvSync01ShowSyncSettingSuccess: ['syncSetting'],
  slsInvSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  slsInvSync01ShowBatchJobStatus: null,
  slsInvSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsInvSync01SyncNow: ['formikBag', 'divisionId'],
  slsInvSync01SyncLoading: ['boolean']
});

export const SlsInvSync01Types = Types;
export default Creators;
