/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { UserList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const userList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const userList01FetchUserList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const userList01FetchUserList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const userList01AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const userList01RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const userList01SetManageRoleVisible = (state, { document, boolean }) => ({
  ...state,
  selectedDocument: document,
  manageRoleIsVisible: boolean
});

export const userList01ManageRoleLoading = (state, { boolean }) => ({
  ...state,
  assignIsLoading: boolean
});

export const userList01ManageRoleSuccess = (state, { roles }) => ({
  ...state,
  selectedDocument: {
    ...state.selectedDocument,
    roles
  }
});

export const userList01FetchRoleOptionLoading = (state, { boolean }) => ({
  ...state,
  roleIsLoading: boolean
});

export const userList01FetchRoleOptionSuccess = (state, { options }) => ({
  ...state,
  roleOptions: options
});

export const userList01SetRoleOption = (state, { option }) => ({
  ...state,
  roleOption: option
});


export const userList01SetManageDivisionVisible = (state, { document, boolean }) => ({
  ...state,
  selectedDocument: document,
  manageDivisionIsVisible: boolean
});

export const userList01ManageDivisionLoading = (state, { boolean }) => ({
  ...state,
  assignIsLoading: boolean
});

export const userList01ManageDivisionSuccess = (state, { divisions }) => ({
  ...state,
  selectedDocument: {
    ...state.selectedDocument,
    divisions
  }
});

export const userList01FetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const userList01FetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const userList01SetDivisionOption = (state, { option }) => ({
  ...state,
  divisionOption: option
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UserList01Types.USER_LIST01_RESET_TIMESTAMP]: userList01ResetTimestamp,
  [UserList01Types.USER_LIST01_FETCH_USER_LIST01_LOADING]: userList01FetchUserList01Loading,
  [UserList01Types.USER_LIST01_FETCH_USER_LIST01_SUCCESS]: userList01FetchUserList01Success,
  [UserList01Types.USER_LIST01_ADD_SELECTED_DOCUMENTS]: userList01AddSelectedDocuments,
  [UserList01Types.USER_LIST01_REMOVE_SELECTED_DOCUMENTS]: userList01RemoveSelectedDocuments,
  [UserList01Types.USER_LIST01_SET_MANAGE_ROLE_VISIBLE]: userList01SetManageRoleVisible,
  [UserList01Types.USER_LIST01_MANAGE_ROLE_LOADING]: userList01ManageRoleLoading,
  [UserList01Types.USER_LIST01_MANAGE_ROLE_SUCCESS]: userList01ManageRoleSuccess,

  [UserList01Types.USER_LIST01_FETCH_ROLE_OPTION_LOADING]: userList01FetchRoleOptionLoading,
  [UserList01Types.USER_LIST01_FETCH_ROLE_OPTION_SUCCESS]: userList01FetchRoleOptionSuccess,
  [UserList01Types.USER_LIST01_SET_ROLE_OPTION]: userList01SetRoleOption,
  
  [UserList01Types.USER_LIST01_SET_MANAGE_DIVISION_VISIBLE]: userList01SetManageDivisionVisible,
  [UserList01Types.USER_LIST01_MANAGE_DIVISION_LOADING]: userList01ManageDivisionLoading,
  [UserList01Types.USER_LIST01_MANAGE_DIVISION_SUCCESS]: userList01ManageDivisionSuccess,

  [UserList01Types.USER_LIST01_FETCH_DIVISION_OPTION_LOADING]: userList01FetchDivisionOptionLoading,
  [UserList01Types.USER_LIST01_FETCH_DIVISION_OPTION_SUCCESS]: userList01FetchDivisionOptionSuccess,
  [UserList01Types.USER_LIST01_SET_DIVISION_OPTION]: userList01SetDivisionOption
});
