import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace, push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import CompanyDetailActions from '../Stores/CompanyDetail/Actions';

const getAppStore = state => state.app;

const getCompanyDetailStore = state => state.companyDetail;

export function* companyDetailInitHeader() {
  try {
    yield put(CompanyDetailActions.companyDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `company/initModel`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CompanyDetailActions.companyDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CompanyDetailActions.companyDetailShowDocumentLoading(false));
  }
}

export function* companyDetailShowHeader({ hdrId }) {
    try {
      yield put(CompanyDetailActions.companyDetailShowDocumentLoading(true));
  
      const app = yield select(getAppStore);
      const getData = {};
  
      const result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `company/showModel/${hdrId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
  
      if (result.isSuccess === true) {
        yield put(CompanyDetailActions.companyDetailShowHeaderSuccess(result.data));
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      }
    } catch (error) {
      yield call(notification.error, {
        message: error.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } finally {
      yield put(CompanyDetailActions.companyDetailShowDocumentLoading(false));
    }
  }

  export function* companyDetailUpdateHeader({ formikBag, documentHeader }) {
    formikBag.setSubmitting(true);
    try {
      const app = yield select(getAppStore);
  
      const postData = {
        data: documentHeader
      };
  
      const result = yield call(
        ApiService.putApi, // function
        app.apiUrl,
        'company/updateModel',
        app.token,
        postData // params
      );
  
      if (result.isSuccess === true) {
        const companyDetail = yield select(getCompanyDetailStore);
  
        const {
          documentHeader: oldDocumentHeader
        } = companyDetail;
  
        const {
          model: retDocumentHeader
        } = result.data;

  
        const processed = UtilService.processHeaderDetails(
          oldDocumentHeader,
          [],
          retDocumentHeader,
          [], []
        );
  
        yield put(
          CompanyDetailActions.companyDetailUpdateHeaderSuccess(retDocumentHeader)
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      }
    } catch (error) {
      yield call(notification.error, {
        message: error.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } finally {
      formikBag.setSubmitting(false);
    }
}

export function* companyDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'company/createModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/companyDetail/update/${result.data}`));
      yield put(CompanyDetailActions.companyDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

