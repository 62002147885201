import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemGroup02List01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemGroup02List01FetchItemGroup02List: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemGroup02List01FetchItemGroup02ListLoading: ['boolean'],
  itemGroup02List01FetchItemGroup02ListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  itemGroup02List01AddSelectedDocuments: ['selectedDocuments'],
  itemGroup02List01RemoveSelectedDocuments: ['selectedDocuments'],
  itemGroup02List01SetWorkspaceVisible: ['boolean'],
  itemGroup02List01GoToDocument: ['id'],
  itemGroup02List01GoToAudit: ['id'],

  itemGroup02List01SetManagePhotoVisible: ['document', 'boolean'],
  itemGroup02List01RemovePhoto: ['id'],
  itemGroup02List01UploadPhoto: ['id', 'file'],
  itemGroup02List01ManagePhotoSuccess: ['document'],
  itemGroup02List01ManagePhotoLoading: ['boolean'],
  itemGroup02List01UploadLoading: ['boolean']
});

export const ItemGroup02List01Types = Types;
export default Creators;
