import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';

import ItemPriceActions from '../Stores/ItemPrice/Actions';
import ItemList01Actions from '../Stores/ItemList01/Actions';

const getAppStore = state => state.app;

export function* itemPriceCreate({ formikBag, itemId, documentDetail }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);
    const postData = {
      data: { ...documentDetail, item_id: itemId }
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/createPriceModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(ItemPriceActions.itemPriceSetVisible(false));
      yield put(ItemList01Actions.itemList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* itemPriceUpdate({ formikBag, documentDetail }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);
    const postData = {
      data: { ...documentDetail }
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `item/updatePriceModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(ItemPriceActions.itemPriceSetVisible(false));
      yield put(ItemList01Actions.itemList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* itemPriceDelete({ id }) {
  try {
    const app = yield select(getAppStore);

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `itemUom/deleteItemUom/${id}`,
      app.token
    );

    if (result.isSuccess === true) {
      yield put(ItemPriceActions.itemPriceSetVisible(false));
      yield put(ItemList01Actions.itemList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  }
}

export function* itemPriceFetchUomOptions({ itemId, search }) {
  try {
    yield put(ItemPriceActions.itemPriceFetchUomOptionsLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      // filters: [
      //   {
      //     field: 'item_id',
      //     value: itemId
      //   }
      // ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `uom/select2`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      // const options = result.data.data.map(d => ({
      //   value: d.uom_id,
      //   label: `${d.uom_code}`
      // }));
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(ItemPriceActions.itemPriceFetchUomOptionsSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemPriceActions.itemPriceFetchUomOptionsLoading(false));
  }
}

export function* itemPriceChangeUom({ formikBag, uomId }) {
  formikBag.setSubmitting(true);
  formikBag.setSubmitting(false);
}
