import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cycleCount02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCount02GoToDocument: ['hdrId'],
  cycleCount02FetchCycleCount02: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCount02FetchCycleCount02Loading: ['boolean'],
  cycleCount02FetchCycleCount02Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  cycleCount02AddSelectedDocuments: ['selectedDocuments'],
  cycleCount02RemoveSelectedDocuments: ['selectedDocuments'],
  cycleCount02SetWorkspaceVisible: ['boolean'],

  cycleCount02SetExpandedRows: ['expandedRows']
});

export const CycleCount02Types = Types;
export default Creators;
