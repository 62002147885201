import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Card } from 'antd';

import WarehouseMapTable from './WarehouseMapTable';
import WarehouseMapDialog from './WarehouseMapDialog';

class WarehouseMapScreen extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'warehouse_map' })}>
        <WarehouseMapTable match={match} />
        <WarehouseMapDialog match={match} />
      </Card>
    );
  }
}

WarehouseMapScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

WarehouseMapScreen.defaultProps = {
  intl: {},
  match: {}
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WarehouseMapScreen));
