/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsRtnSync01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsRtnSync01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const slsRtnSync01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const slsRtnSync01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const slsRtnSync01SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsRtnSync01Types.SLS_RTN_SYNC01_SHOW_SYNC_SETTING_LOADING]: slsRtnSync01ShowSyncSettingLoading,
  [SlsRtnSync01Types.SLS_RTN_SYNC01_SHOW_SYNC_SETTING_SUCCESS]: slsRtnSync01ShowSyncSettingSuccess,
  [SlsRtnSync01Types.SLS_RTN_SYNC01_SHOW_BATCH_JOB_STATUS_SUCCESS]: slsRtnSync01ShowBatchJobStatusSuccess,
  [SlsRtnSync01Types.SLS_RTN_SYNC01_SYNC_LOADING]: slsRtnSync01SyncLoading
});
