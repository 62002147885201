import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { Typography, Row, Col, Modal, Table, Button, Popconfirm, Select } from 'antd';

import GdsRcpt01Actions from '../../Stores/GdsRcpt01/Actions';

const { Option } = Select;
const { Title } = Typography;

class GdsRcpt01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCheckStock = this.useOnCheckStock.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'purchaser' }),
        // sort field
        dataIndex: 'purchaser_username',
        sorter: (a, b) => `${a.purchaser_username}`.localeCompare(b.purchaser_username),
        // filter field
        key: 'salesman',
        render: (text, record) => (
          <>
            <b>{record.purchaser_username}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'business_partner' }),
        // sort field
        dataIndex: 'biz_partner_company_name_01',
        sorter: (a, b) =>
          `${a.biz_partner_company_name_01}`.localeCompare(b.biz_partner_company_name_01),
        // filter field
        key: 'biz_partner_company_name_01',
        render: (text, record) => (
          <>
            <b>{record.biz_partner_code}</b>
            <br />
            {record.biz_partner_company_name_01}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        sorter: (a, b) => a.net_amt - b.net_amt,
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.net_amt)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'case_qty' }),
        // sort field
        dataIndex: 'case_qty',
        sorter: (a, b) => a.case_qty - b.case_qty,
        // filter field
        key: 'case_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        sorter: (a, b) => a.gross_weight - b.gross_weight,
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        sorter: (a, b) => a.cubic_meter - b.cubic_meter,
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        sorter: (a, b) => `${a.str_doc_status}`.localeCompare(b.str_doc_status),
        // filter field
        key: 'str_doc_status',
        render: (text, record) => (
          <>
            <b>{record.str_doc_status}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCheckStock() {
    const { selectedDocuments, toStorageBinOption, createGdsRcpt01 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    if (toStorageBinOption.value > 0) {
      createGdsRcpt01(hdrIds, toStorageBinOption.value);
    }
  }

  useOnCreateDocument() {
    const { selectedDocuments, toStorageBinOption, createGdsRcpt01 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    if (toStorageBinOption.value > 0) {
      createGdsRcpt01(hdrIds, toStorageBinOption.value);
    }
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  handleSearch(value) {
    const { curSiteFlowId, fetchToStorageBinOptions } = this.props;
    fetchToStorageBinOptions(curSiteFlowId, value);
  }

  handleChange(value, option) {
    const { setToStorageBinOption } = this.props;
    const curOption = { value: parseInt(option.key, 10), label: option.props.children };
    setToStorageBinOption(curOption);
  }

  render() {
    const {
      intl,
      newDocuments,
      selectedDocuments,
      workspaceIsVisible,
      toStorageBinOption,
      toStorageBinOptions,
      createIsLoading,
      toStorageBinIsLoading
    } = this.props;

    const options = toStorageBinOptions.map(d => <Option key={d.value}>{`${d.label}`}</Option>);

    // summarise the table
    const calcRow = selectedDocuments.reduce((ttlObj, object) => {
      return {
        net_amt: ('net_amt' in ttlObj ? ttlObj.net_amt : 0) + parseFloat(object.net_amt),
        case_qty: ('case_qty' in ttlObj ? ttlObj.case_qty : 0) + parseFloat(object.case_qty),
        gross_weight:
          ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) + parseFloat(object.gross_weight),
        cubic_meter:
          ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) + parseFloat(object.cubic_meter)
      };
    }, {});

    // override the normal table, and add footer below table
    const tableWithFooter = props => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <table {...props}>
          {props.children}
          {Object.keys(calcRow).length > 0 && (
            <tfoot className="ant-table-tbody">
              <tr className="ant-table-row">
                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={4}>
                  {intl.formatMessage({ id: 'total' })}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.net_amt)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.case_qty)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.gross_weight)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.cubic_meter)}
                </td>
                <td />
              </tr>
            </tfoot>
          )}
        </table>
      );
    };

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_gds_rcpt' })}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0 || toStorageBinOption.value === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={4}>
              <Title level={4}>{intl.formatMessage({ id: 'to_storage_bin' })}</Title>
            </Col>
            <Col span={1}>
              <Title level={4}>:</Title>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                value={toStorageBinOption.label}
                placeholder={intl.formatMessage({ id: 'key_in_code_or_description' })}
                style={{ width: 350 }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
                loading={toStorageBinIsLoading}
              >
                {options}
              </Select>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                components={{ table: tableWithFooter }}
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_gds_rcpts' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

GdsRcpt01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,

  createGdsRcpt01: PropTypes.func,

  setToStorageBinOption: PropTypes.func,
  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinOption: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }),
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object),
  toStorageBinIsLoading: PropTypes.bool
};

GdsRcpt01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  curSiteFlowId: 0,

  createGdsRcpt01() {},

  fetchToStorageBinOptions() {},
  setToStorageBinOption() {},
  toStorageBinOption: { value: 0, label: '' },
  toStorageBinOptions: [],
  toStorageBinIsLoading: false
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.gdsRcpt01.workspaceIsVisible,

  selectedDocuments: state.gdsRcpt01.selectedDocuments,
  newDocuments: state.gdsRcpt01.newDocuments,

  createIsLoading: state.gdsRcpt01.createIsLoading,

  curSiteFlowId: state.app.curSiteFlowId,

  toStorageBinIsLoading: state.gdsRcpt01.toStorageBinIsLoading,
  toStorageBinOptions: state.gdsRcpt01.toStorageBinOptions,
  toStorageBinOption: state.gdsRcpt01.toStorageBinOption
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean => dispatch(GdsRcpt01Actions.gdsRcpt01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcpt01Actions.gdsRcpt01RemoveSelectedDocuments(selectedDocuments)),

  createGdsRcpt01: (hdrIds, toStorageBinId) =>
    dispatch(GdsRcpt01Actions.gdsRcpt01CreateGdsRcpt01(hdrIds, toStorageBinId)),

  fetchToStorageBinOptions: (siteFlowId, search) =>
    dispatch(GdsRcpt01Actions.gdsRcpt01FetchToStorageBinOptions(siteFlowId, search)),
  setToStorageBinOption: option => dispatch(GdsRcpt01Actions.gdsRcpt01SetToStorageBinOption(option))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GdsRcpt01Dialog));
