import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemGroup03List01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemGroup03List01FetchItemGroup03List: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemGroup03List01FetchItemGroup03ListLoading: ['boolean'],
  itemGroup03List01FetchItemGroup03ListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  itemGroup03List01AddSelectedDocuments: ['selectedDocuments'],
  itemGroup03List01RemoveSelectedDocuments: ['selectedDocuments'],
  itemGroup03List01SetWorkspaceVisible: ['boolean'],
  itemGroup03List01GoToDocument: ['id'],
  itemGroup03List01GoToAudit: ['id'],

  itemGroup03List01SetManagePhotoVisible: ['document', 'boolean'],
  itemGroup03List01RemovePhoto: ['id'],
  itemGroup03List01UploadPhoto: ['id', 'file'],
  itemGroup03List01ManagePhotoSuccess: ['document'],
  itemGroup03List01ManagePhotoLoading: ['boolean'],
  itemGroup03List01UploadLoading: ['boolean']
});

export const ItemGroup03List01Types = Types;
export default Creators;
