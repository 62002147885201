/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsRtnDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsRtnDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const slsRtnDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const slsRtnDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const slsRtnDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const slsRtnDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const slsRtnDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const slsRtnDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const slsRtnDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const slsRtnDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const slsRtnDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const slsRtnDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const slsRtnDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const slsRtnDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const slsRtnDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const slsRtnDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const slsRtnDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const slsRtnDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const slsRtnDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const slsRtnDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const slsRtnDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const slsRtnDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const slsRtnDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_RESET_TIMESTAMP]: slsRtnDetailResetTimestamp,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_SET_HDR_ID]: slsRtnDetailSetHdrId,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_SHOW_HEADER_SUCCESS]: slsRtnDetailShowHeaderSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_UPDATE_DOCUMENT_SUCCESS]: slsRtnDetailUpdateDocumentSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_SHOW_DOCUMENT_LOADING]: slsRtnDetailShowDocumentLoading,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_SHOW_DETAILS_SUCCESS]: slsRtnDetailShowDetailsSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_SET_DETAIL_VISIBLE]: slsRtnDetailSetDetailVisible,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_SET_DOCUMENT_DETAIL]: slsRtnDetailSetDocumentDetail,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_UPDATE_DETAILS]: slsRtnDetailUpdateDetails,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_CREATE_DETAIL]: slsRtnDetailCreateDetail,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: slsRtnDetailFetchSalesmanOptionLoading,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: slsRtnDetailFetchSalesmanOptionSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: slsRtnDetailFetchDeliveryPointOptionLoading,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: slsRtnDetailFetchDeliveryPointOptionSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: slsRtnDetailFetchCreditTermOptionLoading,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: slsRtnDetailFetchCreditTermOptionSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: slsRtnDetailFetchCurrencyOptionLoading,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: slsRtnDetailFetchCurrencyOptionSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_ITEM_OPTION_LOADING]: slsRtnDetailFetchItemOptionLoading,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: slsRtnDetailFetchItemOptionSuccess,

  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_UOM_OPTION_LOADING]: slsRtnDetailFetchUomOptionLoading,
  [SlsRtnDetailTypes.SLS_RTN_DETAIL_FETCH_UOM_OPTION_SUCCESS]: slsRtnDetailFetchUomOptionSuccess
});
