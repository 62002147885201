import { put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
// import { replace, push } from 'connected-react-router';
import AppActions from '../Stores/App/Actions';

/**
 * The startup saga is the place to define behavior to execute when the application starts.
 */

const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* startup(pathname) {
  const app = yield select(getAppStore);


  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_API_URL !== null || process.env.REACT_APP_API_URL !== 'undefined' || process.env.REACT_APP_API_URL !== '') {
    // update the API_URL from public/config.js
    // eslint-disable-next-line no-undef
  console.log("API URL: ", process.env.REACT_APP_API_URL)
    yield put(AppActions.appUpdateApiUrl(process.env.REACT_APP_API_URL));
  }

  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_APP_PATH !== null || process.env.REACT_APP_APP_PATH !== 'undefined' || process.env.REACT_APP_APP_PATH !== '') {
    // update the APP_PATH from public/config.js
    // eslint-disable-next-line no-undef
  console.log("APP PATH:", process.env.REACT_APP_APP_PATH)
    yield put(AppActions.appUpdateAppPath(process.env.REACT_APP_APP_PATH));
  }

  if (app.user.username == "" && !RegExp("^/legal").test(pathname.pathname)) {
    yield put(replace(`${app.appPath}/login`))
  }
  // yield put(replace(app.appPath + pathname));
  // if (app.token === null || app.token === '') {
  //   yield put(push(`${app.appPath}/login`));
  // }
}
