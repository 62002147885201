import * as Yup from 'yup';

import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Row, Typography } from 'antd';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FormikButton, FormikDatePicker, FormikSelect, FormikInputNumber, FormikTextNumber } from '../../Components/Formik';

import ItemPriceActions from '../../Stores/ItemPrice/Actions';

const { Text } = Typography;

class ItemPriceForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchUom(form, value) {
    const { fetchUomOptions, documentDetail, itemId } = this.props;
    if (documentDetail.item_id > 0) {
      fetchUomOptions(documentDetail.item_id, value);
    }
    else if (itemId > 0) {
      fetchUomOptions(itemId, value);
    }
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { changeUom } = this.props;
    changeUom(form, value);
  }

  render() {
    const {
      documentDetail,
      itemId,
      createPrice,
      updatePrice,
      uomIsLoading,
      uomOptions,
      intl
    } = this.props;

    const disabled = false;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          const processedValues = {
            id: values.id,
            item_id: values.id > 0 ?  documentDetail.item_id : itemId,
            sale_price: values.sale_price,
            uom_id: values.uom_select2.value,
            uom_rate: values.uom_rate,
            valid_from: new Date(values.valid_from),
            valid_to: new Date(values.valid_to)
          };

          if (values.id > 0) {
            updatePrice(formikBag, processedValues);
          } else {
            createPrice(formikBag, itemId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          sale_price: Yup.number().moreThan(0, intl.formatMessage({ id: 'price_must_be_greater_than_0' })),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          }),
          uom_rate: Yup.number().moreThan(0, intl.formatMessage({ id: 'rate_must_be_greater_than_0' })),
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'uom' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={5}>
                <Field
                  name="uom_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUom}
                  notFoundContent={null}
                  loading={uomIsLoading}
                  options={uomOptions}
                  disabled={documentDetail.id > 0}
                  onChange={this.handleChangeUom}
                />
              </Col>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'rate' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={5}>
                <Field
                  name="uom_rate"
                  component={FormikInputNumber}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  disabled={disabled}
                />
              </Col>
              <Col span={6} />
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'sale_price' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={5}>
                <Field
                  name="sale_price"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                />
              </Col>
              <Col span={15} />
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'valid_from' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={5}>
                <Field name="valid_from" component={FormikDatePicker} disabled={disabled} />
              </Col>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'valid_to' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={5}>
                <Field name="valid_to" component={FormikDatePicker} disabled={disabled} />
              </Col>
              <Col span={6} />
            </Row>
            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={6}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={handleReset}
                  icon="undo"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon="save"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ItemPriceForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  itemId: PropTypes.number,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  documentDetail: PropTypes.shape({}),
  createPrice: PropTypes.func,
  updatePrice: PropTypes.func,
  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func
};

ItemPriceForm.defaultProps = {
  intl: {},
  itemId: 0,
  uomIsLoading: false,
  uomOptions: [],
  documentDetail: {},
  createPrice() {},
  updatePrice() {},
  fetchUomOptions() {},
  changeUom() {}
};

const mapStateToProps = state => ({
  itemId: state.itemPrice.itemId,
  documentDetail: state.itemPrice.documentDetail,

  uomIsLoading: state.itemPrice.uomIsLoading,
  uomOptions: state.itemPrice.uomOptions
});

const mapDispatchToProps = dispatch => ({
  createPrice: (formikBag, itemId, documentDetail) =>
    dispatch(ItemPriceActions.itemPriceCreate(formikBag, itemId, documentDetail)),
  updatePrice: (formikBag, documentDetail) =>
    dispatch(ItemPriceActions.itemPriceUpdate(formikBag, documentDetail)),
  fetchUomOptions: (itemId, search) =>
    dispatch(ItemPriceActions.itemPriceFetchUomOptions(itemId, search)),
  changeUom: (formikBag, uomId) =>
    dispatch(ItemPriceActions.itemPriceChangeUom(formikBag, uomId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ItemPriceForm));
