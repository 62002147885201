/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CycleCountAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const cycleCountAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const cycleCountAnalysisReportInitCycleCountAnalysisSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const cycleCountAnalysisReportCycleCountAnalysisSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const cycleCountAnalysisReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const cycleCountAnalysisReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const cycleCountAnalysisReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const cycleCountAnalysisReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const cycleCountAnalysisReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const cycleCountAnalysisReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const cycleCountAnalysisReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const cycleCountAnalysisReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const cycleCountAnalysisReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const cycleCountAnalysisReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const cycleCountAnalysisReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const cycleCountAnalysisReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const cycleCountAnalysisReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const cycleCountAnalysisReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const cycleCountAnalysisReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const cycleCountAnalysisReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_REPORT_LOADING]: cycleCountAnalysisReportReportLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_INIT_CYCLE_COUNT_ANALYSIS_SUCCESS]: cycleCountAnalysisReportInitCycleCountAnalysisSuccess,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_CYCLE_COUNT_ANALYSIS_SUCCESS]: cycleCountAnalysisReportCycleCountAnalysisSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_OPTION_LOADING]: cycleCountAnalysisReportFetchItemOptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_OPTION_SUCCESS]: cycleCountAnalysisReportFetchItemOptionSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: cycleCountAnalysisReportFetchItemGroup01OptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: cycleCountAnalysisReportFetchItemGroup01OptionSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: cycleCountAnalysisReportFetchItemGroup02OptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: cycleCountAnalysisReportFetchItemGroup02OptionSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: cycleCountAnalysisReportFetchItemGroup03OptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: cycleCountAnalysisReportFetchItemGroup03OptionSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: cycleCountAnalysisReportFetchStorageBinOptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: cycleCountAnalysisReportFetchStorageBinOptionSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: cycleCountAnalysisReportFetchStorageRowOptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: cycleCountAnalysisReportFetchStorageRowOptionSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: cycleCountAnalysisReportFetchStorageBayOptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: cycleCountAnalysisReportFetchStorageBayOptionSuccess,

  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_LOCATION_OPTION_LOADING]: cycleCountAnalysisReportFetchLocationOptionLoading,
  [CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: cycleCountAnalysisReportFetchLocationOptionSuccess
});
