import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdIndexFetchSlsOrdIndex: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdIndexFetchSlsOrdIndexLoading: ['boolean'],
  slsOrdIndexFetchSlsOrdIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  slsOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  slsOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  slsOrdIndexSetWorkspaceVisible: ['boolean'],
  slsOrdIndexGoToDocument: ['hdrId'],
  slsOrdIndexGoToAudit: ['hdrId']
});

export const SlsOrdIndexTypes = Types;
export default Creators;
