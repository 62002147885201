import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickListDetailResetTimestamp: null,
  pickListDetailSetHdrId: ['hdrId'],

  pickListDetailInitHeader: ['siteFlowId'],
  pickListDetailShowHeader: ['hdrId'],
  pickListDetailShowHeaderSuccess: ['documentHeader'],

  pickListDetailUpdateHeader: ['formikBag', 'documentHeader'],
  pickListDetailCreateHeader: ['formikBag', 'documentHeader'],
  pickListDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  pickListDetailShowDocumentLoading: ['boolean'],
  pickListDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  pickListDetailShowDetails: ['hdrId'],
  pickListDetailShowDetailsSuccess: ['documentDetails'],

  pickListDetailSetDetailVisible: ['boolean'],
  pickListDetailSetDocumentDetail: ['documentDetail'],

  pickListDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  pickListDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  pickListDetailDeleteDetail: ['hdrId', 'documentDetail'],

  pickListDetailSetWhseJobType: ['whseJobType'],

  pickListDetailFetchCompanyOptions: ['search'],
  pickListDetailFetchCompanyOptionLoading: ['boolean'],
  pickListDetailFetchCompanyOptionSuccess: ['options'],

  pickListDetailFetchFrStorageBinOptions: ['siteFlowId', 'search'],
  pickListDetailFetchFrStorageBinOptionLoading: ['boolean'],
  pickListDetailFetchFrStorageBinOptionSuccess: ['options'],

  pickListDetailFetchQuantBalOptions: ['companyId', 'storageBinId', 'search'],
  pickListDetailFetchQuantBalOptionLoading: ['boolean'],
  pickListDetailFetchQuantBalOptionSuccess: ['options'],
  pickListDetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  pickListDetailFetchUomOptions: ['itemId', 'search'],
  pickListDetailFetchUomOptionLoading: ['boolean'],
  pickListDetailFetchUomOptionSuccess: ['options'],
  pickListDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  pickListDetailFetchToStorageBinOptions: ['siteFlowId', 'search'],
  pickListDetailFetchToStorageBinOptionLoading: ['boolean'],
  pickListDetailFetchToStorageBinOptionSuccess: ['options']
});

export const PickListDetailTypes = Types;
export default Creators;
