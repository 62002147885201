/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsOrdSync02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdSync02ResetTimestamp = (state, { currentPage, sorts, filters, criteria, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  criteria: criteria || state.criteria,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const slsOrdSync02FetchSlsOrdIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const slsOrdSync02FetchSlsOrdIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const slsOrdSync02FetchSlsOrdIndexSearchSuccess = (state, { documents, currentPage, lastPage, total, pageSize, criteria }) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize,
  criteria
});

export const slsOrdSync02AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const slsOrdSync02RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const slsOrdSync02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const slsOrdSync02SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsOrdSync02Types.SLS_ORD_SYNC02_RESET_TIMESTAMP]: slsOrdSync02ResetTimestamp,
  [SlsOrdSync02Types.SLS_ORD_SYNC02_FETCH_SLS_ORD_INDEX_LOADING]: slsOrdSync02FetchSlsOrdIndexLoading,
  [SlsOrdSync02Types.SLS_ORD_SYNC02_FETCH_SLS_ORD_INDEX_SUCCESS]: slsOrdSync02FetchSlsOrdIndexSuccess,
  
  [SlsOrdSync02Types.SLS_ORD_SYNC02_FETCH_SLS_ORD_INDEX_SEARCH_SUCCESS]: slsOrdSync02FetchSlsOrdIndexSearchSuccess,

  [SlsOrdSync02Types.SLS_ORD_SYNC02_ADD_SELECTED_DOCUMENTS]: slsOrdSync02AddSelectedDocuments,
  [SlsOrdSync02Types.SLS_ORD_SYNC02_REMOVE_SELECTED_DOCUMENTS]: slsOrdSync02RemoveSelectedDocuments,
  [SlsOrdSync02Types.SLS_ORD_SYNC02_SHOW_BATCH_JOB_STATUS_SUCCESS]: slsOrdSync02ShowBatchJobStatusSuccess,
  [SlsOrdSync02Types.SLS_ORD_SYNC02_SYNC_LOADING]: slsOrdSync02SyncLoading
});
