import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { Card, Row, Col, Table, Button, Menu, Icon, Dropdown } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import CartIndexActions from '../../Stores/CartIndex/Actions';

class CartIndexTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchCartIndex,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchCartIndex(curDivisionId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit } = this.props;

    function menu(record) {
      return (
        <Menu>
          <Menu.Item key="0">
            <a onClick={() => goToDocument(record.id)}>
              <Icon type="edit" /> Edit
            </a>
          </Menu.Item>
          <Menu.Item key="1">
            <a onClick={() => goToAudit(record.id)}>
              <Icon type="audit" /> Audit
            </a>
          </Menu.Item>
        </Menu>
      );
    }

    return [
      {
        width: 130,
        align: 'left',
        title: intl.formatMessage({ id: 'created_at' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'created_at'),
        // filter field
        key: 'created_at',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'created_at' }),
          'created_at',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD hh:mm:ss')}</>
      },
      {
        width: 135,
        align: 'left',
        title: intl.formatMessage({ id: 'updated_at' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'updated_at'),
        // filter field
        key: 'updated_at',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'updated_at' }),
          'updated_at',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD hh:mm:ss')}</>
      },
      {
        width: 125,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.doc_code
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'created_by' }),
        // sort field
        dataIndex: 'created_username',
        ...AntDesignTable.getColumnSortProps(sorts, 'created_username'),
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'created_by' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.debtor_companyname}
            <br />
            {record.created_username}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.delivery_point_unit_no} {record.delivery_point_building_name}
            <br />
            {record.delivery_point_street_name} {record.delivery_point_district_01}{' '}
            {record.delivery_point_district_02}
            <br />
            {record.delivery_point_postcode} {record.delivery_point_area_name}{' '}
            {record.delivery_point_state_name}
          </>
        )
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_doc_status'),
        // filter field
        key: 'str_doc_status',
        filters: [
          { text: 'Draft', value: '3' },
          { text: 'Completed', value: '100' }
          // { text: 'Completed', value: '100' },
        ],
        render: (text, record) => (
          <>
            {record.str_doc_status == `DRAFT` ? (
              <span
                style={{
                  backgroundColor: '#1890ff',
                  borderRadius: '24px',
                  padding: '4px 12px',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: '#ffffff'
                }}
              >
                Draft
              </span>
            ) : record.str_doc_status == `WIP` ? (
              <span
                style={{
                  backgroundColor: '#001529',
                  borderRadius: '24px',
                  padding: '4px 12px',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: '#ffffff'
                }}
              >
                Processing
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: '#35AD12',
                  borderRadius: '24px',
                  padding: '4px 12px',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: '#ffffff'
                }}
              >
                Completed
              </span>
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },

      {
        width: 100,
        // fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Actions <Icon type="down" />
              </a>
            </Dropdown>
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'cart' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </Card>
    );
  }
}

CartIndexTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchCartIndex: PropTypes.func
};

CartIndexTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchCartIndex() {}
};

const mapStateToProps = state => ({
  timestamp: state.cartIndex.timestamp,
  workspaceIsVisible: state.cartIndex.workspaceIsVisible,

  documents: state.cartIndex.documents,
  selectedDocuments: state.cartIndex.selectedDocuments,

  sorts: state.cartIndex.sorts,
  filters: state.cartIndex.filters,

  currentPage: state.cartIndex.currentPage,
  pageSize: state.cartIndex.pageSize,
  total: state.cartIndex.total,
  fetchIsLoading: state.cartIndex.fetchIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(CartIndexActions.cartIndexResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(CartIndexActions.cartIndexGoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(CartIndexActions.cartIndexGoToAudit(hdrId)),

  setWorkspaceVisible: boolean => dispatch(CartIndexActions.cartIndexSetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(CartIndexActions.cartIndexAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(CartIndexActions.cartIndexRemoveSelectedDocuments(selectedDocuments)),

  fetchCartIndex: (divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      CartIndexActions.cartIndexFetchCartIndex(divisionId, currentPage, sorts, filters, pageSize)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CartIndexTable));
