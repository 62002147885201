import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PromoIndexActions from '../Stores/PromoIndex/Actions';
import PromoDiscListActions from '../Stores/PromoDiscIndex/Actions';
import PromoDetailActions from '../Stores/PromoDetail/Actions';
import PromoFocListActions from '../Stores/PromoFocIndex/Actions';

const getAppStore = state => state.app;
const getPromoDiscListSelectedDocumentStore = state => state.promoDiscList.selectedDocument
const getPromoIndexStore = state => state.promoIndex;

export function* promoListFetchPromoDiscList({ divisionId, currentPage, sorts, filters, pageSize, filterStr }) {
  try {
    yield put(PromoDiscListActions.promoDiscListFetchPromoListLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });
    
    processedFilters.push(`validity:${filterStr}`);
    
    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/index/disc/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }
      yield put(
        PromoDiscListActions.promoDiscListFetchPromoListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page,
          filterStr
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDiscListActions.promoDiscListFetchPromoListLoading(false));
  }
}

export function* promoListFetchPromoFocList({ divisionId, currentPage, sorts, filters, pageSize, filterStr }) {
  try {
    yield put(PromoFocListActions.promoFocListFetchPromoListLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });
    
    processedFilters.push(`validity:${filterStr}`);
    
    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/index/foc/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }
      yield put(
        PromoFocListActions.promoFocListFetchPromoListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page,
          filterStr
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoFocListActions.promoFocListFetchPromoListLoading(false));
  }
}

export function* promoListGoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/promoDetail/update/${hdrId}`));
}

export function* promoListGoToBroadcast({ hdrId }) {
  console.log("Broadcast Promotion:", hdrId)
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/promoDetail/broadcast/${hdrId}`));
}

export function* promoListUploadPhoto({ promotionId, fileList }) {
  if(typeof promotionId === 'undefined') {
    yield put(PromoDiscListActions.promoDiscListUploadPhotoHold(true, fileList))
    yield call(notification.success, {
      message: "Upload.Image set, submit form to upload",
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } else {
    try {
      yield put(PromoDiscListActions.promoDiscListUploadLoading(true));
  
      const app = yield select(getAppStore);
  
      // eslint-disable-next-line no-undef
      const postData = new FormData();
      fileList.forEach(file => {
        if (Math.sign(file.uid) == -1) {
        } else {
          postData.append('files[]', file.originFileObj, file.name);
        }
      });
  
      const getData = {};
  
      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `promotion/uploadPhotos/${promotionId}`,
        app.token,
        postData,
        getData,
        'multipart/form-data'
      );
  
      if (result.isSuccess === true) {
        const promoDiscListSelectedDocument = yield select(getPromoDiscListSelectedDocumentStore);
  
        yield put(PromoDiscListActions.promoDiscListManagePhotoSuccess(promoDiscListSelectedDocument));
  
        yield put(PromoDiscListActions.promoDiscListResetTimestamp());
        yield put(PromoDetailActions.promoDetailResetTimestamp());
  
        // result.data is total
        yield call(notification.success, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      }
    } catch (error) {
      yield call(notification.error, {
        message: error.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } finally {
      yield put(PromoDiscListActions.promoDiscListUploadLoading(false));
    }
  }
}

export function* promoListRemovePhoto({ promotionId, promotionPhotoId }) {
  if(typeof promotionId === 'undefined') {
    yield put(PromoDiscListActions.promoDiscListUploadPhotoHold(false, []))
    yield call(notification.success, {
      message: "Upload.Image remove from upload queue.",
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } else {
    try {
      yield put(PromoDiscListActions.promoDiscListManagePhotoLoading(true));

      const app = yield select(getAppStore);

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `promotion/deletePhotos/${promotionId}`,
        app.token
      );

      if (result.isSuccess === true) {
        const promoDiscListSelectedDocument = yield select(getPromoDiscListSelectedDocumentStore);

        yield put(PromoDiscListActions.promoDiscListManagePhotoSuccess(promoDiscListSelectedDocument));

        yield put(PromoDiscListActions.promoDiscListResetTimestamp());
        yield put(PromoDetailActions.promoDetailResetTimestamp());

        // result.data is total
        yield call(notification.success, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      }
    } catch (error) {
      yield call(notification.error, {
        message: error.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } finally {
      yield put(PromoDiscListActions.promoDiscListManagePhotoLoading(false));
    }
  }
}

export function* promoListGoToAudit({ userId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditDivision/${userId}`));
}

export function* promoListNewDocument({ promoType }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/promoDetail/create/${promoType}`));
}

export function* promoIndexFetchAllPromotions({ currentPage, pageSize }) {
  try {
    yield put(PromoIndexActions.promoIndexFetchAllPromotionsLoading(true));

    const processedSorts = [];

    const processedFilters = [];
    
    processedSorts.push(`sequence:ASC`);
    processedFilters.push(`status:100`);
    
    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/index/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }
      yield put(
        PromoIndexActions.promoIndexFetchAllPromotionsSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoIndexActions.promoIndexFetchAllPromotionsLoading(false));
  }
}

export function* promoIndexReorderPromotions({ promoId }) {
  yield put(PromoIndexActions.promoIndexFetchAllPromotionsLoading(true));

  try {
    const app = yield select(getAppStore)
    const promoIndex = yield select(getPromoIndexStore)

    const postData = {
      curPromoId: promoId,
      prevPromoId: promoIndex.selectedPromo,
      curDivisionId: app.curDivisionId
    }
    
    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/reorderPromo`,
      app.token,
      postData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PromoIndexActions.promoIndexResetTimestamp())
      yield put(PromoIndexActions.promoIndexSetSelectedPromotion(promoId))
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      })
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoIndexActions.promoIndexFetchAllPromotionsLoading(false));
  }
}