import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import { FormikSelect, FormikButton, FormikInputNumber } from '../../Components/Formik';

import PromoDetailActions from '../../Stores/PromoDetail/Actions';

const { Text } = Typography;

class DetailPercentForm extends React.PureComponent {
  static getVariantInfo(id) {
    let variantType = '';
    switch (id) {
      case 1:
        variantType = 'item_group01';
        break;
      case 2:
        variantType = 'item_group02';
        break;
      case 3:
        variantType = 'item_group03';
        break;
      default:
        variantType = 'item';
        break;
    }

    return {
      variantType,
      showItem: id === 0,
      showItemGroup01: id === 1,
      showItemGroup02: id === 2,
      showItemGroup03: id === 3
    };
  }

  constructor() {
    super();

    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchBrand = this.handleSearchBrand.bind(this);
    this.handleSearchCategory = this.handleSearchCategory.bind(this);
    this.handleSearchManufacturer = this.handleSearchManufacturer.bind(this);
    this.handleSearchVariantType = this.handleSearchVariantType.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeItemGroup = this.handleChangeItemGroup.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
    this.handleChangeVariantType = this.handleChangeVariantType.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchVariantType(form, value) {
    const { fetchVariantTypeOptions } = this.props;
    const { values } = form;

    fetchVariantTypeOptions(values.variant_type_select2.value, value);
  }

  handleSearchItem(form, value) {
    const { curDivisionId, fetchItemOptions } = this.props;

    fetchItemOptions(curDivisionId, value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_select2.value, value);
  }

  handleSearchBrand(form, value) {
    const { curDivisionId, fetchBrandOptions } = this.props;
    const { values } = form;

    // fetchBrandOptions(values.item_group_01_select2.value, value);
    fetchBrandOptions(curDivisionId, value);
  }

  handleSearchCategory(form, value) {
    const { curDivisionId, fetchCategoryOptions } = this.props;
    const { values } = form;

    fetchCategoryOptions(curDivisionId, value);
  }

  handleSearchManufacturer(form, value) {
    const { curDivisionId, fetchManufacturerOptions } = this.props;
    const { values } = form;

    fetchManufacturerOptions(curDivisionId, value);
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { hdrId, changeItem } = this.props;

    changeItem(form, hdrId, value);
  }

  handleChangeItemGroup(form, value) {
    // params: form, value, option
    const { hdrId, changeItemGroup } = this.props;

    changeItemGroup(form, hdrId);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  handleChangeVariantType(form, value) {
    // params: form, value, option
    const { hdrId, changeVariantType } = this.props;
    const { values } = form;

    changeVariantType(form, hdrId, values.variant_type_select2.value, value);
  }

  renderItemForm(values, dirty, isSubmitting, handleSubmit, handleReset, disabled, show) {
    const { intl, itemIsLoading, itemOptions } = this.props;

    if (show) {
      return (
        <Row type="flex" justify="center" gutter={[8, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'item' })}</Text>
          </Col>
          <Col span={1}>
            <Text>:</Text>
          </Col>
          <Col span={20}>
            <Field
              name="item_select2"
              component={FormikSelect}
              showArrow
              filterOption={false}
              onSearch={this.handleSearchItem}
              notFoundContent={null}
              loading={itemIsLoading}
              options={itemOptions}
              disabled={disabled}
              onChange={this.handleChangeItem}
              validate={select2 => {
                let error;
                if (select2.value === 0) {
                  error = intl.formatMessage({ id: 'item_is_required' });
                }
                return error;
              }}
            />
          </Col>
        </Row>
      );
    }

    return null;
  }

  renderBrandForm(values, dirty, isSubmitting, handleSubmit, handleReset, disabled, show) {
    const { intl, brandIsLoading, brandOptions } = this.props;

    if (show) {
      return (
        <Row type="flex" justify="center" gutter={[8, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'brand' })}</Text>
          </Col>
          <Col span={1}>
            <Text>:</Text>
          </Col>
          <Col span={20}>
            <Field
              name="item_group_01_select2"
              component={FormikSelect}
              showArrow
              filterOption={false}
              onSearch={this.handleSearchBrand}
              notFoundContent={null}
              loading={brandIsLoading}
              options={brandOptions}
              disabled={disabled}
              onChange={this.handleChangeItemGroup}
              validate={select2 => {
                let error;
                if (select2.value === 0) {
                  error = intl.formatMessage({ id: 'brand_is_required' });
                }
                return error;
              }}
            />
          </Col>
        </Row>
      );
    }

    return null;
  }

  renderCategoryForm(values, dirty, isSubmitting, handleSubmit, handleReset, disabled, show) {
    const { intl, categoryIsLoading, categoryOptions } = this.props;

    if (show) {
      return (
        <Row type="flex" justify="center" gutter={[8, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'category' })}</Text>
          </Col>
          <Col span={1}>
            <Text>:</Text>
          </Col>
          <Col span={20}>
            <Field
              name="item_group_02_select2"
              component={FormikSelect}
              showArrow
              filterOption={false}
              onSearch={this.handleSearchCategory}
              notFoundContent={null}
              loading={categoryIsLoading}
              options={categoryOptions}
              disabled={disabled}
              onChange={this.handleChangeItemGroup}
              validate={select2 => {
                let error;
                if (select2.value === 0) {
                  error = intl.formatMessage({ id: 'category_is_required' });
                }
                return error;
              }}
            />
          </Col>
        </Row>
      );
    }

    return null;
  }

  renderManufacturerForm(values, dirty, isSubmitting, handleSubmit, handleReset, disabled, show) {
    const { intl, manufacturerIsLoading, manufacturerOptions } = this.props;

    if (show) {
      return (
        <Row type="flex" justify="center" gutter={[8, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'manufacturer' })}</Text>
          </Col>
          <Col span={1}>
            <Text>:</Text>
          </Col>
          <Col span={20}>
            <Field
              name="item_group_03_select2"
              component={FormikSelect}
              showArrow
              filterOption={false}
              onSearch={this.handleSearchManufacturer}
              notFoundContent={null}
              loading={manufacturerIsLoading}
              options={manufacturerOptions}
              disabled={disabled}
              onChange={this.handleChangeItemGroup}
              validate={select2 => {
                let error;
                if (select2.value === 0) {
                  error = intl.formatMessage({ id: 'manufacturer_is_required' });
                }
                return error;
              }}
            />
          </Col>
        </Row>
      );
    }

    return null;
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      documentIsLoading,
      variantTypeOptions,
      variantTypeIsLoading,
      createDetail,
      updateDetails
    } = this.props;
    const disabled = documentHeader && documentHeader.doc_status >= 50;
    
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          const variantInfo = DetailPercentForm.getVariantInfo(values.variant_type_select2.value);

          // remove the submit_action field
          let processedValues = {
            id: values.id,
            variant_type: variantInfo.variantType,
            item_id: 0,
            item_group_01_id: 0,
            item_group_02_id: 0,
            item_group_03_id: 0,
            promotion_type: 'disc_percent',
            disc_perc_01: values.disc_perc_01,
            min_qty: values.min_qty,
            max_qty: values.max_qty
          };

          if (variantInfo.showItem) {
            processedValues.item_id = values.item_select2.value;
          } else if (variantInfo.showItemGroup01) {
            processedValues.item_group_01_id = values.item_group_01_select2.value;
          } else if (variantInfo.showItemGroup02) {
            processedValues.item_group_02_id = values.item_group_02_select2.value;
          } else if (variantInfo.showItemGroup03) {
            processedValues.item_group_03_id = values.item_group_03_select2.value;
          }

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          disc_perc_01: Yup.number()
            .min(0, intl.formatMessage({ id: 'disc_percent_0_to_100' }))
            .max(100, intl.formatMessage({ id: 'disc_percent_0_to_100' })),
          min_qty: Yup.number().min(0, intl.formatMessage({ id: 'min_qty_0' })),
          max_qty: Yup.number().min(0, intl.formatMessage({ id: 'max_qty_0' }))
        })}
      >
        {({ values, dirty, isSubmitting, handleSubmit, handleReset }) => {
          const variantInfo = values.variant_type_select2
            ? DetailPercentForm.getVariantInfo(values.variant_type_select2.value)
            : 0;
          return (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'type' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="variant_type_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchVariantType}
                    notFoundContent={null}
                    loading={variantTypeIsLoading}
                    options={variantTypeOptions}
                    disabled={disabled}
                    onChange={this.handleChangeVariantType}
                  />
                </Col>
                <Col span={12} />
              </Row>
              {this.renderItemForm(
                values,
                dirty,
                isSubmitting,
                handleSubmit,
                handleReset,
                disabled,
                variantInfo.showItem
              )}
              {this.renderBrandForm(
                values,
                dirty,
                isSubmitting,
                handleSubmit,
                handleReset,
                disabled,
                variantInfo.showItemGroup01
              )}
              {this.renderCategoryForm(
                values,
                dirty,
                isSubmitting,
                handleSubmit,
                handleReset,
                disabled,
                variantInfo.showItemGroup02
              )}
              {this.renderManufacturerForm(
                values,
                dirty,
                isSubmitting,
                handleSubmit,
                handleReset,
                disabled,
                variantInfo.showItemGroup03
              )}
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="disc_perc_01"
                    component={FormikInputNumber}
                    disabled={true}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  />
                </Col>
                <Col span={1}>%</Col>
                <Col span={11} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'min_qty' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="min_qty" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'max_qty' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="max_qty" component={FormikInputNumber} disabled={disabled} />
                </Col>
              </Row>

              <Row type="flex" justify="end" gutter={[0, 16]}>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    disabled={disabled}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: values.id > 0 ? 'update' : 'create'
                    })}
                    icon="save"
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

DetailPercentForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  curDivisionId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  changeItemGroup: PropTypes.func,

  fetchBrandOptions: PropTypes.func,
  brandIsLoading: PropTypes.bool,
  brandOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchCategoryOptions: PropTypes.func,
  categoryIsLoading: PropTypes.bool,
  categoryOptions: PropTypes.arrayOf(PropTypes.object),

  fetchManufacturerOptions: PropTypes.func,
  manufacturerIsLoading: PropTypes.bool,
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object),

  fetchVariantTypeOptions: PropTypes.func,
  changeVariantType: PropTypes.func,
  variantTypeIsLoading: PropTypes.bool,
  variantTypeOptions: PropTypes.arrayOf(PropTypes.object)
};

DetailPercentForm.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  curDivisionId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchItemOptions() {},
  fetchUomOptions() {},
  fetchBrandOptions() {},
  fetchCategoryOptions() {},
  fetchManufacturerOptions() {},
  fetchVariantTypeOptions() {},
  changeItem() {},
  changeItemGroup() {},
  changeUom() {},
  changeVariantType() {},

  itemIsLoading: false,
  itemOptions: [],
  uomIsLoading: false,
  uomOptions: [],
  brandIsLoading: false,
  brandOptions: [],
  categoryIsLoading: false,
  categoryOptions: [],
  manufacturerIsLoading: false,
  manufacturerOptions: [],
  variantTypeIsLoading: false,
  variantTypeOptions: []
};

const mapStateToProps = state => ({
  curDivisionId: state.app.curDivisionId,
  hdrId: state.promoDetail.hdrId,
  documentHeader: state.promoDetail.documentHeader,
  documentDetail: state.promoDetail.documentDetail,
  showVariant: state.promoDetail.showVariant,
  documentIsLoading: state.promoDetail.documentIsLoading,

  itemIsLoading: state.promoDetail.itemIsLoading,
  itemOptions: state.promoDetail.itemOptions,

  uomIsLoading: state.promoDetail.uomIsLoading,
  uomOptions: state.promoDetail.uomOptions,

  brandIsLoading: state.promoDetail.brandIsLoading,
  brandOptions: state.promoDetail.brandOptions,

  categoryIsLoading: state.promoDetail.categoryIsLoading,
  categoryOptions: state.promoDetail.categoryOptions,

  manufacturerIsLoading: state.promoDetail.manufacturerIsLoading,
  manufacturerOptions: state.promoDetail.manufacturerOptions,

  variantTypeIsLoading: state.promoDetail.variantTypeIsLoading,
  variantTypeOptions: state.promoDetail.variantTypeOptions
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(PromoDetailActions.promoDetailUpdateDetails(formikBag, hdrId, documentDetails)),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(PromoDetailActions.promoDetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchItemOptions: (divisionId, search) => dispatch(PromoDetailActions.promoDetailFetchItemOptions(divisionId, search)),
  changeItem: (formikBag, hdrId, itemId) =>
    dispatch(PromoDetailActions.promoDetailChangeItem(formikBag, hdrId, itemId)),

  fetchUomOptions: (itemId, search) =>
    dispatch(PromoDetailActions.promoDetailFetchUomOptions(itemId, search)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(PromoDetailActions.promoDetailChangeUom(formikBag, hdrId, itemId, uomId)),

  changeItemGroup: (formikBag, hdrId) =>
    dispatch(PromoDetailActions.promoDetailChangeItemGroup(formikBag, hdrId)),

  fetchBrandOptions: (divisionId, search) => 
    dispatch(PromoDetailActions.promoDetailFetchBrandOptions(divisionId, search)),

  fetchVariantTypeOptions: search =>
    dispatch(PromoDetailActions.promoDetailFetchVariantTypeOptions(search)),
  changeVariantType: (formikBag, hdrId) =>
    dispatch(PromoDetailActions.promoDetailChangeVariantType(formikBag, hdrId)),

  fetchCategoryOptions: (divisionId, search) =>
    dispatch(PromoDetailActions.promoDetailFetchCategoryOptions(divisionId, search)),

  fetchManufacturerOptions: (divisionId, search) =>
    dispatch(PromoDetailActions.promoDetailFetchManufacturerOptions(divisionId, search)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DetailPercentForm));
