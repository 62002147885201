/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AuditUser01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const auditUser01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const auditRes01SetUser = (state, { userId }) => ({
  ...state,
  userId
});

export const auditUser01FetchAuditUser01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const auditUser01FetchAuditUser01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const auditUser01SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AuditUser01Types.AUDIT_USER01_RESET_TIMESTAMP]: auditUser01ResetTimestamp,
  [AuditUser01Types.AUDIT_USER01_SET_USER]: auditRes01SetUser,
  [AuditUser01Types.AUDIT_USER01_FETCH_AUDIT_USER01_LOADING]: auditUser01FetchAuditUser01Loading,
  [AuditUser01Types.AUDIT_USER01_FETCH_AUDIT_USER01_SUCCESS]: auditUser01FetchAuditUser01Success,
  [AuditUser01Types.AUDIT_USER01_SET_EXPANDED_ROWS]: auditUser01SetExpandedRows
});
