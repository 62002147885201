import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Card, Modal, Breadcrumb } from 'antd';
import HeaderForm from './HeaderForm';
// import DetailTable from './DetailTable';
// import DetailForm from './DetailForm';
import ItemDetailActions from '../../Stores/ItemDetail/Actions';

class ItemDetailScreen extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      const hdrId = parseInt(match.params.id, 10);
      let itemId = 0;
      if (match.params.item_id) {
        itemId = parseInt(match.params.item_id, 10);
      }
      setHdrId(hdrId, itemId);
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setDetailVisible, documentIsLoading } = this.props;

    if (documentIsLoading === false) {
      setDetailVisible(false);
    }
  }

  render() {
    const { intl, match, detailIsVisible, documentHeader, appPath } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'item' })}>
        <Breadcrumb>
        </Breadcrumb>
        <HeaderForm match={match} />
      </Card>
    );
  }
}

ItemDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  setHdrId: PropTypes.func,
  setDetailVisible: PropTypes.func,
  documentIsLoading: PropTypes.bool,
  detailIsVisible: PropTypes.bool,
  documentHeader: PropTypes.shape({
    doc_flows: PropTypes.arrayOf(PropTypes.object)
  }),
  appPath: PropTypes.string
};

ItemDetailScreen.defaultProps = {
  intl: {},
  match: {},
  setHdrId() {},
  setDetailVisible() {},
  documentIsLoading: false,
  detailIsVisible: false,
  documentHeader: { doc_flows: [] },
  appPath: ''
};

const mapStateToProps = state => ({
  documentIsLoading: state.itemDetail.documentIsLoading,
  detailIsVisible: state.itemDetail.detailIsVisible,
  documentHeader: state.itemDetail.documentHeader,
  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setHdrId: (hdrId, itemId) => dispatch(ItemDetailActions.itemDetailSetHdrId(hdrId)),
  // setDetailVisible: boolean => dispatch(ItemDetailActions.itemDetailSetDetailVisible(boolean))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ItemDetailScreen));
