import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Row, Col, Upload, Table, Popconfirm, Button } from 'antd';

import CountAdjDetailActions from '../../Stores/CountAdjDetail/Actions';

class DetailTable extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.useOnUpload = this.useOnUpload.bind(this);
    this.useOnDownload = this.useOnDownload.bind(this);

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnSelectItem = this.useOnSelectItem.bind(this);
    this.useCreateDetail = this.useCreateDetail.bind(this);
    this.useOnDeleteItem = this.useOnDeleteItem.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showDetails } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      if (hdrId > 0) {
        showDetails(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 50,
        fixed: 'left',
        align: 'right',
        title: '',
        // sort field
        dataIndex: 'line_no',
        // filter field
        key: 'line_no',
        render: (text, record) => <>{record.line_no}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_code',
        // filter field
        key: 'storage_bin_code',
        render: (text, record) => <>{record.storage_bin_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'pallet_id' }),
        // sort field
        dataIndex: 'handling_unit_barcode',
        // filter field
        key: 'handling_unit_barcode',
        render: (text, record) => <>{record.handling_unit_barcode}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'expiry_date' }),
        // sort field
        dataIndex: 'expiry_date',
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'sign' }),
        // sort field
        dataIndex: 'sign',
        // filter field
        key: 'sign',
        render: (text, record) => <>{record.sign > 0 ? '+' : '-'}</>
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'qty' }),
        // sort field
        dataIndex: 'qty',
        // filter field
        key: 'qty',
        render: (text, record) =>
          record.qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.qty)}
            </>
          ) : (
            ''
          )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'uom' }),
        // sort field
        dataIndex: 'uom_code',
        // filter field
        key: 'uom_code',
        render: (text, record) => record.uom_code
      },
      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon="edit" onClick={() => this.useOnSelectItem(record)} />
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItem(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnSelectItem(record) {
    const { setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(record);

    setDetailVisible(true);
  }

  useOnDeleteItem(record) {
    const { hdrId, deleteDetail } = this.props;

    deleteDetail(hdrId, record);
  }

  useCreateDetail() {
    const { initDocumentDetail, setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(initDocumentDetail);

    setDetailVisible(true);
  }

  useOnUpload() {
    const { hdrId, uploadExcel } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadExcel(hdrId, fileList[0]);
    }
  }

  useOnDownload() {
    const { hdrId, downloadExcel } = this.props;

    downloadExcel(hdrId);
  }

  render() {
    const { intl, hdrId, documentDetails, documentIsLoading, uploadIsLoading } = this.props;
    const { fileList } = this.state;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{ pageSize: 20 }}
          columns={this.getDocumentColumns()}
          dataSource={documentDetails}
          loading={documentIsLoading || uploadIsLoading}
          bordered
          rowClassName={rowData => {
            if ('is_modified' in rowData && rowData.is_modified === true) {
              return 'success-row';
            }
            return '';
          }}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 8]}>
                <Col span={3}>
                  <Button
                    name="add_detail"
                    type="primary"
                    icon="plus"
                    disabled={hdrId === 0}
                    loading={documentIsLoading}
                    onClick={this.useCreateDetail}
                  >
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
                <Col span={8}>
                  <Upload
                    disabled={uploadIsLoading || hdrId === 0}
                    onRemove={() => {
                      this.setState({
                        fileList: []
                      });
                    }}
                    beforeUpload={file => {
                      /*
                      if (isExcelFile) {
                        this.setState({
                          fileList: [file]
                        });
                      } else {
                        notification.error({
                          message: intl.formatMessage({ id: 'you_can_only_upload_excel_file' }),
                          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
                        });
                      }
                      */
                      this.setState({
                        fileList: [file]
                      });

                      return false;
                    }}
                    fileList={fileList}
                  >
                    <Button
                      type="primary"
                      disabled={uploadIsLoading || hdrId === 0}
                      loading={uploadIsLoading}
                    >
                      {intl.formatMessage({ id: 'browse' })}
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={this.useOnUpload}
                    disabled={fileList.length === 0 || uploadIsLoading || hdrId === 0}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'upload' })}
                  </Button>
                  <Button
                    type="primary"
                    disabled={uploadIsLoading || hdrId === 0}
                    onClick={this.useOnDownload}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'download' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

DetailTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showDetails: PropTypes.func,
  setDocumentDetail: PropTypes.func,
  setDetailVisible: PropTypes.func,
  deleteDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentIsLoading: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),
  initDocumentDetail: PropTypes.shape({}),

  uploadIsLoading: PropTypes.bool,
  uploadExcel: PropTypes.func,
  downloadExcel: PropTypes.func
};

DetailTable.defaultProps = {
  intl: {},
  showDetails() {},
  setDocumentDetail() {},
  setDetailVisible() {},
  deleteDetail() {},
  hdrId: 0,
  documentDetails: [],
  documentIsLoading: false,
  initDocumentDetail: {},

  uploadIsLoading: false,
  uploadExcel() {},
  downloadExcel() {}
};

const mapStateToProps = state => ({
  timestamp: state.countAdjDetail.timestamp,
  hdrId: state.countAdjDetail.hdrId,
  documentDetails: state.countAdjDetail.documentDetails,
  initDocumentDetail: state.countAdjDetail.initDocumentDetail,
  documentIsLoading: state.countAdjDetail.documentIsLoading,

  uploadIsLoading: state.countAdjDetail.uploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  showDetails: hdrId => dispatch(CountAdjDetailActions.countAdjDetailShowDetails(hdrId)),
  setDetailVisible: boolean =>
    dispatch(CountAdjDetailActions.countAdjDetailSetDetailVisible(boolean)),
  setJobDetailVisible: boolean =>
    dispatch(CountAdjDetailActions.countAdjDetailSetJobDetailVisible(boolean)),

  setDocumentDetail: documentDetail =>
    dispatch(CountAdjDetailActions.countAdjDetailSetDocumentDetail(documentDetail)),
  deleteDetail: (hdrId, documentDetail) =>
    dispatch(CountAdjDetailActions.countAdjDetailDeleteDetail(hdrId, documentDetail)),

  uploadExcel: (hdrId, file) =>
    dispatch(CountAdjDetailActions.countAdjDetailUploadExcel(hdrId, file)),
  downloadExcel: hdrId => dispatch(CountAdjDetailActions.countAdjDetailDownloadExcel(hdrId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DetailTable));
