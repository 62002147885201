/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemPriceTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemPriceSetVisible = (state, { boolean }) => ({
  ...state,
  itemPriceIsVisible: boolean
});

export const itemPriceSetItemId = (state, { itemId }) => ({
  ...state,
  itemId
});

export const itemPriceInitDocumentDetail = (state, { itemId }) => ({
  ...state,
  itemId,
  documentDetail: { ...INITIAL_STATE.documentDetail, timestamp: Date.now() },
  uomOptions: []
});

export const itemPriceSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  itemId: documentDetail.itemId,
  documentDetail: { ...documentDetail, timestamp: Date.now() },
  uomOptions: []
});

export const itemPriceCreate = state => ({
  ...state
});

export const itemPriceUpdate = state => ({
  ...state
});

export const itemPriceDelete = state => ({
  ...state
});

export const itemPriceChangeUom = (state, { formikBag, uomId }) => ({
  ...state,
  documentDetail: { ...state.documentDetail, uomId }
});

export const itemPriceFetchUomOptionsLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const itemPriceFetchUomOptionsSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemPriceTypes.ITEM_PRICE_SET_VISIBLE]: itemPriceSetVisible,
  [ItemPriceTypes.ITEM_PRICE_SET_ITEM_ID]: itemPriceSetItemId,
  [ItemPriceTypes.ITEM_PRICE_INIT_DOCUMENT_DETAIL]: itemPriceInitDocumentDetail,
  [ItemPriceTypes.ITEM_PRICE_SET_DOCUMENT_DETAIL]: itemPriceSetDocumentDetail,
  [ItemPriceTypes.ITEM_PRICE_CREATE]: itemPriceCreate,
  [ItemPriceTypes.ITEM_PRICE_UPDATE]: itemPriceUpdate,
  [ItemPriceTypes.ITEM_PRICE_DELETE]: itemPriceDelete,
  [ItemPriceTypes.ITEM_PRICE_FETCH_UOM_OPTIONS_LOADING]: itemPriceFetchUomOptionsLoading,
  [ItemPriceTypes.ITEM_PRICE_FETCH_UOM_OPTIONS_SUCCESS]: itemPriceFetchUomOptionsSuccess
});
