import { put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SlsOrdSync03Actions from '../Stores/SlsOrdSync03/Actions';

const getAppStore = state => state.app;

export function* slsOrdSync03ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/SLS_ORD_SYNC_03`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SlsOrdSync03Actions.slsOrdSync03ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  }
}

export function* slsOrdSync03SyncNow({ formikBag, divisionId }) {
  try {
    formikBag.setSubmitting(true);
    yield put(SlsOrdSync03Actions.slsOrdSync03SyncLoading(true));
    
    const app = yield select(getAppStore);
    const getData = {};

    console.log('slsOrd/syncProcess/SLS_ORD_SYNC_03/');

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `slsOrd/syncProcess/SLS_ORD_SYNC_03/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    console.log(result);

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.data.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
      // yield put(SlsOrdSync03Actions.slsOrdSync03ResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(SlsOrdSync03Actions.slsOrdSync03SyncLoading(false));
  }
}
