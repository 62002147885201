import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stockCardReportInitStockCard: ['siteFlowId'],
  stockCardReportInitStockCardSuccess: ['criteria'],
  stockCardReportStockCard: ['formikBag', 'siteFlowId', 'criteria'],
  stockCardReportStockCardSuccess: ['criteria', 'reportData'],
  stockCardReportReportLoading: ['boolean'],

  stockCardReportFetchItemOptions: ['search'],
  stockCardReportFetchItemOptionLoading: ['boolean'],
  stockCardReportFetchItemOptionSuccess: ['options'],

  stockCardReportFetchItemGroup01Options: ['search'],
  stockCardReportFetchItemGroup01OptionLoading: ['boolean'],
  stockCardReportFetchItemGroup01OptionSuccess: ['options'],

  stockCardReportFetchItemGroup02Options: ['search'],
  stockCardReportFetchItemGroup02OptionLoading: ['boolean'],
  stockCardReportFetchItemGroup02OptionSuccess: ['options'],

  stockCardReportFetchItemGroup03Options: ['search'],
  stockCardReportFetchItemGroup03OptionLoading: ['boolean'],
  stockCardReportFetchItemGroup03OptionSuccess: ['options'],

  stockCardReportFetchStorageBinOptions: ['siteFlowId', 'search'],
  stockCardReportFetchStorageBinOptionLoading: ['boolean'],
  stockCardReportFetchStorageBinOptionSuccess: ['options'],

  stockCardReportFetchStorageRowOptions: ['siteFlowId', 'search'],
  stockCardReportFetchStorageRowOptionLoading: ['boolean'],
  stockCardReportFetchStorageRowOptionSuccess: ['options'],

  stockCardReportFetchStorageBayOptions: ['siteFlowId', 'search'],
  stockCardReportFetchStorageBayOptionLoading: ['boolean'],
  stockCardReportFetchStorageBayOptionSuccess: ['options'],

  stockCardReportFetchLocationOptions: ['siteFlowId', 'search'],
  stockCardReportFetchLocationOptionLoading: ['boolean'],
  stockCardReportFetchLocationOptionSuccess: ['options']
});

export const StockCardReportTypes = Types;
export default Creators;
