import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { Card, Row, Col, Table, Button, Dropdown, Icon, Menu } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import SlsOrdIndexActions from '../../Stores/SlsOrdIndex/Actions';

class SlsOrdIndexTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchSlsOrdIndex,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchSlsOrdIndex(curDivisionId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit } = this.props;

    function menu(record) {
      return (
        <Menu>
          <Menu.Item key="0">
            <a onClick={() => goToDocument(record.id)}>
              <Icon type="edit" /> Edit
            </a>
          </Menu.Item>
          <Menu.Item key="1">
            <a onClick={() => goToAudit(record.id)}>
              <Icon type="audit" /> Audit
            </a>
          </Menu.Item>
        </Menu>
      );
    }

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.doc_code
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      // {
      //   width: 120,
      //   align: 'left',
      //   title: intl.formatMessage({ id: 'doc_status' }),
      //   // sort field
      //   dataIndex: 'str_doc_status',
      //   ...AntDesignTable.getColumnSortProps(sorts, 'str_doc_status'),
      //   // filter field
      //   key: 'status',
      //   filters: [
      //     { text: 'Draft', value: '3' },
      //     { text: 'WIP', value: '50' },
      //     { text: 'Completed', value: '100' },
      //   ],
      //   // ...AntDesignTable.getColumnSearchProps(
      //   //   filters,
      //   //   intl.formatMessage({ id: 'doc_status' }),
      //   //   'str_doc_status',
      //   //   this.handleSearch,
      //   //   this.handleReset
      //   // ),
      //   render: (text, record) => <>{record.str_doc_status}</>
      // },
      // {
      //   align: 'left',
      //   title: intl.formatMessage({ id: 'area' }),
      //   // sort field
      //   dataIndex: 'delivery_point_area_code',
      //   ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_area_code'),
      //   // filter field
      //   key: 'delivery_point_area',
      //   ...AntDesignTable.getColumnSearchProps(
      //     filters,
      //     intl.formatMessage({ id: 'area' }),
      //     'delivery_point_area',
      //     this.handleSearch,
      //     this.handleReset
      //   ),
      //   render: (text, record) => (
      //     <>
      //       <b>{record.delivery_point_area_code}</b>
      //       <br />
      //       {record.delivery_point_area_desc_01}
      //     </>
      //   )
      // },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'created_by' }),
        // sort field
        dataIndex: 'created_username',
        ...AntDesignTable.getColumnSortProps(sorts, 'created_username'),
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'created_by' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.debtor_company_name_01}<br/>{record.created_username}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.delivery_point_unit_no} {record.delivery_point_building_name}
            <br />
            {record.delivery_point_street_name} {record.delivery_point_district_01} {record.delivery_point_district_02}
            <br />
            {record.delivery_point_postcode} {record.delivery_point_area_name} {record.delivery_point_state_name}
          </>
        )
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_doc_status'),
        // filter field
        key: 'str_doc_status',
        filters: [
          { text: 'Draft', value: '3' },
          { text: 'Completed', value: '100' },
          // { text: 'Completed', value: '100' },
        ],
        render: (text, record) => (
          <>
          {record.str_doc_status == `DRAFT` ?
          <span style={{backgroundColor: '#1890ff', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>Draft</span> :
          record.str_doc_status == `WIP` ?
          <span style={{backgroundColor: '#001529', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>Processing</span> :
          record.str_doc_status == `VOID` ?
          <span style={{backgroundColor: '#E74C3C', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>Cancelled</span> :
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>Completed</span>}
          </>
        )
      },
      // {
      //   width: 130,
      //   align: 'left',
      //   title: intl.formatMessage({ id: 'salesman' }),
      //   // sort field
      //   dataIndex: 'salesman_username',
      //   ...AntDesignTable.getColumnSortProps(sorts, 'salesman_username'),
      //   // filter field
      //   key: 'salesman',
      //   ...AntDesignTable.getColumnSearchProps(
      //     filters,
      //     intl.formatMessage({ id: 'salesman' }),
      //     'salesman',
      //     this.handleSearch,
      //     this.handleReset
      //   ),
      //   render: (text, record) => (
      //     <>
      //       <b>{record.salesman_username}</b>
      //     </>
      //   )
      // },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },

      {
        width: 100,
        // fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Actions <Icon type="down" />
              </a>
            </Dropdown>
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'sales_orders' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: record => ({
              // Column configuration not to be checked
              disabled: record.net_amt == 0.00,
              name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </Card>
    );
  }
}

SlsOrdIndexTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchSlsOrdIndex: PropTypes.func
};

SlsOrdIndexTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchSlsOrdIndex() {}
};

const mapStateToProps = state => ({
  timestamp: state.slsOrdIndex.timestamp,
  workspaceIsVisible: state.slsOrdIndex.workspaceIsVisible,

  documents: state.slsOrdIndex.documents,
  selectedDocuments: state.slsOrdIndex.selectedDocuments,

  sorts: state.slsOrdIndex.sorts,
  filters: state.slsOrdIndex.filters,

  currentPage: state.slsOrdIndex.currentPage,
  pageSize: state.slsOrdIndex.pageSize,
  total: state.slsOrdIndex.total,
  fetchIsLoading: state.slsOrdIndex.fetchIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(SlsOrdIndexActions.slsOrdIndexResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(SlsOrdIndexActions.slsOrdIndexGoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(SlsOrdIndexActions.slsOrdIndexGoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(SlsOrdIndexActions.slsOrdIndexSetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(SlsOrdIndexActions.slsOrdIndexAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(SlsOrdIndexActions.slsOrdIndexRemoveSelectedDocuments(selectedDocuments)),

  fetchSlsOrdIndex: (divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      SlsOrdIndexActions.slsOrdIndexFetchSlsOrdIndex(
        divisionId,
        currentPage,
        sorts,
        filters,
        pageSize
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SlsOrdIndexTable));
