/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemSync01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemSync01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const itemSync01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const itemSync01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const itemSync01SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemSync01Types.ITEM_SYNC01_SHOW_SYNC_SETTING_LOADING]: itemSync01ShowSyncSettingLoading,
  [ItemSync01Types.ITEM_SYNC01_SHOW_SYNC_SETTING_SUCCESS]: itemSync01ShowSyncSettingSuccess,
  [ItemSync01Types.ITEM_SYNC01_SHOW_BATCH_JOB_STATUS_SUCCESS]: itemSync01ShowBatchJobStatusSuccess,
  [ItemSync01Types.ITEM_SYNC01_SYNC_LOADING]: itemSync01SyncLoading
});
