/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  documents: [],
  fetchIsLoading: false,
  sorts: {
    doc_date: 'descend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 100,
  selectedDocuments: [],
  
  criteria: {
    start_date: new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-01',
    end_date: new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),
    columns: {
      source: [],
      target: []
    }
  },

  batchJobStatus: {
    proc_type: 0,
    user_id: 0,
    status_number: 0
  },
  fetchIsLoading: false,
  syncIsLoading: false
};
