/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsInvSync02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsInvSync02ResetTimestamp = (state, { currentPage, sorts, filters, criteria, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  criteria: criteria || state.criteria,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const slsInvSync02FetchSlsOrdIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const slsInvSync02FetchSlsOrdIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const slsInvSync02FetchSlsOrdIndexSearchSuccess = (state, { documents, currentPage, lastPage, total, pageSize, criteria }) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize,
  criteria
});

export const slsInvSync02AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const slsInvSync02RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const slsInvSync02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const slsInvSync02SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsInvSync02Types.SLS_INV_SYNC02_RESET_TIMESTAMP]: slsInvSync02ResetTimestamp,
  [SlsInvSync02Types.SLS_INV_SYNC02_FETCH_SLS_ORD_INDEX_LOADING]: slsInvSync02FetchSlsOrdIndexLoading,
  [SlsInvSync02Types.SLS_INV_SYNC02_FETCH_SLS_ORD_INDEX_SUCCESS]: slsInvSync02FetchSlsOrdIndexSuccess,
  
  [SlsInvSync02Types.SLS_INV_SYNC02_FETCH_SLS_ORD_INDEX_SEARCH_SUCCESS]: slsInvSync02FetchSlsOrdIndexSearchSuccess,

  [SlsInvSync02Types.SLS_INV_SYNC02_ADD_SELECTED_DOCUMENTS]: slsInvSync02AddSelectedDocuments,
  [SlsInvSync02Types.SLS_INV_SYNC02_REMOVE_SELECTED_DOCUMENTS]: slsInvSync02RemoveSelectedDocuments,
  [SlsInvSync02Types.SLS_INV_SYNC02_SHOW_BATCH_JOB_STATUS_SUCCESS]: slsInvSync02ShowBatchJobStatusSuccess,
  [SlsInvSync02Types.SLS_INV_SYNC02_SYNC_LOADING]: slsInvSync02SyncLoading
});
