/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Card, Select, Typography, DatePicker, Row } from 'antd';
import Moment from 'moment';

import DashboardActions from '../../Stores/Dashboard/Actions';
import Chart from '../../Components/Rechart/CombinedChart';

class ChartHeader extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      chart: '-- Select a chart -- ',
      sortBy: '-- Sort by --',
      title: '',
      rawDate: new Date()
    };
    this.updateData = this.updateData.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  componentDidUpdate(prevProp, prevState) {
    const {
      index,
      setChartOneConfig,
      setChartTwoConfig,
      setChartThreeConfig,
      setChartFourConfig,
      setChartFiveConfig,
      setChartSixConfig
    } = this.props;
    const { chart, sortBy, rawDate } = this.state;

    if (chart !== prevState.chart || sortBy !== prevState.sortBy || rawDate !== prevState.rawDate) {
      const config = {
        chart,
        sortBy,
        rawDate
      };

      if (index === 1) {
        setChartOneConfig(config);
      } else if (index === 2) {
        setChartTwoConfig(config);
      } else if (index === 3) {
        setChartThreeConfig(config);
      } else if (index === 4) {
        setChartFourConfig(config);
      } else if (index === 5) {
        setChartFiveConfig(config);
      } else if (index === 6) {
        setChartSixConfig(config);
      }
    }

    if (rawDate !== prevState.rawDate) {
      this.updateData(rawDate, index, chart);
    }
  }

  /* eslint-disable class-methods-use-this */
  getTitle(sortBy) {
    if (sortBy === 'week') {
      return 'Summary Week Report';
    }

    if (sortBy === 'month') {
      return 'Summary Month Report';
    }

    if (sortBy === 'year') {
      return 'Summary Year Report';
    }

    return null;
  }
  /* eslint-disable class-methods-use-this */

  updateData(date, index, chart) {
    const {
      FetchChartOneData,
      FetchChartTwoData,
      FetchChartThreeData,
      FetchChartFourData,
      FetchChartFiveData,
      FetchChartSixData
    } = this.props;
    const { sortBy } = this.state;
    const stringDate = new Date(date);
    const year = stringDate.getFullYear();
    const month = stringDate.getMonth() + 1;
    const day = stringDate.getDate();
    const newDate = `${year}-${month}-${day}`;

    const tmp = {
      chart,
      newDate,
      index,
      sortBy
    };

    if (index === 1) {
      FetchChartOneData(tmp);
    } else if (index === 2) {
      FetchChartTwoData(tmp);
    } else if (index === 3) {
      FetchChartThreeData(tmp);
    } else if (index === 4) {
      FetchChartFourData(tmp);
    } else if (index === 5) {
      FetchChartFiveData(tmp);
    } else if (index === 6) {
      FetchChartSixData(tmp);
    }
  }

  render() {
    const {
      chart_1_DataIsLoading,
      chart_1_DocumentHeader,
      chart_1_MaxValue,
      chart_2_DataIsLoading,
      chart_2_DocumentHeader,
      chart_2_MaxValue,
      chart_3_DataIsLoading,
      chart_3_DocumentHeader,
      chart_3_MaxValue,
      chart_4_DataIsLoading,
      chart_4_DocumentHeader,
      chart_4_MaxValue,
      chart_5_DataIsLoading,
      chart_5_DocumentHeader,
      chart_5_MaxValue,
      chart_6_DataIsLoading,
      chart_6_DocumentHeader,
      chart_6_MaxValue,
      index,
      loadingStatus,
      chartSettings
    } = this.props;
    const { chart, sortBy, title, isOpen, rawDate } = this.state;
    const { Option } = Select;
    const { MonthPicker, WeekPicker } = DatePicker;
    const { Text } = Typography;
    let datePickerComponent;
    let chartComponent;
    let data;
    let maxValue;

    if (sortBy === 'week') {
      datePickerComponent = (
        <WeekPicker
          onChange={v => {
            // eslint-disable-next-line no-underscore-dangle
            this.setState({ rawDate: v._d });
          }}
          value={Moment(rawDate)}
          style={{ marginLeft: '10px' }}
        />
      );
    } else if (sortBy === 'month') {
      datePickerComponent = (
        <MonthPicker
          onChange={v => {
            this.setState({ rawDate: v });
          }}
          value={Moment(rawDate)}
          style={{ marginLeft: '10px' }}
        />
      );
    } else if (sortBy === 'year') {
      datePickerComponent = (
        <DatePicker
          value={Moment(rawDate)}
          mode="year"
          open={isOpen}
          onOpenChange={status => {
            if (status) {
              this.setState({ isOpen: true });
            } else {
              this.setState({ isOpen: false });
            }
          }}
          onPanelChange={v => {
            this.setState({
              rawDate: v,
              isOpen: false
            });
          }}
          style={{ marginLeft: '10px' }}
        />
      );
    }

    if (index === 1) {
      data = chart_1_DocumentHeader;
      maxValue = chart_1_MaxValue;
    } else if (index === 2) {
      data = chart_2_DocumentHeader;
      maxValue = chart_2_MaxValue;
    } else if (index === 3) {
      data = chart_3_DocumentHeader;
      maxValue = chart_3_MaxValue;
    } else if (index === 4) {
      data = chart_4_DocumentHeader;
      maxValue = chart_4_MaxValue;
    } else if (index === 5) {
      data = chart_5_DocumentHeader;
      maxValue = chart_5_MaxValue;
    } else if (index === 6) {
      data = chart_6_DocumentHeader;
      maxValue = chart_6_MaxValue;
    }

    if (maxValue !== 0) {
      if (chart === 'bar' || chart === 'line' || chart === 'pie') {
        chartComponent = <Chart chartType={chart} data={data} maxValue={maxValue} />;
      }
    } else {
      chartComponent = 'No Data';
    }

    if (loadingStatus && chartSettings !== null) {
      if (index === 1) {
        this.setState({
          chart: chartSettings.chart_1_chart_type,
          sortBy: chartSettings.chart_1_sort_by,
          title: this.getTitle(chartSettings.chart_1_sort_by),
          rawDate
        });
      } else if (index === 2) {
        this.setState({
          chart: chartSettings.chart_2_chart_type,
          sortBy: chartSettings.chart_2_sort_by,
          title: this.getTitle(chartSettings.chart_2_sort_by),
          rawDate
        });
      } else if (index === 3) {
        this.setState({
          chart: chartSettings.chart_3_chart_type,
          sortBy: chartSettings.chart_3_sort_by,
          title: this.getTitle(chartSettings.chart_3_sort_by),
          rawDate
        });
      } else if (index === 4) {
        this.setState({
          chart: chartSettings.chart_4_chart_type,
          sortBy: chartSettings.chart_4_sort_by,
          title: this.getTitle(chartSettings.chart_4_sort_by),
          rawDate
        });
      } else if (index === 5) {
        this.setState({
          chart: chartSettings.chart_5_chart_type,
          sortBy: chartSettings.chart_5_sort_by,
          title: this.getTitle(chartSettings.chart_5_sort_by),
          rawDate
        });
      } else if (index === 6) {
        this.setState({
          chart: chartSettings.chart_6_chart_type,
          sortBy: chartSettings.chart_6_sort_by,
          title: this.getTitle(chartSettings.chart_6_sort_by),
          rawDate
        });
      }
    }

    return (
      <div>
        <Row type="flex" justify="start">
          <Select
            value={chart}
            style={{ width: 150 }}
            onChange={e => {
              this.setState({
                chart: e
              });
            }}
          >
            <Option value="bar">Bar Chart</Option>
            <Option value="line">Line Chart</Option>
            <Option value="pie">Pie Chart (Week)</Option>
          </Select>

          <Select
            value={sortBy}
            style={{ width: 150, marginLeft: '10px' }}
            onChange={e => {
              this.setState({
                sortBy: e,
                title: this.getTitle(e)
              });
            }}
          >
            <Option value="week">Weekly</Option>
            <Option value="month">Monthly</Option>
            <Option value="year">Yearly</Option>
          </Select>

          {datePickerComponent}
        </Row>

        <Text strong style={{ display: 'block', marginTop: '20px', marginLeft: '20px' }}>
          {title}
        </Text>

        <Card
          bordered={false}
          loading={
            /* eslint-disable no-nested-ternary */
            index === 1
              ? chart_1_DataIsLoading
              : index === 2
              ? chart_2_DataIsLoading
              : index === 3
              ? chart_3_DataIsLoading
              : index === 4
              ? chart_4_DataIsLoading
              : index === 5
              ? chart_5_DataIsLoading
              : index === 6
              ? chart_6_DataIsLoading
              : false
            /* eslint-enable no-nested-ternary */
          }
          style={{ height: '400px' }}
        >
          {chartComponent}
        </Card>
      </div>
    );
  }
}

ChartHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // intl: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  index: PropTypes.number,
  FetchChartOneData: PropTypes.func,
  FetchChartTwoData: PropTypes.func,
  FetchChartThreeData: PropTypes.func,
  FetchChartFourData: PropTypes.func,
  FetchChartFiveData: PropTypes.func,
  FetchChartSixData: PropTypes.func,
  setChartOneConfig: PropTypes.func,
  setChartTwoConfig: PropTypes.func,
  setChartThreeConfig: PropTypes.func,
  setChartFourConfig: PropTypes.func,
  setChartFiveConfig: PropTypes.func,
  setChartSixConfig: PropTypes.func,
  chart_1_DocumentHeader: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  chart_1_MaxValue: PropTypes.number,
  chart_1_DataIsLoading: PropTypes.bool,
  chart_2_DocumentHeader: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  chart_2_MaxValue: PropTypes.number,
  chart_2_DataIsLoading: PropTypes.bool,
  chart_3_DocumentHeader: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  chart_3_MaxValue: PropTypes.number,
  chart_3_DataIsLoading: PropTypes.bool,
  chart_4_DocumentHeader: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  chart_4_MaxValue: PropTypes.number,
  chart_4_DataIsLoading: PropTypes.bool,
  chart_5_DocumentHeader: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  chart_5_MaxValue: PropTypes.number,
  chart_5_DataIsLoading: PropTypes.bool,
  chart_6_DocumentHeader: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  chart_6_MaxValue: PropTypes.number,
  chart_6_DataIsLoading: PropTypes.bool,
  loadingStatus: PropTypes.bool,
  chartSettings: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    chart_1_chart_type: PropTypes.string,
    chart_1_sort_by: PropTypes.string,
    chart_2_chart_type: PropTypes.string,
    chart_2_sort_by: PropTypes.string,
    chart_3_chart_type: PropTypes.string,
    chart_3_sort_by: PropTypes.string,
    chart_4_chart_type: PropTypes.string,
    chart_4_sort_by: PropTypes.string,
    chart_5_chart_type: PropTypes.string,
    chart_5_sort_by: PropTypes.string,
    chart_6_chart_type: PropTypes.string,
    chart_6_sort_by: PropTypes.string
  })
};

ChartHeader.defaultProps = {
  // intl: {},
  FetchChartOneData: {},
  FetchChartTwoData: {},
  FetchChartThreeData: {},
  FetchChartFourData: {},
  FetchChartFiveData: {},
  FetchChartSixData: {},
  setChartOneConfig: {},
  setChartTwoConfig: {},
  setChartThreeConfig: {},
  setChartFourConfig: {},
  setChartFiveConfig: {},
  setChartSixConfig: {},
  chart_1_DocumentHeader: [],
  chart_1_MaxValue: 0,
  chart_1_DataIsLoading: false,
  chart_2_DocumentHeader: [],
  chart_2_MaxValue: 0,
  chart_2_DataIsLoading: false,
  chart_3_DocumentHeader: [],
  chart_3_MaxValue: 0,
  chart_3_DataIsLoading: false,
  chart_4_DocumentHeader: [],
  chart_4_MaxValue: 0,
  chart_4_DataIsLoading: false,
  chart_5_DocumentHeader: [],
  chart_5_MaxValue: 0,
  chart_5_DataIsLoading: false,
  chart_6_DocumentHeader: [],
  chart_6_MaxValue: 0,
  chart_6_DataIsLoading: false,
  loadingStatus: false,
  chartSettings: {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  chart_1_DataIsLoading: state.dashboardDetail.chart_1_DataIsLoading,
  chart_1_DocumentHeader: state.dashboardDetail.chart_1_DocumentHeader,
  chart_1_MaxValue: state.dashboardDetail.chart_1_MaxValue,
  chart_2_DataIsLoading: state.dashboardDetail.chart_2_DataIsLoading,
  chart_2_DocumentHeader: state.dashboardDetail.chart_2_DocumentHeader,
  chart_2_MaxValue: state.dashboardDetail.chart_2_MaxValue,
  chart_3_DataIsLoading: state.dashboardDetail.chart_3_DataIsLoading,
  chart_3_DocumentHeader: state.dashboardDetail.chart_3_DocumentHeader,
  chart_3_MaxValue: state.dashboardDetail.chart_3_MaxValue,
  chart_4_DataIsLoading: state.dashboardDetail.chart_4_DataIsLoading,
  chart_4_DocumentHeader: state.dashboardDetail.chart_4_DocumentHeader,
  chart_4_MaxValue: state.dashboardDetail.chart_4_MaxValue,
  chart_5_DataIsLoading: state.dashboardDetail.chart_5_DataIsLoading,
  chart_5_DocumentHeader: state.dashboardDetail.chart_5_DocumentHeader,
  chart_5_MaxValue: state.dashboardDetail.chart_5_MaxValue,
  chart_6_DataIsLoading: state.dashboardDetail.chart_6_DataIsLoading,
  chart_6_DocumentHeader: state.dashboardDetail.chart_6_DocumentHeader,
  chart_6_MaxValue: state.dashboardDetail.chart_6_MaxValue,
  savingStatus: state.dashboardDetail.savingStatus,
  loadingStatus: state.dashboardDetail.loadingStatus,
  chartSettings: state.dashboardDetail.chartSettings
});

const mapDispatchToProps = dispatch => ({
  FetchChartOneData: param => {
    dispatch(DashboardActions.dashboardFetchChartOneData(param));
  },
  FetchChartTwoData: param => {
    dispatch(DashboardActions.dashboardFetchChartTwoData(param));
  },
  FetchChartThreeData: param => {
    dispatch(DashboardActions.dashboardFetchChartThreeData(param));
  },
  FetchChartFourData: param => {
    dispatch(DashboardActions.dashboardFetchChartFourData(param));
  },
  FetchChartFiveData: param => {
    dispatch(DashboardActions.dashboardFetchChartFiveData(param));
  },
  FetchChartSixData: param => {
    dispatch(DashboardActions.dashboardFetchChartSixData(param));
  },
  setChartOneConfig: config => {
    dispatch(DashboardActions.dashboardChartOneConfig(config));
  },
  setChartTwoConfig: config => {
    dispatch(DashboardActions.dashboardChartTwoConfig(config));
  },
  setChartThreeConfig: config => {
    dispatch(DashboardActions.dashboardChartThreeConfig(config));
  },
  setChartFourConfig: config => {
    dispatch(DashboardActions.dashboardChartFourConfig(config));
  },
  setChartFiveConfig: config => {
    dispatch(DashboardActions.dashboardChartFiveConfig(config));
  },
  setChartSixConfig: config => {
    dispatch(DashboardActions.dashboardChartSixConfig(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ChartHeader));
