import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  invDoc01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc01GoToOrderDoc: ['hdrId', 'itemId'],
  invDoc01FetchInvDoc01: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc01FetchInvDoc01Loading: ['boolean'],
  invDoc01FetchInvDoc01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  invDoc01AddSelectedDocuments: ['selectedDocuments'],
  invDoc01RemoveSelectedDocuments: ['selectedDocuments'],
  invDoc01SetWorkspaceVisible: ['boolean'],
  invDoc01CreateInvDoc01: ['hdrIds'],
  invDoc01CreateInvDoc01Loading: ['boolean'],
  invDoc01CreateInvDoc01Success: ['newDocuments'],

  invDoc01SetExpandedRows: ['expandedRows']
});

export const InvDoc01Types = Types;
export default Creators;
