import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import CycleCount02DetailActions from '../Stores/CycleCount02Detail/Actions';

const getAppStore = state => state.app;

const getCycleCount02DetailStore = state => state.cycleCount02Detail;

export function* cycleCount02DetailShowDetails({ hdrId }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `cycleCount/showRecountDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CycleCount02DetailActions.cycleCount02DetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailAutoConfirm({ hdrId }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cycleCount/autoConfirmDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      const cycleCount02Detail = yield select(getCycleCount02DetailStore);

      const { documentDetails: oldDocumentDetails } = cycleCount02Detail;

      const { document_details: retDocumentDetails } = result.data;

      const processed = UtilService.processHeaderDetails(
        {},
        oldDocumentDetails,
        {},
        retDocumentDetails,
        []
      );

      yield put(CycleCount02DetailActions.cycleCount02DetailShowDetailsSuccess(processed.details));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailUpdateRecount({ dtlId, action }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `cycleCount/updateRecountDetails/${dtlId}/${action}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      const cycleCount02Detail = yield select(getCycleCount02DetailStore);

      const { documentDetails: oldDocumentDetails } = cycleCount02Detail;

      const { document_details: retDocumentDetails } = result.data;

      const processedDetails = oldDocumentDetails.map(oldDetail => {
        let a = -1;
        const retDocumentDetail = retDocumentDetails.reduce((lastObj, data, index) => {
          if (oldDetail.id === data.id) {
            a = index;
            return data;
          }
          return lastObj;
        }, {});

        if (a >= 0) {
          return {
            ...oldDetail,
            physical_count_status: retDocumentDetail.physical_count_status,
            str_physical_count_status: retDocumentDetail.str_physical_count_status
          };
        }

        return { ...oldDetail };
      });

      yield put(CycleCount02DetailActions.cycleCount02DetailShowDetailsSuccess(processedDetails));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailCreateCycleCount02({ siteFlowId, groupCount, dtlIds }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const postData = {
      siteFlowId,
      ids: dtlIds,
      data: {
        groupCount
      }
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cycleCount/createProcess/CYCLE_COUNT_02`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(CycleCount02DetailActions.cycleCount02DetailCreateCycleCount02Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}
