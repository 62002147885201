import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cartIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  cartIndexFetchCartIndex: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  cartIndexFetchCartIndexLoading: ['boolean'],
  cartIndexFetchCartIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  cartIndexAddSelectedDocuments: ['selectedDocuments'],
  cartIndexRemoveSelectedDocuments: ['selectedDocuments'],
  cartIndexSetWorkspaceVisible: ['boolean'],
  cartIndexGoToDocument: ['hdrId'],
  cartIndexGoToAudit: ['hdrId']
});

export const CartIndexTypes = Types;
export default Creators;
