/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { ItemGroup02List01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemGroup02List01ResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const itemGroup02List01FetchItemGroup02ListLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const itemGroup02List01FetchItemGroup02ListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const itemGroup02List01AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const itemGroup02List01RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const itemGroup02List01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const itemGroup02List01SetManagePhotoVisible = (state, { document, boolean }) => ({
  ...state,
  selectedDocument: document,
  managePhotoIsVisible: boolean
});

export const itemGroup02List01ManagePhotoLoading = (state, { boolean }) => ({
  ...state,
  photoIsLoading: boolean
});

export const itemGroup02List01ManagePhotoSuccess = (state, { document }) => ({
  ...state,
  selectedDocument: document
});

export const itemGroup02List01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_RESET_TIMESTAMP]: itemGroup02List01ResetTimestamp,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_FETCH_ITEM_GROUP02_LIST_LOADING]: itemGroup02List01FetchItemGroup02ListLoading,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_FETCH_ITEM_GROUP02_LIST_SUCCESS]: itemGroup02List01FetchItemGroup02ListSuccess,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_ADD_SELECTED_DOCUMENTS]: itemGroup02List01AddSelectedDocuments,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_REMOVE_SELECTED_DOCUMENTS]: itemGroup02List01RemoveSelectedDocuments,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_SET_WORKSPACE_VISIBLE]: itemGroup02List01SetWorkspaceVisible,

  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_SET_MANAGE_PHOTO_VISIBLE]: itemGroup02List01SetManagePhotoVisible,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_MANAGE_PHOTO_LOADING]: itemGroup02List01ManagePhotoLoading,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_MANAGE_PHOTO_SUCCESS]: itemGroup02List01ManagePhotoSuccess,
  [ItemGroup02List01Types.ITEM_GROUP02_LIST01_UPLOAD_LOADING]: itemGroup02List01UploadLoading
});
