import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  divisionListResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  divisionListGoToDocument: ['hdrId'],
  divisionListNewDocument: ['siteFlowId'],
  divisionListGoToAudit: ['divisionId'],
  divisionListFetchDivisionList: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  divisionListFetchDivisionListLoading: ['boolean'],
  divisionListFetchDivisionListSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  divisionListAddSelectedDocuments: ['selectedDocuments'],
  divisionListRemoveSelectedDocuments: ['selectedDocuments'],
});

export const DivisionListTypes = Types;
export default Creators;
