import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemProcessFetchItemProcess: ['siteFlowId'],
  itemProcessFetchItemProcessLoading: ['boolean'],
  itemProcessFetchItemProcessSuccess: ['process']
});

export const ItemProcessTypes = Types;
export default Creators;
