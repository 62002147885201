/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PickListDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const pickListDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const pickListDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const pickListDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const pickListDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const pickListDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const pickListDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const pickListDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const pickListDetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const pickListDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const pickListDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const pickListDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const pickListDetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const pickListDetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const pickListDetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const pickListDetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const pickListDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const pickListDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const pickListDetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const pickListDetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

// force refresh the formik form
export const pickListDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const pickListDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PickListDetailTypes.PICK_LIST_DETAIL_RESET_TIMESTAMP]: pickListDetailResetTimestamp,
  [PickListDetailTypes.PICK_LIST_DETAIL_SET_HDR_ID]: pickListDetailSetHdrId,

  [PickListDetailTypes.PICK_LIST_DETAIL_SHOW_HEADER_SUCCESS]: pickListDetailShowHeaderSuccess,

  [PickListDetailTypes.PICK_LIST_DETAIL_UPDATE_DOCUMENT_SUCCESS]: pickListDetailUpdateDocumentSuccess,

  [PickListDetailTypes.PICK_LIST_DETAIL_SHOW_DOCUMENT_LOADING]: pickListDetailShowDocumentLoading,

  [PickListDetailTypes.PICK_LIST_DETAIL_SHOW_DETAILS_SUCCESS]: pickListDetailShowDetailsSuccess,

  [PickListDetailTypes.PICK_LIST_DETAIL_SET_DETAIL_VISIBLE]: pickListDetailSetDetailVisible,
  [PickListDetailTypes.PICK_LIST_DETAIL_SET_DOCUMENT_DETAIL]: pickListDetailSetDocumentDetail,

  [PickListDetailTypes.PICK_LIST_DETAIL_UPDATE_DETAILS]: pickListDetailUpdateDetails,
  [PickListDetailTypes.PICK_LIST_DETAIL_CREATE_DETAIL]: pickListDetailCreateDetail,

  [PickListDetailTypes.PICK_LIST_DETAIL_SET_WHSE_JOB_TYPE]: pickListDetailSetWhseJobType,

  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_COMPANY_OPTION_LOADING]: pickListDetailFetchCompanyOptionLoading,
  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: pickListDetailFetchCompanyOptionSuccess,

  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: pickListDetailFetchFrStorageBinOptionLoading,
  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: pickListDetailFetchFrStorageBinOptionSuccess,

  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: pickListDetailFetchQuantBalOptionLoading,
  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: pickListDetailFetchQuantBalOptionSuccess,

  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_UOM_OPTION_LOADING]: pickListDetailFetchUomOptionLoading,
  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_UOM_OPTION_SUCCESS]: pickListDetailFetchUomOptionSuccess,

  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: pickListDetailFetchToStorageBinOptionLoading,
  [PickListDetailTypes.PICK_LIST_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: pickListDetailFetchToStorageBinOptionSuccess
});
