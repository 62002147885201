/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { OutbOrd01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrd01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const outbOrd01FetchOutbOrd01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const outbOrd01FetchOutbOrd01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const outbOrd01AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const outbOrd01RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const outbOrd01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const outbOrd01CreateOutbOrd01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const outbOrd01CreateOutbOrd01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const outbOrd01SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrd01Types.OUTB_ORD01_RESET_TIMESTAMP]: outbOrd01ResetTimestamp,
  [OutbOrd01Types.OUTB_ORD01_FETCH_OUTB_ORD01_LOADING]: outbOrd01FetchOutbOrd01Loading,
  [OutbOrd01Types.OUTB_ORD01_FETCH_OUTB_ORD01_SUCCESS]: outbOrd01FetchOutbOrd01Success,
  [OutbOrd01Types.OUTB_ORD01_ADD_SELECTED_DOCUMENTS]: outbOrd01AddSelectedDocuments,
  [OutbOrd01Types.OUTB_ORD01_REMOVE_SELECTED_DOCUMENTS]: outbOrd01RemoveSelectedDocuments,
  [OutbOrd01Types.OUTB_ORD01_SET_WORKSPACE_VISIBLE]: outbOrd01SetWorkspaceVisible,
  [OutbOrd01Types.OUTB_ORD01_CREATE_OUTB_ORD01_LOADING]: outbOrd01CreateOutbOrd01Loading,
  [OutbOrd01Types.OUTB_ORD01_CREATE_OUTB_ORD01_SUCCESS]: outbOrd01CreateOutbOrd01Success,
  [OutbOrd01Types.OUTB_ORD01_SET_EXPANDED_ROWS]: outbOrd01SetExpandedRows
});
