/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CycleCountIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const cycleCountIndexResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const cycleCountIndexFetchCycleCountIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const cycleCountIndexFetchCycleCountIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const cycleCountIndexAddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const cycleCountIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const cycleCountIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CycleCountIndexTypes.CYCLE_COUNT_INDEX_RESET_TIMESTAMP]: cycleCountIndexResetTimestamp,
  [CycleCountIndexTypes.CYCLE_COUNT_INDEX_FETCH_CYCLE_COUNT_INDEX_LOADING]: cycleCountIndexFetchCycleCountIndexLoading,
  [CycleCountIndexTypes.CYCLE_COUNT_INDEX_FETCH_CYCLE_COUNT_INDEX_SUCCESS]: cycleCountIndexFetchCycleCountIndexSuccess,
  [CycleCountIndexTypes.CYCLE_COUNT_INDEX_ADD_SELECTED_DOCUMENTS]: cycleCountIndexAddSelectedDocuments,
  [CycleCountIndexTypes.CYCLE_COUNT_INDEX_REMOVE_SELECTED_DOCUMENTS]: cycleCountIndexRemoveSelectedDocuments,
  [CycleCountIndexTypes.CYCLE_COUNT_INDEX_SET_WORKSPACE_VISIBLE]: cycleCountIndexSetWorkspaceVisible
});
