/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AdvShipDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const advShipDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const advShipDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  purchaserOptions: [],
  bizPartnerOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const advShipDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const advShipDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const advShipDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const advShipDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const advShipDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const advShipDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const advShipDetailFetchPurchaserOptionLoading = (state, { boolean }) => ({
  ...state,
  purchaserIsLoading: boolean
});

export const advShipDetailFetchPurchaserOptionSuccess = (state, { options }) => ({
  ...state,
  purchaserOptions: options
});

export const advShipDetailFetchBizPartnerOptionLoading = (state, { boolean }) => ({
  ...state,
  bizPartnerIsLoading: boolean
});

export const advShipDetailFetchBizPartnerOptionSuccess = (state, { options }) => ({
  ...state,
  bizPartnerOptions: options
});

export const advShipDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const advShipDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const advShipDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const advShipDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const advShipDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const advShipDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const advShipDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const advShipDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const advShipDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const advShipDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_RESET_TIMESTAMP]: advShipDetailResetTimestamp,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_SET_HDR_ID]: advShipDetailSetHdrId,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_SHOW_HEADER_SUCCESS]: advShipDetailShowHeaderSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_UPDATE_DOCUMENT_SUCCESS]: advShipDetailUpdateDocumentSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_SHOW_DOCUMENT_LOADING]: advShipDetailShowDocumentLoading,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_SHOW_DETAILS_SUCCESS]: advShipDetailShowDetailsSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_SET_DETAIL_VISIBLE]: advShipDetailSetDetailVisible,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_SET_DOCUMENT_DETAIL]: advShipDetailSetDocumentDetail,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_UPDATE_DETAILS]: advShipDetailUpdateDetails,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_CREATE_DETAIL]: advShipDetailCreateDetail,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_PURCHASER_OPTION_LOADING]: advShipDetailFetchPurchaserOptionLoading,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_PURCHASER_OPTION_SUCCESS]: advShipDetailFetchPurchaserOptionSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_BIZ_PARTNER_OPTION_LOADING]: advShipDetailFetchBizPartnerOptionLoading,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_BIZ_PARTNER_OPTION_SUCCESS]: advShipDetailFetchBizPartnerOptionSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: advShipDetailFetchCreditTermOptionLoading,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: advShipDetailFetchCreditTermOptionSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: advShipDetailFetchCurrencyOptionLoading,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: advShipDetailFetchCurrencyOptionSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_ITEM_OPTION_LOADING]: advShipDetailFetchItemOptionLoading,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: advShipDetailFetchItemOptionSuccess,

  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_UOM_OPTION_LOADING]: advShipDetailFetchUomOptionLoading,
  [AdvShipDetailTypes.ADV_SHIP_DETAIL_FETCH_UOM_OPTION_SUCCESS]: advShipDetailFetchUomOptionSuccess
});
