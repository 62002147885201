/**
 * The initial values for the redux state.
 */
export default {
    timestamp: 0,
    promotions: [],
    fetchIsLoading: false,
    sorts: {
      line_no: 'ascend'
    },
    filters: {},
    pageSize: '20',
    currentPage: 1,
    lastPage: 10,
    total: 100,
    selectedPromo: 0,
  };
  