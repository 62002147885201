import React from 'react';
import DetailFocForm from './DetailFocForm';

class DetailForm extends React.PureComponent {
  render() {
    const { promoType } = this.props;

    let form = null;

    if (promoType == 2) {
      form = <DetailFocForm />;
    } 

    return form;
  }
}

export default DetailForm;
