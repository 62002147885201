import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userList03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  userList03GoToDocument: ['hdrId'],
  userList03NewDocument: ['siteFlowId'],
  userList03GoToAudit: ['userId'],
  userList03FetchUserList03: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  userList03FetchUserList03Loading: ['boolean'],
  userList03FetchUserList03Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  userList03AddSelectedDocuments: ['selectedDocuments'],
  userList03RemoveSelectedDocuments: ['selectedDocuments'],
});

export const UserList03Types = Types;
export default Creators;
