import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { PropTypes } from 'prop-types';

const BarChartComponent = props => {
  const { data, xDataKey, maxValue, barDataKey } = props;

  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey} />
        <YAxis domain={[0, maxValue]} tickCount={10} allowDataOverflow />
        <Tooltip />
        <Bar dataKey={barDataKey} fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

BarChartComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types

  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  xDataKey: PropTypes.string,
  maxValue: PropTypes.number,
  barDataKey: PropTypes.string
};

BarChartComponent.defaultProps = {
  data: [],
  xDataKey: '',
  maxValue: 0,
  barDataKey: ''
};

export default BarChartComponent;
