import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsInvSync02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'criteria', 'pageSize'],
  slsInvSync02FetchSlsOrdIndex: ['divisionId', 'currentPage', 'sorts', 'filters', 'criteria', 'pageSize'],
  slsInvSync02FetchSlsOrdIndexLoading: ['boolean'],
  slsInvSync02FetchSlsOrdIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  slsInvSync02AddSelectedDocuments: ['selectedDocuments'],
  slsInvSync02RemoveSelectedDocuments: ['selectedDocuments'],
  slsInvSync02GoToDocument: ['hdrId'],

  slsInvSync02FetchSlsOrdIndexSearch: ['formikBag', 'criteria', 'divisionId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  slsInvSync02FetchSlsOrdIndexSearchSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize', 'criteria'],

  slsInvSync02ShowBatchJobStatus: null,
  slsInvSync02ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsInvSync02SyncNow: ['formikBag', 'divisionId', 'criteria'],
  slsInvSync02SyncLoading: ['boolean']
});

export const SlsInvSync02Types = Types;
export default Creators;
