import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikTransfer,
  FormikDatePicker,
  FormikButton
} from '../../Components/Formik';
import SlsInvSync02Actions from '../../Stores/SlsInvSync02/Actions';
import SlsOrdSync03Actions from '../../Stores/SlsOrdSync03/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const {
      intl,
      criteria,
      curDivisionId,
      currentPage,
      sorts,
      filters,
      pageSize,
      fetchSlsOrdIndexSearch,
      syncIsLoading,
      syncNow,
      syncCancelledSlsOrdNow
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'search') {
            fetchSlsOrdIndexSearch(formikBag, values, curDivisionId, currentPage, sorts, filters, pageSize);
          } else if (values.submit_action === 'sync_now') {
            syncNow(formikBag, curDivisionId, values);
            syncCancelledSlsOrdNow(formikBag, curDivisionId);
          }
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1970, 1, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'order_date' })} :</Text>
              </Col>
              <Col span={5}>
                <Field
                  name="start_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting}
                />
                <Text>&nbsp;&nbsp;&nbsp;-</Text>
              </Col>
              <Col span={5}>
                <Field
                  name="end_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting}
                />
              </Col>
              <Col span={6}>
                <Field
                  name="submit_action"
                  value="search"
                  component={FormikButton}
                  loading={isSubmitting || syncIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'search'
                  })}
                  icon="play-circle"
                  type="primary"
                />
                &nbsp;
                <Field
                  name="submit_action"
                  value="sync_now"
                  component={FormikButton}
                  // disabled={values.doc_status >= 50}
                  loading={isSubmitting || syncIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({ id: 'sync_now' })}
                  icon="sync"
                  type="primary"
                />
              </Col>
              <Col span={4}>
                <Text></Text>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  
  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchSlsOrdIndexSearch: PropTypes.func,
  
  syncIsLoading: PropTypes.bool,
  syncNow: PropTypes.func,
  syncCancelledSlsOrdNow: PropTypes.func,
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchSlsOrdIndexSearch() {},
  
  syncIsLoading: false,
  syncNow() {},
  syncCancelledSlsOrdNow() {},
};

const mapStateToProps = state => ({
  timestamp: state.slsInvSync02.timestamp,
  criteria: state.slsInvSync02.criteria,
  sorts: state.slsInvSync02.sorts,
  filters: state.slsInvSync02.filters,

  currentPage: state.slsInvSync02.currentPage,
  pageSize: state.slsInvSync02.pageSize,
  total: state.slsInvSync02.total,
  fetchIsLoading: state.slsInvSync02.fetchIsLoading,

  curDivisionId: state.app.curDivisionId,
  syncIsLoading: state.slsInvSync02.syncIsLoading,
});

const mapDispatchToProps = dispatch => ({
  fetchSlsOrdIndexSearch: (formikBag, criteria, divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      SlsInvSync02Actions.slsInvSync02FetchSlsOrdIndexSearch(
        formikBag,
        criteria,
        divisionId,
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  
  syncNow: (formikBag, divisionId, criteria) =>
    dispatch(SlsInvSync02Actions.slsInvSync02SyncNow(formikBag, divisionId, criteria)),
  syncCancelledSlsOrdNow: (formikBag, divisionId) =>
    dispatch(SlsOrdSync03Actions.slsOrdSync03SyncNow(formikBag, divisionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CriteriaForm));
