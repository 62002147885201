import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Row, Col, Table, Button, Menu, Icon, Dropdown, Radio } from 'antd';
import AntDesignTable from '../../Components/AntDesignTable';
import PromoDiscListActions from '../../Stores/PromoDiscIndex/Actions';

import ManagePhotoDialog from './ManagePhotoDialog';

class PromoDiscListTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0 || curDivisionId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      curSiteFlowId,
      resetTimestamp,
      fetchPromoDiscList,
      currentPage,
      sorts,
      filters,
      pageSize,
      filterStr
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      fetchPromoDiscList(curDivisionId, currentPage, sorts, filters, pageSize, filterStr);
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, setManagePhotoVisible, goToAudit } = this.props;

    function menu(record) { return (
      <Menu>
        <Menu.Item key="0">
          <a onClick={() => goToDocument(record.id)}><Icon type="edit" /> Edit</a>
        </Menu.Item>
        <Menu.Item key="1">
          <a onClick={() => setManagePhotoVisible(record, true)}><Icon type="picture" /> Photos</a>
        </Menu.Item>
        {/* {
          record.str_status != "ACTIVE" &&
          <Menu.Item key="2">
            <a onClick={() => updateItem(record.id, "ACTIVE")}><Icon type="check" /> Set Active</a>
          </Menu.Item>
        }
        {
          record.str_status != "INACTIVE" &&
          <Menu.Item key="3">
            <a onClick={() => updateItem(record.id, "INACTIVE")}><Icon type="stop" /> Set Inactive</a>
          </Menu.Item>
        }
        <Menu.Divider />
        <Menu.Item key="4">
          <a onClick={() => goToAudit(record.id)}><Icon type="audit" /> Audit</a>
        </Menu.Item> */}
      </Menu>
    )};

    return [
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'type' }),
        // sort field
        dataIndex: 'type',
        ...AntDesignTable.getColumnSortProps(sorts, 'type'),
        // filter field
        key: 'type',
        filters: [
          { text: intl.formatMessage({ id: 'discount_percent' }), value: '0' },
          { text: intl.formatMessage({ id: 'discount_fixed' }), value: '1' },
          // { text: 'Completed', value: '100' },
        ],
        render: (text, record) => (
          <>
          {record.type == 0 ?
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{intl.formatMessage({ id: 'discount_percent' })}</span> :
          record.type == 1 ?
          <span style={{backgroundColor: '#001529', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{intl.formatMessage({ id: 'discount_fixed' })}</span> :
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>other</span>}
          </>
        )
      },
      {
        width: 50,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'desc' }),
        // sort field
        dataIndex: 'desc',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc'),
        // filter field
        key: 'desc',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc' }),
          'desc',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'valid_from' }),
        // sort field
        dataIndex: 'valid_from',
        ...AntDesignTable.getColumnSortProps(sorts, 'valid_from'),
        // filter field
        key: 'valid_from',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'valid_from' }),
          'valid_from',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.valid_from}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'valid_to' }),
        // sort field
        dataIndex: 'valid_to',
        ...AntDesignTable.getColumnSortProps(sorts, 'valid_to'),
        // filter field
        key: 'valid_to',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'valid_to' }),
          'valid_to',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.valid_to}</>
      },
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_status'),
        // filter field
        key: 'str_status',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'status' }),
          'str_status',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
          {record.str_status == 'ACTIVE' ?
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{record.str_status}</span> :
          record.str_status == 'INACTIVE' ?
          <span style={{backgroundColor: '#001529', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{record.str_status}</span> :
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>other</span>}
          </>
        )
        // <>{record.str_status}</>
      },
      {
        width: 100,
        // fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Actions <Icon type="down" />
              </a>
            </Dropdown>
          </>
        )
      },
      // {
      //   width: 30,
      //   key: 'action',
      //   render: (text, record) => (
      //     <>
      //       <Button
      //         type="dashed"
      //         size="small"
      //         icon="edit"
      //         onClick={() => goToDocument(record.id)}
      //       />
      //       <Button type="dashed" size="small" icon="audit" onClick={() => goToAudit(record.id)} />
      //     </>
      //   )
      // }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }
  
  handleFilterChange(e) {
    
    const {
      curDivisionId,
      fetchPromoDiscList,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    fetchPromoDiscList(curDivisionId, currentPage, sorts, filters, pageSize, e.target.value);
    
  }

  render() {
    const {
      intl,
      filterStr,
      newDocument,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'promos_disc' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={7}>
                  <Radio.Group value={filterStr} onChange={this.handleFilterChange}>
                    <Radio.Button value="ongoing">{intl.formatMessage({ id: 'ongoing' })}</Radio.Button>
                    <Radio.Button value="upcoming">{intl.formatMessage({ id: 'upcoming' })}</Radio.Button>
                    <Radio.Button value="expired">{intl.formatMessage({ id: 'expired' })}</Radio.Button>
                  </Radio.Group>
                </Col>
                <Col span={1}>
                  {/* <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  /> */}
                </Col>
                <Col span={8}>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    loading={fetchIsLoading}
                    onClick={() => newDocument(0)}
                    icon="plus"
                    style={{float: 'right', marginRight:'1px'}}
                  >
                    {intl.formatMessage({ id: 'new_percent' })}
                  </Button>
                  &nbsp;
                  <Button
                    type="primary"
                    loading={fetchIsLoading}
                    onClick={() => newDocument(1)}
                    icon="plus"
                    style={{float: 'right', marginRight:'1px'}}
                  >
                    {intl.formatMessage({ id: 'new_fixed_price' })}
                  </Button>
                </Col>
                <Col span={20} />
              </Row>
            </>
          )}
          // scroll={{ x: 950 }}
        />
        <ManagePhotoDialog />
      </Card>
    );
  }
}

PromoDiscListTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  newDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  filterStr: PropTypes.string,
  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  curDivisionId: PropTypes.number,
  fetchPromoDiscList: PropTypes.func
};

PromoDiscListTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  newDocument() {},
  goToAudit() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  filterStr: 'ongoing',
  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  curDivisionId: 0,
  fetchPromoDiscList() {}
};

const mapStateToProps = state => ({
  timestamp: state.promoDiscList.timestamp,

  documents: state.promoDiscList.documents,
  selectedDocuments: state.promoDiscList.selectedDocuments,

  sorts: state.promoDiscList.sorts,
  filters: state.promoDiscList.filters,

  filterStr: state.promoDiscList.filterStr,
  currentPage: state.promoDiscList.currentPage,
  pageSize: state.promoDiscList.pageSize,
  total: state.promoDiscList.total,
  fetchIsLoading: state.promoDiscList.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId,
  curDivisionId: state.app.curDivisionId,
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PromoDiscListActions.promoDiscListResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToDocument: hdrId => dispatch(PromoDiscListActions.promoDiscListGoToDocument(hdrId)),
  newDocument: promoType => dispatch(PromoDiscListActions.promoDiscListNewDocument(promoType)),
  goToAudit: divisionId => dispatch(PromoDiscListActions.promoDiscListGoToAudit(divisionId)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(PromoDiscListActions.promoDiscListAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(PromoDiscListActions.promoDiscListRemoveSelectedDocuments(selectedDocuments)),

  fetchPromoDiscList: (divisionId, currentPage, sorts, filters, pageSize, filterStr) =>
    dispatch(
      PromoDiscListActions.promoDiscListFetchPromoList(
        divisionId,
        currentPage,
        sorts,
        filters,
        pageSize,
        filterStr
      )
    ),
  setManagePhotoVisible: (document, boolean) =>
    dispatch(PromoDiscListActions.promoDiscListSetManagePhotoVisible(document, boolean)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PromoDiscListTable));
