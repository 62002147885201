/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DeliveryPointTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const deliveryPointSetVisible = (state, { boolean }) => ({
  ...state,
  deliveryPointIsVisible: boolean
});

export const deliveryPointSetDebtorId = (state, { debtorId }) => ({
  ...state,
  debtorId
});

export const deliveryPointInitDocumentDetail = (state, { debtorId }) => ({
  ...state,
  debtorId,
  documentDetail: { ...INITIAL_STATE.documentDetail, timestamp: Date.now() },
  stateOptions: [],
  areaOptions: []
});

export const deliveryPointSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  debtorId: documentDetail.debtorId,
  documentDetail: { ...documentDetail, timestamp: Date.now() },
  stateOptions: [],
  areaOptions: []
});

export const deliveryPointCreate = state => ({
  ...state
});

export const deliveryPointUpdate = state => ({
  ...state
});

export const deliveryPointFetchStateOptionsLoading = (state, { boolean }) => ({
  ...state,
  stateIsLoading: boolean
});

export const deliveryPointFetchStateOptionsSuccess = (state, { options }) => ({
  ...state,
  stateOptions: options
});

export const deliveryPointChangeState = (state, { formikBag, stateId }) => ({
  ...state,
  documentDetail: { ...state.documentDetail }
});

export const deliveryPointChangeArea = (state, { formikBag, areaId }) => ({
  ...state,
  documentDetail: { ...state.documentDetail, areaId }
});

export const deliveryPointFetchAreaOptionsLoading = (state, { boolean }) => ({
  ...state,
  areaIsLoading: boolean
});

export const deliveryPointFetchAreaOptionsSuccess = (state, { options }) => ({
  ...state,
  areaOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DeliveryPointTypes.DELIVERY_POINT_SET_VISIBLE]: deliveryPointSetVisible,
  [DeliveryPointTypes.DELIVERY_POINT_SET_DEBTOR_ID]: deliveryPointSetDebtorId,
  [DeliveryPointTypes.DELIVERY_POINT_INIT_DOCUMENT_DETAIL]: deliveryPointInitDocumentDetail,
  [DeliveryPointTypes.DELIVERY_POINT_SET_DOCUMENT_DETAIL]: deliveryPointSetDocumentDetail,
  [DeliveryPointTypes.DELIVERY_POINT_CREATE]: deliveryPointCreate,
  [DeliveryPointTypes.DELIVERY_POINT_UPDATE]: deliveryPointUpdate,

  [DeliveryPointTypes.DELIVERY_POINT_CHANGE_STATE]: deliveryPointChangeState,

  [DeliveryPointTypes.DELIVERY_POINT_FETCH_STATE_OPTIONS_LOADING]: deliveryPointFetchStateOptionsLoading,
  [DeliveryPointTypes.DELIVERY_POINT_FETCH_STATE_OPTIONS_SUCCESS]: deliveryPointFetchStateOptionsSuccess,
  [DeliveryPointTypes.DELIVERY_POINT_FETCH_AREA_OPTIONS_LOADING]: deliveryPointFetchAreaOptionsLoading,
  [DeliveryPointTypes.DELIVERY_POINT_FETCH_AREA_OPTIONS_SUCCESS]: deliveryPointFetchAreaOptionsSuccess
});
