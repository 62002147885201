import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwayIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayIndexGoToDocument: ['hdrId'],
  putAwayIndexGoToAudit: ['hdrId'],
  putAwayIndexFetchPutAwayIndex: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayIndexFetchPutAwayIndexLoading: ['boolean'],
  putAwayIndexFetchPutAwayIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  putAwayIndexAddSelectedDocuments: ['selectedDocuments'],
  putAwayIndexRemoveSelectedDocuments: ['selectedDocuments'],
  putAwayIndexSetWorkspaceVisible: ['boolean']
});

export const PutAwayIndexTypes = Types;
export default Creators;
