import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob17DetailResetTimestamp: null,
  whseJob17DetailSetHdrId: ['hdrId'],

  whseJob17DetailInitHeader: ['siteFlowId'],
  whseJob17DetailShowHeader: ['hdrId'],
  whseJob17DetailShowHeaderSuccess: ['documentHeader'],

  whseJob17DetailUpdateHeader: ['formikBag', 'documentHeader'],
  whseJob17DetailCreateHeader: ['formikBag', 'documentHeader'],
  whseJob17DetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  whseJob17DetailShowDocumentLoading: ['boolean'],
  whseJob17DetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  whseJob17DetailShowDetails: ['hdrId'],
  whseJob17DetailShowDetailsSuccess: ['documentDetails'],

  whseJob17DetailSetDetailVisible: ['boolean'],
  whseJob17DetailSetJobDetailVisible: ['boolean'],
  whseJob17DetailSetDocumentDetail: ['documentDetail'],

  whseJob17DetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  whseJob17DetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  whseJob17DetailDeleteDetail: ['hdrId', 'documentDetail'],

  whseJob17DetailSetWhseJobType: ['whseJobType'],

  whseJob17DetailFetchItemOptions: ['search'],
  whseJob17DetailFetchItemOptionLoading: ['boolean'],
  whseJob17DetailFetchItemOptionSuccess: ['options'],
  whseJob17DetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  whseJob17DetailFetchItemBatchOptions: ['itemId', 'search'],
  whseJob17DetailFetchItemBatchOptionLoading: ['boolean'],
  whseJob17DetailFetchItemBatchOptionSuccess: ['options'],
  whseJob17DetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  whseJob17DetailFetchUomOptions: ['itemId', 'search'],
  whseJob17DetailFetchUomOptionLoading: ['boolean'],
  whseJob17DetailFetchUomOptionSuccess: ['options'],
  whseJob17DetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  whseJob17DetailFetchFrStorageBinOptions: ['whseJobType', 'siteFlowId', 'search'],
  whseJob17DetailFetchFrStorageBinOptionLoading: ['boolean'],
  whseJob17DetailFetchFrStorageBinOptionSuccess: ['options'],

  whseJob17DetailFetchToStorageBinOptions: ['whseJobType', 'siteFlowId', 'search'],
  whseJob17DetailFetchToStorageBinOptionLoading: ['boolean'],
  whseJob17DetailFetchToStorageBinOptionSuccess: ['options'],

  whseJob17DetailFetchHandlingUnitOptions: ['siteFlowId', 'search'],
  whseJob17DetailFetchHandlingUnitOptionLoading: ['boolean'],
  whseJob17DetailFetchHandlingUnitOptionSuccess: ['options'],

  whseJob17DetailFetchStorageRowOptions: ['siteFlowId', 'search'],
  whseJob17DetailFetchStorageRowOptionLoading: ['boolean'],
  whseJob17DetailFetchStorageRowOptionSuccess: ['options'],

  whseJob17DetailFetchStorageBayOptions: ['siteFlowId', 'search'],
  whseJob17DetailFetchStorageBayOptionLoading: ['boolean'],
  whseJob17DetailFetchStorageBayOptionSuccess: ['options'],

  whseJob17DetailFetchQuantBalOptions: ['storageBinId', 'search'],
  whseJob17DetailFetchQuantBalOptionLoading: ['boolean'],
  whseJob17DetailFetchQuantBalOptionSuccess: ['options'],
  whseJob17DetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  whseJob17DetailFetchWorkerOptions: ['search'],
  whseJob17DetailFetchWorkerOptionLoading: ['boolean'],
  whseJob17DetailFetchWorkerOptionSuccess: ['options']
});

export const WhseJob17DetailTypes = Types;
export default Creators;
