import React from 'react';
import DetailPercentForm from './DetailPercentForm';
import DetailFixedPriceForm from './DetailFixedPriceForm';
import DetailMainForm from './DetailMainForm';

class DetailForm extends React.PureComponent {
  render() {
    const { promoType } = this.props;

    let form = null;

    if (promoType == 0) {
      form = <DetailPercentForm />;
    } else if (promoType == 1) {
      form = <DetailFixedPriceForm />;
    } else if (promoType == 2) {
      form = <DetailMainForm />;
    }
    return form;
  }
}

export default DetailForm;
