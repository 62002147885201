import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
// import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import ItemDetailActions from '../Stores/ItemDetail/Actions';

const getAppStore = state => state.app;

export function* itemDetailInitHeader() {
  try {
    yield put(ItemDetailActions.itemDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `item/initModel`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ItemDetailActions.itemDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemDetailActions.itemDetailShowDocumentLoading(false));
  }
}

export function* itemDetailShowHeader({ hdrId }) {
  try {
    yield put(ItemDetailActions.itemDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `item/showModel/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ItemDetailActions.itemDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemDetailActions.itemDetailShowDocumentLoading(false));
  }
}

export function* itemDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'item/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      // const itemDetail = yield select(getItemDetailStore);

      // const { documentHeader: oldDocumentHeader } = itemDetail;

      const { model: retDocumentHeader } = result.data;

      // UtilService.processResources(oldDocumentHeader, retDocumentHeader);

      yield put(ItemDetailActions.itemDetailUpdateHeaderSuccess(retDocumentHeader));
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* itemDetailCreateHeader({ formikBag, documentHeader, divisionId }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/createModel/${divisionId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/itemDetail/update/${result.data}`));
      yield put(ItemDetailActions.itemDetailSetHdrId(result.data));
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* itemDetailFetchBrandOptions({ search }) {
  try {
    yield put(ItemDetailActions.itemDetailFetchBrandOptionLoading(true));

    const app = yield select(getAppStore);
    const divisionId = app.curDivisionId;
    const postData = {
      search,
      filters: [],
      divisionId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2ByDivision`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(ItemDetailActions.itemDetailFetchBrandOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemDetailActions.itemDetailFetchBrandOptionLoading(false));
  }
}

export function* itemDetailFetchCategoryOptions({ search }) {
  try {
    yield put(ItemDetailActions.itemDetailFetchCategoryOptionLoading(true));

    const app = yield select(getAppStore);
    const divisionId = app.curDivisionId;
    const postData = {
      search,
      filters: [],
      divisionId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2ByDivision`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(ItemDetailActions.itemDetailFetchCategoryOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemDetailActions.itemDetailFetchCategoryOptionLoading(false));
  }
}

export function* itemDetailFetchManufacturerOptions({ search }) {
  try {
    yield put(ItemDetailActions.itemDetailFetchManufacturerOptionLoading(true));

    const app = yield select(getAppStore);
    const divisionId = app.curDivisionId;
    const postData = {
      search,
      filters: [],
      divisionId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2ByDivision`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(ItemDetailActions.itemDetailFetchManufacturerOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemDetailActions.itemDetailFetchManufacturerOptionLoading(false));
  }
}

// export function* itemDetailFetchDebtorOptions({ search }) {
//   try {
//     yield put(ItemDetailActions.itemDetailFetchDebtorOptionLoading(true));

//     const app = yield select(getAppStore);
//     const postData = {
//       search,
//       filters: []
//     };

//     const result = yield call(
//       ApiService.postApi, // function
//       app.apiUrl,
//       `debtor/select2`,
//       app.token,
//       postData
//     );

//     if (result.isSuccess === true) {
//       const options = result.data.data.map(d => ({
//         value: d.id,
//         label: `${d.code} ${d.company_name_01}`
//       }));

//       yield put(ItemDetailActions.itemDetailFetchDebtorOptionSuccess(options));
//     } else if (result.isTokenExpired === true) {
//       yield put(AppActions.appTokenExpired(result.message));
//     } else if (result.isPasswordExpired === true) {
//       yield put(AppActions.appPasswordExpired(result.message));
//     } else {
//       yield call(notification.error, {
//         message: result.message,
//         duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
//       });
//     }
//   } catch (error) {
//     yield call(notification.error, {
//       message: error.message,
//       duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
//     });
//   } finally {
//     yield put(ItemDetailActions.itemDetailFetchDebtorOptionLoading(false));
//   }
// }
