/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CycleCount03Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const cycleCount03ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const cycleCount03FetchCycleCount03Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const cycleCount03FetchCycleCount03Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const cycleCount03AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const cycleCount03RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const cycleCount03SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const cycleCount03PrintCycleCount03Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CycleCount03Types.CYCLE_COUNT03_RESET_TIMESTAMP]: cycleCount03ResetTimestamp,
  [CycleCount03Types.CYCLE_COUNT03_FETCH_CYCLE_COUNT03_LOADING]: cycleCount03FetchCycleCount03Loading,
  [CycleCount03Types.CYCLE_COUNT03_FETCH_CYCLE_COUNT03_SUCCESS]: cycleCount03FetchCycleCount03Success,
  [CycleCount03Types.CYCLE_COUNT03_ADD_SELECTED_DOCUMENTS]: cycleCount03AddSelectedDocuments,
  [CycleCount03Types.CYCLE_COUNT03_REMOVE_SELECTED_DOCUMENTS]: cycleCount03RemoveSelectedDocuments,
  [CycleCount03Types.CYCLE_COUNT03_SET_WORKSPACE_VISIBLE]: cycleCount03SetWorkspaceVisible,
  [CycleCount03Types.CYCLE_COUNT03_PRINT_CYCLE_COUNT03_LOADING]: cycleCount03PrintCycleCount03Loading
});
