/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  id: 0,
  debtorId: 0,
  documentDetail: {
    unit_no: '',
    street_name: '',
    building_name: '',
    state_id: 0,
    area_id: 0,
    postcode: '',
    state_select2: { value: 0, label: '' },
    area_select2: { value: 0, label: '' },
    attention: '',
    phone_01: '',
    timestamp: 0
  },
  documentIsLoading: false,
  deliveryPointIsVisible: false,

  stateOptions: [],
  stateIsLoading: false,

  areaOptions: [],
  areaIsLoading: false
};
