import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  userList01GoToDocument: ['hdrId'],
  userList01NewDocument: ['siteFlowId'],
  userList01GoToAudit: ['userId'],
  userList01FetchUserList01: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  userList01FetchUserList01Loading: ['boolean'],
  userList01FetchUserList01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  userList01AddSelectedDocuments: ['selectedDocuments'],
  userList01RemoveSelectedDocuments: ['selectedDocuments'],
  userList01SetManageRoleVisible: ['document', 'boolean'],
  userList01RemoveRole: ['userId', 'roleId'],
  userList01AddRole: ['userId', 'roleId'],
  userList01ManageRoleSuccess: ['roles'],
  userList01ManageRoleLoading: ['boolean'],

  userList01FetchRoleOptions: ['userId', 'search'],
  userList01FetchRoleOptionLoading: ['boolean'],
  userList01FetchRoleOptionSuccess: ['options'],
  userList01SetRoleOption: ['option'],

  userList01SetManageDivisionVisible: ['document', 'boolean'],
  userList01RemoveDivision: ['userId', 'divisionId'],
  userList01AddDivision: ['userId', 'divisionId'],
  userList01ManageDivisionSuccess: ['divisions'],
  userList01ManageDivisionLoading: ['boolean'],

  userList01FetchDivisionOptions: ['userId', 'search'],
  userList01FetchDivisionOptionLoading: ['boolean'],
  userList01FetchDivisionOptionSuccess: ['options'],
  userList01SetDivisionOption: ['option']
});

export const UserList01Types = Types;
export default Creators;
