import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikText,
  FormikInputNumber,
  FormikTextNumber
} from '../../Components/Formik';
import WhseJob14DetailActions from '../../Stores/WhseJob14Detail/Actions';

const { Text } = Typography;

class WhseJobType01Form extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemBatch = this.handleSearchItemBatch.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchItemCond01 = this.handleSearchItemCond01.bind(this);
    this.handleSearchToStorageBin = this.handleSearchToStorageBin.bind(this);
    this.handleSearchToHandlingUnit = this.handleSearchToHandlingUnit.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeItemBatch = this.handleChangeItemBatch.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchItemBatch(form, value) {
    const { fetchItemBatchOptions } = this.props;
    const { values } = form;

    fetchItemBatchOptions(values.item_select2.value, value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_select2.value, value);
  }

  handleSearchItemCond01(form, value) {
    const { fetchItemCond01Options } = this.props;

    fetchItemCond01Options(value);
  }

  handleSearchToStorageBin(form, value) {
    const { curSiteFlowId, fetchToStorageBinOptions } = this.props;

    fetchToStorageBinOptions(curSiteFlowId, value);
  }

  handleSearchToHandlingUnit(form, value) {
    const { curSiteFlowId, fetchToHandlingUnitOptions } = this.props;

    fetchToHandlingUnitOptions(curSiteFlowId, value);
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { hdrId, changeItem } = this.props;

    changeItem(form, hdrId, value);
  }

  handleChangeItemBatch(form, value) {
    // params: form, value, option
    const { hdrId, changeItemBatch } = this.props;
    const { values } = form;

    changeItemBatch(form, hdrId, values.item_select2.value, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      companyIsLoading,
      companyOptions,
      itemIsLoading,
      itemOptions,
      itemBatchIsLoading,
      itemBatchOptions,
      uomIsLoading,
      uomOptions,
      itemCond01IsLoading,
      itemCond01Options,
      toStorageBinIsLoading,
      toStorageBinOptions,
      toHandlingUnitIsLoading,
      toHandlingUnitOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          company_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          item_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_is_required' }))
          }),
          item_batch_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_batch_is_required' }))
          }),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          }),
          qty: Yup.number().moreThan(0, intl.formatMessage({ id: 'qty_must_be_greater_than_0' })),
          item_cond_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_cond_01_is_required' }))
          }),
          to_storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'to_storage_bin_is_required' }))
          }),
          to_handling_unit_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'to_pallet_id_is_required' }))
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'company' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="company_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleCompanySearch}
                  notFoundContent={null}
                  loading={companyIsLoading}
                  options={companyOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={disabled}
                  onChange={this.handleChangeItem}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_01" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_02" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_batch' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_batch_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemBatch}
                  notFoundContent={null}
                  loading={itemBatchIsLoading}
                  options={itemBatchOptions}
                  disabled={disabled}
                  onChange={this.handleChangeItemBatch}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="batch_serial_no" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="expiry_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="receipt_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'qty' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="qty" component={FormikInputNumber} disabled={disabled} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="uom_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUom}
                  notFoundContent={null}
                  loading={uomIsLoading}
                  options={uomOptions}
                  disabled={disabled}
                  onChange={this.handleChangeUom}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="uom_rate"
                  component={FormikTextNumber}
                  minimumfractiondigits={6}
                  maximumfractiondigits={6}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_cond_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_cond_01_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemCond01}
                  notFoundContent={null}
                  loading={itemCond01IsLoading}
                  options={itemCond01Options}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'to_storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="to_storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchToStorageBin}
                  notFoundContent={null}
                  loading={toStorageBinIsLoading}
                  options={toStorageBinOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'to_pallet_id' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="to_handling_unit_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchToHandlingUnit}
                  notFoundContent={null}
                  loading={toHandlingUnitIsLoading}
                  options={toHandlingUnitOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon="undo"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon="save"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

WhseJobType01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemBatchOptions: PropTypes.func,
  changeItemBatch: PropTypes.func,
  itemBatchIsLoading: PropTypes.bool,
  itemBatchOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemCond01Options: PropTypes.func,
  itemCond01IsLoading: PropTypes.bool,
  itemCond01Options: PropTypes.arrayOf(PropTypes.object),

  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinIsLoading: PropTypes.bool,
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchToHandlingUnitOptions: PropTypes.func,
  toHandlingUnitIsLoading: PropTypes.bool,
  toHandlingUnitOptions: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number
};

WhseJobType01Form.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchItemOptions() {},
  changeItem() {},
  itemIsLoading: false,
  itemOptions: [],

  fetchItemBatchOptions() {},
  changeItemBatch() {},
  itemBatchIsLoading: false,
  itemBatchOptions: [],

  fetchUomOptions() {},
  changeUom() {},
  uomIsLoading: false,
  uomOptions: [],

  fetchItemCond01Options() {},
  itemCond01IsLoading: false,
  itemCond01Options: [],

  fetchToStorageBinOptions() {},
  toStorageBinIsLoading: false,
  toStorageBinOptions: [],

  fetchToHandlingUnitOptions() {},
  toHandlingUnitIsLoading: false,
  toHandlingUnitOptions: [],

  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  hdrId: state.whseJob14Detail.hdrId,
  documentHeader: state.whseJob14Detail.documentHeader,
  documentDetail: state.whseJob14Detail.documentDetail,
  documentIsLoading: state.whseJob14Detail.documentIsLoading,

  companyIsLoading: state.whseJob14Detail.companyIsLoading,
  companyOptions: state.whseJob14Detail.companyOptions,

  itemIsLoading: state.whseJob14Detail.itemIsLoading,
  itemOptions: state.whseJob14Detail.itemOptions,

  itemBatchIsLoading: state.whseJob14Detail.itemBatchIsLoading,
  itemBatchOptions: state.whseJob14Detail.itemBatchOptions,

  uomIsLoading: state.whseJob14Detail.uomIsLoading,
  uomOptions: state.whseJob14Detail.uomOptions,

  itemCond01IsLoading: state.whseJob14Detail.itemCond01IsLoading,
  itemCond01Options: state.whseJob14Detail.itemCond01Options,

  toStorageBinIsLoading: state.whseJob14Detail.toStorageBinIsLoading,
  toStorageBinOptions: state.whseJob14Detail.toStorageBinOptions,

  toHandlingUnitIsLoading: state.whseJob14Detail.toHandlingUnitIsLoading,
  toHandlingUnitOptions: state.whseJob14Detail.toHandlingUnitOptions,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(
      WhseJob14DetailActions.whseJob14DetailUpdateDetails(formikBag, hdrId, documentDetails)
    ),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchCompanyOptions: search =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchCompanyOptions(search)),

  fetchItemOptions: search =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchItemOptions(search)),
  changeItem: (formikBag, hdrId, itemId) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailChangeItem(formikBag, hdrId, itemId)),

  fetchItemBatchOptions: (itemId, search) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchItemBatchOptions(itemId, search)),
  changeItemBatch: (formikBag, hdrId, itemBatchId) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailChangeItemBatch(formikBag, hdrId, itemBatchId)),

  fetchUomOptions: (itemId, search) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchItemCond01Options: search =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchItemCond01Options(search)),

  fetchToStorageBinOptions: (siteFlowId, search) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchToStorageBinOptions(siteFlowId, search)),

  fetchToHandlingUnitOptions: (siteFlowId, search) =>
    dispatch(WhseJob14DetailActions.whseJob14DetailFetchToHandlingUnitOptions(siteFlowId, search))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WhseJobType01Form));
