/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  documents: [],
  fetchIsLoading: false,
  sorts: {
    code: 'ascend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 100,
  selectedDocuments: [],
  workspaceIsVisible: false,
  createIsLoading: false,
  newDocuments: [],

  selectedDocuments: [],
  managePhotoIsVisible: false,
  selectedDocument: {},

  photo: { value: 0, image: null },
  photoIsLoading: false,
  uploadIsLoading: false,
  
  expandedRows: []
};
