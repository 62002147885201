/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CycleCount02DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const cycleCount02DetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  // documentDetails: [],
  workspaceIsVisible: false
});

export const cycleCount02DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  workspaceIsVisible: false
});

export const cycleCount02DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const cycleCount02DetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const cycleCount02DetailSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const cycleCount02DetailCreateCycleCount02Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_RESET_TIMESTAMP]: cycleCount02DetailResetTimestamp,
  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SET_HDR_ID]: cycleCount02DetailSetHdrId,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SHOW_DOCUMENT_LOADING]: cycleCount02DetailShowDocumentLoading,
  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SHOW_DETAILS_SUCCESS]: cycleCount02DetailShowDetailsSuccess,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SET_WORKSPACE_VISIBLE]: cycleCount02DetailSetWorkspaceVisible,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_CREATE_CYCLE_COUNT02_SUCCESS]: cycleCount02DetailCreateCycleCount02Success
});
