/* eslint-disable react/prop-types */
import React from 'react';
import { Select } from 'antd';
import { ErrorMessage } from 'formik';

const { Option } = Select;

const FormikSelect = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const {
    selectMode,
    options,
    placeholder,
    defaultActiveFirstOption,
    showArrow,
    filterOption,
    onSearch,
    notFoundContent,
    loading,
    disabled,
    onChange
  } = props;

  const processedOptions = options
    ? options.map(d => <Option value={d.value} key={d.value}>{`${d.label}`}</Option>)
    : [];
    
  let style = {};
  if (form.errors[field.name]) {
    style = {
      ...style,
      borderColor: '#FF0000'
    };
  } else if (form.touched[field.name]) {
    style = {
      ...style,
      borderColor: '#00FF00'
    };
  }

  return (
    <>
      <Select
        mode={selectMode || 'default'}
        showSearch
        value={
          // eslint-disable-next-line no-nested-ternary
          // selectMode === 'multiple'
          // ? field.value.map(d => (typeof d === 'object' && 'label' in d ? d.label : d.value))
          // : typeof field.value === 'object' && 'label' in field.value
          // ? field.value.label
          // : field.value
          //---------------fixes---------------
          //use value instead of label to properly assign values (ids)
          //options has to be assigned as well in order to let component know which options are pre-selected
          selectMode === 'multiple' && typeof field.value !== 'undefined'
            ? field.value.map(d => (typeof d === 'object' && 'label' in d ? d.value : d.value))
            : typeof field.value === 'object' && 'label' in field.value
            ? field.value.label
            : field.value
        }
        style={style}
        placeholder={placeholder}
        defaultActiveFirstOption={defaultActiveFirstOption}
        showArrow={showArrow}
        filterOption={filterOption}
        onDropdownVisibleChange={open => {
          if (open && processedOptions.length === 0) {
            if (onSearch) {
              onSearch(form, '');
            }
          }
        }}
        onSearch={value => {
          if (onSearch) {
            onSearch(form, value);
          }
        }}
        onChange={(value, option) => {
          if (selectMode === 'multiple') {
            const curOptions = option.map(d => ({
              value: parseInt(d.key, 10),
              label: d.props.children
            }));
            form.setFieldValue(field.name, curOptions);
          } else {
            const curOption = { value: parseInt(option.key, 10), label: option.props.children };
            form.setFieldValue(field.name, curOption);
          }

          if (onChange) {
            onChange(form, value, option);
          }
        }}
        notFoundContent={notFoundContent}
        loading={loading}
        disabled={form.isSubmitting || disabled}
      >
        {processedOptions}
      </Select>
      <ErrorMessage
        name={field.name}
        render={msg => {
          return typeof msg === 'object' && 'value' in msg ? (
            <div style={{ color: 'red' }}>{msg.value}</div>
          ) : (
            <div style={{ color: 'red' }}>{msg}</div>
          );
        }}
      />
    </>
  );
};

export default FormikSelect;
