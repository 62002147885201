/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PromoIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const promoIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
    ...state,
    currentPage: currentPage || state.currentPage,
    sorts: sorts || state.sorts,
    filters: filters || state.filters,
    pageSize: pageSize || state.pageSize,
    timestamp: Date.now(),
});

export const promoIndexFetchAllPromotionsLoading = (state, { boolean }) => ({
    ...state,
    fetchIsLoading: boolean
});

export const promoIndexFetchAllPromotionsSuccess = ( state, { promotions, currentPage, lastPage, total, pageSize } ) => ({
    ...state,
    promotions,
    currentPage,
    lastPage,
    total,
    pageSize,
});

export const promoIndexSetSelectedPromotion = (state, { selectedPromo }) => ({
    ...state,
    selectedPromo
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [PromoIndexTypes.PROMO_INDEX_RESET_TIMESTAMP]: promoIndexResetTimestamp,
    [PromoIndexTypes.PROMO_INDEX_FETCH_ALL_PROMOTIONS_LOADING]: promoIndexFetchAllPromotionsLoading,
    [PromoIndexTypes.PROMO_INDEX_FETCH_ALL_PROMOTIONS_SUCCESS]: promoIndexFetchAllPromotionsSuccess,
    [PromoIndexTypes.PROMO_INDEX_SET_SELECTED_PROMOTION]: promoIndexSetSelectedPromotion,
});
