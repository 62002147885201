import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikTextNumber,
  FormikInputNumber
} from '../../Components/Formik';

import PromoDetailActions from '../../Stores/PromoDetail/Actions';

const { Text } = Typography;

class PromoRuleForm extends React.PureComponent {
  static getRuleInfo(id) {
    let ruleType = '';
    switch (id) {
      case 3:
        ruleType = 'debtor';
        break;
      case 4:
        ruleType = 'debtor_group01';
        break;
      default:
        ruleType = 'not supported';
        break;
    }

    return {
      ruleType,
      showDebtor: id === 3,
      showDebtorGroup01: id === 4
    };
  }

  constructor() {
    super();

    this.handleSearchDebtor = this.handleSearchDebtor.bind(this);
    this.handleSearchDebtorGroup = this.handleSearchDebtorGroup.bind(this);
    this.handleSearchRuleType = this.handleSearchRuleType.bind(this);

    this.handleChangeDebtor = this.handleChangeDebtor.bind(this);
    this.handleChangeDebtorGroup = this.handleChangeDebtorGroup.bind(this);
    this.handleChangeRuleType = this.handleChangeRuleType.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  static getRuleType(id) {
    switch (id) {
      case 3:
        return 'debtor';
      case 4:
        return 'debtor_group02';
      default:
        return 'not supported';
    }
  }

  handleSearchDebtor(form, value) {
    const { curDivisionId, fetchDebtorOptions } = this.props;

    fetchDebtorOptions(curDivisionId, value);
  }

  handleSearchDebtorGroup(form, value) {
    const { curDivisionId, fetchDebtorGroupOptions } = this.props;

    fetchDebtorGroupOptions(curDivisionId, value);
  }

  handleSearchRuleType(form, value) {
    const { fetchRuleTypeOptions } = this.props;
    const { values } = form;

    fetchRuleTypeOptions(values.rule_type_select2.value, value);
  }

  handleChangeDebtor(form, value) {
    // params: form, value, option
    const { changeDebtor } = this.props;
    changeDebtor(form, value);
  }

  handleChangeDebtorGroup(form, value) {
    // params: form, value, option
    const { changeDebtorGroup } = this.props;
    changeDebtorGroup(form, value);
  }

  handleChangeRuleType(form, value) {
    // params: form, value, option
    const { hdrId, changeRuleType } = this.props;
    const { values } = form;

    changeRuleType(form, hdrId, values.rule_type_select2.value, value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      rule,
      documentIsLoading,
      ruleTypeOptions,
      ruleTypeIsLoading,
      createRule,
      updateRule,
      debtorOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={rule}
        onSubmit={(values, formikBag) => {
          const ruleInfo = PromoRuleForm.getRuleInfo(values.rule_type_select2.value);

          // remove the submit_action field
          let processedValues = {
            id: values.id,
            type: ruleInfo.ruleType,
            debtor_ids: [],
            debtor_group_01_ids: []
          };

          if (ruleInfo.showDebtor) {
            processedValues.debtor_ids_select2 = values.debtor_ids_select2;
          } else if (ruleInfo.showDebtorGroup01) {
            processedValues.debtor_group_ids_select2 = values.debtor_group_ids_select2;
          }

          if (values.id > 0) {
            updateRule(formikBag, hdrId, processedValues);
          } else {
            createRule(formikBag, hdrId, processedValues);
          }
        }}
        // validationSchema={values.rule_type_select2.value == 3 ? Yup.object().shape({
        //   debtor_ids_select2: Yup.array().min(
        //     1,
        //     intl.formatMessage({ id: 'debtor_is_required' })
        //   )
        //   }) : Yup.object().shape({
        //     debtor_group_ids_select2: Yup.array().min(
        //       1,
        //       intl.formatMessage({ id: 'debtor_group_is_required' })
        //     )
        //     })
        // }
      >
        {({ values, dirty, isSubmitting, handleSubmit, handleReset, setFieldValue }) => {
          const ruleInfo = values.rule_type_select2
            ? PromoRuleForm.getRuleInfo(values.rule_type_select2.value)
            : 0;
          return (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'type' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="rule_type_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchRuleType}
                    notFoundContent={null}
                    loading={ruleTypeIsLoading}
                    options={ruleTypeOptions}
                    disabled={disabled || values.id > 0}
                    onChange={this.handleChangeRuleType}
                  />
                </Col>
                <Col span={12} />
              </Row>
              {this.renderDebtorRuleForm(
                values,
                dirty,
                isSubmitting,
                handleSubmit,
                handleReset,
                disabled,
                ruleInfo.showDebtor,
                setFieldValue
              )}
              {this.renderDebtorGroupRuleForm(
                values,
                dirty,
                isSubmitting,
                handleSubmit,
                handleReset,
                disabled,
                ruleInfo.showDebtorGroup01,
                setFieldValue
              )}

              <Row type="flex" justify="end" gutter={[0, 16]}>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    disabled={disabled}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: values.id > 0 ? 'update' : 'create'
                    })}
                    icon="save"
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
  
  renderDebtorRuleForm(values, dirty, isSubmitting, handleSubmit, handleReset, disabled, show, setFieldValue) {
    const { intl, debtorIsLoading, debtorOptions } = this.props;
    if (show) {
      return (
        <Row type="flex" justify="center" gutter={[8, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'debtor' })}</Text>
          </Col>
          <Col span={1}>
            <Text>:</Text>
          </Col>
          <Col span={20}>
            <Field
              name="debtor_ids_select2"
              component={FormikSelect}
              selectMode="multiple"
              showArrow
              filterOption={false}
              onSearch={this.handleSearchDebtor}
              notFoundContent={null}
              loading={debtorIsLoading}
              options={debtorOptions}
              disabled={disabled}
              onChange={this.handleChangeDebtor}
            />
          </Col>
        </Row>
      );
    }

    return null;
  }
  
  renderDebtorGroupRuleForm(values, dirty, isSubmitting, handleSubmit, handleReset, disabled, show, setFieldValue) {
    const { intl, debtorGroupIsLoading, debtorGroupOptions } = this.props;

    if (show) {
      return (
        <Row type="flex" justify="center" gutter={[8, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'debtor_group' })}</Text>
          </Col>
          <Col span={1}>
            <Text>:</Text>
          </Col>
          <Col span={20}>
            <Field
              name="debtor_group_ids_select2"
              component={FormikSelect}
              selectMode="multiple"
              showArrow
              filterOption={false}
              onSearch={this.handleSearchDebtorGroup}
              notFoundContent={null}
              loading={debtorGroupIsLoading}
              options={debtorGroupOptions}
              disabled={disabled}
              onChange={this.handleChangeDebtorGroup}
            />
          </Col>
        </Row>
      );
    }

    return null;
  }

}

PromoRuleForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateRule: PropTypes.func,
  createRule: PropTypes.func,
  hdrId: PropTypes.number,
  curDivisionId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  rule: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchDebtorOptions: PropTypes.func,
  fetchDebtorGroupOptions: PropTypes.func,
  changeDebtor: PropTypes.func,
  changeDebtorGroup: PropTypes.func,
  debtorIsLoading: PropTypes.bool,
  debtorGroupIsLoading: PropTypes.bool,
  debtorOptions: PropTypes.arrayOf(PropTypes.object),
  debtorGroupOptions: PropTypes.arrayOf(PropTypes.object),

  fetchRuleTypeOptions: PropTypes.func,
  changeRuleType: PropTypes.func,
  ruleTypeIsLoading: PropTypes.bool,
  ruleTypeOptions: PropTypes.arrayOf(PropTypes.object)
};

PromoRuleForm.defaultProps = {
  intl: {},
  updateRule() {},
  createRule() {},
  hdrId: 0,
  curDivisionId: 0,
  documentHeader: { doc_status: 3 },
  rule: {},
  documentIsLoading: false,

  fetchDebtorOptions() {},
  fetchDebtorGroupOptions() {},
  fetchRuleTypeOptions() {},
  changeDebtor() {},
  changeDebtorGroup() {},
  changeRuleType() {},

  debtorIsLoading: false,
  debtorGroupIsLoading: false,
  debtorOptions: [],
  debtorGroupOptions: [],

  ruleTypeIsLoading: false,
  ruleTypeOptions: []
};

const mapStateToProps = state => ({
  curDivisionId: state.app.curDivisionId,

  hdrId: state.promoDetail.hdrId,
  documentHeader: state.promoDetail.documentHeader,
  rule: state.promoDetail.rule,
  documentIsLoading: state.promoDetail.documentIsLoading,

  debtorIsLoading: state.promoDetail.debtorIsLoading,
  debtorGroupIsLoading: state.promoDetail.debtorGroupIsLoading,
  debtorOptions: state.promoDetail.debtorOptions,
  debtorGroupOptions: state.promoDetail.debtorGroupOptions,

  ruleTypeIsLoading: state.promoDetail.ruleTypeIsLoading,
  ruleTypeOptions: state.promoDetail.ruleTypeOptions
});

const mapDispatchToProps = dispatch => ({
  updateRule: (formikBag, hdrId, rule) =>
    dispatch(PromoDetailActions.promoDetailUpdateRule(formikBag, hdrId, rule)),
  createRule: (formikBag, hdrId, rule) =>
    dispatch(PromoDetailActions.promoDetailCreateRule(formikBag, hdrId, rule)),

  fetchDebtorOptions: (divisionId, search) => dispatch(PromoDetailActions.promoDetailFetchDebtorOptions(divisionId, search)),
  fetchDebtorGroupOptions: (divisionId, search) => dispatch(PromoDetailActions.promoDetailFetchDebtorGroupOptions(divisionId, search)),
  // changeDebtor: (formikBag, debtorId) =>
  //   dispatch(PromoDetailActions.promoDetailChangeDebtor(formikBag, debtorId)),

  fetchRuleTypeOptions: search =>
    dispatch(PromoDetailActions.promoDetailFetchRuleTypeOptions(search)),
  changeRuleType: (formikBag, hdrId) =>
    dispatch(PromoDetailActions.promoDetailChangeRuleType(formikBag, hdrId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PromoRuleForm));
