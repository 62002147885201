/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob0301Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob0301ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const whseJob0301FetchWhseJob0301Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob0301FetchWhseJob0301Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob0301AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const whseJob0301RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const whseJob0301SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob0301CreateWhseJob0301Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const whseJob0301CreateWhseJob0301Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const whseJob0301FetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const whseJob0301FetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const whseJob0301SetToStorageBinOption = (state, { option }) => ({
  ...state,
  toStorageBinOption: option
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob0301Types.WHSE_JOB0301_RESET_TIMESTAMP]: whseJob0301ResetTimestamp,
  [WhseJob0301Types.WHSE_JOB0301_FETCH_WHSE_JOB0301_LOADING]: whseJob0301FetchWhseJob0301Loading,
  [WhseJob0301Types.WHSE_JOB0301_FETCH_WHSE_JOB0301_SUCCESS]: whseJob0301FetchWhseJob0301Success,
  [WhseJob0301Types.WHSE_JOB0301_ADD_SELECTED_DOCUMENTS]: whseJob0301AddSelectedDocuments,
  [WhseJob0301Types.WHSE_JOB0301_REMOVE_SELECTED_DOCUMENTS]: whseJob0301RemoveSelectedDocuments,
  [WhseJob0301Types.WHSE_JOB0301_SET_WORKSPACE_VISIBLE]: whseJob0301SetWorkspaceVisible,
  [WhseJob0301Types.WHSE_JOB0301_CREATE_WHSE_JOB0301_LOADING]: whseJob0301CreateWhseJob0301Loading,
  [WhseJob0301Types.WHSE_JOB0301_CREATE_WHSE_JOB0301_SUCCESS]: whseJob0301CreateWhseJob0301Success,
  [WhseJob0301Types.WHSE_JOB0301_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: whseJob0301FetchToStorageBinOptionLoading,
  [WhseJob0301Types.WHSE_JOB0301_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: whseJob0301FetchToStorageBinOptionSuccess,
  [WhseJob0301Types.WHSE_JOB0301_SET_TO_STORAGE_BIN_OPTION]: whseJob0301SetToStorageBinOption
});
