import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Card, Modal, Breadcrumb } from 'antd';
import HeaderForm from './HeaderForm';
// import DetailTable from './DetailTable';
// import DetailForm from './DetailForm';
import DebtorDetailActions from '../../Stores/DebtorDetail/Actions';

class DebtorDetailScreen extends React.PureComponent {
    constructor() {
        super();
    
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        const { match, setHdrId } = this.props;
    
        if (match.params.action === 'create') {
          setHdrId(0);
        } else if (match.params.action === 'update') {
          const hdrId = parseInt(match.params.id, 10);
        //   if (match.params.item_id) {
        //     itemId = parseInt(match.params.item_id, 10);
        //   }
          setHdrId(hdrId);
        }
    }

    componentDidUpdate() {}

    componentWillUnmount() {}

    handleCancel() {
        const { setDetailVisible, documentIsLoading } = this.props;

        if (documentIsLoading === false) {
        setDetailVisible(false);
        }
    }

    render() {
        const { intl, match, detailIsVisible, documentHeader, appPath } = this.props;
        
        return (
            <Card title={intl.formatMessage({ id: 'debtor' })}>
                <HeaderForm match={match} />
                {/* <DetailTable />
                <Modal
                    visible={detailIsVisible}
                    title={intl.formatMessage({ id: 'sales_order_detail' })}
                    // style={{top:20}}
                    width="90%"
                    centered
                    forceRender
                    // onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >   
                <DetailForm match={match} />
                </Modal> */}
            </Card>
        );
    }
}

DebtorDetailScreen.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    intl: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    match: PropTypes.object,
    setHdrId: PropTypes.func,
    setDetailVisible: PropTypes.func,
    documentIsLoading: PropTypes.bool,
    detailIsVisible: PropTypes.bool,
    documentHeader: PropTypes.shape({
      doc_flows: PropTypes.arrayOf(PropTypes.object)
    }),
    appPath: PropTypes.string
};

DebtorDetailScreen.defaultProps = {
    intl: {},
    match: {},
    setHdrId() {},
    setDetailVisible() {},
    documentIsLoading: false,
    detailIsVisible: false,
    documentHeader: { doc_flows: [] },
    appPath: ''
}

const mapStateToProps = state => ({
    documentIsLoading: state.debtorDetail.documentIsLoading,
    detailIsVisible: state.debtorDetail.detailIsVisible,
    documentHeader: state.debtorDetail.documentHeader,
    appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
    setHdrId: (hdrId) => dispatch(DebtorDetailActions.debtorDetailSetHdrId(hdrId)),
    // setDetailVisible: boolean => dispatch(DebtorDetailActions.debtorDetailSetDetailVisible(boolean))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DebtorDetailScreen));