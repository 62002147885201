/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CycleCountDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const cycleCountDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const cycleCountDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  jobDetailIsVisible: false
});

export const cycleCountDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const cycleCountDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const cycleCountDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const cycleCountDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const cycleCountDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const cycleCountDetailSetJobDetailVisible = (state, { boolean }) => ({
  ...state,
  jobDetailIsVisible: boolean
});

export const cycleCountDetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const cycleCountDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const cycleCountDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const cycleCountDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const cycleCountDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const cycleCountDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const cycleCountDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const cycleCountDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const cycleCountDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const cycleCountDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const cycleCountDetailFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const cycleCountDetailFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const cycleCountDetailFetchHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  handlingUnitIsLoading: boolean
});

export const cycleCountDetailFetchHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  handlingUnitOptions: options
});

// force refresh the formik form
export const cycleCountDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const cycleCountDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const cycleCountDetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const cycleCountDetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const cycleCountDetailFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const cycleCountDetailFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_RESET_TIMESTAMP]: cycleCountDetailResetTimestamp,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SET_HDR_ID]: cycleCountDetailSetHdrId,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SHOW_HEADER_SUCCESS]: cycleCountDetailShowHeaderSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_UPDATE_DOCUMENT_SUCCESS]: cycleCountDetailUpdateDocumentSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SHOW_DOCUMENT_LOADING]: cycleCountDetailShowDocumentLoading,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SHOW_DETAILS_SUCCESS]: cycleCountDetailShowDetailsSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SET_DETAIL_VISIBLE]: cycleCountDetailSetDetailVisible,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SET_JOB_DETAIL_VISIBLE]: cycleCountDetailSetJobDetailVisible,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SET_DOCUMENT_DETAIL]: cycleCountDetailSetDocumentDetail,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_UPDATE_DETAILS]: cycleCountDetailUpdateDetails,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_CREATE_DETAIL]: cycleCountDetailCreateDetail,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_SET_WHSE_JOB_TYPE]: cycleCountDetailSetWhseJobType,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_COMPANY_OPTION_LOADING]: cycleCountDetailFetchCompanyOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: cycleCountDetailFetchCompanyOptionSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_ITEM_OPTION_LOADING]: cycleCountDetailFetchItemOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: cycleCountDetailFetchItemOptionSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: cycleCountDetailFetchItemBatchOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: cycleCountDetailFetchItemBatchOptionSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_UOM_OPTION_LOADING]: cycleCountDetailFetchUomOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_UOM_OPTION_SUCCESS]: cycleCountDetailFetchUomOptionSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_BIN_OPTION_LOADING]: cycleCountDetailFetchStorageBinOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_BIN_OPTION_SUCCESS]: cycleCountDetailFetchStorageBinOptionSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_HANDLING_UNIT_OPTION_LOADING]: cycleCountDetailFetchHandlingUnitOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_HANDLING_UNIT_OPTION_SUCCESS]: cycleCountDetailFetchHandlingUnitOptionSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: cycleCountDetailFetchStorageRowOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: cycleCountDetailFetchStorageRowOptionSuccess,

  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_BAY_OPTION_LOADING]: cycleCountDetailFetchStorageBayOptionLoading,
  [CycleCountDetailTypes.CYCLE_COUNT_DETAIL_FETCH_STORAGE_BAY_OPTION_SUCCESS]: cycleCountDetailFetchStorageBayOptionSuccess
});
