/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PromoFocListTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const promoFocListResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const promoFocListFetchPromoListLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const promoFocListFetchPromoListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize, filterStr }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize,
  filterStr
});

export const promoFocListAddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const promoFocListRemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PromoFocListTypes.PROMO_FOC_LIST_RESET_TIMESTAMP]: promoFocListResetTimestamp,
  [PromoFocListTypes.PROMO_FOC_LIST_FETCH_PROMO_LIST_LOADING]: promoFocListFetchPromoListLoading,
  [PromoFocListTypes.PROMO_FOC_LIST_FETCH_PROMO_LIST_SUCCESS]: promoFocListFetchPromoListSuccess,
  [PromoFocListTypes.PROMO_FOC_LIST_ADD_SELECTED_DOCUMENTS]: promoFocListAddSelectedDocuments,
  [PromoFocListTypes.PROMO_FOC_LIST_REMOVE_SELECTED_DOCUMENTS]: promoFocListRemoveSelectedDocuments
});
