import React from 'react';
import { injectIntl } from 'react-intl';
import DetailPercentTable from './DetailPercentTable';
import DetailFixedPriceTable from './DetailFixedPriceTable';
import DetailFocTable from './DetailFocTable';

class DetailTable extends React.PureComponent {
  render() {
    const { promoType } = this.props;
    if (promoType == 0) {
      return <DetailPercentTable promoType={promoType} />;
    } else if (promoType == 1) {
      return <DetailFixedPriceTable promoType={promoType} />;
    } else if (promoType == 2) {
      return <DetailFocTable promoType={promoType} />;
    }
    return null;
  }
}

export default injectIntl(DetailTable);
