import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemGroup01List01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemGroup01List01FetchItemGroup01ListAll: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemGroup01List01FetchItemGroup01ListAllSuccess: ['brands'],
  itemGroup01List01FetchItemGroup01List: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemGroup01List01FetchItemGroup01ListLoading: ['boolean'],
  itemGroup01List01FetchItemGroup01ListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  itemGroup01List01AddSelectedDocuments: ['selectedDocuments'],
  itemGroup01List01RemoveSelectedDocuments: ['selectedDocuments'],
  itemGroup01List01SetWorkspaceVisible: ['boolean'],
  itemGroup01List01GoToDocument: ['id'],
  itemGroup01List01GoToAudit: ['id'],
  itemGroup01List01SetSelectedBrand: ['selectedBrand'],
  itemGroup01List01ResetSelectedBrand: [],
  itemGroup01List01ReorderBrands: ['brandId'],

  itemGroup01List01SetManagePhotoVisible: ['document', 'boolean'],
  itemGroup01List01RemovePhoto: ['id'],
  itemGroup01List01UploadPhoto: ['id', 'file'],
  itemGroup01List01ManagePhotoSuccess: ['document'],
  itemGroup01List01ManagePhotoLoading: ['boolean'],
  itemGroup01List01UploadLoading: ['boolean']
});

export const ItemGroup01List01Types = Types;
export default Creators;
