import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import { PropTypes } from 'prop-types';

const PieChartComponent = props => {
  const { data, xDataKey } = props;

  return (
    <ResponsiveContainer width="99%" height={300}>
      <PieChart>
        <Pie
          dataKey={xDataKey}
          isAnimationActive={false}
          data={data}
          cx={250}
          cy={150}
          outerRadius={80}
          fill="#8884d8"
          label
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

PieChartComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_date: PropTypes.string,
      sales: PropTypes.string
    })
  ),
  xDataKey: PropTypes.string
};

PieChartComponent.defaultProps = {
  data: [],
  xDataKey: ''
};

export default PieChartComponent;
