/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { InvDoc0102Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const invDoc0102ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const invDoc0102FetchInvDoc0102Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const invDoc0102FetchInvDoc0102Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const invDoc0102AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const invDoc0102RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const invDoc0102SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const invDoc0102PrintInvDoc0102Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InvDoc0102Types.INV_DOC0102_RESET_TIMESTAMP]: invDoc0102ResetTimestamp,
  [InvDoc0102Types.INV_DOC0102_FETCH_INV_DOC0102_LOADING]: invDoc0102FetchInvDoc0102Loading,
  [InvDoc0102Types.INV_DOC0102_FETCH_INV_DOC0102_SUCCESS]: invDoc0102FetchInvDoc0102Success,
  [InvDoc0102Types.INV_DOC0102_ADD_SELECTED_DOCUMENTS]: invDoc0102AddSelectedDocuments,
  [InvDoc0102Types.INV_DOC0102_REMOVE_SELECTED_DOCUMENTS]: invDoc0102RemoveSelectedDocuments,
  [InvDoc0102Types.INV_DOC0102_SET_WORKSPACE_VISIBLE]: invDoc0102SetWorkspaceVisible,
  [InvDoc0102Types.INV_DOC0102_PRINT_INV_DOC0102_LOADING]: invDoc0102PrintInvDoc0102Loading
});
