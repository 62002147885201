import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Card, Button, Row, Col, Typography } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikDatePicker, FormikInputNumber } from '../../Components/Formik';
import PromoDetailActions from '../../Stores/PromoDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class HeaderForm extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      showHeader(hdrId);
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      createHeader,
      updateHeader,
      curDivisionId,
    } = this.props;

    const disabled = true;
    
    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            const processedValues = {
              id: values.id,
              code: values.code,
              desc_01: values.desc_01,
              desc_02: values.desc_02,
              valid_from: values.valid_from,
              valid_to: values.valid_to,
              status: values.status,
              buy_qty: values.buy_qty,
              foc_qty: values.foc_qty,
              disc_fixed_price: values.disc_fixed_price,
              disc_perc_01: values.disc_perc_01
            };

            if (match.params.action === 'create') {
              createHeader(curDivisionId, formikBag, match.params.id, processedValues);
            } else {
              updateHeader(formikBag, processedValues);
            }
          }
        }}
        validationSchema={(documentHeader.type == 2 || documentHeader.type == 'foc') ? 
        Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          buy_qty: Yup.number().min(1, intl.formatMessage({ id: 'qty_is_required' })),
          foc_qty: Yup.number().min(1, intl.formatMessage({ id: 'qty_is_required' })),
        }) : (documentHeader.type == 1 || documentHeader.type == 'disc_fixed_price') ?
        Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          disc_fixed_price: Yup.number().min(0.00, intl.formatMessage({ id: 'qty_is_required' })),
        }) : 
        Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          buy_qty: Yup.number().min(1, intl.formatMessage({ id: 'qty_is_required' })),
          disc_perc_01: Yup.number().min(0.00, intl.formatMessage({ id: 'qty_is_required' })),
        })}
      >
        {({
          values,
          dirty,
          isSubmitting,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'promo_detail' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="code" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_01" component={FormikInput} disabled={disabled} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_02" component={FormikInput} disabled={disabled} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'valid_from' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="valid_from" component={FormikDatePicker} disabled={disabled} />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'valid_to' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="valid_to" component={FormikDatePicker} disabled={disabled} />
                </Col>
              </Row>
            </Card>
            {(documentHeader.type == 2 || documentHeader.type == 'foc') && 
            <Card type="inner" title={intl.formatMessage({ id: 'foc_setting' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={5}>
                  <Text><b>{intl.formatMessage({ id: 'buy_qty' })} ({intl.formatMessage({ id: 'main_product' })})</b></Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={3}>
                  <Field name="buy_qty" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={1}></Col>
                <Col span={3}>
                  <Text><b>{intl.formatMessage({ id: 'foc_qty' })}</b></Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={3}>
                  <Field name="foc_qty" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={7}></Col>
              </Row>
            </Card>
            }
            
            {(documentHeader.type == 0 || documentHeader.type == 'disc_percent') &&
            <Card type="inner">
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text><b>{intl.formatMessage({ id: 'min_assorted_qty' })}</b></Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={3}>
                  <Field name="buy_qty" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={1}></Col>
                <Col span={3}>
                  <Text><b>{intl.formatMessage({ id: 'disc_perc_01' })}</b></Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={3}>
                  <Field name="disc_perc_01" component={FormikInputNumber} disabled={disabled} precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}/>
                </Col>
                <Col span={9}>
                  
                </Col>
              </Row>
            </Card>
            }
            {(documentHeader.type == 1 || documentHeader.type == 'disc_fixed_price') &&
            <Card type="inner">
                <Row type="flex" justify="center" gutter={[8,8]}>
                  <Col span={3}>
                    <Text><b>{intl.formatMessage({ id: 'disc_amount' })}</b></Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={3}>
                    <Field name="disc_fixed_price" component={FormikInputNumber} disabled={disabled} precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}/>
                  </Col>
                  <Col span={17}></Col>
                </Row>
            </Card>
            }

            {/* <Card>
              <Row type="flex" justify="space-between" gutter={[16, 16]}>
                <Col span={7}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon="arrow-left"
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={7}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon="save"
                  />
                  <Popconfirm
                    placement="left"
                    title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
                    onConfirm={() => this.useOnDeleteItem()}
                    onCancel={() => {}}
                    okText={intl.formatMessage({ id: 'yes' })}
                    cancelText={intl.formatMessage({ id: 'cancel' })}
                  >
                    <Field
                      type="danger"
                      component={FormikButton}
                      disabled={values.doc_status >= 50}
                      loading={isSubmitting || documentIsLoading}
                      label={intl.formatMessage({
                        id: 'delete'
                      })}
                      icon="delete"
                    />
                  </Popconfirm>
                </Col>
              </Row>
            </Card>
           */}
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool,
  curDivisionId: PropTypes.number,
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},
  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false,
  curDivisionId: 0,
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.promoDetail.timestamp,
  hdrId: state.promoDetail.hdrId,
  documentHeader: state.promoDetail.documentHeader,
  documentIsLoading: state.promoDetail.documentIsLoading,
  curDivisionId: state.app.curDivisionId,
});

const mapDispatchToProps = dispatch => ({
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  resetTimestamp: () => dispatch(PromoDetailActions.promoDetailResetTimestamp()),
  initHeader: (divisionId, promoType) => dispatch(PromoDetailActions.promoDetailInitHeader(divisionId, promoType)),
  showHeader: hdrId => dispatch(PromoDetailActions.promoDetailShowHeader(hdrId)),
  createHeader: (divisionId, formikBag, promoType, documentHeader) =>
    dispatch(PromoDetailActions.promoDetailCreateHeader(divisionId, formikBag, promoType, documentHeader)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(PromoDetailActions.promoDetailUpdateHeader(formikBag, documentHeader)),
  deleteHeader: (hdrId) => dispatch(PromoDetailActions.promoDetailDeleteHeader(hdrId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HeaderForm));
