import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Typography, Modal, Row, Col, Table, Button, Popconfirm, Select } from 'antd';

import UserList01Actions from '../../Stores/UserList01/Actions';

const { Option } = Select;
const { Title } = Typography;

class ManageDivisionDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.useOnAddDivision = this.useOnAddDivision.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, selectedDocument, removeDivision } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        sorter: (a, b) => `${a.code}`.localeCompare(b.code),
        // filter field
        key: 'code',
        render: (text, record) => (
          <>
            <b>{record.code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'name' }),
        // sort field
        dataIndex: 'name_01',
        sorter: (a, b) => `${a.name_01}`.localeCompare(b.name_01),
        // filter field
        key: 'name_01',
        render: (text, record) => <>{record.name_01}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_division' })}
              onConfirm={() => {
                removeDivision(selectedDocument.id, record.id);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  handleCancel() {
    const { setManageDivisionVisible, assignIsLoading } = this.props;

    if (assignIsLoading === false) {
      setManageDivisionVisible({}, false);
    }
  }

  handleSearch(value) {
    const { selectedDocument, fetchDivisionOptions } = this.props;
    fetchDivisionOptions(selectedDocument.id, value);
  }

  handleChange(value, option) {
    const { setDivisionOption } = this.props;
    const curOption = { value: parseInt(option.key, 10), label: option.props.children };
    setDivisionOption(curOption);
  }

  useOnAddDivision() {
    const { selectedDocument, divisionOption, addDivision } = this.props;

    if (divisionOption.value > 0) {
      addDivision(selectedDocument.id, divisionOption.value);
    }
  }

  render() {
    const {
      intl,
      selectedDocument,
      manageDivisionIsVisible,
      assignIsLoading,
      divisionOption,
      divisionOptions,
      divisionIsLoading
    } = this.props;

    const options = divisionOptions.map(d => <Option key={d.value}>{`${d.label}`}</Option>);

    return (
      <>
        <Modal
          visible={manageDivisionIsVisible}
          title={`${intl.formatMessage({ id: 'manage_division' })} ${selectedDocument.username}`}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row type="flex" justify="start" gutter={[0, 8]}>
            <Col span={3}>
              <Title level={4}>{intl.formatMessage({ id: 'new_division' })}</Title>
            </Col>
            <Col span={8}>
              <Select
                showSearch
                value={divisionOption.label}
                placeholder={intl.formatMessage({ id: 'key_in_code_or_description' })}
                style={{ width: 350 }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
                loading={divisionIsLoading}
              >
                {options}
              </Select>
            </Col>
            <Col span={3}>
              <Button type="primary" disabled={divisionOption.value === 0} onClick={this.useOnAddDivision}>
                {intl.formatMessage({ id: 'add' })}
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="start" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocument.divisions}
                loading={assignIsLoading}
                bordered
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

ManageDivisionDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setManageDivisionVisible: PropTypes.func,
  manageDivisionIsVisible: PropTypes.bool,

  removeDivision: PropTypes.func,
  addDivision: PropTypes.func,
  selectedDocument: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    divisions: PropTypes.arrayOf(PropTypes.object)
  }),

  assignIsLoading: PropTypes.bool,

  fetchDivisionOptions: PropTypes.func,
  setDivisionOption: PropTypes.func,

  divisionOption: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }),
  divisionOptions: PropTypes.arrayOf(PropTypes.object),
  divisionIsLoading: PropTypes.bool
};

ManageDivisionDialog.defaultProps = {
  intl: {},
  setManageDivisionVisible() {},
  manageDivisionIsVisible: false,

  removeDivision() {},
  addDivision() {},
  selectedDocument: {
    id: 0,
    username: '',
    divisions: []
  },

  assignIsLoading: false,

  fetchDivisionOptions() {},
  setDivisionOption() {},

  divisionOption: { value: 0, label: '' },
  divisionOptions: [],
  divisionIsLoading: false
};

const mapStateToProps = state => ({
  manageDivisionIsVisible: state.userList01.manageDivisionIsVisible,
  selectedDocument: state.userList01.selectedDocument,
  assignIsLoading: state.userList01.assignIsLoading,

  divisionOption: state.userList01.divisionOption,
  divisionOptions: state.userList01.divisionOptions,
  divisionIsLoading: state.userList01.divisionIsLoading
});

const mapDispatchToProps = dispatch => ({
  setManageDivisionVisible: boolean =>
    dispatch(UserList01Actions.userList01SetManageDivisionVisible(boolean)),
  removeDivision: (userId, divisionId) => dispatch(UserList01Actions.userList01RemoveDivision(userId, divisionId)),
  addDivision: (userId, divisionId) => dispatch(UserList01Actions.userList01AddDivision(userId, divisionId)),
  fetchDivisionOptions: (userId, search) =>
    dispatch(UserList01Actions.userList01FetchDivisionOptions(userId, search)),
  setDivisionOption: option => dispatch(UserList01Actions.userList01SetDivisionOption(option))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ManageDivisionDialog));
