import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdj01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdj01GoToDocument: ['hdrId'],
  countAdj01FetchCountAdj01: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  countAdj01FetchCountAdj01Loading: ['boolean'],
  countAdj01FetchCountAdj01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  countAdj01AddSelectedDocuments: ['selectedDocuments'],
  countAdj01RemoveSelectedDocuments: ['selectedDocuments'],
  countAdj01SetWorkspaceVisible: ['boolean'],
  countAdj01CreateCountAdj01: ['hdrIds'],
  countAdj01CreateCountAdj01Loading: ['boolean'],
  countAdj01CreateCountAdj01Success: ['newDocuments']
});

export const CountAdj01Types = Types;
export default Creators;
