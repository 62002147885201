import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikTransfer,
  FormikDatePicker,
  FormikButton
} from '../../Components/Formik';
import SlsOrdSync02Actions from '../../Stores/SlsOrdSync02/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const {
      intl,
      criteria,
      curDivisionId,
      currentPage,
      sorts,
      filters,
      pageSize,
      fetchSlsOrdIndexSearch,
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          fetchSlsOrdIndexSearch(formikBag, values, curDivisionId, currentPage, sorts, filters, pageSize);
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1970, 1, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="start" gutter={[8, 8]}>
              <Col>
                <Text>{intl.formatMessage({ id: 'order_date' })}:&nbsp;{intl.formatMessage({ id: 'from' })}&nbsp;</Text>
                <Field
                  name="start_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting}
                />
              </Col>
              <Col>
                <Text>{intl.formatMessage({ id: 'to' })}&nbsp;&nbsp;</Text>
                <Field
                  name="end_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting}
                />
              </Col>
              <Col>
                <Field
                  name="submit_action"
                  value="search"
                  component={FormikButton}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'search'
                  })}
                  icon="play-circle"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  
  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchSlsOrdIndexSearch: PropTypes.func
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchSlsOrdIndexSearch() {},
};

const mapStateToProps = state => ({
  timestamp: state.slsOrdSync02.timestamp,
  criteria: state.slsOrdSync02.criteria,
  sorts: state.slsOrdSync02.sorts,
  filters: state.slsOrdSync02.filters,

  currentPage: state.slsOrdSync02.currentPage,
  pageSize: state.slsOrdSync02.pageSize,
  total: state.slsOrdSync02.total,
  fetchIsLoading: state.slsOrdSync02.fetchIsLoading,

  curDivisionId: state.app.curDivisionId,
});

const mapDispatchToProps = dispatch => ({
  fetchSlsOrdIndexSearch: (formikBag, criteria, divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndexSearch(
        formikBag,
        criteria,
        divisionId,
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CriteriaForm));
