import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SalesProcessActions from '../Stores/SalesProcess/Actions';

const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* salesProcessFetchSalesProcess({ divisionId }) {
  try {
    yield put(SalesProcessActions.salesProcessFetchSalesProcessLoading(true));
    
    const result = [
      //{ proc_type: 'DEBTOR_EXCEL_01', icon: 'fa fa-file-excel-o' },
      { proc_type: 'SLS_ORD_SYNC_02', icon: 'fa fa-refresh' }, 
      { proc_type: 'SLS_INV_SYNC_02', icon: 'fa fa-cubes' },
    ];
    yield put(SalesProcessActions.salesProcessFetchSalesProcessSuccess(result));

    // const app = yield select(getAppStore);
    // const getData = {};

    // const result = yield call(
    //   ApiService.getApi, // function
    //   app.apiUrl,
    //   `division/indexSalesFlow/${divisionId}`,
    //   app.token,
    //   getData,
    //   'multipart/form-data' // params
    // );
    // if (result.isSuccess === true) {
    //   yield put(SalesProcessActions.salesProcessFetchSalesProcessSuccess(result.data));
    // } else if (result.isTokenExpired === true) {
    //   yield put(AppActions.appTokenExpired(result.message));
    // } else if (result.isPasswordExpired === true) {
    //   yield put(AppActions.appPasswordExpired(result.message));
    // } else {
    //   yield call(notification.error, {
    //     message: result.message,
    //     duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    //   });
    // }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SalesProcessActions.salesProcessFetchSalesProcessLoading(false));
  }
}
