import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptIndexGoToDocument: ['hdrId'],
  gdsRcptIndexGoToAudit: ['hdrId'],
  gdsRcptIndexFetchGdsRcptIndex: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptIndexFetchGdsRcptIndexLoading: ['boolean'],
  gdsRcptIndexFetchGdsRcptIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsRcptIndexAddSelectedDocuments: ['selectedDocuments'],
  gdsRcptIndexRemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcptIndexSetWorkspaceVisible: ['boolean']
});

export const GdsRcptIndexTypes = Types;
export default Creators;
