import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import ItemProcessActions from '../../Stores/ItemProcess/Actions';

import ItemList01Table from './ItemList01Table';

const { TabPane } = Tabs;

class ItemProcessDeletedScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchItemProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchItemProcess(curSiteFlowId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchItemProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchItemProcess(curSiteFlowId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      
      <Card>{match.params.proc_type === 'ITEM_LIST_01' && <ItemList01Table match={match} />}</Card>
    );
  }
}

ItemProcessDeletedScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number,
  fetchItemProcess: PropTypes.func
};

ItemProcessDeletedScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curSiteFlowId: 0,
  fetchItemProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.itemProcess.process,
  processIsLoading: state.itemProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchItemProcess: siteFlowId =>
    dispatch(ItemProcessActions.itemProcessFetchItemProcess(siteFlowId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemProcessDeletedScreen));
