import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob150101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob150101GoToDocument: ['hdrId'],
  whseJob150101FetchWhseJob150101: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob150101FetchWhseJob150101Loading: ['boolean'],
  whseJob150101FetchWhseJob150101Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob150101AddSelectedDocuments: ['selectedDocuments'],
  whseJob150101RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob150101SetWorkspaceVisible: ['boolean'],
  whseJob150101PrintWhseJob150101: ['siteFlowId', 'hdrIds'],
  whseJob150101PrintWhseJob150101Loading: ['boolean']
});

export const WhseJob150101Types = Types;
export default Creators;
