import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import UserProcessActions from '../../Stores/UserProcess/Actions';

import UserList01Table from '../UserList01/UserList01Table';
import UserList02Table from '../UserList02/UserList02Table';
import UserList03Table from '../UserList03/UserList03Table';
import UserExcel01Table from '../UserExcel01/UserExcel01Table';
import UserExcel03Table from '../UserExcel03/UserExcel03Table';

const { TabPane } = Tabs;

class UserProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchUserProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchUserProcess(curSiteFlowId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchUserProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchUserProcess(curSiteFlowId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          {process.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                >
                  <Link
                    style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                    to={`${appPath}/userProcess/index/${value.proc_type}`}
                  >
                    <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                  </Link>
                </Tooltip>
              }
              key={value.proc_type}
            />
          ))}
        </Tabs>

        {match.params.proc_type === 'USER_LIST_01' && <UserList01Table match={match} />}
        {match.params.proc_type === 'USER_LIST_02' && <UserList02Table match={match} />}
        {match.params.proc_type === 'USER_LIST_03' && <UserList03Table match={match} />}
        {match.params.proc_type === 'USER_EXCEL_01' && <UserExcel01Table match={match} />}
        {match.params.proc_type === 'USER_EXCEL_03' && <UserExcel03Table match={match} />}
      </Card>
    );
  }
}

UserProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number,
  fetchUserProcess: PropTypes.func
};

UserProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curSiteFlowId: 0,
  fetchUserProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.userProcess.process,
  processIsLoading: state.userProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchUserProcess: siteFlowId =>
    dispatch(UserProcessActions.userProcessFetchUserProcess(siteFlowId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UserProcessScreen));
