import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userList02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  userList02GoToDocument: ['hdrId'],
  userList02NewDocument: ['siteFlowId'],
  userList02GoToAudit: ['userId'],
  userList02FetchUserList02: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  userList02FetchUserList02Loading: ['boolean'],
  userList02FetchUserList02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  userList02AddSelectedDocuments: ['selectedDocuments'],
  userList02RemoveSelectedDocuments: ['selectedDocuments'],

  userList02SetManageDivisionVisible: ['document', 'boolean'],
  userList02RemoveDivision: ['userId', 'divisionId'],
  userList02AddDivision: ['userId', 'divisionId'],
  userList02ManageDivisionSuccess: ['divisions'],
  userList02ManageDivisionLoading: ['boolean'],

  userList02FetchDivisionOptions: ['userId', 'search'],
  userList02FetchDivisionOptionLoading: ['boolean'],
  userList02FetchDivisionOptionSuccess: ['options'],
  userList02SetDivisionOption: ['option']
});

export const UserList02Types = Types;
export default Creators;
