import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';

import { Card, Row, Col, Button, Icon, Typography } from 'antd';
import { FormikInput, FormikPassword } from '../../Components/Formik';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class LoginScreen extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      username: '',
      password: ''
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, apiUrl, authenticate, appPath } = this.props;
    const { username, password } = this.state;

    const initialValues = {
      username,
      password,
      apiUrl
    };

    return (
      <Card title={intl.formatMessage({ id: 'bizorder_login' })}>
        <img width="400px" src={`${appPath}/logo.jpeg`} alt="logo" />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, formikBag) => {
            const { username: curUsername, password: curPassword } = values;
            // save to state
            this.setState({
              username: curUsername,
              password: curPassword
            });
            // dispatch the action
            authenticate(formikBag, curUsername, curPassword);
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required(intl.formatMessage({ id: 'username_is_required' })),
            password: Yup.string().required(intl.formatMessage({ id: 'password_is_required' })),
            apiUrl: Yup.string().required(intl.formatMessage({ id: 'url_is_required' }))
          })}
        >
          {({
            // values,
            // handleChange,
            // errors,
            // setFieldTouched,
            // dirty,
            // touched,
            isSubmitting,
            isValid,
            handleSubmit
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={3}>
                  <Text strong>{intl.formatMessage({ id: 'username' })}</Text>
                </Col>
                <Col span={1}>
                  <Text strong>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="username"
                    component={FormikInput}
                    placeholder={intl.formatMessage({ id: 'username' })}
                    addonBefore={<Icon type="user" />}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={3}>
                  <Text strong>{intl.formatMessage({ id: 'password' })}</Text>
                </Col>
                <Col span={1}>
                  <Text strong>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="password"
                    component={FormikPassword}
                    placeholder={intl.formatMessage({ id: 'password' })}
                    addonBefore={<Icon type="lock" />}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={3}>
                  <Text strong>{intl.formatMessage({ id: 'url' })}</Text>
                </Col>
                <Col span={1}>
                  <Text strong>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="apiUrl"
                    component={FormikInput}
                    placeholder={intl.formatMessage({ id: 'url' })}
                    addonBefore={<Icon type="wifi" />}
                    disabled
                  />
                </Col>
              </Row>
              <Row type="flex" justify="start" gutter={[8, 8]}>
                <Col span={3}>
                  <Button
                    type="primary"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {intl.formatMessage({ id: 'login' })}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

LoginScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  authenticate: PropTypes.func,
  apiUrl: PropTypes.string,
  appPath: PropTypes.string
};

LoginScreen.defaultProps = {
  intl: {},
  authenticate() {},
  apiUrl: '',
  appPath: ''
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  authenticate: (formikBag, username, password) =>
    dispatch(AppActions.appAuthenticate(formikBag, username, password)),
  updateApiUrl: apiUrl => dispatch(AppActions.appUpdateApiUrl(apiUrl))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginScreen));
