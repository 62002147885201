import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Modal, Button, Upload, Row, Alert, Col } from 'antd';

import PromoDiscListActions from '../../Stores/PromoDiscIndex/Actions';

class ManagePhotoDialog extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.handleCancel = this.handleCancel.bind(this);

    //this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);

    //this.handleSearch = this.handleSearch.bind(this);
    //this.handleChange = this.handleChange.bind(this);

    this.useOnUpload = this.useOnUpload.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { selectedDocument } = this.props

    // if(prevProps.selectedDocument.asset_url !== selectedDocument.asset_url) {
    //   console.log("Different Asset URL")
    //   this.handleCancel()
    // }
  }

  componentWillUnmount() {}

  handleCancel() {
    const { setManagePhotoVisible, photoIsLoading } = this.props;

    if (photoIsLoading === false) {
      setManagePhotoVisible({}, false);
      this.setState({ fileList: [] });
    }
  }

  useOnUpload() {
    const { selectedDocument, uploadPhoto } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadPhoto(selectedDocument.id, fileList);
    }
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });
  handlePreviewCancel = () => this.setState({ previewVisible: false });

  handleRemove = file => {
    const { selectedDocument, removePhoto, intl } = this.props;

    if (Math.sign(file.uid) == -1) {
      if (window.confirm(`${intl.formatMessage({ id: 'confirm_remove' })}`)) {
        removePhoto(selectedDocument.id, file.uid);
        this.setState({ fileList: [] });
      }
    }
  };

  render() {
    const {
      intl,
      selectedDocument,
      managePhotoIsVisible,
      photoIsLoading,
      uploadIsLoading
    } = this.props;

    const { previewVisible, previewImage, fileList } = this.state;
    
    if (selectedDocument.asset_url != null) {
      for (var i = fileList.length - 1; i >= 0; --i) {
        if (Math.sign(fileList[i].uid) == -1) {
          fileList.splice(i, 1);
        }
      }
      
      var uid = selectedDocument.id * -1;
      fileList.push({ uid: uid, status: 'done', name: selectedDocument.photo_desc_01, url: selectedDocument.asset_url });
    }

    const browseButton = (
      <div>
        <div className="ant-upload-text">{intl.formatMessage({ id: 'browse' })}</div>
      </div>
    );

    return (
      <>
        <Modal
          visible={managePhotoIsVisible}
          title={`${intl.formatMessage({ id: 'manage_photo' })} [ ${selectedDocument.desc_01} ]`}
          // style={{top:20}}
          width="50%"
          centered
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
        >
          <Row type="flex">
            <Upload
              //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              accept=".jpg,.jpeg,.png"
              fileList={fileList}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              onRemove={this.handleRemove}
              beforeUpload={file => {
                //file["status"] = "uploading";
                this.setState(state => ({
                  fileList: [...state.fileList, file]
                }));
                // console.log(fileList);
                return false;
              }}
            >
              {fileList.length >= 1 ? null : browseButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handlePreviewCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Button
              type="primary"
              onClick={this.useOnUpload}
              disabled={fileList.length === 0}
              loading={uploadIsLoading || photoIsLoading}
            >
              {intl.formatMessage({ id: 'upload' })}
            </Button>
          </Row>
          <Row type="flex" style={{marginTop: 10}}>
            <Col span={24}>
            <Alert type="warning" message="Please use a banner image of 5:2 ratio for the best compatibility. Example: 1280px x 512px, 1600px x 640px."/>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

ManagePhotoDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setManagePhotoVisible: PropTypes.func,
  managePhotoIsVisible: PropTypes.bool,

  removePhoto: PropTypes.func,
  uploadPhoto: PropTypes.func,
  selectedDocument: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string
  }),

  uploadIsLoading: PropTypes.bool,

  photos: PropTypes.arrayOf(PropTypes.object),
  photoIsLoading: PropTypes.bool
};

ManagePhotoDialog.defaultProps = {
  intl: {},
  setManagePhotoVisible() {},
  managePhotoIsVisible: false,

  removePhoto() {},
  uploadPhoto() {},
  selectedDocument: {
    id: 0,
    code: ''
  },

  uploadIsLoading: false,

  photos: [],
  photoIsLoading: false
};

const mapStateToProps = state => ({
  managePhotoIsVisible: state.promoDiscList.managePhotoIsVisible,
  selectedDocument: state.promoDiscList.selectedDocument,
  photoIsLoading: state.promoDiscList.photoIsLoading,
  uploadIsLoading: state.promoDiscList.uploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  setManagePhotoVisible: (document, boolean) =>
    dispatch(PromoDiscListActions.promoDiscListSetManagePhotoVisible(document, boolean)),
  removePhoto: (promotionId, promotionPhotoId) =>
    dispatch(PromoDiscListActions.promoDiscListRemovePhoto(promotionId, promotionPhotoId)),
  uploadPhoto: (promotionId, fileList) =>
    dispatch(PromoDiscListActions.promoDiscListUploadPhoto(promotionId, fileList))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ManagePhotoDialog));
