import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  invDoc0101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc0101GoToDocument: ['hdrId'],
  invDoc0101FetchInvDoc0101: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc0101FetchInvDoc0101Loading: ['boolean'],
  invDoc0101FetchInvDoc0101Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  invDoc0101AddSelectedDocuments: ['selectedDocuments'],
  invDoc0101RemoveSelectedDocuments: ['selectedDocuments'],
  invDoc0101SetWorkspaceVisible: ['boolean'],
  invDoc0101PrintInvDoc0101: ['siteFlowId', 'hdrIds'],
  invDoc0101PrintInvDoc0101Loading: ['boolean']
});

export const InvDoc0101Types = Types;
export default Creators;
