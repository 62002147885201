import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Card, Row, Col, Divider, Typography } from 'antd';
import { FormikPassword, FormikInput, FormikText, FormikButton, FormikSelect } from '../../Components/Formik';

import DivisionDetailActions from '../../Stores/DivisionDetail/Actions';

const { Text } = Typography;

const ItemGroupOptions = [
  { value: '', label: 'N/A' },
  { value: 0, label: 'Item Group' },
  { value: 1, label: 'Item Group 01' },
  { value: 2, label: 'Item Group 02' },
];

const CustomerCategoryOptions = [
  { value: '', label: 'N/A' },
  { value: 0, label: 'Customer Category' },
  { value: 1, label: 'Customer Category 01' },
  { value: 2, label: 'Customer Category 02' },
];

class SyncSettingForm extends React.PureComponent {

  static getItemGroupValue(id) {
    switch (id) {
      case 0:
        return 'item_group';
      case 1:
        return 'item_group_01';
      case 2:
        return 'item_group_02';
      default:
        return '';
    }
  }

  static getCustomerCategoryValue(id) {
    switch (id) {
      case 0:
        return 'customer_category';
      case 1:
        return 'customer_category_01';
      case 2:
        return 'customer_category_02';
      default:
        return '';
    }
  }

  componentDidMount() {
    const { hdrId, showSyncSetting } = this.props;
    if (hdrId > 0) {
      showSyncSetting(hdrId);
    }
  }

  componentDidUpdate(prevProps) {
    const { hdrId, showSyncSetting } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('hdrId')) {
      if (hdrId > 0) {
        showSyncSetting(hdrId);
      }
    }
    // if (hdrId > 0) {
    //   showSyncSetting(hdrId);
    // }

  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      hdrId,
      fetchIsLoading,
      syncSetting,
      updateSyncSetting
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={syncSetting}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          let processedValues = {
            id: values.id,
            division_id: values.division_id,
            url: values.url,
            page_size: values.page_size,
            username: values.username,
            password: values.password,
            item_brand_mapping_select2: '',
            item_category_mapping_select2: '',
            item_manufacturer_mapping_select2: '',
            debtor_category_mapping_select2: '',
          };

          if (values.submit_action === 'update') {
            if ('item_brand_mapping_select2' in values) {
              processedValues.item_brand_mapping_select2 = SyncSettingForm.getItemGroupValue(values.item_brand_mapping_select2.value);
            }
            if ('item_category_mapping_select2' in values) {
              processedValues.item_category_mapping_select2 = SyncSettingForm.getItemGroupValue(values.item_category_mapping_select2.value);
            }
            if ('item_manufacturer_mapping_select2' in values) {
              processedValues.item_manufacturer_mapping_select2 = SyncSettingForm.getItemGroupValue(values.item_manufacturer_mapping_select2.value);
            }
            if ('debtor_category_mapping_select2' in values) {
              processedValues.debtor_category_mapping_select2 = SyncSettingForm.getCustomerCategoryValue(values.debtor_category_mapping_select2.value);
            }
            
            updateSyncSetting(formikBag, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          // item_brand_mapping: Yup.object().shape({
          //   value: Yup.number().min(0, intl.formatMessage({ id: 'item_is_required' }))
          // }),
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          // dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit
          // handleReset
        }) => (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'sync_setting' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'url' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="url" component={FormikInput} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'page_size' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="page_size" component={FormikInput} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'username' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="username" component={FormikInput} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'password' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="password" component={FormikPassword} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'last_synced_at' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="last_synced_at" component={FormikText} loading={fetchIsLoading} />
                </Col>
              </Row>

              <Divider>{intl.formatMessage({ id: 'field_mapping' })}</Divider>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={5}>
                  <Text>{intl.formatMessage({ id: 'item_brand_mapping' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="item_brand_mapping_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    notFoundContent={null}
                    loading={fetchIsLoading}
                    options={ItemGroupOptions}
                  />
                </Col>
                <Col span={10}>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={5}>
                  <Text>{intl.formatMessage({ id: 'item_category_mapping' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="item_category_mapping_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    notFoundContent={null}
                    loading={fetchIsLoading}
                    options={ItemGroupOptions}
                  />
                </Col>
                <Col span={10}>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={5}>
                  <Text>{intl.formatMessage({ id: 'item_manufacturer_mapping' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="item_manufacturer_mapping_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    notFoundContent={null}
                    loading={fetchIsLoading}
                    options={ItemGroupOptions}
                  />
                </Col>
                <Col span={10}>
                </Col>
              </Row><Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={5}>
                  <Text>{intl.formatMessage({ id: 'debtor_category_mapping' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="debtor_category_mapping_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    notFoundContent={null}
                    loading={fetchIsLoading}
                    options={CustomerCategoryOptions}
                  />
                </Col>
                <Col span={10}>
                </Col>
              </Row>

              <Divider></Divider>

              <Row type="flex" justify="start" gutter={[0, 16]}>
                
                <Col span={3}>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || fetchIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({ id: 'update' })}
                    icon="save"
                  />
                </Col>
              </Row>

            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

SyncSettingForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  syncSetting: PropTypes.shape({}),
  fetchIsLoading: PropTypes.bool,
  syncIsLoading: PropTypes.bool,

  showSyncSetting: PropTypes.func,
  updateSyncSetting: PropTypes.func,

  hdrId: PropTypes.number
};

SyncSettingForm.defaultProps = {
  intl: {},

  syncSetting: {},
  fetchIsLoading: false,

  showSyncSetting() {},
  updateSyncSetting() {},

  hdrId: 0
};

const mapStateToProps = state => ({
  syncSetting: state.divisionDetail.syncSetting,
  fetchIsLoading: state.divisionDetail.fetchIsLoading,
  hdrId: state.divisionDetail.hdrId,
});

const mapDispatchToProps = dispatch => ({
  showSyncSetting: hdrId =>
    dispatch(DivisionDetailActions.divisionDetailShowSyncSetting(hdrId)),
  updateSyncSetting: (formikBag, syncSetting) =>
    dispatch(DivisionDetailActions.divisionDetailUpdateSyncSetting(formikBag, syncSetting)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SyncSettingForm));
