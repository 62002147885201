/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob140101Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob140101ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false,
  printIsLoading: false
});

export const whseJob140101FetchWhseJob140101Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob140101FetchWhseJob140101Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob140101AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const whseJob140101RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const whseJob140101SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob140101PrintWhseJob140101Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob140101Types.WHSE_JOB140101_RESET_TIMESTAMP]: whseJob140101ResetTimestamp,
  [WhseJob140101Types.WHSE_JOB140101_FETCH_WHSE_JOB140101_LOADING]: whseJob140101FetchWhseJob140101Loading,
  [WhseJob140101Types.WHSE_JOB140101_FETCH_WHSE_JOB140101_SUCCESS]: whseJob140101FetchWhseJob140101Success,
  [WhseJob140101Types.WHSE_JOB140101_ADD_SELECTED_DOCUMENTS]: whseJob140101AddSelectedDocuments,
  [WhseJob140101Types.WHSE_JOB140101_REMOVE_SELECTED_DOCUMENTS]: whseJob140101RemoveSelectedDocuments,
  [WhseJob140101Types.WHSE_JOB140101_SET_WORKSPACE_VISIBLE]: whseJob140101SetWorkspaceVisible,
  [WhseJob140101Types.WHSE_JOB140101_PRINT_WHSE_JOB140101_LOADING]: whseJob140101PrintWhseJob140101Loading
});
