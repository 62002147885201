import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { push, replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import ItemGroup01ListActions from '../Stores/ItemGroup01List01/Actions';

const getAppStore = state => state.app;
const getItemGroup01List01Store = state => state.itemGroup01List01;

export function* itemGroup01List01FetchItemGroup01ListAll({ currentPage, sorts, filters, pageSize }) {
  try {
    const app = yield select(getAppStore);

    filters = {'division_id': app.curDivisionId};
    sorts = {'sequence': 'ascend'}
    
    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize: pageSize
    };
    console.log("Get Data: ", getData)
    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `itemGroup01/index`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    console.log("Result: ", result)
    if (result.isSuccess === true) {
      yield put(
        ItemGroup01ListActions.itemGroup01List01FetchItemGroup01ListAllSuccess(
          result.data.data,
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  }
}

export function* itemGroup01List01ReorderBrands({ brandId }) {
  yield put(ItemGroup01ListActions.itemGroup01List01FetchItemGroup01ListLoading(true));

  try {
    const app = yield select(getAppStore)
    const itemGroup01List01 = yield select(getItemGroup01List01Store)

    const postData = {
      curBrandId: brandId,
      prevBrandId: itemGroup01List01.selectedBrand,
      curDivisionId: app.curDivisionId
    }
    
    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01Division/reorderBrand`,
      app.token,
      postData,
      'multipart/form-data' // params
    );
    if (result.isSuccess === true) {
      yield put(ItemGroup01ListActions.itemGroup01List01ResetTimestamp())
      yield put(ItemGroup01ListActions.itemGroup01List01SetSelectedBrand(brandId))
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      })
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemGroup01ListActions.itemGroup01List01FetchItemGroup01ListLoading(false));
  }
}

export function* itemGroup01List01FetchItemGroup01List({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(ItemGroup01ListActions.itemGroup01List01FetchItemGroup01ListLoading(true));

    const app = yield select(getAppStore);

    filters = {'division_id': app.curDivisionId};
    sorts = {'sequence': 'ascend'}

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize: pageSize
    };
    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `itemGroup01/index`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    
    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        ItemGroup01ListActions.itemGroup01List01FetchItemGroup01ListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemGroup01ListActions.itemGroup01List01FetchItemGroup01ListLoading(false));
  }
}

export function* itemGroup01List01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/cartDetail/update/${hdrId}`));
}

export function* itemGroup01List01GoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/CART/${hdrId}/CartHdr`));
}

export function* itemGroup01List01UploadPhoto({ id, file }) {
  try {
    yield put(ItemGroup01ListActions.itemGroup01List01UploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    if (Math.sign(file.uid) !== -1) {
      postData.append('file', file.originFileObj, file.name);
    }

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/uploadPhoto/${id}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      yield put(ItemGroup01ListActions.itemGroup01List01ManagePhotoSuccess(result.data));
      yield put(ItemGroup01ListActions.itemGroup01List01ResetTimestamp());

      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemGroup01ListActions.itemGroup01List01UploadLoading(false));
  }
}

export function* itemGroup01List01RemovePhoto({ id }) {
  try {
    yield put(ItemGroup01ListActions.itemGroup01List01UploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/deletePhoto/${id}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(ItemGroup01ListActions.itemGroup01List01ManagePhotoSuccess(result.data));
      yield put(ItemGroup01ListActions.itemGroup01List01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemGroup01ListActions.itemGroup01List01UploadLoading(false));
  }
}
