import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob14DetailResetTimestamp: null,
  whseJob14DetailSetHdrId: ['hdrId'],

  whseJob14DetailInitHeader: ['siteFlowId'],
  whseJob14DetailShowHeader: ['hdrId'],
  whseJob14DetailShowHeaderSuccess: ['documentHeader'],

  whseJob14DetailUpdateHeader: ['formikBag', 'documentHeader'],
  whseJob14DetailCreateHeader: ['formikBag', 'documentHeader'],
  whseJob14DetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  whseJob14DetailShowDocumentLoading: ['boolean'],
  whseJob14DetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  whseJob14DetailShowDetails: ['hdrId'],
  whseJob14DetailShowDetailsSuccess: ['documentDetails'],

  whseJob14DetailSetDetailVisible: ['boolean'],
  whseJob14DetailSetDocumentDetail: ['documentDetail'],

  whseJob14DetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  whseJob14DetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  whseJob14DetailDeleteDetail: ['hdrId', 'documentDetail'],

  whseJob14DetailSetWhseJobType: ['whseJobType'],

  whseJob14DetailFetchCompanyOptions: ['search'],
  whseJob14DetailFetchCompanyOptionLoading: ['boolean'],
  whseJob14DetailFetchCompanyOptionSuccess: ['options'],

  whseJob14DetailFetchItemOptions: ['search'],
  whseJob14DetailFetchItemOptionLoading: ['boolean'],
  whseJob14DetailFetchItemOptionSuccess: ['options'],
  whseJob14DetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  whseJob14DetailFetchItemBatchOptions: ['itemId', 'search'],
  whseJob14DetailFetchItemBatchOptionLoading: ['boolean'],
  whseJob14DetailFetchItemBatchOptionSuccess: ['options'],
  whseJob14DetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  whseJob14DetailFetchUomOptions: ['itemId', 'search'],
  whseJob14DetailFetchUomOptionLoading: ['boolean'],
  whseJob14DetailFetchUomOptionSuccess: ['options'],
  whseJob14DetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  whseJob14DetailFetchItemCond01Options: ['search'],
  whseJob14DetailFetchItemCond01OptionLoading: ['boolean'],
  whseJob14DetailFetchItemCond01OptionSuccess: ['options'],

  whseJob14DetailFetchToStorageBinOptions: ['siteFlowId', 'search'],
  whseJob14DetailFetchToStorageBinOptionLoading: ['boolean'],
  whseJob14DetailFetchToStorageBinOptionSuccess: ['options'],

  whseJob14DetailFetchToHandlingUnitOptions: ['siteFlowId', 'search'],
  whseJob14DetailFetchToHandlingUnitOptionLoading: ['boolean'],
  whseJob14DetailFetchToHandlingUnitOptionSuccess: ['options'],

  whseJob14DetailFetchWorkerOptions: ['search'],
  whseJob14DetailFetchWorkerOptionLoading: ['boolean'],
  whseJob14DetailFetchWorkerOptionSuccess: ['options']
});

export const WhseJob14DetailTypes = Types;
export default Creators;
