import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    userDetailResetTimestamp: null,
    userDetailInitHeader: ['login_type'],
    userDetailSetHdrId: ['hdrId'],
    userDetailShowHeader: ['hdrId'],
    userDetailShowDocumentLoading: ['boolean'],
    userDetailShowHeaderSuccess: ['documentHeader'],
    
    userDetailCreateHeader: ['formikBag', 'documentHeader'],
    userDetailUpdateHeader: ['formikBag', 'documentHeader'],
    userDetailUpdateHeaderSuccess: ['documentHeader'],
    
    userDetailFetchDebtorOptions: ['search'],
    userDetailFetchDebtorOptionLoading: ['boolean'],
    userDetailFetchDebtorOptionSuccess: ['options'],
    
    userDetailGoToChangePassword: ['hdrId'],
    userDetailChangePassword: ['formikBag', 'hdrId', 'newPassword'],
});

export const UserDetailTypes = Types;
export default Creators;