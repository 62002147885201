import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Typography, Modal, Row, Col, Table, Button, Popconfirm, Select } from 'antd';

import UserList01Actions from '../../Stores/UserList01/Actions';

const { Option } = Select;
const { Title } = Typography;

class ManageRoleDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.useOnAddRole = this.useOnAddRole.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, selectedDocument, removeRole } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'name' }),
        // sort field
        dataIndex: 'name',
        sorter: (a, b) => `${a.name}`.localeCompare(b.name),
        // filter field
        key: 'name',
        render: (text, record) => (
          <>
            <b>{record.name}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_role' })}
              onConfirm={() => {
                removeRole(selectedDocument.id, record.id);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  handleCancel() {
    const { setManageRoleVisible, assignIsLoading } = this.props;

    if (assignIsLoading === false) {
      setManageRoleVisible({}, false);
    }
  }

  handleSearch(value) {
    const { selectedDocument, fetchRoleOptions } = this.props;
    fetchRoleOptions(selectedDocument.id, value);
  }

  handleChange(value, option) {
    const { setRoleOption } = this.props;
    const curOption = { value: parseInt(option.key, 10), label: option.props.children };
    setRoleOption(curOption);
  }

  useOnAddRole() {
    const { selectedDocument, roleOption, addRole } = this.props;

    if (roleOption.value > 0) {
      addRole(selectedDocument.id, roleOption.value);
    }
  }

  render() {
    const {
      intl,
      selectedDocument,
      manageRoleIsVisible,
      assignIsLoading,
      roleOption,
      roleOptions,
      roleIsLoading
    } = this.props;

    const options = roleOptions.map(d => <Option key={d.value}>{`${d.label}`}</Option>);

    return (
      <>
        <Modal
          visible={manageRoleIsVisible}
          title={`${intl.formatMessage({ id: 'manage_role' })} ${selectedDocument.username}`}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row type="flex" justify="start" gutter={[0, 8]}>
            <Col span={3}>
              <Title level={4}>{intl.formatMessage({ id: 'new_role' })}</Title>
            </Col>
            <Col span={8}>
              <Select
                showSearch
                value={roleOption.label}
                placeholder={intl.formatMessage({ id: 'key_in_code_or_description' })}
                style={{ width: 350 }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
                loading={roleIsLoading}
              >
                {options}
              </Select>
            </Col>
            <Col span={3}>
              <Button type="primary" disabled={roleOption.value === 0} onClick={this.useOnAddRole}>
                {intl.formatMessage({ id: 'add' })}
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="start" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocument.roles}
                loading={assignIsLoading}
                bordered
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

ManageRoleDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setManageRoleVisible: PropTypes.func,
  manageRoleIsVisible: PropTypes.bool,

  removeRole: PropTypes.func,
  addRole: PropTypes.func,
  selectedDocument: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.object)
  }),

  assignIsLoading: PropTypes.bool,

  fetchRoleOptions: PropTypes.func,
  setRoleOption: PropTypes.func,

  roleOption: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }),
  roleOptions: PropTypes.arrayOf(PropTypes.object),
  roleIsLoading: PropTypes.bool
};

ManageRoleDialog.defaultProps = {
  intl: {},
  setManageRoleVisible() {},
  manageRoleIsVisible: false,

  removeRole() {},
  addRole() {},
  selectedDocument: {
    id: 0,
    username: '',
    roles: []
  },

  assignIsLoading: false,

  fetchRoleOptions() {},
  setRoleOption() {},

  roleOption: { value: 0, label: '' },
  roleOptions: [],
  roleIsLoading: false
};

const mapStateToProps = state => ({
  manageRoleIsVisible: state.userList01.manageRoleIsVisible,
  selectedDocument: state.userList01.selectedDocument,
  assignIsLoading: state.userList01.assignIsLoading,

  roleOption: state.userList01.roleOption,
  roleOptions: state.userList01.roleOptions,
  roleIsLoading: state.userList01.roleIsLoading
});

const mapDispatchToProps = dispatch => ({
  setManageRoleVisible: boolean =>
    dispatch(UserList01Actions.userList01SetManageRoleVisible(boolean)),
  removeRole: (userId, roleId) => dispatch(UserList01Actions.userList01RemoveRole(userId, roleId)),
  addRole: (userId, roleId) => dispatch(UserList01Actions.userList01AddRole(userId, roleId)),
  fetchRoleOptions: (userId, search) =>
    dispatch(UserList01Actions.userList01FetchRoleOptions(userId, search)),
  setRoleOption: option => dispatch(UserList01Actions.userList01SetRoleOption(option))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ManageRoleDialog));
