import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  promoDetailResetTimestamp: null,
  promoDetailSetHdrId: ['hdrId', 'itemId'],

  promoDetailInitHeader: ['divisionId', 'promoType'],
  promoDetailShowHeader: ['hdrId'],
  promoDetailShowHeaderSuccess: ['documentHeader'],

  promoDetailCreateHeader: ['divisionId', 'formikBag', 'promoType', 'documentHeader'],

  promoDetailUpdateHeader: ['formikBag', 'documentHeader'],
  promoDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],
  promoDetailDeleteHeader: ['hdrId'],

  promoDetailShowDocumentLoading: ['boolean'],
  promoDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  promoDetailShowDetails: ['hdrId'],
  promoDetailShowDetailsSuccess: ['documentDetails'],

  promoDetailShowDetailsMain: ['hdrId'],
  promoDetailShowDetailsMainSuccess: ['documentDetails'],

  promoDetailShowDetailsAddOn: ['hdrId'],
  promoDetailShowDetailsAddOnSuccess: ['documentDetails'],

  promoDetailSetDetailVisible: ['boolean'],
  promoDetailSetDetailAddOnVisible: ['boolean'],
  promoDetailSetDocumentDetail: ['documentDetail'],

  promoDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  promoDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  promoDetailDeleteDetail: ['hdrId', 'documentDetail'],

  promoDetailUpdateDetailsSuccess: ['documentDetails'],
  promoDetailUpdateDetailsSuccessMain: ['documentDetails'],
  promoDetailUpdateDetailsSuccessAddOn: ['documentDetails'],

  promoDetailFetchItemOptions: ['divisionId', 'search'],
  promoDetailFetchItemOptionLoading: ['boolean'],
  promoDetailFetchItemOptionSuccess: ['options'],
  promoDetailChangeItem: ['formikBag', 'itemId'],
  promoDetailChangeItemGroup: ['formikBag', 'hdrId'],

  promoDetailFetchBrandOptions: ['divisionId', 'search'],
  promoDetailFetchBrandOptionLoading: ['boolean'],
  promoDetailFetchBrandOptionSuccess: ['options'],
  promoDetailChangeBrand: ['formikBag', 'hdrId', 'branId'],

  promoDetailFetchCategoryOptions: ['divisionId', 'search'],
  promoDetailFetchCategoryOptionLoading: ['boolean'],
  promoDetailFetchCategoryOptionSuccess: ['options'],
  promoDetailChangeCategory: ['formikBag', 'hdrId', 'categoryId'],

  promoDetailFetchManufacturerOptions: ['divisionId', 'search'],
  promoDetailFetchManufacturerOptionLoading: ['boolean'],
  promoDetailFetchManufacturerOptionSuccess: ['options'],
  promoDetailChangeManufacturer: ['formikBag', 'hdrId', 'manufacturerId'],
  
  promoDetailShowRules: ['hdrId'],
  promoDetailShowRulesSuccess: ['rules'],

  promoDetailSetRuleVisible: ['boolean'],
  promoDetailSetRule: ['rule'],

  promoDetailCreateRule: ['formikBag', 'hdrId', 'rule'],
  promoDetailUpdateRule: ['formikBag', 'hdrId', 'rule'],
  promoDetailDeleteRule: ['hdrId', 'rule'],
  promoDetailUpdateRuleSuccess: ['rules'],

  promoDetailFetchVariantTypeOptions: ['search'],
  promoDetailFetchVariantTypeOptionLoading: ['boolean'],
  promoDetailFetchVariantTypeOptionSuccess: ['options'],
  promoDetailChangeVariantType: ['formikBag', 'hdrId', 'variantType'],

  promoDetailFetchRuleTypeOptions: ['search'],
  promoDetailFetchRuleTypeOptionLoading: ['boolean'],
  promoDetailFetchRuleTypeOptionSuccess: ['options'],
  promoDetailChangeRuleType: ['formikBag', 'hdrId', 'ruleType'],
  
  promoDetailFetchDebtorOptions: ['divisionId', 'search'],
  promoDetailFetchBroadcastDebtorOptions: ['divisionId', 'search'],
  promoDetailFetchDebtorOptionLoading: ['boolean'],
  promoDetailFetchDebtorOptionSuccess: ['options'],
  //promoDetailChangeDebtor: ['formikBag', 'debtorId']
  
  promoDetailFetchDebtorGroupOptions: ['divisionId', 'search'],
  promoDetailFetchBroadcastDebtorGroupOptions: ['divisionId', 'search'],
  promoDetailFetchDebtorGroupOptionLoading: ['boolean'],
  promoDetailFetchDebtorGroupOptionSuccess: ['options'],

  // promoDetailSetRecipientOption: ['option'],
  promoDetailBroadcastPromo: ['formikBag', 'hdrId', 'broadcastValues'],
});

export const PromoDetailTypes = Types;
export default Creators;
