export default {
  timestamp: 0,
  hdrId: 0,
  documentIsLoading: false,
  documentDetails: [],
  detailIsVisible: false,

  documentHeader: {
    status: 3,
    str_status: 'DRAFT',
    code: '',
    ref_code_01: '',
    company_name_01: '',
    company_name_02: '',
    cmpy_register_no: '',
    tax_register_no: '',
    debtor_category_01_id: 0,
    debtor_category_02_id: 0,
    debtor_category_03_id: 0,
    area_id: 0,
    area_code: 0,
    state_id: '',
    address_01: '',
    address_02: '',
    address_03: '',
    address_04: '',
    unit_no: '',
    building_name: '',
    street_name: '',
    district_01: '',
    district_02: '',
    postcode: '',
    state_name: '',
    country_name: '',
    attention: '',
    phone_01: '',
    phone_02: '',
    fax_01: '',
    fax_02: '',
    email_01: '',
    email_02: ''
  },
}