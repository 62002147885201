import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Table, Popconfirm, Button } from 'antd';

import CycleCountDetailActions from '../../Stores/CycleCountDetail/Actions';

class JobDetailTable extends React.PureComponent {
  constructor() {
    super();

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnDeleteJob = this.useOnDeleteJob.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'right',
        title: intl.formatMessage({ id: 'job' }),
        // sort field
        dataIndex: 'job_no',
        // filter field
        key: 'job_no',
        render: (text, record) => <>{record.job_no}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'job_desc_01',
        // filter field
        key: 'job_desc_01',
        render: (text, record) => <>{record.job_desc_01}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteJob(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnDeleteJob(record) {
    const { hdrId, deleteJobDetail } = this.props;

    deleteJobDetail(hdrId, record.job_no);
  }

  render() {
    const { documentDetails, documentIsLoading } = this.props;

    const jobNoHash = {};
    const jobDetails = documentDetails.filter(curValue => {
      if (curValue.job_no in jobNoHash) {
        return false;
      }
      jobNoHash[curValue.job_no] = curValue.job_no;
      return true;
    });

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={false}
          columns={this.getDocumentColumns()}
          dataSource={jobDetails}
          loading={documentIsLoading}
          bordered
          rowClassName={rowData => {
            if ('is_modified' in rowData && rowData.is_modified === true) {
              return 'success-row';
            }
            return '';
          }}
        />
      </>
    );
  }
}

JobDetailTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  deleteJobDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentIsLoading: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),
  initDocumentDetail: PropTypes.shape({})
};

JobDetailTable.defaultProps = {
  intl: {},
  deleteJobDetail() {},
  hdrId: 0,
  documentDetails: [],
  documentIsLoading: false,
  initDocumentDetail: {}
};

const mapStateToProps = state => ({
  hdrId: state.cycleCountDetail.hdrId,
  documentDetails: state.cycleCountDetail.documentDetails,
  documentIsLoading: state.cycleCountDetail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  deleteJobDetail: (hdrId, jobNo) =>
    dispatch(CycleCountDetailActions.cycleCountDetailDeleteJobDetail(hdrId, jobNo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(JobDetailTable));
