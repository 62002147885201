import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  invAuditProcessFetchInvAuditProcess: ['siteFlowId'],
  invAuditProcessFetchInvAuditProcessLoading: ['boolean'],
  invAuditProcessFetchInvAuditProcessSuccess: ['process']
});

export const InvAuditProcessTypes = Types;
export default Creators;
