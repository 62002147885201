import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  palletLabelProcessFetchPalletLabelProcess: ['siteFlowId'],
  palletLabelProcessFetchPalletLabelProcessLoading: ['boolean'],
  palletLabelProcessFetchPalletLabelProcessSuccess: ['process']
});

export const PalletLabelProcessTypes = Types;
export default Creators;
