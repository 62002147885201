import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Row, Col, Table, Button } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import ItemGroup02ListActions from '../../Stores/ItemGroup02List01/Actions';
import ManageItemGroup02PhotoDialog from './ManageItemGroup02PhotoDialog';

class ItemGroup02IndexScreen extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchItemGroup02List,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchItemGroup02List(curDivisionId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, setManagePhotoVisible, goToAudit } = this.props;
    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.code
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_02'),
        // filter field
        key: 'desc_02',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc_02' }),
          'desc_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.desc_02
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_02'),
        // filter field
        key: 'desc_02',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc_02' }),
          'desc_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.desc_02
      },
      {
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon="edit"
              size="small"
              onClick={() => goToDocument(record.id)}
            />
            <Button
              type="dashed"
              size="small"
              icon="picture"
              onClick={() => setManagePhotoVisible(record, true)}
            />
            <Button type="dashed" icon="audit" size="small" onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;
    return (
      <Card title={intl.formatMessage({ id: 'categories' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
        <ManageItemGroup02PhotoDialog />
      </Card>
    );
  }
}

ItemGroup02IndexScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  setManagePhotoVisible: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchItemGroup02List: PropTypes.func
};

ItemGroup02IndexScreen.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  setManagePhotoVisible() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchItemGroup02List() {}
};

const mapStateToProps = state => ({
  timestamp: state.itemGroup02List01.timestamp,
  workspaceIsVisible: state.itemGroup02List01.workspaceIsVisible,

  documents: state.itemGroup02List01.documents,
  selectedDocuments: state.itemGroup02List01.selectedDocuments,

  sorts: state.itemGroup02List01.sorts,
  filters: state.itemGroup02List01.filters,

  currentPage: state.itemGroup02List01.currentPage,
  pageSize: state.itemGroup02List01.pageSize,
  total: state.itemGroup02List01.total,
  fetchIsLoading: state.itemGroup02List01.fetchIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemGroup02ListActions.itemGroup02List01ResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToDocument: hdrId => dispatch(ItemGroup02ListActions.itemGroup02List01GoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(ItemGroup02ListActions.itemGroup02List01GoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(ItemGroup02ListActions.itemGroup02List01SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(ItemGroup02ListActions.itemGroup02ListAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(ItemGroup02ListActions.itemGroup02ListRemoveSelectedDocuments(selectedDocuments)),

  fetchItemGroup02List: (divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemGroup02ListActions.itemGroup02List01FetchItemGroup02List(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  setManagePhotoVisible: (document, boolean) =>
    dispatch(ItemGroup02ListActions.itemGroup02List01SetManagePhotoVisible(document, boolean))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ItemGroup02IndexScreen));
