import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import GdsDelProcessActions from '../../Stores/GdsDelProcess/Actions';

import PickList01Table from '../PickList01/PickList01Table';
import WhseJob0301Table from '../WhseJob0301/WhseJob0301Table';
import WhseJob030102Table from '../WhseJob030102/WhseJob030102Table';
import InvDoc01Table from '../InvDoc01/InvDoc01Table';
import InvDoc0101Table from '../InvDoc0101/InvDoc0101Table';
import InvDoc0102Table from '../InvDoc0102/InvDoc0102Table';

const { TabPane } = Tabs;

class GdsDelProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchGdsDelProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchGdsDelProcess(curSiteFlowId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchGdsDelProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchGdsDelProcess(curSiteFlowId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          {process.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                >
                  <Link
                    style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                    to={`${appPath}/gdsDelProcess/index/${value.proc_type}`}
                  >
                    <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                  </Link>
                </Tooltip>
              }
              key={value.proc_type}
            />
          ))}
        </Tabs>

        {match.params.proc_type === 'PICK_LIST_01' && <PickList01Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_03_01' && <WhseJob0301Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_03_01_02' && <WhseJob030102Table match={match} />}
        {match.params.proc_type === 'INV_DOC_01' && <InvDoc01Table match={match} />}
        {match.params.proc_type === 'INV_DOC_01_01' && <InvDoc0101Table match={match} />}
        {match.params.proc_type === 'INV_DOC_01_02' && <InvDoc0102Table match={match} />}
      </Card>
    );
  }
}

GdsDelProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number,
  fetchGdsDelProcess: PropTypes.func
};

GdsDelProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curSiteFlowId: 0,
  fetchGdsDelProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.gdsDelProcess.process,
  processIsLoading: state.gdsDelProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchGdsDelProcess: siteFlowId =>
    dispatch(GdsDelProcessActions.gdsDelProcessFetchGdsDelProcess(siteFlowId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GdsDelProcessScreen));
