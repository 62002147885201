import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { IntlProvider, FormattedMessage } from 'react-intl';

import { Row, Col, Layout, Menu, Icon, Dropdown, Button } from 'antd';
import { history } from '../../Stores/CreateStore';

import AppNavigator from '../../Navigators/AppNavigator';
import languageObject from '../../Translations';
import StartupActions from '../../Stores/Startup/Actions';
import SiteFlowSelect from './SiteFlowSelect';
import DivisionSelect from './DivisionSelect';
import AppActions from '../../Stores/App/Actions';

const { Header, Sider, Content, Footer } = Layout;

const { SubMenu } = Menu;

const menuKeys = {
  dashboard: '1',

  sales: '2',
  debtorProcess: '2.1',
  deliveryPointProcess: '2.2',
  salesProcess: '2.3',
  slsRtnProcess: '2.4',
  salesReports: '2.5',
  outbOrdAnalysisReport: '2.5.1',
  salesDocuments: '2.6',
  cartIndex: '2.6.1',
  slsOrdIndex: '2.6.2',
  slsInvIndex: '2.6.3',
  slsRtnIndex: '2.6.4',
  rtnRcptIndex: '2.6.5',
  debtorProcessDeleted: '2.7',
  admin: '2.8',

  purchase: '3',
  creditors: '3.1',
  purchaseProcess: '3.2',
  purchaseDocuments: '3.3',
  advShipIndex: '3.3.1',

  outbound: '4',
  gdsDelProcess: '4.1',
  outboundDocuments: '4.2',
  outbOrdIndex: '4.2.1',
  pickListIndex: '4.2.2',
  invDoc0102: '4.2.3',

  inbound: '5',
  gdsRcptProcess: '5.1',
  rtnRcptProcess: '5.2',
  inboundDocuments: '5.3',
  inbOrdIndex: '5.3.1',
  gdsRcptIndex: '5.3.2',
  putAwayIndex: '5.3.3',

  internalMovement: '6',
  binTrfProcess: '6.1',
  invAuditProcess: '6.2',
  internalMovementReports: '6.3',
  stockBalanceReport: '6.3.1',
  stockCardReport: '6.3.2',
  reservedStockReport: '6.3.3',
  cycleCountAnalysisReport: '6.3.4',
  countAdjAnalysisReport: '6.3.5',
  internalMovementDocuments: '6.4',
  cycleCountIndex: '6.4.1',
  countAdjIndex: '6.4.2',
  binTrfIndex: '6.4.3',

  warehouseSetup: '7',
  warehouseMap: '7.1',
  itemProcess: '7.2',
  storageBinProcess: '7.3',
  palletLabelProcess: '7.4',
  pickFaceStrategyProcess: '7.5',
  itemGroup01Index: '7.6',
  itemGroup02Index: '7.7',
  itemGroup03Index: '7.8',
  marketingTools: '7.9',
  promoDiscIndex: '7.9.1',
  promoFocIndex: '7.9.2',
  promoAllInedx: `7.9.3`,
  promoBundleIndex: '7.9.3',
  promoProcess: '7.9.4',
  itemProcessDeleted: '7.10',

  setup: '8',
  companyProcess: '8.1',
  divisionProcess: '8.2',

  settings: '9',
  userProcess: '9.1',
  roleProcess: '9.2',

  userMenu: '10',
  changePassword: '10.1',
  logout: '10.2',

  legal: '11',
  privacyPolicy: '11.1',
  termsConditions: '11.2',
};

const rootSubmenuKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

class RootScreen extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      collapsed: false,
      openKeys: [],
      selectedKeys: [],
      prevOpenKeys: []
    };

    this.useOnToggle = this.useOnToggle.bind(this);
    this.useOnOpenChange = this.useOnOpenChange.bind(this);
    this.useOnSelect = this.useOnSelect.bind(this);
  }

  componentDidMount() {
    const { pathname, startup } = this.props;
    const { location } = history;

    // Run the startup saga when the application is starting
    startup(pathname);

    const pathnameParts = location.pathname.split('/');
    // convert pathname to menukey
    const pathnameKey = pathnameParts.reduce((lastKey, path) => {
      if (menuKeys[path]) {
        return menuKeys[path];
      }
      return lastKey;
    }, '');
    // find out the openKeys based on menukey
    const pathnameOpenKeys = [];
    if (pathnameKey && pathnameKey.includes('.')) {
      const pathnameKeyParts = pathnameKey.split('.');
      pathnameKeyParts.forEach((part, index) => {
        let tmpOpenKey = '';
        for (let i = 0; i < index + 1; i += 1) {
          if (tmpOpenKey.length > 0) {
            tmpOpenKey += `.${pathnameKeyParts[i]}`;
          } else {
            tmpOpenKey += pathnameKeyParts[i];
          }
        }
        pathnameOpenKeys.push(tmpOpenKey);
      });
    }
    this.setState({ openKeys: pathnameOpenKeys, selectedKeys: [pathnameKey] });
  }

  useOnToggle() {
    const { collapsed, openKeys, prevOpenKeys } = this.state;

    this.setState({
      collapsed: !collapsed,
      prevOpenKeys: openKeys,
      openKeys: []
    });

    if(collapsed) {
      this.setState({
        openKeys: prevOpenKeys
      })
    }
  }

  useOnOpenChange(openKeys) {
    const { openKeys: sOpenKeys } = this.state;

    // make sure open current submenu only
    const latestOpenKey = openKeys.find(key => sOpenKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      // this is current submenu
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  }

  useOnSelect(item) {
    this.setState({ selectedKeys: [item.key] });
  }

  render() {
    const { token, locale, appPath, user, logout } = this.props;
    const { collapsed, openKeys, selectedKeys } = this.state;

    const userMenu = (
      <Menu>
        <Menu.Item key={menuKeys.changePassword}>
          <Link to={`${appPath}/changePassword`}>
            <Button type="link">
              <FormattedMessage id="change_password" />
            </Button>
          </Link>
        </Menu.Item>
        <Menu.Item key={menuKeys.logout}>
          <Button type="link" onClick={logout}>
            <FormattedMessage id="logout" />
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <IntlProvider locale={locale} messages={languageObject[locale]}>
        <ConnectedRouter history={history}>
          <Layout>
            <Sider
              width={250}
              style={{ height: '100vh', position: 'fixed', overflow: 'auto', left: '0'}}
              trigger={null}
              collapsible
              collapsed={collapsed}
              theme="dark"
            >
              <div
                style={{
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                {/* <img width="50px" src={`${appPath}/logo.png`} alt="logo" /> */}
              </div>
              <Menu
                openKeys={openKeys}
                onOpenChange={this.useOnOpenChange}
                onSelect={this.useOnSelect}
                theme="dark"
                mode="inline"
                inlineIndent={12}
                selectedKeys={selectedKeys}
              >
                <Menu.Item key={menuKeys.dashboard}>
                  <Link to={`${appPath}/dashboard`}>
                    <Icon type="dashboard" />
                    <span>
                      <FormattedMessage id="dashboard" />
                    </span>
                  </Link>
                </Menu.Item>
                <SubMenu
                  key={menuKeys.sales}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="transaction" />
                      <span>
                        <FormattedMessage id="sales" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.salesProcess}>
                    <Link to={`${appPath}/salesProcess/index/SLS_ORD_SYNC_02`}>
                      <FormattedMessage id="order_process" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.debtors}>
                    <Link to={`${appPath}/debtorProcess/index/DEBTOR_LIST_01`}>
                      <FormattedMessage id="debtors" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key={menuKeys.admin}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="user" />
                        <span>
                          <FormattedMessage id="Admin" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.debtorsDeleted}>
                      <Link to={`${appPath}/debtorProcessDeleted/index/DEBTOR_LIST_01`}>
                        <FormattedMessage id="debtorsDeleted" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* <SubMenu
                    key={menuKeys.salesReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="snippets" />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.outbOrdAnalysisReport}>
                      <Link to={`${appPath}/outbOrdAnalysisReport`}>
                        <FormattedMessage id="outbound_order_analysis" />
                      </Link>
                    </Menu.Item>
                  </SubMenu> */}
                  <SubMenu
                    key={menuKeys.salesDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="folder-open" />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.cartIndex}>
                      <Link to={`${appPath}/cartIndex`}>
                        <FormattedMessage id="cart" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.slsOrdIndex}>
                      <Link to={`${appPath}/slsOrdIndex`}>
                        <FormattedMessage id="sales_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.slsInvIndex}>
                      <Link to={`${appPath}/slsInvIndex`}>
                        <FormattedMessage id="sales_invoice" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
                {/* <SubMenu
                  key={menuKeys.purchase}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="shopping-cart" />
                      <span>
                        <FormattedMessage id="purchase" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.creditors}>
                    <FormattedMessage id="creditors" />
                  </Menu.Item>
                  <Menu.Item key={menuKeys.purchaseProcess}>
                    <Link to={`${appPath}/purchaseProcess/index/INB_ORD_01`}>
                      <FormattedMessage id="purchasing" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.purchaseDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="folder-open" />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.advShipIndex}>
                      <Link to={`${appPath}/advShipIndex`}>
                        <FormattedMessage id="adv_ship_notice" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu> */}

                {/* <SubMenu
                  key={menuKeys.outbound}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="export" />
                      <span>
                        <FormattedMessage id="outbound" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.gdsDelProcess}>
                    <Link to={`${appPath}/gdsDelProcess/index/PICK_LIST_01`}>
                      <FormattedMessage id="goods_delivery" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.outboundDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="folder-open" />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.outbOrdIndex}>
                      <Link to={`${appPath}/outbOrdIndex`}>
                        <FormattedMessage id="outbound_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.pickListIndex}>
                      <Link to={`${appPath}/pickListIndex`}>
                        <FormattedMessage id="pick_list" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.invDoc0102}>
                      <Link to={`${appPath}/gdsDelProcess/index/INV_DOC_01_02`}>
                        <FormattedMessage id="reprint_shipping_document" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu> */}

                {/* <SubMenu
                  key={menuKeys.inbound}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="import" />
                      <span>
                        <FormattedMessage id="inbound" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.gdsRcptProcess}>
                    <Link to={`${appPath}/gdsRcptProcess/index/GDS_RCPT_01`}>
                      <FormattedMessage id="goods_receipt" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.rtnRcptProcess}>
                    <Link to={`${appPath}/rtnRcptProcess/index/GDS_RCPT_02`}>
                      <FormattedMessage id="return_receipt" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.inboundDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="folder-open" />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.inbOrdIndex}>
                      <Link to={`${appPath}/inbOrdIndex`}>
                        <FormattedMessage id="inbound_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsRcptIndex}>
                      <Link to={`${appPath}/gdsRcptIndex`}>
                        <FormattedMessage id="goods_receipt" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.putAwayIndex}>
                      <Link to={`${appPath}/putAwayIndex`}>
                        <FormattedMessage id="put_away" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu> */}

                {/* <SubMenu
                  key={menuKeys.internalMovement}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="audit" />
                      <span>
                        <FormattedMessage id="internal_movement" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.binTrfProcess}>
                    <Link to={`${appPath}/binTrfProcess/index/BIN_TRF_02`}>
                      <FormattedMessage id="bin_to_bin_transfer" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.invAuditProcess}>
                    <Link to={`${appPath}/invAuditProcess/index/CYCLE_COUNT_03`}>
                      <FormattedMessage id="inventory_audit" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.internalMovementReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="snippets" />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.stockBalanceReport}>
                      <Link to={`${appPath}/stockBalanceReport`}>
                        <FormattedMessage id="stock_balance" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stockCardReport}>
                      <Link to={`${appPath}/stockCardReport`}>
                        <FormattedMessage id="stock_card" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.reservedStockReport}>
                      <Link to={`${appPath}/reservedStockReport`}>
                        <FormattedMessage id="reserved_stock" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.cycleCountAnalysisReport}>
                      <Link to={`${appPath}/cycleCountAnalysisReport`}>
                        <FormattedMessage id="cycle_count_analysis" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.countAdjAnalysisReport}>
                      <Link to={`${appPath}/countAdjAnalysisReport`}>
                        <FormattedMessage id="count_adjustment_analysis" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={menuKeys.internalMovementDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="folder-open" />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.cycleCountIndex}>
                      <Link to={`${appPath}/cycleCountIndex`}>
                        <FormattedMessage id="cycle_count" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.countAdjIndex}>
                      <Link to={`${appPath}/countAdjIndex`}>
                        <FormattedMessage id="count_adjustment" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.binTrfIndex}>
                      <Link to={`${appPath}/binTrfIndex`}>
                        <FormattedMessage id="bin_transfer" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu> */}

                <SubMenu
                  key={menuKeys.warehouseSetup}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="shop" />
                      <span>
                        <FormattedMessage id="item_setup" />
                      </span>
                    </>
                  }
                >
                  {/* <Menu.Item key={menuKeys.warehouseMap}>
                    <Link to={`${appPath}/warehouseMap`}>
                      <FormattedMessage id="warehouse_map" />
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key={menuKeys.itemProcess}>
                    <Link to={`${appPath}/itemProcess/index/ITEM_LIST_01`}>
                      <FormattedMessage id="stock_items" />
                    </Link>
                  </Menu.Item>
                  
                  {/* <Menu.Item key={menuKeys.storageBinProcess}>
                    <Link to={`${appPath}/storageBinProcess/index/STORAGE_BIN_LIST_01`}>
                      <FormattedMessage id="storage_bins" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.palletLabelProcess}>
                    <Link to={`${appPath}/palletLabelProcess/index/PALLET_LABEL_LIST_01`}>
                      <FormattedMessage id="pallet_labels" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.pickFaceStrategyProcess}>
                    <Link
                      to={`${appPath}/pickFaceStrategyProcess/index/PICK_FACE_STRATEGY_LIST_01`}
                    >
                      <FormattedMessage id="pick_face_strategy" />
                    </Link>
                  </Menu.Item> */}

                  <Menu.Item key={menuKeys.itemGroup01Index}>
                    <Link to={`${appPath}/itemGroup01Index`}>
                      <FormattedMessage id="brands" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={menuKeys.itemGroup02Index}>
                    <Link to={`${appPath}/itemGroup02Index`}>
                      <FormattedMessage id="categories" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={menuKeys.itemGroup03Index}>
                    <Link to={`${appPath}/itemGroup03Index`}>
                      <FormattedMessage id="manufacturers" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.marketingTools}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="notification" />
                        <span>
                          <FormattedMessage id="marketing_tools" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.promoAllIndex}>
                      <Link to={`${appPath}/promoAllIndex`}>
                        <FormattedMessage id="sort_banner" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.promoDiscIndex}>
                      <Link to={`${appPath}/promoDiscIndex`}>
                        <FormattedMessage id="promos_disc" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key={menuKeys.promoFocIndex}>
                      <Link to={`${appPath}/promoFocIndex`}>
                        <FormattedMessage id="promos_foc" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key={menuKeys.promoProcess}>
                      <Link to={`${appPath}/promoProcess/index/PROMO_SYNC_01`}>
                        <FormattedMessage id="promos_sync" />
                      </Link>
                    </Menu.Item>

                    {/* <Menu.Item key={menuKeys.promoBundleIndex}>
                      <Link to={`${appPath}/promoDiscIndex`}>
                        <FormattedMessage id="bundle_deals" />
                      </Link>
                    </Menu.Item> */}
                  </SubMenu>
                  <SubMenu
                    key={menuKeys.admin}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <Icon type="user" />
                        <span>
                          <FormattedMessage id="Admin" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.itemProcessDeleted}>
                      <Link to={`${appPath}/itemProcessDeleted/index/ITEM_LIST_01`}>
                        <FormattedMessage id="deleted_items" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
                <SubMenu
                  key={menuKeys.setup}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="setting" />
                      <span>
                        <FormattedMessage id="setup" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.companyProcess}>
                    <Link to={`${appPath}/company/index`}>
                      <FormattedMessage id="companies" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.divisionProcess}>
                    <Link to={`${appPath}/division/index`}>
                      <FormattedMessage id="divisions" />
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key={menuKeys.settings}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="setting" />
                      <span>
                        <FormattedMessage id="settings" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.userProcess}>
                    <Link to={`${appPath}/userProcess/index/USER_LIST_01`}>
                      <FormattedMessage id="user_management" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.roleProcess}>
                    <Link to={`${appPath}/roleProcess/index/ROLE_LIST_01`}>
                      <FormattedMessage id="role_management" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key={menuKeys.legal}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Icon type="copy" />
                      <span>
                        <FormattedMessage id="legal" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.privacyPolicy}>
                    <Link to={`${appPath}/legal/PRIVACY_POLICY`}>
                      <FormattedMessage id="privacy_policy" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.termsConditions}>
                    <Link to={`${appPath}/legal/TERMS_AND_CONDITIONS`}>
                      <FormattedMessage id="terms_conditions" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>
            <Layout style={{ marginLeft: collapsed ? 80 : 250 }}>
              <Header style={{ background: '#fff', padding: 10 }}>
                <Row type="flex" justify="space-between" gutter={[8, 8]}>
                  <Col span={1}>
                    <Icon
                      className="trigger"
                      type={collapsed ? 'menu-unfold' : 'menu-fold'}
                      onClick={this.useOnToggle}
                    />
                  </Col>
                  {token ? (
                    <>
                      <Col span={18} style={{ textAlign: 'left' }}>
                        <DivisionSelect />
                        <SiteFlowSelect />
                      </Col>
                      <Col span={3}>
                        <Dropdown overlay={userMenu} trigger={['click']}>
                          <Button type="link" icon="user">
                            {user.username}
                          </Button>
                        </Dropdown>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Row>
              </Header>
              <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                <AppNavigator appPath={appPath} />
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                BizOrder ©2020 Created by Solutions Sdn Bhd
              </Footer>
            </Layout>
          </Layout>
        </ConnectedRouter>
      </IntlProvider>
    );
  }
}

RootScreen.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string
  }),
  appPath: PropTypes.string,
  pathname: PropTypes.string,
  locale: PropTypes.string,
  token: PropTypes.string,
  startup: PropTypes.func,
  logout: PropTypes.func
};

RootScreen.defaultProps = {
  user: {
    username: ''
  },
  appPath: '',
  pathname: '',
  locale: '',
  token: '',
  startup() {},
  logout() {}
};

const mapStateToProps = state => ({
  user: state.app.user,
  pathname: state.router.location.pathname,
  locale: state.app.locale,
  appPath: state.app.appPath,
  token: state.app.token
});

const mapDispatchToProps = dispatch => ({
  startup: pathname => dispatch(StartupActions.startup(pathname)),
  logout: () => dispatch(AppActions.appLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootScreen);
