import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  promoFocListResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  promoFocListGoToDocument: ['hdrId'],
  promoFocListNewDocument: ['promoType'],
  promoFocListGoToAudit: ['divisionId'],
  promoFocListFetchPromoList: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize', 'filterStr'],
  promoFocListFetchPromoListLoading: ['boolean'],
  promoFocListFetchPromoListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize',
    'filterStr'
  ],
  promoFocListAddSelectedDocuments: ['selectedDocuments'],
  promoFocListRemoveSelectedDocuments: ['selectedDocuments']
});

export const PromoFocListTypes = Types;
export default Creators;
