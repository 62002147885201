import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import InvDoc0102Actions from '../../Stores/InvDoc0102/Actions';

class InvDoc0102Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnPrintDocument = this.useOnPrintDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnPrintDocument() {
    const { selectedDocuments, printInvDoc0102, curSiteFlowId } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    printInvDoc0102(curSiteFlowId, hdrIds);
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, printIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'print_shipping_documents' })}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="print"
              disabled={selectedDocuments.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintDocument}
            >
              {intl.formatMessage({ id: 'print' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={printIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

InvDoc0102Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  printIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,

  printInvDoc0102: PropTypes.func
};

InvDoc0102Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  printIsLoading: false,

  curSiteFlowId: 0,

  printInvDoc0102() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.invDoc0102.workspaceIsVisible,

  selectedDocuments: state.invDoc0102.selectedDocuments,

  printIsLoading: state.invDoc0102.printIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(InvDoc0102Actions.invDoc0102SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(InvDoc0102Actions.invDoc0102RemoveSelectedDocuments(selectedDocuments)),

  printInvDoc0102: (curSiteFlowId, hdrIds) =>
    dispatch(InvDoc0102Actions.invDoc0102PrintInvDoc0102(curSiteFlowId, hdrIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InvDoc0102Dialog));
