import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd01GoToDocument: ['hdrId'],
  inbOrd01FetchInbOrd01: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd01FetchInbOrd01Loading: ['boolean'],
  inbOrd01FetchInbOrd01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd01AddSelectedDocuments: ['selectedDocuments'],
  inbOrd01RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd01SetWorkspaceVisible: ['boolean'],
  inbOrd01CreateInbOrd01: ['hdrIds'],
  inbOrd01CreateInbOrd01Loading: ['boolean'],
  inbOrd01CreateInbOrd01Success: ['newDocuments']
});

export const InbOrd01Types = Types;
export default Creators;
