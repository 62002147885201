/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DebtorList01Types } from './Actions';
import INITIAL_STATE from './InitialState';
import { debtorList01ChangeStatus } from '../../Sagas/DebtorList01Saga';

export const debtorList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const debtorList01FetchDebtorList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const debtorList01FetchDebtorList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const debtorList01AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const debtorList01RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const debtorList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const debtorList01SetManageDivisionVisible = (state, { document, boolean }) => ({
  ...state,
  selectedDocument: document,
  manageDivisionIsVisible: boolean
});

export const debtorList01ManageDivisionLoading = (state, { boolean }) => ({
  ...state,
  assignIsLoading: boolean
});

export const debtorList01ManageDivisionSuccess = (state, { divisions }) => ({
  ...state,
  selectedDocument: {
    ...state.selectedDocument,
    divisions
  }
});

export const debtorList01FetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const debtorList01FetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const debtorList01SetDivisionOption = (state, { option }) => ({
  ...state,
  divisionOption: option
});

export const debtorList01SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DebtorList01Types.DEBTOR_LIST01_RESET_TIMESTAMP]: debtorList01ResetTimestamp,
  [DebtorList01Types.DEBTOR_LIST01_FETCH_DEBTOR_LIST01_LOADING]: debtorList01FetchDebtorList01Loading,
  [DebtorList01Types.DEBTOR_LIST01_FETCH_DEBTOR_LIST01_SUCCESS]: debtorList01FetchDebtorList01Success,
  [DebtorList01Types.DEBTOR_LIST01_ADD_SELECTED_DOCUMENTS]: debtorList01AddSelectedDocuments,
  [DebtorList01Types.DEBTOR_LIST01_REMOVE_SELECTED_DOCUMENTS]: debtorList01RemoveSelectedDocuments,
  [DebtorList01Types.DEBTOR_LIST01_SET_WORKSPACE_VISIBLE]: debtorList01SetWorkspaceVisible,

  [DebtorList01Types.DEBTOR_LIST01_SET_MANAGE_DIVISION_VISIBLE]: debtorList01SetManageDivisionVisible,
  [DebtorList01Types.DEBTOR_LIST01_MANAGE_DIVISION_LOADING]: debtorList01ManageDivisionLoading,
  [DebtorList01Types.DEBTOR_LIST01_MANAGE_DIVISION_SUCCESS]: debtorList01ManageDivisionSuccess,

  [DebtorList01Types.DEBTOR_LIST01_FETCH_DIVISION_OPTION_LOADING]: debtorList01FetchDivisionOptionLoading,
  [DebtorList01Types.DEBTOR_LIST01_FETCH_DIVISION_OPTION_SUCCESS]: debtorList01FetchDivisionOptionSuccess,
  [DebtorList01Types.DEBTOR_LIST01_SET_DIVISION_OPTION]: debtorList01SetDivisionOption,

  [DebtorList01Types.DEBTOR_LIST01_SET_EXPANDED_ROWS]: debtorList01SetExpandedRows,
});
