import { put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SlsOrdSync02Actions from '../Stores/SlsOrdSync02/Actions';

const getAppStore = state => state.app;

export function* slsOrdSync02FetchSlsOrdIndex({
  divisionId,
  currentPage,
  sorts,
  filters,
  criteria,
  pageSize
}) {
  try {
    yield put(SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndexLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    var filterHasStatus = false;
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
      if (key == 'status') {
        filterHasStatus = true;
      }
    });
    if (!filterHasStatus) {
      processedFilters.push(`status:3,50`);
    }
    
    const objCriteria = [];
    if ('start_date' in criteria) {
      objCriteria.push(`start_date:${criteria.start_date}`);
    }
    if ('end_date' in criteria) {
      objCriteria.push(`end_date:${criteria.end_date}`);
    }

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      criteria: objCriteria,
      pageSize
    };
    // console.log(getData);

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `slsOrd/index/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndexSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndexLoading(false));
  }
}

export function* slsOrdSync02GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/slsOrdDetail/update/${hdrId}`));
}

export function* slsOrdSync02FetchSlsOrdIndexSearch({ 
  formikBag, 
  criteria,
  divisionId,
  currentPage,
  sorts,
  filters,
  pageSize }) {
  try {
    formikBag.setSubmitting(true);
    yield put(SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndexLoading(true));

    // const objCriteria = {};
    // if ('date' in criteria) {
    //   objCriteria.date = criteria.date;
    // }
    const objCriteria = [];
    if ('start_date' in criteria) {
      objCriteria.push(`start_date:${criteria.start_date}`);
    }
    if ('end_date' in criteria) {
      objCriteria.push(`end_date:${criteria.end_date}`);
    }

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    var filterHasStatus = false;
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
      if (key == 'status') {
        filterHasStatus = true;
      }
    });
    if (!filterHasStatus) {
      processedFilters.push(`status:3,50`);
    }

    const app = yield select(getAppStore);
    
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      criteria: objCriteria,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `slsOrd/index/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndexSearchSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page,
          criteria
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(SlsOrdSync02Actions.slsOrdSync02FetchSlsOrdIndexLoading(false));
  }
}

export function* slsOrdSync02ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/SLS_ORD_SYNC_02`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SlsOrdSync02Actions.slsOrdSync02ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  }
}

export function* slsOrdSync02SyncNow({ divisionId, selectedDocuments }) {
  try {
    // formikBag.setSubmitting(true);
    yield put(SlsOrdSync02Actions.slsOrdSync02SyncLoading(true));
    
    const hdrIds = [];
    selectedDocuments.forEach(entry => {
        hdrIds.push(entry.id);
    });
    const app = yield select(getAppStore);
    
    const getData = {
      data: hdrIds
    };
    console.log('slsOrd/syncProcess/SLS_ORD_SYNC_02/');
    console.log(divisionId);

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `slsOrd/syncProcess/SLS_ORD_SYNC_02/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );


    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.data.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
      yield put(SlsOrdSync02Actions.slsOrdSync02ResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    // formikBag.setSubmitting(false);
    yield put(SlsOrdSync02Actions.slsOrdSync02SyncLoading(false));
  }
}
