import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Table, Popconfirm, Button } from 'antd';

import PickListDetailActions from '../../Stores/PickListDetail/Actions';

class DetailTable extends React.PureComponent {
  constructor() {
    super();

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnSelectItem = this.useOnSelectItem.bind(this);
    this.useCreateDetail = this.useCreateDetail.bind(this);
    this.useOnDeleteItem = this.useOnDeleteItem.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showDetails } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      if (hdrId > 0) {
        showDetails(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 50,
        fixed: 'left',
        align: 'right',
        title: '',
        // sort field
        dataIndex: 'line_no',
        // filter field
        key: 'line_no',
        render: (text, record) => <>{record.line_no}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'from_storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_code',
        // filter field
        key: 'storage_bin_code',
        render: (text, record) => <>{record.storage_bin_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'pallet_id' }),
        // sort field
        dataIndex: 'handling_unit_barcode',
        // filter field
        key: 'handling_unit_barcode',
        render: (text, record) => <>{record.handling_unit_barcode}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'expiry_date' }),
        // sort field
        dataIndex: 'expiry_date',
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'case' }),
        // sort field
        dataIndex: 'case_qty',
        // filter field
        key: 'case_qty',
        render: (text, record) =>
          record.case_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_qty)}
              <br />
              {record.item_case_uom_code}
            </>
          ) : (
            ''
          )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'loose' }),
        // sort field
        dataIndex: 'loose_qty',
        // filter field
        key: 'loose_qty',
        render: (text, record) =>
          record.loose_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.loose_qty)}
              <br />
              {record.item_loose_uom_code}
            </>
          ) : (
            ''
          )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'to_storage_bin' }),
        // sort field
        dataIndex: 'to_storage_bin_code',
        // filter field
        key: 'to_storage_bin_code',
        render: (text, record) => <>{record.to_storage_bin_code}</>
      },
      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon="edit" onClick={() => this.useOnSelectItem(record)} />
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItem(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnSelectItem(record) {
    const { setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(record);

    setDetailVisible(true);
  }

  useOnDeleteItem(record) {
    const { hdrId, deleteDetail } = this.props;

    deleteDetail(hdrId, record);
  }

  useCreateDetail() {
    const { initDocumentDetail, setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(initDocumentDetail);

    setDetailVisible(true);
  }

  render() {
    const { intl, hdrId, documentDetails, documentIsLoading } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{ pageSize: 20 }}
          columns={this.getDocumentColumns()}
          dataSource={documentDetails}
          loading={documentIsLoading}
          bordered
          rowClassName={rowData => {
            if ('is_modified' in rowData && rowData.is_modified === true) {
              return 'success-row';
            }
            return '';
          }}
          title={() => (
            <Button
              name="add_detail"
              type="primary"
              icon="plus"
              disabled={hdrId === 0}
              loading={documentIsLoading}
              onClick={this.useCreateDetail}
            >
              {intl.formatMessage({ id: 'add' })}
            </Button>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

DetailTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showDetails: PropTypes.func,
  setDocumentDetail: PropTypes.func,
  setDetailVisible: PropTypes.func,
  deleteDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentIsLoading: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),
  initDocumentDetail: PropTypes.shape({})
};

DetailTable.defaultProps = {
  intl: {},
  showDetails() {},
  setDocumentDetail() {},
  setDetailVisible() {},
  deleteDetail() {},
  hdrId: 0,
  documentDetails: [],
  documentIsLoading: false,
  initDocumentDetail: {}
};

const mapStateToProps = state => ({
  timestamp: state.pickListDetail.timestamp,
  hdrId: state.pickListDetail.hdrId,
  documentDetails: state.pickListDetail.documentDetails,
  initDocumentDetail: state.pickListDetail.initDocumentDetail,
  documentIsLoading: state.pickListDetail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  showDetails: hdrId => dispatch(PickListDetailActions.pickListDetailShowDetails(hdrId)),
  setDetailVisible: boolean =>
    dispatch(PickListDetailActions.pickListDetailSetDetailVisible(boolean)),
  setDocumentDetail: documentDetail =>
    dispatch(PickListDetailActions.pickListDetailSetDocumentDetail(documentDetail)),
  deleteDetail: (hdrId, documentDetail) =>
    dispatch(PickListDetailActions.pickListDetailDeleteDetail(hdrId, documentDetail))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DetailTable));
