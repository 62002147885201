/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  hdrId: 0,
  documentIsLoading: false,
  documentDetails: [],
  pageSize: '20',
  workspaceIsVisible: false,

  newDocuments: []
};
