import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1501ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1501GoToDocument: ['hdrId'],
  whseJob1501FetchWhseJob1501: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1501FetchWhseJob1501Loading: ['boolean'],
  whseJob1501FetchWhseJob1501Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob1501AddSelectedDocuments: ['selectedDocuments'],
  whseJob1501RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1501SetWorkspaceVisible: ['boolean'],
  whseJob1501CreateWhseJob1501: ['hdrIds'],
  whseJob1501CreateWhseJob1501Loading: ['boolean'],
  whseJob1501CreateWhseJob1501Success: ['newDocuments']
});

export const WhseJob1501Types = Types;
export default Creators;
