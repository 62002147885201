import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { Row, Col, Table, Button, Badge, Card, Dropdown, Icon, Menu, Popconfirm, Modal } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import ItemList01Actions from '../../Stores/ItemList01/Actions';
import ItemPriceActions from '../../Stores/ItemPrice/Actions';

import ItemPriceForm from './ItemPriceForm';
import ManagePhotoDialog from './ManagePhotoDialog';

class ItemList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);

    this.useOnCreatePrice = this.useOnCreatePrice.bind(this);
    this.useOnSelectPrice = this.useOnSelectPrice.bind(this);
    this.useOnDeleteUOM = this.useOnDeleteUOM.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0 || curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      curSiteFlowId,
      resetTimestamp,
      fetchItemList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);


    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0 || curDivisionId > 0) {
        fetchItemList01(curDivisionId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}
  
  useOnCreatePrice(itemId) {
    const { itemPriceSetVisible, itemPriceInitDocumentDetail } = this.props;

    itemPriceInitDocumentDetail(itemId);
    itemPriceSetVisible(true);
  }

  useOnSelectPrice(record) {
    const { itemPriceSetVisible, itemPriceSetDocumentDetail } = this.props;

    itemPriceSetDocumentDetail(record);
    itemPriceSetVisible(true);
  }

  useOnDeleteUOM(record) {
    const { itemPriceDelete } = this.props;
    itemPriceDelete(record.id);
    
  }


  handleCancel() {
    const { itemPriceSetVisible } = this.props;

    itemPriceSetVisible(false);
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit, setManagePhotoVisible, updateItem } = this.props;

    function menu(record) { return (
      <Menu>
        <Menu.Item key="0">
          <a onClick={() => goToDocument(record.id)}><Icon type="edit" /> Edit</a>
        </Menu.Item>
        <Menu.Item key="1">
          <a onClick={() => setManagePhotoVisible(record, true)}><Icon type="picture" /> Photos</a>
        </Menu.Item>
        {
          record.str_status != "ACTIVE" &&
          <Menu.Item key="2">
            <a onClick={() => updateItem(record.id, "ACTIVE")}><Icon type="check" /> Set Active</a>
          </Menu.Item>
        }
        {
          record.str_status != "INACTIVE" &&
          <Menu.Item key="3">
            <a onClick={() => updateItem(record.id, "INACTIVE")}><Icon type="stop" /> Set Inactive</a>
          </Menu.Item>

          
        }
          <Menu.Item key="4">
          
          <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() =>  updateItem(record.id, "DELETED")}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <a ><Icon type="delete" /> Delete</a>
            </Popconfirm>
          
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="5">
          <a onClick={() => goToAudit(record.id)}><Icon type="audit" /> Audit</a>
        </Menu.Item>
      </Menu>
    )};

    return [
      {
        width: 75,
        align: 'left',
        // sort field
        dataIndex: 'image',
        render: (text, record) => (
          <>
          <Badge count={record.promotions.length} style={{ backgroundColor: '#52c41a', marginTop: 5 }}>
            <a onClick={() => setManagePhotoVisible(record, true)}>
            {record.photos.length > 0 ? 
              <img style={{width: 50, height: 50}} alt={record.photos[0].path} src={record.photos[0].path} /> 
              : <div style={{width: 50, height: 50, backgroundColor: '#D5D8DC', display: 'flex', justifyContent: 'center', alignItems: 'center' , color: '#FDFEFE', fontSize: 24}}>?</div>
            }
            </a>
          </Badge>
          </>
          )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'description' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'brand' }),
        // sort field
        dataIndex: 'item_group_01_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'item_group_01_code'),
        // filter field
        key: 'item_group_01_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'brand' }),
          'item_group_01_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.item_group_01_code}</>
      },
      {
        width: 130,
        align: 'left',
        title: intl.formatMessage({ id: 'category' }),
        // sort field
        dataIndex: 'item_group_02_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'item_group_02_code'),
        // filter field
        key: 'item_group_02_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'category' }),
          'item_group_02_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.item_group_02_code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'manufacturer' }),
        // sort field
        dataIndex: 'item_group_03_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'item_group_03_code'),
        // filter field
        key: 'item_group_03_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'manufacturer' }),
          'item_group_03_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.item_group_03_code}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_status'),
        // filter field
        key: 'str_status',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'status' }),
          'str_status',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
          {record.str_status == `ACTIVE` ?
          <span style={{backgroundColor: '#35AD12', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{record.str_status}</span> :
          <span style={{backgroundColor: '#707B7C', borderRadius: '24px', padding: '4px 12px', fontSize: '11px', fontWeight: 'bold', color: '#ffffff'}}>{record.str_status}</span>}
          </>
        )
      },
      {
        width: 100,
        // fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Actions <Icon type="down" />
              </a>
            </Dropdown>
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    // process the filters
    const processedFilters = {};
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value && value[0]) {
        const filterVal = value.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data;
          }
          return `${lastObj},${data}`;
        }, '');
        processedFilters[key] = filterVal;
      }
    });

    // process the sorts, now just support 1 column
    const processedSorts = {};
    if (Object.entries(sorter).length > 0) {
      processedSorts[sorter.field] = sorter.order;
    }

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }
  
  showExpandedRow(record) {
    const { fetchIsLoading, intl } = this.props;

    return (
      <> 
      <Card bordered={false} size="small" title={intl.formatMessage({ id: 'uom_price' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          bordered={false}
          pagination={{
            pageSize: 20
          }}
          columns={this.getExpandedColumns()}
          dataSource={record.prices}
          loading={fetchIsLoading}
          pagination={{ position: ['none', 'none'] }}
          // defaultExpandAllRows
          // scroll={{ x: 750 }}
          title={() => (
            <Button
              name="add_detail"
              type="primary"
              icon="plus"
              // disabled={hdrId === 0}
              // loading={documentIsLoading}
              onClick={() => this.useOnCreatePrice(record.id)}
            >
              {intl.formatMessage({ id: 'add' })}
            </Button>
          )}
        />
      </Card>
      <Card bordered={false} size="small" title={intl.formatMessage({ id: 'promotions' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          bordered={false}
          pagination={{
            pageSize: 20
          }}
          columns={this.getExpandedColumns2()}
          dataSource={record.promotions}
          loading={fetchIsLoading}
          pagination={{ position: ['none', 'none'] }}
          // defaultExpandAllRows
          // scroll={{ x: 750 }}
        />
      </Card>
      </>
    );
  }
  

  getExpandedColumns() {
    const { intl } = this.props;

    function menu(record, parent) { return (
      <Menu>
        <Menu.Item key="0">
          <a onClick={() => parent.useOnSelectPrice(record)}><Icon type="edit" /> Edit</a>
        </Menu.Item>
        <Menu.Item key="1">
          
          <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => parent.useOnDeleteUOM(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <a ><Icon type="delete" /> Delete</a>
          </Popconfirm>
          
        </Menu.Item>
      </Menu>
    )};

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'uom' }),
        // sort field
        dataIndex: 'uom',
        // filter field
        key: 'uom',
        render: (text, record) => <>{record.uom_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'uom_factor' }),
        // sort field
        dataIndex: 'uom_factor',
        // filter field
        key: 'uom_rate',
        render: (text, record) => <>{record.uom_rate}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'sale_price' }),
        // sort field
        dataIndex: 'sale_price',
        // filter field
        key: 'price',
        render: (text, record) =>
          record.sale_price > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.sale_price)}
            </>
          ) : (
            ''
          )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'valid_from' }),
        // sort field
        dataIndex: 'valid_from',
        // filter field
        key: 'valid_from',
        render: (text, record) => <>{moment(record.valid_from).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'valid_to' }),
        // sort field
        dataIndex: 'valid_to',
        // filter field
        key: 'valid_to',
        render: (text, record) => <>{moment(record.valid_to).format('YYYY-MM-DD')}</>
      },
      {
        width: 70,
        // fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Dropdown overlay={menu(record, this)} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Actions <Icon type="down" />
              </a>
            </Dropdown>
          </>
        )
      }
    ];
  }
  
  getExpandedColumns2() {
    const { intl, appPath } = this.props;

    function menu(record) { return (
      <Menu>
        <Menu.Item key="0">
          <Link to={`${appPath}/promoDetail/update/${record.id}`}>
            <span><Icon type="edit" /> Edit</span>
          </Link>
        </Menu.Item>
      </Menu>
    )};

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        // filter field
        key: 'code',
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 250,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'valid_from' }),
        // sort field
        dataIndex: 'valid_from',
        // filter field
        key: 'valid_from',
        render: (text, record) => <>{moment(record.valid_from).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'valid_to' }),
        // sort field
        dataIndex: 'valid_to',
        // filter field
        key: 'valid_to',
        render: (text, record) => <>{moment(record.valid_to).format('YYYY-MM-DD')}</>
      },
      {
        width: 70,
        // fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Actions <Icon type="down" />
              </a>
            </Dropdown>
          </>
        )
      }
    ];
  }

  render() {
    const {
      resetTimestamp,
      newDocument,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      expandedRows,
      setExpandedRows,
      itemPriceIsVisible,
      itemId,
      intl
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          // rowSelection={{
          //   selectedRowKeys: selectedDocuments.map(value => value.id),
          //   onChange: () => {
          //     // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          //   },
          //   onSelect: (record, selected) => {
          //     // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
          //     if (selected === true) {
          //       addSelectedDocuments([record]);
          //     } else {
          //       removeSelectedDocuments([record]);
          //     }
          //   },
          //   onSelectAll: (selected, selectedRows, changeRows) => {
          //     // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
          //     if (selected === true) {
          //       addSelectedDocuments(changeRows);
          //     } else {
          //       removeSelectedDocuments(changeRows);
          //     }
          //   },
          //   onSelectInvert: selectedRows => {
          //     // console.log('onSelectInvert selectedRows: ', selectedRows);
          //     removeSelectedDocuments(selectedRows);
          //   },
          //   getCheckboxProps: () => ({
          //     // Column configuration not to be checked
          //     disabled: true
          //     // name: record.name,
          //   })
          // }}
          onChange={this.useOnTableChange}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          title={() => (
            <>
              <Row type="flex" justify="left" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon="reload"
                  />
                </Col>
                <Col span={3}>
                  <Button
                    name="add_detail"
                    type="primary"
                    icon="plus"
                    // disabled={hdrId === 0}
                    // loading={documentIsLoading}
                    onClick={() => newDocument()}
                  >
                    {intl.formatMessage({ id: 'new_item' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px'
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon="laptop"
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>
        <Modal
          visible={itemPriceIsVisible}
          title={intl.formatMessage({ id: 'uom_price' })}
          // style={{top:20}}
          width="60%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <ItemPriceForm itemId={itemId} />
        </Modal>
        <ManagePhotoDialog />
      </>
    );
  }
}

ItemList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  newDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  curDivisionId: PropTypes.number, 
  fetchItemList01: PropTypes.func,
  
  itemId: PropTypes.number,
  itemPriceSetVisible: PropTypes.func,
  itemPriceInitDocumentDetail: PropTypes.func,
  itemPriceSetDocumentDetail: PropTypes.func,
  itemPriceSetItemId: PropTypes.func,

  setManagePhotoVisible: PropTypes.func,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func,

  updateItem: PropTypes.func,

  appPath: PropTypes.string,

  itemPriceDelete: PropTypes.func
};

ItemList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  newDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 100,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  curDivisionId: 0,
  fetchItemList01() {},

  itemId: 0,
  itemPriceSetVisible() {},
  itemPriceInitDocumentDetail() {},
  itemPriceSetDocumentDetail() {},
  itemPriceSetItemId() {},
  
  setManagePhotoVisible() {},

  itemPriceDelete(){},

  expandedRows: [],
  setExpandedRows() {},
  updateItem() {},
  appPath: ''
};

const mapStateToProps = state => ({
  timestamp: state.itemList01.timestamp,
  workspaceIsVisible: state.itemList01.workspaceIsVisible,

  documents: state.itemList01.documents,
  selectedDocuments: state.itemList01.selectedDocuments,

  sorts: state.itemList01.sorts,
  filters: state.itemList01.filters,

  currentPage: state.itemList01.currentPage,
  pageSize: state.itemList01.pageSize,
  total: state.itemList01.total,
  fetchIsLoading: state.itemList01.fetchIsLoading,

  itemId: state.itemPrice.itemId,
  itemPriceIsVisible: state.itemPrice.itemPriceIsVisible,

  curSiteFlowId: state.app.curSiteFlowId,
  curDivisionId: state.app.curDivisionId,

  expandedRows: state.itemList01.expandedRows,

  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(ItemList01Actions.itemList01ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(ItemList01Actions.itemList01GoToDocument(hdrId)),
  newDocument: () => dispatch(ItemList01Actions.itemList01NewDocument()),
  goToAudit: hdrId => dispatch(ItemList01Actions.itemList01GoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(ItemList01Actions.itemList01SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(ItemList01Actions.itemList01AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(ItemList01Actions.itemList01RemoveSelectedDocuments(selectedDocuments)),

  fetchItemList01: (divisionId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemList01Actions.itemList01FetchItemList01(
        divisionId,
        currentPage,
        sorts,
        filters,
        pageSize,
        'Not_Deleted'
      )
    ),

  itemPriceSetVisible: boolean => dispatch(ItemPriceActions.itemPriceSetVisible(boolean)),
  itemPriceInitDocumentDetail: itemId =>
    dispatch(ItemPriceActions.itemPriceInitDocumentDetail(itemId)),
  itemPriceSetDocumentDetail: documentDetail =>
    dispatch(ItemPriceActions.itemPriceSetDocumentDetail(documentDetail)),
  itemPriceSetItemId: itemId => dispatch(ItemPriceActions.itemPriceSetItemId(itemId)),
  itemPriceDelete: Id => dispatch(ItemPriceActions.itemPriceDelete(Id)),

  setManagePhotoVisible: (document, boolean) =>
    dispatch(ItemList01Actions.itemList01SetManagePhotoVisible(document, boolean)),

  setExpandedRows: expandedRows =>
    dispatch(ItemList01Actions.itemList01SetExpandedRows(expandedRows)),

  updateItem: (id, status, currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemList01Actions.itemList01UpdateItem(id, status, currentPage, sorts, filters, pageSize)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemList01Table));
