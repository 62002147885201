/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { BinTrfIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const binTrfIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const binTrfIndexFetchBinTrfIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const binTrfIndexFetchBinTrfIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const binTrfIndexAddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const binTrfIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const binTrfIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [BinTrfIndexTypes.BIN_TRF_INDEX_RESET_TIMESTAMP]: binTrfIndexResetTimestamp,
  [BinTrfIndexTypes.BIN_TRF_INDEX_FETCH_BIN_TRF_INDEX_LOADING]: binTrfIndexFetchBinTrfIndexLoading,
  [BinTrfIndexTypes.BIN_TRF_INDEX_FETCH_BIN_TRF_INDEX_SUCCESS]: binTrfIndexFetchBinTrfIndexSuccess,
  [BinTrfIndexTypes.BIN_TRF_INDEX_ADD_SELECTED_DOCUMENTS]: binTrfIndexAddSelectedDocuments,
  [BinTrfIndexTypes.BIN_TRF_INDEX_REMOVE_SELECTED_DOCUMENTS]: binTrfIndexRemoveSelectedDocuments,
  [BinTrfIndexTypes.BIN_TRF_INDEX_SET_WORKSPACE_VISIBLE]: binTrfIndexSetWorkspaceVisible
});
