import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { push, replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import DebtorList01Actions from '../Stores/DebtorList01/Actions';
import DebtorProcessActions from '../Stores/DebtorProcess/Actions';

const getAppStore = state => state.app;
const getDebtorList01SelectedDocumentStore = state => state.debtorList01.selectedDocument;
const getDebtorList01DocumentsStore = state => state.debtorList01.documents;

export function* debtorList01FetchDebtorList01({ divisionId, currentPage, sorts, filters, pageSize,status }) {
  try {
    yield put(DebtorList01Actions.debtorList01FetchDebtorList01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });
    
    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize,
      status
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/indexDivision/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        DebtorList01Actions.debtorList01FetchDebtorList01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorList01Actions.debtorList01FetchDebtorList01Loading(false));
  }
}

export function* debtorList01NewDocument() {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/debtorDetail/create/0`));
}

export function* debtorList01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/debtorDetail/update/${hdrId}`));
}

export function* debtorList01ChangeStatus({ hdrId, status, curDivisionId, currentPage, sorts, filters, pageSize}) {
  yield put(DebtorList01Actions.debtorList01FetchDebtorList01Loading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: { id: hdrId, status: status, divisionId: curDivisionId, currentPage: currentPage, sorts: sorts, filters: filters, pageSize: pageSize}
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'debtor/changeStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      /*const debtorList01Documents = yield select(getDebtorList01DocumentsStore);
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        DebtorList01Actions.debtorList01FetchDebtorList01Success(
          debtorList01Documents,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );*/

      // yield put(push(`${app.appPath}/debtorProcess/index/DEBTOR_LIST_01`))

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorList01Actions.debtorList01FetchDebtorList01Loading(false));
    yield put(DebtorList01Actions.debtorList01ResetTimestamp());
  }
}

export function* debtorList01GoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/DEBTOR/${hdrId}/Debtor`));
}
 
export function* debtorList01RemoveDivision({ debtorId, divisionId }) {
  try {
    yield put(DebtorList01Actions.debtorList01ManageDivisionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ division_id: divisionId }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `debtor/deleteDivisions/${debtorId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const debtorList01SelectedDocument = yield select(getDebtorList01SelectedDocumentStore);

      const { divisions: oldDivisions } = debtorList01SelectedDocument;
      
      const { divisions: retDivisions, deleted_divisions: retDeletedDivisions } = result.data;

      const processed = UtilService.processResources(oldDivisions, retDivisions, retDeletedDivisions);

      yield put(DebtorList01Actions.debtorList01ManageDivisionSuccess(processed.resources));

      yield put(DebtorList01Actions.debtorList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorList01Actions.debtorList01ManageDivisionLoading(false));
  }
}

export function* debtorList01FetchDivisionOptions({ debtorId, search }) {
  try {
    yield put(DebtorList01Actions.debtorList01FetchDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'debtor_id',
          operator: 'NOT IN',
          value: debtorId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` + ' - ' + `${d.name_01}` }));

      yield put(DebtorList01Actions.debtorList01FetchDivisionOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorList01Actions.debtorList01FetchDivisionOptionLoading(false));
  }
}

export function* debtorList01AddDivision({ debtorId, divisionId }) {
  try {
    yield put(DebtorList01Actions.debtorList01ManageDivisionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ division_id: divisionId }]
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `debtor/createDivisions/${debtorId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const debtorList01SelectedDocument = yield select(getDebtorList01SelectedDocumentStore);

      const { divisions: oldDivisions } = debtorList01SelectedDocument;

      const { divisions: retDivisions, deleted_divisions: retDeletedDivisions } = result.data;

      const processed = UtilService.processResources(oldDivisions, retDivisions, retDeletedDivisions);

      yield put(DebtorList01Actions.debtorList01ManageDivisionSuccess(processed.resources));

      yield put(DebtorList01Actions.debtorList01ResetTimestamp());

      // clear the select2 options and option
      yield put(DebtorList01Actions.debtorList01FetchDivisionOptionSuccess([]));
      yield put(DebtorList01Actions.debtorList01SetDivisionOption({ value: 0, label: '' }));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorList01Actions.debtorList01ManageDivisionLoading(false));
  }
}
