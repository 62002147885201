import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Card, Button, Row, Col, Typography, Radio } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikText,
  FormikDatePicker,
  FormikButton,
  FormikSelect,
  FormikInputNumber,
  FormikTextNumber,
  FormikRadio
} from '../../Components/Formik';
import UserDetailActions from '../../Stores/UserDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

const loginTypeOptions = [
  <Radio.Button key={'w'} value={'w'}>
    {'WEB'}
  </Radio.Button>,
  <Radio.Button key={'v'} value={'v'}>
    {'VENDOR USER'}
  </Radio.Button>,
  <Radio.Button key={'c'} value={'c'}>
    {'CUSTOMER USER'}
  </Radio.Button>,
];

const statusOptions = [
  <Radio.Button key={100} value={100}>
    {'ACTIVE'}
  </Radio.Button>,
  <Radio.Button key={50} value={50}>
    {'PENDING'}
  </Radio.Button>,
  <Radio.Button key={2} value={2}>
    {'INACTIVE'}
  </Radio.Button>,
];

class HeaderForm extends React.PureComponent {
  constructor() {
    super();
    this.handleDebtorSearch = this.handleDebtorSearch.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { match, hdrId, initHeader, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId <= 0) {
        initHeader(match.params.id);
      } else {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}
  
  handleDebtorSearch(form, value) {
    const { fetchDebtorOptions } = this.props;

    fetchDebtorOptions(value);
  }

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      transitionToStatus,
      createHeader,
      updateHeader,
      historyGoBack,
      goToChangePassword,
      documentIsLoading,
      debtorIsLoading,
      debtorOptions,
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values);
            } else {
              updateHeader(formikBag, values);
            }
          } else if (values.submit_action === 'void') {
            transitionToStatus(formikBag, hdrId, 'VOID');
          } else if (values.submit_action === 'draft') {
            transitionToStatus(formikBag, hdrId, 'DRAFT');
          } else if (values.submit_action === 'wip') {
            transitionToStatus(formikBag, hdrId, 'WIP');
          } else if (values.submit_action === 'complete') {
            transitionToStatus(formikBag, hdrId, 'COMPLETE');
          }
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required(
            intl.formatMessage({ id: 'username_is_required' })
          ),
          first_name: Yup.string().required(
            intl.formatMessage({ id: 'first_name_is_required' })
          ),
          last_name: Yup.string().required(
            intl.formatMessage({ id: 'last_name_is_required' })
          ),
          email: Yup.string().required(
            intl.formatMessage({ id: 'email_is_required' })
          ),
          debtor_id_select2:
              documentHeader.login_type == 'c'
              ? Yup.object().shape({
                value: Yup.number().min(1, intl.formatMessage({ id: 'debtor_is_required' }))})
              : ''
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'basic_data' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'username' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="username" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={12}>
                </Col>
              </Row>
              
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'login_type' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="login_type"
                    component={FormikRadio}
                    options={loginTypeOptions}
                    disabled={true}
                  />
                </Col>
              </Row>
              {documentHeader.login_type == 'c' &&
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'debtor' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="debtor_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleDebtorSearch}
                    notFoundContent={null}
                    loading={debtorIsLoading}
                    options={debtorOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              }
              

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'email' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="email" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={12}>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'first_name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="first_name" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'last_name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="last_name" component={FormikInput} disabled={disabled} />
                </Col>
              </Row>
              
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="status"
                    component={FormikRadio}
                    options={statusOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

            </Card>

            <Card>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon="arrow-left"
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={9}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Button
                    type="primary"
                    // disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={() => goToChangePassword(documentHeader.id)}
                    icon="warning"
                  >
                    {intl.formatMessage({ id: 'change_password' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon="save"
                  />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  goToChangePassword: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool,
  
  fetchLoginTypeOptions: PropTypes.func,
  loginTypeIsLoading: PropTypes.bool,
  loginTypeOptions: PropTypes.arrayOf(PropTypes.object),
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},
  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  goToChangePassword() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false,

  fetchDebtorOptions() {},
  debtorIsLoading: false,
  debtorOptions: [],
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.userDetail.timestamp,
  hdrId: state.userDetail.hdrId,
  documentHeader: state.userDetail.documentHeader,
  documentIsLoading: state.userDetail.documentIsLoading,
  debtorIsLoading: state.userDetail.debtorIsLoading,
  debtorOptions: state.userDetail.debtorOptions,
});

const mapDispatchToProps = dispatch => ({
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  initHeader: login_type => dispatch(UserDetailActions.userDetailInitHeader(login_type)),
  showHeader: hdrId => dispatch(UserDetailActions.userDetailShowHeader(hdrId)),
  createHeader: (formikBag, documentHeader) =>
    dispatch(UserDetailActions.userDetailCreateHeader(formikBag, documentHeader)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(UserDetailActions.userDetailUpdateHeader(formikBag, documentHeader)),
  fetchDebtorOptions: search =>
    dispatch(UserDetailActions.userDetailFetchDebtorOptions(search)),
  goToChangePassword: hdrId => dispatch(UserDetailActions.userDetailGoToChangePassword(hdrId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HeaderForm));
