import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  companyListResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  companyListGoToDocument: ['hdrId'],
  companyListNewDocument: ['siteFlowId'],
  companyListGoToAudit: ['companyId'],
  companyListFetchCompanyList: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  companyListFetchCompanyListLoading: ['boolean'],
  companyListFetchCompanyListSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  companyListAddSelectedDocuments: ['selectedDocuments'],
  companyListRemoveSelectedDocuments: ['selectedDocuments'],
});

export const CompanyListTypes = Types;
export default Creators;
