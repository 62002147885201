import { createReducer } from 'reduxsauce';
import { DebtorDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const debtorDetailResetTimestamp = state => ({
    ...state,
    timestamp: Date.now()
});

export const debtorDetailSetHdrId = (state, { hdrId }) => ({
    ...state,
    timestamp: Date.now(),
    documentIsLoading: false,
    hdrId,
});

export const debtorDetailShowDocumentLoading = (state, { boolean }) => ({
    ...state,
    documentIsLoading: boolean
});

export const debtorDetailShowHeaderSuccess = (state, { documentHeader }) => ({
    ...state,
    documentHeader
});

export const debtorDetailUpdateDocumentSuccess = (state, { documentHeader }) => ({
    ...state,
    documentHeader
});

export const debtorDetailShowDetailsSuccess = (state, { documentDetails }) => ({
    ...state,
    documentDetails
});

export const debtorDetailSetDetailVisible = (state, { boolean }) => ({
    ...state,
    detailIsVisible: boolean
});

export const debtorDetailSetDocumentDetail = (state, { documentDetail }) => ({
    ...state,
    documentDetail,
    itemOptions: [],
    uomOptions: []
});

// force refresh the formik form
export const debtorDetailUpdateDetails = (state, { documentDetails }) => ({
    ...state,
    documentDetail: documentDetails[0]
  });
  
  // force refresh the formik form
  export const debtorDetailCreateDetail = (state, { documentDetail }) => ({
    ...state,
    documentDetail
  });

export const reducer = createReducer(INITIAL_STATE, {
    [DebtorDetailTypes.DEBTOR_DETAIL_RESET_TIMESTAMP]: debtorDetailResetTimestamp,
    [DebtorDetailTypes.DEBTOR_DETAIL_SET_HDR_ID]: debtorDetailSetHdrId,
  
    [DebtorDetailTypes.DEBTOR_DETAIL_SHOW_HEADER_SUCCESS]: debtorDetailShowHeaderSuccess,

    [DebtorDetailTypes.DEBTOR_DETAIL_UPDATE_DOCUMENT_SUCCESS]: debtorDetailUpdateDocumentSuccess,

    [DebtorDetailTypes.DEBTOR_DETAIL_SHOW_DOCUMENT_LOADING]: debtorDetailShowDocumentLoading,
});