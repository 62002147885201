import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import SalesProcessActions from '../../Stores/SalesProcess/Actions';

import SlsOrdSync02Table from '../SlsOrdSync02/SlsOrdSync02Table';
import SlsInvSync02Table from '../SlsInvSync02/SlsInvSync02Table';

const { TabPane } = Tabs;

class SalesProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchSalesProcess } = this.props;

    if (curDivisionId > 0) {
      fetchSalesProcess(curDivisionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchSalesProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchSalesProcess(curDivisionId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          {process.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                >
                  <Link
                    style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                    to={`${appPath}/salesProcess/index/${value.proc_type}`}
                  >
                    <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                  </Link>
                </Tooltip>
              }
              key={value.proc_type}
            />
          ))}
        </Tabs>

        {match.params.proc_type === 'SLS_ORD_SYNC_02' && <SlsOrdSync02Table match={match} />}
        {match.params.proc_type === 'SLS_INV_SYNC_02' && <SlsInvSync02Table match={match} />}
      </Card>
    );
  }
}

SalesProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curDivisionId: PropTypes.number,
  fetchSalesProcess: PropTypes.func
};

SalesProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curDivisionId: 0,
  fetchSalesProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.salesProcess.process,
  processIsLoading: state.salesProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchSalesProcess: divisionId =>
    dispatch(SalesProcessActions.salesProcessFetchSalesProcess(divisionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SalesProcessScreen));
