import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Card, Button, Row, Col, Typography, Radio } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikText,
  FormikDatePicker,
  FormikButton,
  FormikSelect,
  FormikInputNumber,
  FormikTextNumber,
  FormikRadio
} from '../../Components/Formik';
import DivisionDetailActions from '../../Stores/DivisionDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class HeaderForm extends React.PureComponent {
  constructor() {
    super();
    this.handleCompanySearch = this.handleCompanySearch.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { match, hdrId, initHeader, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId <= 0) {
        initHeader(match.params.id);
      } else {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}
  
  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      createHeader,
      updateHeader,
      historyGoBack,
      documentIsLoading,
      companyIsLoading,
      companyOptions,
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values);
            } else {
              updateHeader(formikBag, values);
            }
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(
            intl.formatMessage({ id: 'code_is_required' })
          ),
          name_01: Yup.string().required(
            intl.formatMessage({ id: 'name_is_required' })
          ),
          company_id_select2:
              Yup.object().shape({
                value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))})
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'basic_data' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={4}>
                  <Field name="code" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={16}>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={12}>
                  <Field name="name_01" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={8}>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text></Text>
                </Col>
                <Col span={1}>
                  <Text></Text>
                </Col>
                <Col span={12}>
                  <Field name="name_02" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={8}>
                </Col>
              </Row>
              
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'company' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="company_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCompanySearch}
                    notFoundContent={null}
                    loading={companyIsLoading}
                    options={companyOptions}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12}>
                </Col>
              </Row>

            </Card>

            <Card>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={9}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon="arrow-left"
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon="save"
                  />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},
  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false,
  fetchCompanyOptions() {},
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.divisionDetail.timestamp,
  hdrId: state.divisionDetail.hdrId,
  documentHeader: state.divisionDetail.documentHeader,
  documentIsLoading: state.divisionDetail.documentIsLoading,
  companyIsLoading: state.divisionDetail.companyIsLoading,
  companyOptions: state.divisionDetail.companyOptions,
});

const mapDispatchToProps = dispatch => ({
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  initHeader: () => dispatch(DivisionDetailActions.divisionDetailInitHeader()),
  showHeader: hdrId => dispatch(DivisionDetailActions.divisionDetailShowHeader(hdrId)),
  createHeader: (formikBag, documentHeader) =>
    dispatch(DivisionDetailActions.divisionDetailCreateHeader(formikBag, documentHeader)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(DivisionDetailActions.divisionDetailUpdateHeader(formikBag, documentHeader)),
  fetchCompanyOptions: search =>
    dispatch(DivisionDetailActions.divisionDetailFetchCompanyOptions(search)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HeaderForm));
