/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob150101Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob150101ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false,
  printIsLoading: false
});

export const whseJob150101FetchWhseJob150101Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob150101FetchWhseJob150101Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob150101AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const whseJob150101RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const whseJob150101SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob150101PrintWhseJob150101Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob150101Types.WHSE_JOB150101_RESET_TIMESTAMP]: whseJob150101ResetTimestamp,
  [WhseJob150101Types.WHSE_JOB150101_FETCH_WHSE_JOB150101_LOADING]: whseJob150101FetchWhseJob150101Loading,
  [WhseJob150101Types.WHSE_JOB150101_FETCH_WHSE_JOB150101_SUCCESS]: whseJob150101FetchWhseJob150101Success,
  [WhseJob150101Types.WHSE_JOB150101_ADD_SELECTED_DOCUMENTS]: whseJob150101AddSelectedDocuments,
  [WhseJob150101Types.WHSE_JOB150101_REMOVE_SELECTED_DOCUMENTS]: whseJob150101RemoveSelectedDocuments,
  [WhseJob150101Types.WHSE_JOB150101_SET_WORKSPACE_VISIBLE]: whseJob150101SetWorkspaceVisible,
  [WhseJob150101Types.WHSE_JOB150101_PRINT_WHSE_JOB150101_LOADING]: whseJob150101PrintWhseJob150101Loading
});
