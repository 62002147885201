/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  hdrId: 0,
  itemId: 0,
  documentIsLoading: false,
  documentDetails: [],
  detailIsVisible: false,
  detailAddOnIsVisible: false,
  ruleIsVisible: false,
  rules: [],

  documentDetail: {
    item_id: 0,
    item_group_01_id: 0,
    item_group_02_id: 0,
    item_group_03_id: 0,
    disc_perc_01: 0,
    disc_fixed_price: 0,
    min_qty: 0,
    max_qty: 0,

    variant_type_select2: {
      value: 0,
      label: 'Item'
    },

    item_select2: {
      value: 0,
      label: ''
    },

    item_group_01_select2: {
      value: 0,
      label: ''
    },
    item_group_02_select2: {
      value: 0,
      label: ''
    },
    item_group_03_select2: {
      value: 0,
      label: ''
    }
  },

  initDocumentDetail: {
    item_id: 0,
    item_group_01_id: 0,
    item_group_02_id: 0,
    item_group_03_id: 0,
    disc_perc_01: 0,
    disc_fixed_price: 0,
    min_qty: 0,
    max_qty: 0,

    variant_type_select2: {
      value: 0,
      label: 'Item'
    },

    item_select2: {
      value: 0,
      label: ''
    },

    item_group_01_select2: {
      value: 0,
      label: ''
    },
    item_group_02_select2: {
      value: 0,
      label: ''
    },
    item_group_03_select2: {
      value: 0,
      label: ''
    }
  },
  
  initDocumentDetailFoc: {
    item_id: 0,
    item_group_01_id: 0,
    item_group_02_id: 0,
    item_group_03_id: 0,
    disc_perc_01: 100,
    disc_fixed_price: 0,
    min_qty: 0,
    max_qty: 0,
    item_select2: {
      value: 0,
      label: ''
    }
  },

  itemOptions: [],
  itemIsLoading: false,

  brandOptions: [],
  brandIsLoading: false,

  groupOptions: [],
  groupIsLoading: false,

  manufacturerOptions: [],
  manufacturerIsLoading: false,

  variantTypes: [],
  variantTypesLoading: false,

  debtorOptions: [],
  debtorIsLoading: false,

  debtorGroupOptions: [],
  debtorGroupIsLoading: false,
  
  debtorCategoryOptions: [],
  debtorCategoryIsLoading: false,

  ruleTypes: [],
  ruleTypesLoading: false,

  rule: {
    debtor_id: 0,
    debtor_group_01_id: 0,

    debtor_ids_select2: [],
    debtor_group_ids_select2: [],

    rule_type_select2: {
      value: 3,
      label: 'Debtor'
    },
  },

  initRule: {
    debtor_id: 0,
    debtor_group_01_id: 0,

    debtor_ids_select2: [],
    debtor_group_ids_select2: [],

    rule_type_select2: {
      value: 3,
      label: 'Debtor'
    },
  },

  broadcast: {
    title: '',
    content: [],
    priority: 0,
    // recipient_option: 0,
    recipients: [],
  }
};
