import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PromoDetailActions from '../Stores/PromoDetail/Actions';
import PromoDiscListActions from '../Stores/PromoDiscIndex/Actions';
import UtilService from '../Services/UtilService';

const getAppStore = state => state.app;

const getPromoDetailStore = state => state.promoDetail;

const getPromoDiscListStore = state => state.promoDiscList;

export function* promoDetailChangeItem({ formikBag, itemId }) {
  const promoDetail = yield select(getPromoDetailStore)

  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      itemId,
      promotionId: promoDetail.hdrId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      // formikBag.setFieldValue('uom_select2', {
      //   value: 0,
      //   label: ''
      // });
      // formikBag.setFieldValue('uom_rate', 1);

      // yield put(PromoDetailActions.promoDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* promoDetailChangeItemGroup({ formikBag, hdrId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      promotionId: hdrId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/changeItemGroup`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* promoDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));

  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/createVariant/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const promoDetail = yield select(getPromoDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = promoDetail;

      const { promotion: retDocumentHeader, promotion_variants: retDocumentDetails } = result.data;

      const processed = UtilService.processResources(
        oldDocumentDetails,
        retDocumentDetails,
        []
      );

      if (documentDetail.is_main) {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccessMain(processed.resources)
        );
      }
      else if (documentDetail.is_add_on) {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccessAddOn(processed.resources)
        );
      }
      else {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccess(processed.resources)
        );
      }

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailCreateHeader({ divisionId, formikBag, promoType, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);
    const promoDiscList = yield select(getPromoDiscListStore)

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/createModel/${divisionId}/${promoType}`,
      app.token,
      postData // params
    );
    
    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/promoDetail/update/${result.data}`));
      yield put(PromoDetailActions.promoDetailSetHdrId(result.data));

      if(promoDiscList.uploadIsHold === true && promoDiscList.fileList.length > 0) {
        const postDataPhoto = new FormData()

        promoDiscList.fileList.forEach(file => {
          if (Math.sign(file.uid) == -1) {
          } else {
            postDataPhoto.append('files[]', file.originFileObj, file.name);
          }
        });
  
        const resultPhoto = yield call(
          ApiService.postApi,
          app.apiUrl,
          `promotion/uploadPhotos/${result.data}`,
          app.token,
          postDataPhoto,
          'multipart/form-data'
        )
  
        if(resultPhoto.isSuccess === true) {
          yield put(PromoDiscListActions.promoDiscListUploadPhotoHold(false, []))

          yield call(notification.success, {
            message: resultPhoto.message,
            duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
          });
        } else {
          yield call(notification.error, {
            message: resultPhoto.message,
            duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
          });
        }
      }

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* promoDetailCreateRule({ formikBag, hdrId, rule }) {
  formikBag.setSubmitting(true);
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    if (rule.debtor_ids_select2) {
      const values = rule.debtor_ids_select2;
      if (values !== null && values.length > 0) {
        rule.debtor_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    else if (rule.debtor_group_ids_select2) {
      const values = rule.debtor_group_ids_select2;
      if (values !== null && values.length > 0) {
        rule.debtor_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    const postData = {
      data: rule
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/createRule/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const promoDetail = yield select(getPromoDetailStore);

      const { rules: oldRules } = promoDetail;

      const { promotion_rules: retRules } = result.data;
      const processed = UtilService.processResources(oldRules, retRules, []);

      yield put(
        PromoDetailActions.promoDetailUpdateRuleSuccess(processed.resources)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `promotion/deleteVariant/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const promoDetail = yield select(getPromoDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = promoDetail;

      const {
        promotion: retDocumentHeader,
        deleted_promotion_variant: delDocumentDetail
      } = result.data;

      const processed = UtilService.processResources(
        oldDocumentDetails,
        [],
        [delDocumentDetail]
      );

      if (documentDetail.is_main) {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccessMain(processed.resources)
        );
      }
      else if (documentDetail.is_add_on) {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccessAddOn(processed.resources)
        );
      }
      else {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccess(processed.resources)
        );
      }

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailDeleteHeader({hdrId}) {
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: { id: hdrId }
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `promotion/deleteModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/promoDiscIndex`));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailDeleteRule({ hdrId, rule }) {
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: rule
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `promotion/deleteRule/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const promoDetail = yield select(getPromoDetailStore);

      const { rules: oldRules } = promoDetail;
      const { deleted_promotion_rule: delRule } = result.data;
      const processed = UtilService.processResources(oldRules, [], [delRule]);

      yield put(
        PromoDetailActions.promoDetailUpdateRuleSuccess(processed.resources)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailFetchBrandOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchBrandOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      divisionId: divisionId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2ByDivision`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(PromoDetailActions.promoDetailFetchBrandOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchBrandOptionLoading(false));
  }
}

export function* promoDetailFetchBroadcastDebtorOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchDebtorOptionLoading(true));
    const promoDetail = yield select(getPromoDetailStore)

    let debtor_ids = []
    let debtor_group_ids = []

    console.log("Broadcast Promo", promoDetail.rules)

    // promoDetail.rules.forEach(rule => {
    //   if(rule.type_str == 'debtor') {
    //     rule.debtor_ids_select2.map(id => debtor_ids.push(id.value))
    //   } else if(rule.type_str == 'debtor_group') {
    //     rule.debtor_group_ids_select2.map(id => debtor_group_ids.push(id.value))
    //   }
    // })

    // const app = yield select(getAppStore);
    // const postData = {
    //   search,
    //   filters: [
    //     {
    //       field: 'division_id',
    //       value: divisionId
    //     }
    //   ]
    // };

    // if(debtor_ids.length > 0) {
    //   postData.filters.push({
    //     field: 'id',
    //     value: debtor_ids
    //   })
    // } 
    
    // if(debtor_group_ids.length > 0) {
    //   postData.filters.push({
    //     field: 'debtor_category_01_id',
    //     value: debtor_group_ids
    //   })
    // }


    // const result = yield call(
    //   ApiService.postApi, // function
    //   app.apiUrl,
    //   `debtor/select2`,
    //   app.token,
    //   postData
    // );
    
    // if (result.isSuccess === true) {
    //   const options = result.data.data.map(d => ({
    //     value: d.id,
    //     label: `${d.code} ${d.company_name_01}`
    //   }));
    //   yield put(PromoDetailActions.promoDetailFetchDebtorOptionSuccess(options));
    // } else if (result.isTokenExpired === true) {
    //   yield put(AppActions.appTokenExpired(result.message));
    // } else if (result.isPasswordExpired === true) {
    //   yield put(AppActions.appPasswordExpired(result.message));
    // } else {
    //   yield call(notification.error, {
    //     message: result.message,
    //     duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    //   });
    // }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchDebtorOptionLoading(false));
  }
}

export function* promoDetailFetchBroadcastDebtorGroupOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchDebtorGroupOptionLoading(true));
    const app = yield select(getAppStore);
    const promoDetail = yield select(getPromoDetailStore)
    
    let debtor_ids = []
    let debtor_group_ids = []

    promoDetail.rules.forEach(rule => {
      if(rule.type_str == 'debtor') {
        rule.debtor_ids_select2.map(id => debtor_ids.push(id.value))
      } else if(rule.type_str == 'debtor_group') {
        rule.debtor_group_ids_select2.map(id => debtor_group_ids.push(id.value))
      }
    })

    if ((debtor_ids.length > 0 && debtor_group_ids.length > 0) ||
        (debtor_ids.length == 0 && debtor_group_ids.length == 0) ||
        (debtor_ids.length == 0 && debtor_group_ids.length > 0)){

      const postData = {
        search,
        filters: []
      };

      if(debtor_group_ids.length > 0) {
        postData.filters.push({
          field: 'debtor_category_01_id',
          value: debtor_group_ids
        })
      }

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `debtorGroup01/select2`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const options = result.data.data.map(d => ({
          value: d.id,
          label: `${d.code} ${d.desc_01}`
        }));
        yield put(PromoDetailActions.promoDetailFetchDebtorGroupOptionSuccess(options));
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchDebtorGroupOptionLoading(false));
  }
}

export function* promoDetailFetchCategoryOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchCategoryOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      divisionId: divisionId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2ByDivision`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(PromoDetailActions.promoDetailFetchCategoryOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchCategoryOptionLoading(false));
  }
}

export function* promoDetailFetchDebtorOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchDebtorOptionLoading(true));
    
    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'division_id',
          operator: '=',
          value: divisionId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/select2`,
      app.token,
      postData
    );
    
    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01}`
      }));
      yield put(PromoDetailActions.promoDetailFetchDebtorOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchDebtorOptionLoading(false));
  }
}

export function* promoDetailFetchDebtorGroupOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchDebtorGroupOptionLoading(true));
    
    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtorGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));
      yield put(PromoDetailActions.promoDetailFetchDebtorGroupOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchDebtorGroupOptionLoading(false));
  }
}

export function* promoDetailFetchItemOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchItemOptionLoading(true));
    
    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'division_id',
          operator: '=',
          value: divisionId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(PromoDetailActions.promoDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchItemOptionLoading(false));
  }
}

export function* promoDetailFetchManufacturerOptions({ divisionId, search }) {
  try {
    yield put(PromoDetailActions.promoDetailFetchManufacturerOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      divisionId: divisionId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2ByDivision`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(PromoDetailActions.promoDetailFetchManufacturerOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailFetchManufacturerOptionLoading(false));
  }
}

export function* promoDetailFetchRuleTypeOptions({ search }) {
  yield put(PromoDetailActions.promoDetailFetchRuleTypeOptionLoading(true));
  const variantTypes = [
    { value: 3, label: 'Debtor' },
    { value: 4, label: 'Debtor Group' }
  ];
  yield put(PromoDetailActions.promoDetailFetchRuleTypeOptionSuccess(variantTypes));
  yield put(PromoDetailActions.promoDetailFetchRuleTypeOptionLoading(false));
}

export function* promoDetailFetchVariantTypeOptions({ search }) {
  yield put(PromoDetailActions.promoDetailFetchVariantTypeOptionLoading(true));
  const variantTypes = [
    { value: 0, label: 'Item' },
    { value: 1, label: 'Brand' },
    { value: 2, label: 'Category' },
    { value: 3, label: 'Manufacturer' }
  ];
  yield put(PromoDetailActions.promoDetailFetchVariantTypeOptionSuccess(variantTypes));
  yield put(PromoDetailActions.promoDetailFetchVariantTypeOptionLoading(false));
}

export function* promoDetailInitHeader({ divisionId, promoType }) {
  try {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/initModel/${divisionId}/${promoType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      var type = '';
      if (promoType === '0') {
        type = 'disc_percent';
      }
      else if (promoType === '1') {
        type = 'disc_fixed_price';
      }
      else if (promoType === '2') {
        type = 'foc';
      }
      const documentHeader = {
        ...result.data,
        type: type
      };
      yield put(PromoDetailActions.promoDetailShowHeaderSuccess(documentHeader));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailShowDetails({ hdrId }) {
  try {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/showVariants/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PromoDetailActions.promoDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailShowDetailsAddOn({ hdrId }) {
  try {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/showAddOnVariants/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PromoDetailActions.promoDetailShowDetailsAddOnSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailShowDetailsMain({ hdrId }) {
  try {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/showMainVariants/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PromoDetailActions.promoDetailShowDetailsMainSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailShowHeader({ hdrId }) {
  try {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/showModel/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    console.log("PromoDetailSaga", result.data)
    if (result.isSuccess === true) {
      result.data.actions.forEach(action => {
        if(action.config !== null && action.config.hasOwnProperty('disc_fixed_price')) {
          result.data['disc_fixed_price'] = action.config.disc_fixed_price
        }
        if(action.config !== null && action.config.hasOwnProperty('disc_perc_01')) {
          result.data['disc_perc_01'] = action.config.disc_perc_01
        }
      })

      yield put(PromoDetailActions.promoDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailShowRules({ hdrId }) {
  try {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `promotion/showRules/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PromoDetailActions.promoDetailShowRulesSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails[0]
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `promotion/updateVariant/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const promoDetail = yield select(getPromoDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = promoDetail;

      const { promotion: retDocumentHeader, promotion_variants: retDocumentDetails } = result.data;

      const processed = UtilService.processResources(
        oldDocumentDetails,
        retDocumentDetails,
        []
      );

      if (documentDetails[0].is_main) {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccessMain(processed.resources)
        );
      }
      else if (documentDetails[0].is_add_on) {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccessAddOn(processed.resources)
        );
      }
      else {
        yield put(
          PromoDetailActions.promoDetailUpdateDetailsSuccess(processed.resources)
        );
      }

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'promotion/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const promoDetail = yield select(getPromoDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = promoDetail;

      const { model: retDocumentHeader, documentDetails: retDocumentDetails } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        [], // TODO FIX Get details as well
        []
      );
      
      yield put(PromoDetailActions.promoDetailUpdateDocumentSuccess(processed.header, processed.details));
      yield put(PromoDetailActions.promoDetailResetTimestamp())

      
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* promoDetailUpdateRule({ formikBag, hdrId, rule }) {
  formikBag.setSubmitting(true);
  yield put(PromoDetailActions.promoDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    if (rule.debtor_ids_select2) {
      const values = rule.debtor_ids_select2;
      if (values !== null && values.length > 0) {
        rule.debtor_ids = values.map(entry => {
            return entry.value;
        });
      }
    }
    else if (rule.debtor_group_ids_select2) {
      const values = rule.debtor_group_ids_select2;
      if (values !== null && values.length > 0) {
        rule.debtor_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    const postData = {
      data: rule
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/updateRule/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const promoDetail = yield select(getPromoDetailStore);

      const { rules: oldRules } = promoDetail;

      const { promotion_rules: retRules } = result.data;
      const processed = UtilService.processResources(oldRules, retRules, []);

      yield put(
        PromoDetailActions.promoDetailUpdateRuleSuccess(processed.resources)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(PromoDetailActions.promoDetailShowDocumentLoading(false));
  }
}

export function* promoDetailBroadcastPromo({ formikBag, hdrId, broadcastValues }) {
  formikBag.setSubmitting(true)
  try {
    const app = yield select(getAppStore)

    if (broadcastValues.rules) {
      broadcastValues.rules.map(rule => {
        if(rule.type_str == 'debtor') {
          broadcastValues.debtor_ids = rule.debtor_ids_select2.map(entry => {
            return entry.value
          })
        } else if(rule.type_str = 'debtor_group') {
          broadcastValues.debtor_group_ids = rule.debtor_group_ids_select2.map(entry => {
            return entry.value
          })
        }
      })
    }

    const postData = {
      data: broadcastValues,
      divisionId: app.curDivisionId
    };
    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `promotion/broadcastPromo`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}