import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikText,
  FormikInputNumber,
  FormikTextNumber
} from '../../Components/Formik';
import CountAdjDetailActions from '../../Stores/CountAdjDetail/Actions';

const { Text } = Typography;

class DetailForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemBatch = this.handleSearchItemBatch.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchStorageBin = this.handleSearchStorageBin.bind(this);
    this.handleSearchHandlingUnit = this.handleSearchHandlingUnit.bind(this);
    this.handleSearchQuantBal = this.handleSearchQuantBal.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeItemBatch = this.handleChangeItemBatch.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
    this.handleChangeQuantBal = this.handleChangeQuantBal.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchItemBatch(form, value) {
    const { fetchItemBatchOptions } = this.props;
    const { values } = form;

    fetchItemBatchOptions(values.item_select2.value, value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_select2.value, value);
  }

  handleSearchStorageBin(form, value) {
    const { curSiteFlowId, fetchStorageBinOptions } = this.props;

    fetchStorageBinOptions(curSiteFlowId, value);
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { hdrId, changeItem } = this.props;

    changeItem(form, hdrId, value);
  }

  handleChangeItemBatch(form, value) {
    // params: form, value, option
    const { hdrId, changeItemBatch } = this.props;
    const { values } = form;

    changeItemBatch(form, hdrId, values.item_select2.value, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  handleSearchHandlingUnit(form, value) {
    const { curSiteFlowId, fetchHandlingUnitOptions } = this.props;

    fetchHandlingUnitOptions(curSiteFlowId, value);
  }

  handleSearchQuantBal(form, value) {
    const { documentHeader, fetchQuantBalOptions } = this.props;
    const { values } = form;

    fetchQuantBalOptions(documentHeader.company_id, values.storage_bin_select2.value, value);
  }

  handleChangeQuantBal(form, value) {
    // params: form, value, option

    const { hdrId, changeQuantBal } = this.props;

    changeQuantBal(form, hdrId, value);
  }

  handleChangeStorageBin(form) {
    // params: form, value, option

    const { fetchQuantBalOptionSuccess } = this.props;
    const { values: curValues, setFieldValue } = form;

    if ('storage_bin_select2' in curValues) {
      setFieldValue('quant_bal_select2', {
        value: 0,
        label: ''
      });
      setFieldValue('handling_unit_barcode', '');
      setFieldValue('item_code', '');
      setFieldValue('item_desc_01', '');
      setFieldValue('item_desc_02', '');

      fetchQuantBalOptionSuccess([]);
    }
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      itemIsLoading,
      itemOptions,
      itemBatchIsLoading,
      itemBatchOptions,
      uomIsLoading,
      uomOptions,
      storageBinIsLoading,
      storageBinOptions,
      quantBalIsLoading,
      quantBalOptions,
      handlingUnitIsLoading,
      handlingUnitOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    const signOptions = [
      {
        value: -1,
        label: '-'
      },
      {
        value: 1,
        label: '+'
      }
    ];

    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'storage_bin_is_required' }))
          }),
          item_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_is_required' }))
          }),
          item_batch_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_batch_is_required' }))
          }),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBin}
                  notFoundContent={null}
                  loading={storageBinIsLoading}
                  options={storageBinOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'quant_bal' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="quant_bal_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchQuantBal}
                  notFoundContent={null}
                  loading={quantBalIsLoading}
                  options={quantBalOptions}
                  disabled={disabled}
                  onChange={this.handleChangeQuantBal}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'pallet_id' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="handling_unit_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchHandlingUnit}
                  notFoundContent={null}
                  loading={handlingUnitIsLoading}
                  options={handlingUnitOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={disabled}
                  onChange={this.handleChangeItem}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_01" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_02" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_batch' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_batch_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemBatch}
                  notFoundContent={null}
                  loading={itemBatchIsLoading}
                  options={itemBatchOptions}
                  disabled={disabled}
                  onChange={this.handleChangeItemBatch}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="batch_serial_no" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="expiry_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="receipt_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[0, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'sign' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="sign_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  // onSearch={this.handleCompanySearch}
                  notFoundContent={null}
                  options={signOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'qty' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="qty" component={FormikInputNumber} disabled={disabled} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="uom_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUom}
                  notFoundContent={null}
                  loading={uomIsLoading}
                  options={uomOptions}
                  disabled={disabled}
                  onChange={this.handleChangeUom}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="uom_rate"
                  component={FormikTextNumber}
                  minimumfractiondigits={6}
                  maximumfractiondigits={6}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon="undo"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon="save"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

DetailForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number,
    company_id: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemBatchOptions: PropTypes.func,
  changeItemBatch: PropTypes.func,
  itemBatchIsLoading: PropTypes.bool,
  itemBatchOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchStorageBinOptions: PropTypes.func,
  storageBinIsLoading: PropTypes.bool,
  storageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchHandlingUnitOptions: PropTypes.func,
  handlingUnitIsLoading: PropTypes.bool,
  handlingUnitOptions: PropTypes.arrayOf(PropTypes.object),

  fetchQuantBalOptions: PropTypes.func,
  fetchQuantBalOptionSuccess: PropTypes.func,
  changeQuantBal: PropTypes.func,
  quantBalIsLoading: PropTypes.bool,
  quantBalOptions: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number
};

DetailForm.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3, company_id: 0 },
  documentDetail: {},
  documentIsLoading: false,

  fetchItemOptions() {},
  changeItem() {},
  itemIsLoading: false,
  itemOptions: [],

  fetchItemBatchOptions() {},
  changeItemBatch() {},
  itemBatchIsLoading: false,
  itemBatchOptions: [],

  fetchUomOptions() {},
  changeUom() {},
  uomIsLoading: false,
  uomOptions: [],

  fetchStorageBinOptions() {},
  storageBinIsLoading: false,
  storageBinOptions: [],

  fetchHandlingUnitOptions() {},
  handlingUnitIsLoading: false,
  handlingUnitOptions: [],

  fetchQuantBalOptions() {},
  fetchQuantBalOptionSuccess() {},
  quantBalIsLoading: false,
  quantBalOptions: [],
  changeQuantBal() {},

  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  hdrId: state.countAdjDetail.hdrId,
  documentHeader: state.countAdjDetail.documentHeader,
  documentDetail: state.countAdjDetail.documentDetail,
  documentIsLoading: state.countAdjDetail.documentIsLoading,

  itemIsLoading: state.countAdjDetail.itemIsLoading,
  itemOptions: state.countAdjDetail.itemOptions,

  itemBatchIsLoading: state.countAdjDetail.itemBatchIsLoading,
  itemBatchOptions: state.countAdjDetail.itemBatchOptions,

  uomIsLoading: state.countAdjDetail.uomIsLoading,
  uomOptions: state.countAdjDetail.uomOptions,

  storageBinIsLoading: state.countAdjDetail.storageBinIsLoading,
  storageBinOptions: state.countAdjDetail.storageBinOptions,

  handlingUnitIsLoading: state.countAdjDetail.handlingUnitIsLoading,
  handlingUnitOptions: state.countAdjDetail.handlingUnitOptions,

  quantBalIsLoading: state.countAdjDetail.quantBalIsLoading,
  quantBalOptions: state.countAdjDetail.quantBalOptions,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(CountAdjDetailActions.countAdjDetailUpdateDetails(formikBag, hdrId, documentDetails)),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(CountAdjDetailActions.countAdjDetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchItemOptions: search =>
    dispatch(CountAdjDetailActions.countAdjDetailFetchItemOptions(search)),
  changeItem: (formikBag, hdrId, itemId) =>
    dispatch(CountAdjDetailActions.countAdjDetailChangeItem(formikBag, hdrId, itemId)),

  fetchItemBatchOptions: (itemId, search) =>
    dispatch(CountAdjDetailActions.countAdjDetailFetchItemBatchOptions(itemId, search)),
  changeItemBatch: (formikBag, hdrId, itemBatchId) =>
    dispatch(CountAdjDetailActions.countAdjDetailChangeItemBatch(formikBag, hdrId, itemBatchId)),

  fetchUomOptions: (itemId, search) =>
    dispatch(CountAdjDetailActions.countAdjDetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(CountAdjDetailActions.countAdjDetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(CountAdjDetailActions.countAdjDetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchStorageBinOptions: (siteFlowId, search) =>
    dispatch(CountAdjDetailActions.countAdjDetailFetchStorageBinOptions(siteFlowId, search)),

  fetchHandlingUnitOptions: (siteFlowId, search) =>
    dispatch(CountAdjDetailActions.countAdjDetailFetchHandlingUnitOptions(siteFlowId, search)),

  fetchQuantBalOptions: (companyId, storageBinId, search) =>
    dispatch(
      CountAdjDetailActions.countAdjDetailFetchQuantBalOptions(companyId, storageBinId, search)
    ),
  fetchQuantBalOptionSuccess: options =>
    dispatch(CountAdjDetailActions.countAdjDetailFetchQuantBalOptionSuccess(options)),
  changeQuantBal: (formikBag, hdrId, quantBalId) =>
    dispatch(CountAdjDetailActions.countAdjDetailChangeQuantBal(formikBag, hdrId, quantBalId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DetailForm));
