import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  promoDiscListResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  promoDiscListGoToDocument: ['hdrId'],
  promoDiscListGoToBroadcast: ['hdrId'],  
  promoDiscListNewDocument: ['promoType'],
  promoDiscListGoToAudit: ['divisionId'],
  promoDiscListFetchPromoList: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize', 'filterStr'],
  promoDiscListFetchPromoListLoading: ['boolean'],
  promoDiscListFetchPromoListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize',
    'filterStr'
  ],
  promoDiscListAddSelectedDocuments: ['selectedDocuments'],
  promoDiscListRemoveSelectedDocuments: ['selectedDocuments'],
  promoDiscListSetManagePhotoVisible: ['document', 'boolean'],
  promoDiscListRemovePhoto: ['promotionId', 'promotionPhotoId'],
  promoDiscListUploadPhoto: ['promotionId', 'fileList'],
  promoDiscListManagePhotoSuccess: ['selectedDocuments'],
  promoDiscListManagePhotoLoading: ['boolean'],
  promoDiscListUploadLoading: ['boolean'],
  promoDiscListUploadPhotoHold: ['boolean', 'fileList']
});

export const PromoDiscListTypes = Types;
export default Creators;
