import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Button, Row, Col, Typography } from 'antd';
import { FormikSelect, FormikButton, FormikText } from '../../Components/Formik';
import PutAwayDetailActions from '../../Stores/PutAwayDetail/Actions';

const { Text } = Typography;

class WhseJobType11Form extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchFrStorageBin = this.handleSearchFrStorageBin.bind(this);
    this.handleSearchQuantBal = this.handleSearchQuantBal.bind(this);
    this.handleSearchItemCond01 = this.handleSearchItemCond01.bind(this);
    this.handleSearchToStorageBin = this.handleSearchToStorageBin.bind(this);

    this.handleChangeFrStorageBin = this.handleChangeFrStorageBin.bind(this);
    this.handleChangeQuantBal = this.handleChangeQuantBal.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchFrStorageBin(form, value) {
    const { curSiteFlowId, fetchFrStorageBinOptions } = this.props;

    fetchFrStorageBinOptions(curSiteFlowId, value);
  }

  handleSearchQuantBal(form, value) {
    const { fetchQuantBalOptions } = this.props;
    const { values } = form;

    fetchQuantBalOptions(values.storage_bin_select2.value, value);
  }

  handleSearchItemCond01(form, value) {
    const { fetchItemCond01Options } = this.props;

    fetchItemCond01Options(value);
  }

  handleSearchToStorageBin(form, value) {
    const { curSiteFlowId, fetchToStorageBinOptions } = this.props;

    fetchToStorageBinOptions(curSiteFlowId, value);
  }

  handleChangeFrStorageBin(form) {
    // params: form, value, option

    const { fetchQuantBalOptionSuccess } = this.props;
    const { values: curValues, setFieldValue } = form;

    if ('storage_bin_select2' in curValues) {
      setFieldValue('quant_bal_select2', {
        value: 0,
        label: ''
      });
      setFieldValue('handling_unit_barcode', '');
      setFieldValue('item_code', '');
      setFieldValue('item_desc_01', '');
      setFieldValue('item_desc_02', '');

      fetchQuantBalOptionSuccess([]);
    }
  }

  handleChangeQuantBal(form, value) {
    // params: form, value, option

    const { hdrId, changeQuantBal } = this.props;

    changeQuantBal(form, hdrId, value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      frStorageBinIsLoading,
      frStorageBinOptions,
      quantBalIsLoading,
      quantBalOptions,
      itemCond01IsLoading,
      itemCond01Options,
      toStorageBinIsLoading,
      toStorageBinOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={props => {
          return props.documentHeader.doc_status >= 50
            ? Yup.object().shape({
                storage_bin_select2: Yup.object().shape({
                  value: Yup.number().min(
                    1,
                    intl.formatMessage({ id: 'from_storage_bin_is_required' })
                  )
                }),
                quant_bal_select2: Yup.object().shape({
                  value: Yup.number().min(1, intl.formatMessage({ id: 'quant_bal_is_required' }))
                }),
                item_cond_01_select2: Yup.object().shape({
                  value: Yup.number().min(1, intl.formatMessage({ id: 'item_cond_01_is_required' }))
                }),
                to_storage_bin_select2: Yup.object().shape({
                  value: Yup.number().min(
                    1,
                    intl.formatMessage({ id: 'to_storage_bin_is_required' })
                  )
                })
              })
            : Yup.object().shape({
                storage_bin_select2: Yup.object().shape({
                  value: Yup.number().min(
                    1,
                    intl.formatMessage({ id: 'from_storage_bin_is_required' })
                  )
                }),
                quant_bal_select2: Yup.object().shape({
                  value: Yup.number().min(1, intl.formatMessage({ id: 'quant_bal_is_required' }))
                }),
                item_cond_01_select2: Yup.object().shape({
                  value: Yup.number().min(1, intl.formatMessage({ id: 'item_cond_01_is_required' }))
                })
              });
        }}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'from_storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchFrStorageBin}
                  notFoundContent={null}
                  loading={frStorageBinIsLoading}
                  options={frStorageBinOptions}
                  disabled={disabled}
                  onChange={this.handleChangeFrStorageBin}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'quant_bal' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="quant_bal_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchQuantBal}
                  notFoundContent={null}
                  loading={quantBalIsLoading}
                  options={quantBalOptions}
                  disabled={disabled}
                  onChange={this.handleChangeQuantBal}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'pallet_id' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="handling_unit_barcode" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="item_code" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_01" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_02" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="batch_serial_no" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="expiry_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="receipt_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_cond_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_cond_01_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemCond01}
                  notFoundContent={null}
                  loading={itemCond01IsLoading}
                  options={itemCond01Options}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'to_storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="to_storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchToStorageBin}
                  notFoundContent={null}
                  loading={toStorageBinIsLoading}
                  options={toStorageBinOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon="undo"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon="save"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

WhseJobType11Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchFrStorageBinOptions: PropTypes.func,
  frStorageBinIsLoading: PropTypes.bool,
  frStorageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchQuantBalOptions: PropTypes.func,
  fetchQuantBalOptionSuccess: PropTypes.func,
  changeQuantBal: PropTypes.func,
  quantBalIsLoading: PropTypes.bool,
  quantBalOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemCond01Options: PropTypes.func,
  itemCond01IsLoading: PropTypes.bool,
  itemCond01Options: PropTypes.arrayOf(PropTypes.object),

  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinIsLoading: PropTypes.bool,
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number
};

WhseJobType11Form.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchFrStorageBinOptions() {},
  frStorageBinIsLoading: false,
  frStorageBinOptions: [],

  fetchQuantBalOptions() {},
  fetchQuantBalOptionSuccess() {},
  quantBalIsLoading: false,
  quantBalOptions: [],
  changeQuantBal() {},

  fetchItemCond01Options() {},
  itemCond01IsLoading: false,
  itemCond01Options: [],

  fetchToStorageBinOptions() {},
  toStorageBinIsLoading: false,
  toStorageBinOptions: [],

  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  hdrId: state.putAwayDetail.hdrId,
  documentHeader: state.putAwayDetail.documentHeader,
  documentDetail: state.putAwayDetail.documentDetail,
  documentIsLoading: state.putAwayDetail.documentIsLoading,

  frStorageBinIsLoading: state.putAwayDetail.frStorageBinIsLoading,
  frStorageBinOptions: state.putAwayDetail.frStorageBinOptions,

  quantBalIsLoading: state.putAwayDetail.quantBalIsLoading,
  quantBalOptions: state.putAwayDetail.quantBalOptions,

  itemCond01IsLoading: state.putAwayDetail.itemCond01IsLoading,
  itemCond01Options: state.putAwayDetail.itemCond01Options,

  toStorageBinIsLoading: state.putAwayDetail.toStorageBinIsLoading,
  toStorageBinOptions: state.putAwayDetail.toStorageBinOptions,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(PutAwayDetailActions.putAwayDetailUpdateDetails(formikBag, hdrId, documentDetails)),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(PutAwayDetailActions.putAwayDetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchFrStorageBinOptions: (siteFlowId, search) =>
    dispatch(PutAwayDetailActions.putAwayDetailFetchFrStorageBinOptions(siteFlowId, search)),
  changeFrStorageBin: (formikBag, hdrId, frStorageBinId) =>
    dispatch(
      PutAwayDetailActions.putAwayDetailChangeFrStorageBin(formikBag, hdrId, frStorageBinId)
    ),

  fetchToStorageBinOptions: (siteFlowId, search) =>
    dispatch(PutAwayDetailActions.putAwayDetailFetchToStorageBinOptions(siteFlowId, search)),

  fetchUomOptions: (itemId, search) =>
    dispatch(PutAwayDetailActions.putAwayDetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(PutAwayDetailActions.putAwayDetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(PutAwayDetailActions.putAwayDetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchItemCond01Options: search =>
    dispatch(PutAwayDetailActions.putAwayDetailFetchItemCond01Options(search)),

  fetchQuantBalOptions: (storageBinId, search) =>
    dispatch(PutAwayDetailActions.putAwayDetailFetchQuantBalOptions(storageBinId, search)),
  fetchQuantBalOptionSuccess: options =>
    dispatch(PutAwayDetailActions.putAwayDetailFetchQuantBalOptionSuccess(options)),
  changeQuantBal: (formikBag, hdrId, quantBalId) =>
    dispatch(PutAwayDetailActions.putAwayDetailChangeQuantBal(formikBag, hdrId, quantBalId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WhseJobType11Form));
