/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WarehouseMapTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const warehouseMapResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const warehouseMapSavePageSize = (state, { pageSize }) => ({
  ...state,
  pageSize
});

export const warehouseMapFetchStorageBinList02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const warehouseMapFetchStorageBinList02Success = (
  state,
  { rowLevels, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  rowLevels,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const warehouseMapSelectStorageBin = (state, { storageBin, quantBals }) => ({
  ...state,
  selectedStorageBin: storageBin,
  selectedQuantBals: quantBals
});

export const warehouseMapSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WarehouseMapTypes.WAREHOUSE_MAP_RESET_TIMESTAMP]: warehouseMapResetTimestamp,
  [WarehouseMapTypes.WAREHOUSE_MAP_SAVE_PAGE_SIZE]: warehouseMapSavePageSize,
  [WarehouseMapTypes.WAREHOUSE_MAP_FETCH_STORAGE_BIN_LIST02_LOADING]: warehouseMapFetchStorageBinList02Loading,
  [WarehouseMapTypes.WAREHOUSE_MAP_FETCH_STORAGE_BIN_LIST02_SUCCESS]: warehouseMapFetchStorageBinList02Success,

  [WarehouseMapTypes.WAREHOUSE_MAP_SELECT_STORAGE_BIN]: warehouseMapSelectStorageBin,
  [WarehouseMapTypes.WAREHOUSE_MAP_SET_WORKSPACE_VISIBLE]: warehouseMapSetWorkspaceVisible
});
