/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { SlsInvIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsInvIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const slsInvIndexFetchSlsInvIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const slsInvIndexFetchSlsInvIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const slsInvIndexAddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const slsInvIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const slsInvIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsInvIndexTypes.SLS_INV_INDEX_RESET_TIMESTAMP]: slsInvIndexResetTimestamp,
  [SlsInvIndexTypes.SLS_INV_INDEX_FETCH_SLS_INV_INDEX_LOADING]: slsInvIndexFetchSlsInvIndexLoading,
  [SlsInvIndexTypes.SLS_INV_INDEX_FETCH_SLS_INV_INDEX_SUCCESS]: slsInvIndexFetchSlsInvIndexSuccess,
  [SlsInvIndexTypes.SLS_INV_INDEX_ADD_SELECTED_DOCUMENTS]: slsInvIndexAddSelectedDocuments,
  [SlsInvIndexTypes.SLS_INV_INDEX_REMOVE_SELECTED_DOCUMENTS]: slsInvIndexRemoveSelectedDocuments,
  [SlsInvIndexTypes.SLS_INV_INDEX_SET_WORKSPACE_VISIBLE]: slsInvIndexSetWorkspaceVisible
});
