import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import DebtorSync01Actions from '../Stores/DebtorSync01/Actions';

const getAppStore = state => state.app;

export function* debtorSync01ShowSyncSetting({ divisionId }) {
  try {
    yield put(DebtorSync01Actions.debtorSync01ShowSyncSettingLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `syncSetting/showDivisionSetting/SYNC_EFICHAIN_01/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DebtorSync01Actions.debtorSync01ShowSyncSettingSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorSync01Actions.debtorSync01ShowSyncSettingLoading(false));
  }
}

export function* debtorSync01UpdateSyncSetting({ formikBag, syncSetting }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: syncSetting
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'syncSetting/updateSetting',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* debtorSync01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/DEBTOR_SYNC_01`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DebtorSync01Actions.debtorSync01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  }
}

export function* debtorSync01SyncNow({ formikBag, divisionId }) {
  try {
    formikBag.setSubmitting(true);
    yield put(DebtorSync01Actions.debtorSync01SyncLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/syncProcess/DEBTOR_SYNC_01/1/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DebtorSync01Actions.debtorSync01SyncLoading(false));
  }
}
