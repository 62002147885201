import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  promoProcessFetchPromoProcess: ['siteFlowId'],
  promoProcessFetchPromoProcessLoading: ['boolean'],
  promoProcessFetchPromoProcessSuccess: ['process']
});

export const PromoProcessTypes = Types;
export default Creators;
