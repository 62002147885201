import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Card, Button, Row, Col, Typography, Popconfirm, Radio } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikText,
  FormikButton,
  FormikRadio,
} from '../../Components/Formik';
import DebtorDetailActions from '../../Stores/DebtorDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

const statusOptions = [
  <Radio.Button key={100} value={100}>
    {'ACTIVE'}
  </Radio.Button>,
  <Radio.Button key={2} value={2}>
    {'INACTIVE'}
  </Radio.Button>,
];

class HeaderForm extends React.PureComponent {
  // constructor() {
  //   super();
  // }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { match, hdrId, initHeader, showHeader, curDivisionId } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId <= 0) {
        initHeader(curDivisionId);
      } else {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  // handleSalesmanSearch(form, value) {
  //   const { fetchSalesmanOptions } = this.props;

  //   fetchSalesmanOptions(value);
  // }

  // handleDeliveryPointSearch(form, value) {
  //   const { fetchDeliveryPointOptions } = this.props;

  //   fetchDeliveryPointOptions(value);
  // }

  // handleCreditTermSearch(form, value) {
  //   const { fetchCreditTermOptions } = this.props;

  //   fetchCreditTermOptions(value);
  // }

  // handleCurrencySearch(form, value) {
  //   const { fetchCurrencyOptions } = this.props;

  //   fetchCurrencyOptions(value);
  // }

  // handleChangeDeliveryPoint(form, value) {
  //   // params: form, value, option
  //   const { changeDeliveryPoint } = this.props;

  //   changeDeliveryPoint(form, value);
  // }

  // handleChangeCurrency(form, value) {
  //   // params: form, value, option
  //   const { changeCurrency } = this.props;

  //   changeCurrency(form, value);
  // }

  useOnDeleteItem() {
    const { hdrId, deleteHeader} = this.props;

    deleteHeader(hdrId);
  }


  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      transitionToStatus,
      createHeader,
      updateHeader,
      historyGoBack,
      documentIsLoading,
      curDivisionId,
    } = this.props;

    const disabled = documentHeader && documentHeader.status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values, curDivisionId);
            } else {
              updateHeader(formikBag, values);
            }
          }
        }}
        validationSchema={Yup.object().shape({
          doc_no_id:
            match.params.action === 'create'
              ? Yup.number().moreThan(0, intl.formatMessage({ id: 'doc_code_is_required' }))
              : '',
          doc_date: Yup.date().min(
            new Date(1970, 1, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          ),
          credit_term_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'credit_term_is_required' }))
          }),
          currency_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'currency_is_required' }))
          }),
          currency_rate: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'currency_rate_must_be_greater_than_0' })
          )
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'basic_data' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'debtor_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  {match.params.action === 'create' ? (
                    <Field
                      name="code"
                      component={FormikInput}
                      // disabled={disabled}
                    />
                  ) : (
                    <Field 
                      name="code" 
                      component={FormikInput}
                      // disabled={disabled}
                    />
                  )}
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="status"
                    component={FormikRadio}
                    options={statusOptions}
                    selected={statusOptions.key === values.status}
                    // disabled={disabled}
                  />
                </Col>
              </Row>

              {/* <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="submit_action"
                    value="void"
                    component={FormikButton}
                    style={
                      values.doc_status === 2 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 2 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'void'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="draft"
                    component={FormikButton}
                    style={
                      values.doc_status === 3 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 3 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'draft'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="wip"
                    component={FormikButton}
                    style={
                      values.doc_status === 50 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 50 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'wip'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="complete"
                    component={FormikButton}
                    style={
                      values.doc_status === 100 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 100 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'complete'
                    })}
                  />
                </Col>
              </Row> */}

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'debtor_company_name_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="company_name_01" component={FormikInput} />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'debtor_company_name_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="company_name_02" component={FormikInput}  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'address_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="address_01" component={FormikInput}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'address_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="address_02" component={FormikInput}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'address_03' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="address_03" component={FormikInput}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'address_04' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="address_04" component={FormikInput}
                  />
                </Col>
              </Row>
              
              
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'attention' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="attention" component={FormikInput} />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="ref_code_01" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'phone_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="phone_01" component={FormikInput} />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'phone_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="phone_02" component={FormikInput} />
                </Col>
              </Row>


              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'fax_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="fax_01" component={FormikInput} />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'fax_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="fax_02" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'email_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="email_01" component={FormikInput} />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'email_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="email_02" component={FormikInput} />
                </Col>
              </Row>
            </Card>

            <Card>
              <Row type="flex" justify="space-between" gutter={[16, 16]}>
                <Col span={9}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon="arrow-left"
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    disabled={values.doc_status >= 50}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon="save"
                  />
                  <Popconfirm
                    placement="left"
                    title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
                    onConfirm={() => this.useOnDeleteItem()}
                    onCancel={() => {}}
                    okText={intl.formatMessage({ id: 'yes' })}
                    cancelText={intl.formatMessage({ id: 'cancel' })}
                  >
                    <Field
                      type="danger"
                      component={FormikButton}
                      disabled={values.doc_status >= 50}
                      loading={isSubmitting || documentIsLoading}
                      label={intl.formatMessage({
                        id: 'delete'
                      })}
                      icon="delete"
                    />
                  </Popconfirm>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  deleteHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},
  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  deleteHeader() {},
  historyGoBack() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false,

  curDivisionId: 0
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.debtorDetail.timestamp,
  hdrId: state.debtorDetail.hdrId,
  documentHeader: state.debtorDetail.documentHeader,
  documentIsLoading: state.debtorDetail.documentIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  initHeader: curDivisionId => dispatch(DebtorDetailActions.debtorDetailInitHeader(curDivisionId)),
  showHeader: hdrId => dispatch(DebtorDetailActions.debtorDetailShowHeader(hdrId)),
  transitionToStatus: (formikBag, hdrId, docStatus) =>
    dispatch(DebtorDetailActions.debtorDetailTransitionToStatus(formikBag, hdrId, docStatus)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(DebtorDetailActions.debtorDetailUpdateHeader(formikBag, documentHeader)),
  createHeader: (formikBag, documentHeader, curDivisionId) =>
    dispatch(DebtorDetailActions.debtorDetailCreateHeader(formikBag, documentHeader, curDivisionId)),
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  deleteHeader: (hdrId) =>
    dispatch(DebtorDetailActions.debtorDetailDeleteHeader(hdrId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HeaderForm));
