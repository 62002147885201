import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { push, replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import DebtorDetailActions from '../Stores/DebtorDetail/Actions';

const getAppStore = state => state.app;

const getDebtorDetailStore = state => state.debtorDetail;

export function* debtorDetailInitHeader({ divisionId }) {
  try {
    yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/initHeader/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DebtorDetailActions.debtorDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(false));
  }
}

export function* debtorDetailShowHeader({ hdrId }) {
  try {
    yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DebtorDetailActions.debtorDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(false));
  }
}

export function* debtorDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'debtor/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const debtorDetail = yield select(getDebtorDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = debtorDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        DebtorDetailActions.debtorDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(false));
  }
}

export function* debtorDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };
    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'debtor/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const debtorDetail = yield select(getDebtorDetailStore);

      const {
        documentHeader: oldDocumentHeader
      } = debtorDetail;

      const {
        model: retDocumentHeader
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        [],
        retDocumentHeader,
        [], []
      );

      yield put(
        DebtorDetailActions.debtorDetailUpdateDocumentSuccess(processed.header)
      );
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}


export function* debtorDetailCreateHeader({ formikBag, documentHeader, divisionId }) {
    formikBag.setSubmitting(true);
    try {
      const app = yield select(getAppStore);
  
      const postData = {
        data: documentHeader
      };
  
      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `debtor/createHeader/${divisionId}`,
        app.token,
        postData // params
      );
  
      if (result.isSuccess === true) {
        yield put(replace(`${app.appPath}/debtorDetail/update/${result.data}`));
        yield put(DebtorDetailActions.debtorDetailSetHdrId(result.data));
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
        });
      }
    } catch (error) {
      yield call(notification.error, {
        message: error.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  }
  
export function* debtorDetailDeleteHeader({ hdrId }) {
  yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: { id: hdrId }
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `debtor/deleteHeader`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/debtorProcess/index/DEBTOR_LIST_01`));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorDetailActions.debtorDetailShowDocumentLoading(false));
  }
}
