import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import treeChanges from 'tree-changes';

import { Card, Modal, Breadcrumb } from 'antd';
import BannerForm from './BannerForm';
import HeaderForm from './HeaderForm';
import DetailTable from './DetailTable';
import DetailForm from './DetailForm';
import DetailAddOnForm from './DetailAddOnForm';
import RuleTable from './RuleTable';
import RuleForm from './RuleForm';
import BroadcastForm from './BroadcastForm'
import PromoDetailActions from '../../Stores/PromoDetail/Actions';
import PromoDiscIndexActions from '../../Stores/PromoDiscIndex/Actions'

class PromoDetailScreen extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      const hdrId = parseInt(match.params.id, 10);
      setHdrId(hdrId);
    }
  }

  componentDidUpdate(prevProps) {
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      this.forceUpdate()
    }
  }

  componentWillUnmount() {
    const { resetUploadHold } = this.props
    
    resetUploadHold(false, [])
  }

  handleCancel() {
    const { setDetailVisible,setDetailAddOnVisible, setRuleVisible, setRecipientVisible, documentIsLoading } = this.props;

    if (documentIsLoading === false) {
      setDetailVisible(false);
      setDetailAddOnVisible(false);
      setRuleVisible(false);
      setRecipientVisible(false)
    }
  }

  render() {
    const { intl, match, detailIsVisible, detailAddOnIsVisible, ruleIsVisible, recipientIsVisible, documentHeader, appPath } = this.props;

    var title = '';
    if (match.params.action === 'create') {
      if (documentHeader.type == 'disc_percent') {
        title = intl.formatMessage({ id: 'new_percent' });
      }
      else if (documentHeader.type == 'disc_fixed_price') {
        title = intl.formatMessage({ id: 'new_fixed_price' });
      }
      else if (documentHeader.type == 'foc') {
        title = intl.formatMessage({ id: 'new_foc' });
      }
      else
      {
        title = '';
      }
    }
    else {
      if (documentHeader.type == 0) {
        title = intl.formatMessage({ id: 'discount_percent' });
      }
      else if (documentHeader.type == 1) {
        title = intl.formatMessage({ id: 'discount_fixed' });
      }
      else if (documentHeader.type == 2) {
        title = intl.formatMessage({ id: 'foc' });
      }
      else
      {
        title = documentHeader.type;
      }
    }
    title = title + ' ' + intl.formatMessage({ id: 'promo' });

    return (
      <Card title={title}>
        <Breadcrumb></Breadcrumb>
        <BannerForm />
        <HeaderForm match={match} />
        <DetailTable promoType={documentHeader.type} />

        <Modal
          visible={detailIsVisible}
          title={intl.formatMessage({ id: 'promo_detail' })}
          // style={{top:20}}
          width="90%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <DetailForm match={match} promoType={documentHeader.type} />
        </Modal>
        
        <Modal
          visible={detailAddOnIsVisible}
          title={intl.formatMessage({ id: 'promo_detail_add_on' })}
          // style={{top:20}}
          width="90%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <DetailAddOnForm match={match} promoType={documentHeader.type} />
        </Modal>

        <RuleTable match={match} />
        <Modal
          visible={ruleIsVisible}
          title={intl.formatMessage({ id: 'promo_rule' })}
          // style={{top:20}}
          width="90%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <RuleForm match={match} />
        </Modal>

        <BroadcastForm />
      </Card>
    );
  }
}

PromoDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  setHdrId: PropTypes.func,
  setDetailVisible: PropTypes.func,
  setDetailAddOnVisible: PropTypes.func,
  setRuleVisible: PropTypes.func,
  documentIsLoading: PropTypes.bool,
  detailIsVisible: PropTypes.bool,
  detailAddOnIsVisible: PropTypes.bool,
  ruleIsVisible: PropTypes.bool,
  recipientIsVisible: PropTypes.bool,
  documentHeader: PropTypes.shape({
    doc_flows: PropTypes.arrayOf(PropTypes.object)
  }),
  appPath: PropTypes.string
};

PromoDetailScreen.defaultProps = {
  intl: {},
  match: {},
  setHdrId() {},
  setDetailVisible() {},
  setDetailAddOnVisible() {},
  setRuleVisible() {},
  documentIsLoading: false,
  detailIsVisible: false,
  detailAddOnIsVisible: false,
  ruleIsVisible: false,
  recipientIsVisible: false,
  documentHeader: { doc_flows: [] },
  appPath: ''
};

const mapStateToProps = state => ({
  documentIsLoading: state.promoDetail.documentIsLoading,
  detailIsVisible: state.promoDetail.detailIsVisible,
  detailAddOnIsVisible: state.promoDetail.detailAddOnIsVisible,
  ruleIsVisible: state.promoDetail.ruleIsVisible,
  recipientIsVisible: state.promoDetail.recipientIsVisible,
  documentHeader: state.promoDetail.documentHeader,
  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setHdrId: hdrId => dispatch(PromoDetailActions.promoDetailSetHdrId(hdrId)),
  setDetailVisible: boolean => dispatch(PromoDetailActions.promoDetailSetDetailVisible(boolean)),
  setDetailAddOnVisible: boolean => dispatch(PromoDetailActions.promoDetailSetDetailAddOnVisible(boolean)),
  setRuleVisible: boolean => dispatch(PromoDetailActions.promoDetailSetRuleVisible(boolean)),
  setRecipientVisible: boolean => dispatch(PromoDetailActions.promoDetailSetRecipientVisible(boolean)),
  resetUploadHold: (boolean, fileList) => dispatch(PromoDiscIndexActions.promoDiscListUploadPhotoHold(boolean, fileList))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PromoDetailScreen));
