import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Card, Button, Row, Col, Typography, Radio } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikButton, FormikSelect, FormikRadio } from '../../Components/Formik';
import ItemDetailActions from '../../Stores/ItemDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

const statusOptions = [
  <Radio.Button key={100} value={100}>
    {'ACTIVE'}
  </Radio.Button>,
  <Radio.Button key={50} value={50}>
    {'PENDING'}
  </Radio.Button>,
  <Radio.Button key={2} value={2}>
    {'INACTIVE'}
  </Radio.Button>
];

class HeaderForm extends React.PureComponent {
  constructor() {
    super();
    this.handleBrandSearch = this.handleBrandSearch.bind(this);
    this.handleCategorySearch = this.handleCategorySearch.bind(this);
    this.handleManufacturerSearch = this.handleManufacturerSearch.bind(this);
    // this.handleDebtorSearch = this.handleDebtorSearch.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { match, hdrId, initHeader, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId <= 0) {
        initHeader(match.params.id);
      } else {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  handleBrandSearch(form, value) {
    const { fetchBrandOptions } = this.props;

    fetchBrandOptions(value);
  }

  handleCategorySearch(form, value) {
    const { fetchCategoryOptions } = this.props;

    fetchCategoryOptions(value);
  }

  handleManufacturerSearch(form, value) {
    const { fetchManufacturerOptions } = this.props;

    fetchManufacturerOptions(value);
  }

  // handleDebtorSearch(form, value) {
  //   const { fetchDebtorOptions } = this.props;

  //   fetchDebtorOptions(value);
  // }

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      transitionToStatus,
      createHeader,
      updateHeader,
      historyGoBack,
      // goToChangePassword,
      documentIsLoading,
      brandIsLoading,
      categoryIsLoading,
      manufacturerIsLoading,
      brandOptions,
      categoryOptions,
      manufacturerOptions,
      curDivisionId
      // debtorIsLoading,
      // debtorOptions,
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values, curDivisionId);
            } else {
              updateHeader(formikBag, values);
            }
          } else if (values.submit_action === 'void') {
            transitionToStatus(formikBag, hdrId, 'VOID');
          } else if (values.submit_action === 'draft') {
            transitionToStatus(formikBag, hdrId, 'DRAFT');
          } else if (values.submit_action === 'wip') {
            transitionToStatus(formikBag, hdrId, 'WIP');
          } else if (values.submit_action === 'complete') {
            transitionToStatus(formikBag, hdrId, 'COMPLETE');
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          brand_id_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'brand_is_required' }))
          }),
          category_id_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'category_is_required' }))
          }),
          manufacturer_id_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'manufacturer_is_required' }))
          })
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'basic_data' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="code" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={12}>
                  <Field name="desc_01" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={8} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={12}>
                  <Field name="desc_02" component={FormikInput} disabled={disabled} />
                </Col>
                <Col span={8} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'brand' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="brand_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleBrandSearch}
                    notFoundContent={null}
                    loading={brandIsLoading}
                    options={brandOptions}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'category' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="category_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCategorySearch}
                    notFoundContent={null}
                    loading={categoryIsLoading}
                    options={categoryOptions}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'manufacturer' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="manufacturer_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleManufacturerSearch}
                    notFoundContent={null}
                    loading={manufacturerIsLoading}
                    options={manufacturerOptions}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="status"
                    component={FormikRadio}
                    options={statusOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </Card>

            <Card>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon="arrow-left"
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={9}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon="undo"
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon="save"
                  />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  // goToChangePassword: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool,
  brandIsLoading: PropTypes.bool,
  categoryIsLoading: PropTypes.bool,
  manufacturerIsLoading: PropTypes.bool,
  fetchBrandOptions: PropTypes.func,
  fetchCategoryOptions: PropTypes.func,
  fetchManufacturerOptions: PropTypes.func,
  curDivisionId: PropTypes.number
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},
  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  // goToChangePassword() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false,
  brandIsLoading: false,
  categoryIsLoading: false,
  manufacturerIsLoading: false,
  fetchBrandOptions() {},
  fetchCategoryOptions() {},
  fetchManufacturerOptions() {},
  curDivisionId: 0

  // fetchDebtorOptions() {},
  // debtorIsLoading: false,
  // debtorOptions: [],
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.itemDetail.timestamp,
  hdrId: state.itemDetail.hdrId,
  documentHeader: state.itemDetail.documentHeader,
  documentIsLoading: state.itemDetail.documentIsLoading,
  brandIsLoading: state.itemDetail.brandIsLoading,
  categoryIsLoading: state.itemDetail.CategoryIsLoading,
  manufacturerIsLoading: state.itemDetail.ManufacturerIsLoading,
  brandOptions: state.itemDetail.brandOptions,
  categoryOptions: state.itemDetail.categoryOptions,
  manufacturerOptions: state.itemDetail.manufacturerOptions,
  curDivisionId: state.app.curDivisionId
  // debtorIsLoading: state.itemDetail.debtorIsLoading,
  // debtorOptions: state.itemDetail.debtorOptions,
});

const mapDispatchToProps = dispatch => ({
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  initHeader: login_type => dispatch(ItemDetailActions.itemDetailInitHeader(login_type)),
  showHeader: hdrId => dispatch(ItemDetailActions.itemDetailShowHeader(hdrId)),
  createHeader: (formikBag, documentHeader, divisionId) =>
    dispatch(ItemDetailActions.itemDetailCreateHeader(formikBag, documentHeader, divisionId)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(ItemDetailActions.itemDetailUpdateHeader(formikBag, documentHeader)),
  fetchBrandOptions: search => dispatch(ItemDetailActions.itemDetailFetchBrandOptions(search)),
  fetchCategoryOptions: search =>
    dispatch(ItemDetailActions.itemDetailFetchCategoryOptions(search)),
  fetchManufacturerOptions: search =>
    dispatch(ItemDetailActions.itemDetailFetchManufacturerOptions(search))

  // fetchDebtorOptions: search =>
  //   dispatch(ItemDetailActions.itemDetailFetchDebtorOptions(search))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HeaderForm));
