import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cycleCount02DetailResetTimestamp: null,
  cycleCount02DetailSetHdrId: ['hdrId'],

  cycleCount02DetailShowDetails: ['hdrId'],
  cycleCount02DetailShowDocumentLoading: ['boolean'],
  cycleCount02DetailShowDetailsSuccess: ['documentDetails'],

  cycleCount02DetailAutoConfirm: ['hdrId'],

  cycleCount02DetailUpdateRecount: ['dtlId', 'action'],
  cycleCount02DetailCreateCycleCount02: ['siteFlowId', 'groupCount', 'dtlIds'],
  cycleCount02DetailCreateCycleCount02Success: ['newDocuments'],

  cycleCount02DetailSetWorkspaceVisible: ['boolean']
});

export const CycleCount02DetailTypes = Types;
export default Creators;
