import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  debtorList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  debtorList01NewDocument: [],
  debtorList01GoToDocument: ['hdrId'],
  debtorList01ChangeStatus: ['hdrId', 'status', 'curDivisionId', 'currentPage', 'sorts', 'filters', 'pageSize',],
  debtorList01GoToAudit: ['hdrId'],
  debtorList01FetchDebtorList01: ['divisionId', 'currentPage', 'sorts', 'filters', 'pageSize','status'],
  debtorList01FetchDebtorList01Loading: ['boolean'],
  debtorList01FetchDebtorList01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  debtorList01AddSelectedDocuments: ['selectedDocuments'],
  debtorList01RemoveSelectedDocuments: ['selectedDocuments'],
  debtorList01SetWorkspaceVisible: ['boolean'],

  debtorList01SetManageDivisionVisible: ['document', 'boolean'],
  debtorList01RemoveDivision: ['debtorId', 'divisionId'],
  debtorList01AddDivision: ['debtorId', 'divisionId'],
  debtorList01ManageDivisionSuccess: ['divisions'],
  debtorList01ManageDivisionLoading: ['boolean'],

  debtorList01FetchDivisionOptions: ['debtorId', 'search'],
  debtorList01FetchDivisionOptionLoading: ['boolean'],
  debtorList01FetchDivisionOptionSuccess: ['options'],
  debtorList01SetDivisionOption: ['option'],
  
  debtorList01SetExpandedRows: ['expandedRows'],
});

export const DebtorList01Types = Types;
export default Creators;
