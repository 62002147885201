import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  purchaseProcessFetchPurchaseProcess: ['divisionId'],
  purchaseProcessFetchPurchaseProcessLoading: ['boolean'],
  purchaseProcessFetchPurchaseProcessSuccess: ['process']
});

export const PurchaseProcessTypes = Types;
export default Creators;
