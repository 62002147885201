import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Card, Modal } from 'antd';
import DetailTable from './DetailTable';
import WorkspaceDialog from './WorkspaceDialog';
import CycleCount02DetailActions from '../../Stores/CycleCount02Detail/Actions';

class CycleCount02DetailScreen extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      setHdrId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setDetailVisible, documentIsLoading } = this.props;

    if (documentIsLoading === false) {
      setDetailVisible(false);
    }
  }

  handleSelectChange(value) {
    const { setWhseJobType } = this.props;

    setWhseJobType(value);
  }

  render() {
    const { intl, match, detailIsVisible } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'recount' })}>
        <DetailTable match={match} />
        <Modal
          visible={detailIsVisible}
          title={intl.formatMessage({ id: 'create_cycle_count_from_recount' })}
          // style={{top:20}}
          width="90%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        />

        <WorkspaceDialog />
      </Card>
    );
  }
}

CycleCount02DetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  setHdrId: PropTypes.func,
  setDetailVisible: PropTypes.func,
  setWhseJobType: PropTypes.func,
  documentIsLoading: PropTypes.bool,
  detailIsVisible: PropTypes.bool
};

CycleCount02DetailScreen.defaultProps = {
  intl: {},
  match: {},
  setHdrId() {},
  setDetailVisible() {},
  setWhseJobType() {},
  documentIsLoading: false,
  detailIsVisible: false
};

const mapStateToProps = state => ({
  documentIsLoading: state.cycleCount02Detail.documentIsLoading,
  detailIsVisible: state.cycleCount02Detail.detailIsVisible,
  jobDetailIsVisible: state.cycleCount02Detail.jobDetailIsVisible,

  whseJobType: state.cycleCount02Detail.whseJobType
});

const mapDispatchToProps = dispatch => ({
  setHdrId: hdrId => dispatch(CycleCount02DetailActions.cycleCount02DetailSetHdrId(hdrId)),
  setDetailVisible: boolean =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailSetDetailVisible(boolean)),
  setJobDetailVisible: boolean =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailSetJobDetailVisible(boolean)),

  setWhseJobType: whseJobType =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailSetWhseJobType(whseJobType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CycleCount02DetailScreen));
