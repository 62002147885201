import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  auditLogResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  auditLogSetAuditable: ['auditableId'],
  auditLogFetchAuditLog: ['auditableId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  auditLogFetchAuditLogLoading: ['boolean'],
  auditLogFetchAuditLogSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],

  auditLogSetExpandedRows: ['expandedRows']
});

export const AuditLogTypes = Types;
export default Creators;
