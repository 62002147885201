import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import CartDetailActions from '../Stores/CartDetail/Actions';

const getAppStore = state => state.app;

const getCartDetailStore = state => state.cartDetail;

export function* cartDetailInitHeader({ divisionId }) {
  try {
    yield put(CartDetailActions.cartDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `cart/initHeader/${divisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CartDetailActions.cartDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailShowDocumentLoading(false));
  }
}

export function* cartDetailShowHeader({ hdrId }) {
  try {
    yield put(CartDetailActions.cartDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `cart/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CartDetailActions.cartDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailShowDocumentLoading(false));
  }
}

export function* cartDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(CartDetailActions.cartDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'cart/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const cartDetail = yield select(getCartDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = cartDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CartDetailActions.cartDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CartDetailActions.cartDetailShowDocumentLoading(false));
  }
}

export function* cartDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'cart/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const cartDetail = yield select(getCartDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = cartDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CartDetailActions.cartDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* cartDetailShowDetails({ hdrId }) {
  try {
    yield put(CartDetailActions.cartDetailShowDocumentLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (hdrId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `cart/showDetails/${hdrId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(CartDetailActions.cartDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailShowDocumentLoading(false));
  }
}

export function* cartDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(CartDetailActions.cartDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `cart/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const cartDetail = yield select(getCartDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = cartDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CartDetailActions.cartDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CartDetailActions.cartDetailShowDocumentLoading(false));
  }
}

export function* cartDetailFetchSalesmanOptions({ search }) {
  try {
    yield put(CartDetailActions.cartDetailFetchSalesmanOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(CartDetailActions.cartDetailFetchSalesmanOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailFetchSalesmanOptionLoading(false));
  }
}

export function* cartDetailFetchDeliveryPointOptions({ search }) {
  try {
    yield put(CartDetailActions.cartDetailFetchDeliveryPointOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01}`
      }));

      yield put(CartDetailActions.cartDetailFetchDeliveryPointOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailFetchDeliveryPointOptionLoading(false));
  }
}

export function* cartDetailFetchCreditTermOptions({ search }) {
  try {
    yield put(CartDetailActions.cartDetailFetchCreditTermOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `creditTerm/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(CartDetailActions.cartDetailFetchCreditTermOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailFetchCreditTermOptionLoading(false));
  }
}

export function* cartDetailFetchCurrencyOptions({ search }) {
  try {
    yield put(CartDetailActions.cartDetailFetchCurrencyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `currency/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(CartDetailActions.cartDetailFetchCurrencyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailFetchCurrencyOptionLoading(false));
  }
}

export function* cartDetailChangeDeliveryPoint({ formikBag, deliveryPointId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      deliveryPointId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cart/changeDeliveryPoint`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* cartDetailChangeCurrency({ formikBag, currencyId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      currencyId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cart/changeCurrency`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* cartDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'cart/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/cartDetail/update/${result.data}`));
      yield put(CartDetailActions.cartDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* cartDetailFetchItemOptions({ search }) {
  try {
    yield put(CartDetailActions.cartDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(CartDetailActions.cartDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailFetchItemOptionLoading(false));
  }
}

export function* cartDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cart/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(CartDetailActions.cartDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* cartDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cart/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* cartDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(CartDetailActions.cartDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.uom_id,
        label: `${d.uom_code}`
      }));

      yield put(CartDetailActions.cartDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailFetchUomOptionLoading(false));
  }
}

export function* cartDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(CartDetailActions.cartDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cart/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const cartDetail = yield select(getCartDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = cartDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CartDetailActions.cartDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CartDetailActions.cartDetailShowDocumentLoading(false));
  }
}

export function* cartDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(CartDetailActions.cartDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `cart/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const cartDetail = yield select(getCartDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = cartDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CartDetailActions.cartDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CartDetailActions.cartDetailShowDocumentLoading(false));
  }
}
