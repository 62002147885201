import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Row, Col, Table, Popconfirm, Button, Popover, Icon, Badge } from 'antd';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import AntDesignTable from '../../Components/AntDesignTable';
import CycleCount02DetailActions from '../../Stores/CycleCount02Detail/Actions';
import AppActions from '../../Stores/App/Actions';

class DetailTable extends React.PureComponent {
  constructor() {
    super();

    this.state = { pageSize: 20, filters: {} };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);

    this.useConfirmDtl = this.useConfirmDtl.bind(this);
    this.useDropDtl = this.useDropDtl.bind(this);
    this.useRecountDtl = this.useRecountDtl.bind(this);
    this.useResetRecountDtl = this.useResetRecountDtl.bind(this);

    this.useOnRefresh = this.useOnRefresh.bind(this);
    this.useOnAutoConfirm = this.useOnAutoConfirm.bind(this);
    this.useOnDownloadCsv = this.useOnDownloadCsv.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showDetails } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      if (hdrId > 0) {
        showDetails(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, documentDetails } = this.props;
    const { filters } = this.state;

    const jobFilterValues = {};
    const groupFilterValues = {};
    const physicalCountStatusFilterValues = {};
    documentDetails.forEach(entry => {
      jobFilterValues[entry.job_no] = { text: entry.job_no, value: entry.job_no };
      groupFilterValues[entry.group_no] = { text: entry.group_no, value: entry.group_no };
      physicalCountStatusFilterValues[entry.str_physical_count_status] = {
        text: entry.str_physical_count_status,
        value: entry.str_physical_count_status
      };
    });

    return [
      {
        fixed: 'left',
        width: 50,
        align: 'right',
        title: '#',
        // sort field
        dataIndex: 'line_no',
        sorter: (a, b) => a.line_no - b.line_no,
        // filter field
        key: 'line_no',
        render: (text, record) => <span style={{ wordBreak: 'normal' }}>{record.line_no}</span>
      },
      {
        fixed: 'left',
        width: 70,
        align: 'right',
        title: (
          <Popover
            title={intl.formatMessage({ id: 'job' })}
            content={intl.formatMessage({ id: 'job_no' })}
            trigger="hover"
          >
            J
          </Popover>
        ),
        // sort field
        dataIndex: 'job_no',
        sorter: (a, b) => a.job_no - b.job_no,
        // filter field
        key: 'job_no',
        filters: Object.values(jobFilterValues),
        onFilter: (value, record) => record.job_no === value,
        render: (text, record) => <>{record.job_no}</>
      },
      {
        fixed: 'left',
        width: 70,
        align: 'right',
        title: (
          <Popover
            title={intl.formatMessage({ id: 'group' })}
            content={intl.formatMessage({ id: 'group_no' })}
            trigger="hover"
          >
            G
          </Popover>
        ),
        // sort field
        dataIndex: 'group_no',
        sorter: (a, b) => a.group_no - b.group_no,
        // filter field
        key: 'group_no',
        filters: Object.values(groupFilterValues),
        onFilter: (value, record) => record.group_no === value,
        render: (text, record) => record.group_no
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_code',
        sorter: (a, b) => `${a.storage_bin_code}`.localeCompare(b.storage_bin_code),
        // filter field
        key: 'storage_bin_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'storage_bin_code',
          // handleSearch
          (selectedKeys, confirm) => {
            confirm();
            this.setState({
              filters: { ...filters, storage_bin_code: selectedKeys[0] }
            });
          },
          // handleReset
          clearFilters => {
            clearFilters();
            this.setState({
              filters: { ...filters, storage_bin_code: '' }
            });
          },
          // onFilter
          (value, record) =>
            record.storage_bin_code
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        ),
        render: (text, record) => <>{record.storage_bin_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        sorter: (a, b) => `${a.item_code}`.localeCompare(b.item_code),
        // filter field
        key: 'item_code',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'item_code',
          // handleSearch
          (selectedKeys, confirm) => {
            confirm();
            this.setState({
              filters: { ...filters, item_code: selectedKeys[0] }
            });
          },
          // handleReset
          clearFilters => {
            clearFilters();
            this.setState({
              filters: { ...filters, item_code: '' }
            });
          },
          // onFilter
          (value, record) =>
            record.item_code
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        ),
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'pallet_id' }),
        // sort field
        dataIndex: 'handling_unit_barcode',
        sorter: (a, b) => `${a.handling_unit_barcode}`.localeCompare(b.handling_unit_barcode),
        // filter field
        key: 'handling_unit_barcode',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'handling_unit_barcode',
          // handleSearch
          (selectedKeys, confirm) => {
            confirm();
            this.setState({
              filters: { ...filters, handling_unit_barcode: selectedKeys[0] }
            });
          },
          // handleReset
          clearFilters => {
            clearFilters();
            this.setState({
              filters: { ...filters, handling_unit_barcode: '' }
            });
          },
          // onFilter
          (value, record) =>
            record.handling_unit_barcode
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        ),
        render: (text, record) => <>{record.handling_unit_barcode}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchProps(
          filters,
          intl.formatMessage({ id: 'desc' }),
          'desc_01',
          // handleSearch
          (selectedKeys, confirm) => {
            confirm();
            this.setState({
              filters: { ...filters, desc_01: selectedKeys[0] }
            });
          },
          // handleReset
          clearFilters => {
            clearFilters();
            this.setState({
              filters: { ...filters, desc_01: '' }
            });
          },
          // onFilter
          (value, record) =>
            record.desc_01
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        ),
        render: (text, record) => (
          <>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'item_batch' }),
        // sort field
        dataIndex: 'expiry_date',
        sorter: (a, b) => new Date(a.expiry_date) - new Date(b.expiry_date),
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'balance_qty' }),
        // sort field
        dataIndex: 'balance_unit_qty',
        sorter: (a, b) => a.balance_unit_qty - b.balance_unit_qty,
        // filter field
        key: 'balance_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.balance_unit_qty)
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'count_qty' }),
        // sort field
        dataIndex: 'ttl_count_unit_qty',
        sorter: (a, b) => a.ttl_count_unit_qty - b.ttl_count_unit_qty,
        // filter field
        key: 'ttl_count_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.ttl_count_unit_qty)
      },
      {
        width: 100,
        align: 'right',
        title: `${intl.formatMessage({ id: 'variance' })} %`,
        // sort field
        dataIndex: 'variance_perc',
        sorter: (a, b) => a.variance_perc - b.variance_perc,
        // filter field
        key: 'variance_perc',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.variance_perc)
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_physical_count_status',
        sorter: (a, b) =>
          `${a.str_physical_count_status}`.localeCompare(b.str_physical_count_status),
        // filter field
        key: 'str_physical_count_status',
        filters: Object.values(physicalCountStatusFilterValues),
        onFilter: (value, record) => record.str_physical_count_status === value,
        render: (text, record) => intl.formatMessage({ id: record.str_physical_count_status })
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => record.updated_at
      },

      {
        width: 90,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code} - ${record.item_code}`}
              icon={<Icon type="check" style={{ color: '#34A835' }} />}
              onConfirm={() => this.useConfirmDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_confirm_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon="check"
                style={{ backgroundColor: '#34A835' }}
              />
            </Popconfirm>
            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code} - ${record.item_code}`}
              icon={<Icon type="close" style={{ color: '#007ad9' }} />}
              onConfirm={() => this.useDropDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_drop_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon="close"
                style={{ backgroundColor: '#007ad9' }}
              />
            </Popconfirm>

            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code} - ${record.item_code}`}
              icon={<Icon type="form" style={{ color: '#e91224' }} />}
              onConfirm={() => this.useRecountDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_recount_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon="form"
                style={{ backgroundColor: '#e91224' }}
              />
            </Popconfirm>
            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code} - ${record.item_code}`}
              icon={<Icon type="redo" style={{ color: '#ffba01' }} />}
              onConfirm={() => this.useResetRecountDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_reset_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon="redo"
                style={{ backgroundColor: '#ffba01' }}
              />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useConfirmDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'MARK_CONFIRMED');
  }

  useDropDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'MARK_DROPPED');
  }

  useRecountDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'MARK_RECOUNT');
  }

  useResetRecountDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'RESET');
  }

  useOnRefresh() {
    const { resetTimestamp } = this.props;

    resetTimestamp();
  }

  useOnAutoConfirm() {
    const { hdrId, autoConfirm } = this.props;

    autoConfirm(hdrId);
  }

  useOnDownloadCsv() {
    const { documentDetails } = this.props;

    const mappedDetails = documentDetails.map(entry => {
      return {
        line_no: entry.line_no,
        job_no: entry.job_no,
        group_no: entry.group_no,
        storage_bin_code: entry.storage_bin_code,
        item_code: entry.item_code,
        pallet_id: entry.handling_unit_barcode,
        desc_01: entry.desc_01,
        desc_02: entry.desc_02,
        batch_serial_no: entry.batch_serial_no,
        expiry_date: entry.expiry_date,
        receipt_date: entry.receipt_date,
        balance_unit_qty: entry.balance_unit_qty,
        ttl_count_unit_qty: entry.ttl_count_unit_qty,
        variance_perc: entry.variance_perc,
        str_physical_count_status: entry.str_physical_count_status,
        updated_at: entry.updated_at
      };
    });

    const ws = XLSX.utils.json_to_sheet(mappedDetails);

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // eslint-disable-next-line no-undef
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `RecountDetail.xlsx`);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const { intl, historyGoBack, documentDetails, documentIsLoading } = this.props;
    const { pageSize: statePageSize } = this.state;

    const recountDetails = documentDetails.filter(curValue => {
      return curValue.str_physical_count_status === 'MARK_RECOUNT';
    });

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            showSizeChanger: true,
            pageSizeOptions: ['20', '21', '22', '50', '51', '52', '100', '101', '102'],
            onShowSizeChange: (current, pageSize) => {
              this.setState({
                pageSize
              });
            },
            pageSize: statePageSize
          }}
          columns={this.getDocumentColumns()}
          dataSource={documentDetails}
          loading={documentIsLoading}
          rowClassName={rowData => {
            if (rowData.physical_count_status === 52) {
              return 'info-row';
            }
            if (rowData.physical_count_status === 51) {
              return 'success-row';
            }
            if (rowData.physical_count_status === 50) {
              return 'error-row';
            }
            if (rowData.physical_count_status === 100) {
              return 'warning-row';
            }
            return '';
          }}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={documentIsLoading}
                    onClick={historyGoBack}
                    icon="arrow-left"
                  >
                    {intl.formatMessage({ id: 'back' })}
                  </Button>
                </Col>
                <Col span={10}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={documentIsLoading}
                    onClick={this.useOnDownloadCsv}
                    icon="cloud-download"
                  >
                    {intl.formatMessage({ id: 'excel' })}
                  </Button>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={documentIsLoading}
                    onClick={this.useOnRefresh}
                    icon="reload"
                  >
                    {intl.formatMessage({ id: 'refresh_all' })}
                  </Button>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={documentIsLoading}
                    onClick={this.useOnAutoConfirm}
                    icon="file-search"
                  >
                    {intl.formatMessage({ id: 'auto_confirm' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            zIndex: 10000,
            position: 'fixed',
            bottom: '35px',
            right: '35px'
          }}
        >
          <Badge count={recountDetails.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon="laptop"
              onClick={this.useShowWorkspace}
              loading={documentIsLoading}
            />
          </Badge>
        </div>
      </>
    );
  }
}

DetailTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  resetTimestamp: PropTypes.func,
  historyGoBack: PropTypes.func,
  setWorkspaceVisible: PropTypes.func,
  showDetails: PropTypes.func,
  autoConfirm: PropTypes.func,
  hdrId: PropTypes.number,
  documentIsLoading: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),
  initDocumentDetail: PropTypes.shape({}),

  updateRecount: PropTypes.func
};

DetailTable.defaultProps = {
  intl: {},

  resetTimestamp() {},
  historyGoBack() {},
  setWorkspaceVisible() {},
  showDetails() {},
  autoConfirm() {},
  hdrId: 0,
  documentDetails: [],
  documentIsLoading: false,
  initDocumentDetail: {},

  updateRecount() {}
};

const mapStateToProps = state => ({
  timestamp: state.cycleCount02Detail.timestamp,
  hdrId: state.cycleCount02Detail.hdrId,
  documentDetails: state.cycleCount02Detail.documentDetails,
  documentIsLoading: state.cycleCount02Detail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  resetTimestamp: () => dispatch(CycleCount02DetailActions.cycleCount02DetailResetTimestamp()),
  setWorkspaceVisible: boolean =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailSetWorkspaceVisible(boolean)),

  showDetails: hdrId => dispatch(CycleCount02DetailActions.cycleCount02DetailShowDetails(hdrId)),

  updateRecount: (dtlId, action) =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailUpdateRecount(dtlId, action)),

  autoConfirm: hdrId => dispatch(CycleCount02DetailActions.cycleCount02DetailAutoConfirm(hdrId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DetailTable));
