/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StorageBinProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const storageBinProcessFetchStorageBinProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const storageBinProcessFetchStorageBinProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StorageBinProcessTypes.STORAGE_BIN_PROCESS_FETCH_STORAGE_BIN_PROCESS_LOADING]: storageBinProcessFetchStorageBinProcessLoading,
  [StorageBinProcessTypes.STORAGE_BIN_PROCESS_FETCH_STORAGE_BIN_PROCESS_SUCCESS]: storageBinProcessFetchStorageBinProcessSuccess
});
