import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjIndexGoToDocument: ['hdrId'],
  countAdjIndexNewDocument: ['siteFlowId'],
  countAdjIndexGoToAudit: ['hdrId'],
  countAdjIndexFetchCountAdjIndex: ['siteFlowId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjIndexFetchCountAdjIndexLoading: ['boolean'],
  countAdjIndexFetchCountAdjIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  countAdjIndexAddSelectedDocuments: ['selectedDocuments'],
  countAdjIndexRemoveSelectedDocuments: ['selectedDocuments'],
  countAdjIndexSetWorkspaceVisible: ['boolean'],
  countAdjIndexPrintCountAdjIndex: ['siteFlowId', 'hdrIds'],
  countAdjIndexPrintCountAdjIndexLoading: ['boolean']
});

export const CountAdjIndexTypes = Types;
export default Creators;
