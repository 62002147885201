import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageBinProcessFetchStorageBinProcess: ['siteFlowId'],
  storageBinProcessFetchStorageBinProcessLoading: ['boolean'],
  storageBinProcessFetchStorageBinProcessSuccess: ['process']
});

export const StorageBinProcessTypes = Types;
export default Creators;
