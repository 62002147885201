import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Typography, Modal, Row, Col, Table, Button, Popconfirm, Upload, notification } from 'antd';

import ItemGroup03List01Actions from '../../Stores/ItemGroup03List01/Actions';

const { Title } = Typography;

class ManageItemGroup03PhotoDialog extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnUpload = this.useOnUpload.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setManagePhotoVisible, photoIsLoading } = this.props;

    if (photoIsLoading === false) {
      setManagePhotoVisible({}, false);
      this.setState({ fileList: [] });
    }
  }

  useOnUpload() {
    const { selectedDocument, uploadPhoto } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadPhoto(selectedDocument.id, fileList[0]);
      this.setState({ fileList: [] });
    }
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });
  handlePreviewCancel = () => this.setState({ previewVisible: false });

  handleRemove = async file => {
    const { selectedDocument, removePhoto, intl } = this.props;
    const { fileList } = this.state;
    if (Math.sign(file.uid) == -1) {
      if (window.confirm(`${intl.formatMessage({ id: 'confirm_remove' })}`)) {
        removePhoto(selectedDocument.id);
      }
    }
    this.setState({ fileList: fileList.filter(item => item.uid !== file.uid) });
  };

  render() {
    const {
      intl,
      selectedDocument,
      managePhotoIsVisible,
      photoIsLoading,
      uploadIsLoading
    } = this.props;

    const { previewVisible, previewImage, fileList } = this.state;

    for (var i = fileList.length - 1; i >= 0; --i) {
      if (Math.sign(fileList[i].uid) == -1) {
        fileList.splice(i, 1);
      }
    }

    if (selectedDocument.image_path != null && selectedDocument.image_path.length > 0) {
      var uid = selectedDocument.id * -1;
      fileList.push({
        uid: uid,
        status: 'done',
        name: selectedDocument.image_desc_01,
        url: selectedDocument.image_path
      });
    }

    const browseButton = (
      <div>
        <div className="ant-upload-text">{intl.formatMessage({ id: 'browse' })}</div>
      </div>
    );

    return (
      <>
        <Modal
          visible={managePhotoIsVisible}
          title={`${intl.formatMessage({ id: 'manage_photo' })} [ ${selectedDocument.desc_01} ]`}
          // style={{top:20}}
          width="90%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Upload
            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
            beforeUpload={file => {
              //file["status"] = "uploading";
              this.setState(state => ({
                fileList: [...state.fileList, file]
              }));
              // console.log(fileList);
              return false;
            }}
          >
            {fileList.length >= 1 ? null : browseButton}
          </Upload>
          <Modal visible={previewVisible} footer={null} onCancel={this.handlePreviewCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
          <Button
            type="primary"
            onClick={this.useOnUpload}
            disabled={fileList.length === 0}
            loading={uploadIsLoading || photoIsLoading}
          >
            {intl.formatMessage({ id: 'upload' })}
          </Button>
        </Modal>
      </>
    );
  }
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

ManageItemGroup03PhotoDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setManagePhotoVisible: PropTypes.func,
  managePhotoIsVisible: PropTypes.bool,

  removePhoto: PropTypes.func,
  uploadPhoto: PropTypes.func,
  selectedDocument: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string
  }),

  uploadIsLoading: PropTypes.bool,

  photos: PropTypes.arrayOf(PropTypes.object),
  photoIsLoading: PropTypes.bool
};

ManageItemGroup03PhotoDialog.defaultProps = {
  intl: {},
  setManagePhotoVisible() {},
  managePhotoIsVisible: false,

  removePhoto() {},
  uploadPhoto() {},
  selectedDocument: {
    id: 0,
    code: ''
  },

  uploadIsLoading: false,

  photos: [],
  photoIsLoading: false
};

const mapStateToProps = state => ({
  managePhotoIsVisible: state.itemGroup03List01.managePhotoIsVisible,
  selectedDocument: state.itemGroup03List01.selectedDocument,
  photoIsLoading: state.itemGroup03List01.photoIsLoading,
  uploadIsLoading: state.itemGroup03List01.uploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  setManagePhotoVisible: boolean =>
    dispatch(ItemGroup03List01Actions.itemGroup03List01SetManagePhotoVisible(boolean)),
  removePhoto: id => dispatch(ItemGroup03List01Actions.itemGroup03List01RemovePhoto(id)),
  uploadPhoto: (id, fileList) =>
    dispatch(ItemGroup03List01Actions.itemGroup03List01UploadPhoto(id, fileList))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ManageItemGroup03PhotoDialog));
