// eslint-disable-next-line import/no-extraneous-dependencies
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  // eslint-disable-next-line no-undef
  key: process.env.REACT_APP_APP_KEY,
  storage,
  // whitelist
  whitelist: ['app']
};

export const history = createBrowserHistory({ basename: "/bizorder-web" });

export default (rootReducer, rootSaga) => {
  const middleware = [];
  const enhancers = [];

  // Connect the sagas to the redux store
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(routerMiddleware(history));
  middleware.push(sagaMiddleware);
  middleware.push(logger);

  enhancers.push(applyMiddleware(...middleware));

  // Redux persist
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  const store = createStore(persistedReducer, compose(...enhancers));
  const persistor = persistStore(store);

  // Kick off the root saga
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
