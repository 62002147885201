import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import SlsRtnProcessActions from '../../Stores/SlsRtnProcess/Actions';

import SlsRtnSync01Form from '../SlsRtnSync01/SlsRtnSync01Form';
import InbOrd02Table from '../InbOrd02/InbOrd02Table';
import RtnRcptSync01Form from '../RtnRcptSync01/RtnRcptSync01Form';

const { TabPane } = Tabs;

class SlsRtnProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchSlsRtnProcess } = this.props;

    if (curDivisionId > 0) {
      fetchSlsRtnProcess(curDivisionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchSlsRtnProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchSlsRtnProcess(curDivisionId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          {process.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                >
                  <Link
                    style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                    to={`${appPath}/slsRtnProcess/index/${value.proc_type}`}
                  >
                    <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                  </Link>
                </Tooltip>
              }
              key={value.proc_type}
            />
          ))}
        </Tabs>

        {match.params.proc_type === 'SLS_RTN_SYNC_01' && <SlsRtnSync01Form match={match} />}
        {match.params.proc_type === 'INB_ORD_02' && <InbOrd02Table match={match} />}
        {match.params.proc_type === 'RTN_RCPT_SYNC_01' && <RtnRcptSync01Form match={match} />}
      </Card>
    );
  }
}

SlsRtnProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curDivisionId: PropTypes.number,
  fetchSlsRtnProcess: PropTypes.func
};

SlsRtnProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curDivisionId: 0,
  fetchSlsRtnProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.slsRtnProcess.process,
  processIsLoading: state.slsRtnProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchSlsRtnProcess: divisionId =>
    dispatch(SlsRtnProcessActions.slsRtnProcessFetchSlsRtnProcess(divisionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SlsRtnProcessScreen));
