/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CountAdj01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const countAdj01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const countAdj01FetchCountAdj01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const countAdj01FetchCountAdj01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const countAdj01AddSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: [
    ...state.selectedDocuments,
    ...selectedDocuments.filter(data => {
      const selectIds = state.selectedDocuments.map(value => value.id);
      return !selectIds.includes(data.id);
    })
  ]
});

export const countAdj01RemoveSelectedDocuments = (state, { selectedDocuments }) => ({
  ...state,
  selectedDocuments: state.selectedDocuments.filter(data => {
    const selectIds = selectedDocuments.map(value => value.id);
    return !selectIds.includes(data.id);
  })
});

export const countAdj01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const countAdj01CreateCountAdj01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const countAdj01CreateCountAdj01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CountAdj01Types.COUNT_ADJ01_RESET_TIMESTAMP]: countAdj01ResetTimestamp,
  [CountAdj01Types.COUNT_ADJ01_FETCH_COUNT_ADJ01_LOADING]: countAdj01FetchCountAdj01Loading,
  [CountAdj01Types.COUNT_ADJ01_FETCH_COUNT_ADJ01_SUCCESS]: countAdj01FetchCountAdj01Success,
  [CountAdj01Types.COUNT_ADJ01_ADD_SELECTED_DOCUMENTS]: countAdj01AddSelectedDocuments,
  [CountAdj01Types.COUNT_ADJ01_REMOVE_SELECTED_DOCUMENTS]: countAdj01RemoveSelectedDocuments,
  [CountAdj01Types.COUNT_ADJ01_SET_WORKSPACE_VISIBLE]: countAdj01SetWorkspaceVisible,
  [CountAdj01Types.COUNT_ADJ01_CREATE_COUNT_ADJ01_LOADING]: countAdj01CreateCountAdj01Loading,
  [CountAdj01Types.COUNT_ADJ01_CREATE_COUNT_ADJ01_SUCCESS]: countAdj01CreateCountAdj01Success
});
