import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Table, Popconfirm, Button } from 'antd';

import PromoDetailActions from '../../Stores/PromoDetail/Actions';

class DetailFocTable extends React.PureComponent {
  constructor() {
    super();

    this.getMainColumns = this.getMainColumns.bind(this);
    this.getFocColumns = this.getFocColumns.bind(this);
    this.useOnSelectItemMain = this.useOnSelectItemMain.bind(this);
    this.useOnSelectItemAddOn = this.useOnSelectItemAddOn.bind(this);
    this.useCreateDetail = this.useCreateDetail.bind(this);
    this.useCreateDetailAddOn = this.useCreateDetailAddOn.bind(this);
    this.useOnDeleteItemMain = this.useOnDeleteItemMain.bind(this);
    this.useOnDeleteItemAddOn = this.useOnDeleteItemAddOn.bind(this);
  }

  componentDidMount() {
    const { hdrId, showDetailsMain, showDetailsAddOn } = this.props;
    if (hdrId > 0) {
        showDetailsMain(hdrId);
        showDetailsAddOn(hdrId);
    }
  }

  componentDidUpdate(prevProps) {
    const { hdrId, showDetailsMain, showDetailsAddOn } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
        showDetailsMain(hdrId);
      showDetailsAddOn(hdrId);
    }
  }

  getMainColumns() {
    const { intl } = this.props;
    return [
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'item' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.item_code}
            <br />
            {record.item_desc_01}
            {record.item_desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'current_price' }),
        // sort field
        dataIndex: 'sale_price',
        // filter field
        key: 'sale_price',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.sale_price)}
          </>
        )
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'min_qty' }),
        // sort field
        dataIndex: 'min_qty',
        // filter field
        key: 'min_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.min_qty)}
          </>
        )
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'max_qty' }),
        // sort field
        dataIndex: 'max_qty',
        // filter field
        key: 'max_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.max_qty)}
          </>
        )
      },
      {
        width: 80,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon="edit" onClick={() => this.useOnSelectItemMain(record)} />
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItemMain(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }
  
  getFocColumns() {
    const { intl } = this.props;
    return [
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'item' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.item_code}
            <br />
            {record.item_desc_01}
            {record.item_desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'current_price' }),
        // sort field
        dataIndex: 'sale_price',
        // filter field
        key: 'sale_price',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.sale_price)}
          </>
        )
      },
      {
        width: 80,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon="edit" onClick={() => this.useOnSelectItemAddOn(record)} />
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItemAddOn(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon="delete" />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnSelectItemMain(record) {
    const { setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(record);

    setDetailVisible(true);
  }

  useOnSelectItemAddOn(record) {
    const { setDocumentDetail, setDetailAddOnVisible } = this.props;

    setDocumentDetail(record);

    setDetailAddOnVisible(true);
  }

  useCreateDetail() {
    const { initDocumentDetail, setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(initDocumentDetail);

    setDetailVisible(true);
  }

  useCreateDetailAddOn() {
    const { initDocumentDetailFoc, setDocumentDetail, setDetailAddOnVisible } = this.props;

    setDocumentDetail(initDocumentDetailFoc);

    setDetailAddOnVisible(true);
  }

  useOnDeleteItemMain(record) {
    const { hdrId, deleteDetail } = this.props;

    const processedValues = {
        id: record.id,
        is_main: true
      };

    deleteDetail(hdrId, processedValues);
  }

  useOnDeleteItemAddOn(record) {
    const { hdrId, deleteDetail } = this.props;

    const processedValues = {
        id: record.id,
        is_add_on: true
      };

    deleteDetail(hdrId, processedValues);
  }

  render() {
    const { intl, hdrId, itemId, documentMainDetails, documentAddOnDetails, documentIsLoading } = this.props;
    return (
      <>
        <Table
          size="small"
          rowKey="id"
          pagination={{ pageSize: 20 }}
          columns={this.getMainColumns()}
          dataSource={documentMainDetails}
          loading={documentIsLoading}
          bordered
          rowClassName={rowData => {
            if ('is_modified' in rowData && rowData.is_modified === true) {
              return 'success-row';
            }
            if (rowData.item_id === itemId) {
              return 'error-row';
            }
            return '';
          }}
          title={() => (
            <Button
              name="add_detail"
              type="primary"
              icon="plus"
              disabled={hdrId === 0}
              loading={documentIsLoading}
              onClick={this.useCreateDetail}
            >
              {intl.formatMessage({ id: 'add_product_main' })}
            </Button>
          )}
          scroll={{ x: 950 }}
        />
        <Table
          size="small"
          rowKey="id"
          pagination={{ pageSize: 20 }}
          columns={this.getFocColumns()}
          dataSource={documentAddOnDetails}
          loading={documentIsLoading}
          bordered
          rowClassName={rowData => {
            if ('is_modified' in rowData && rowData.is_modified === true) {
              return 'success-row';
            }
            if (rowData.item_id === itemId) {
              return 'error-row';
            }
            return '';
          }}
          title={() => (
            <Button
              name="add_detail"
              type="primary"
              icon="plus"
              disabled={hdrId === 0}
              loading={documentIsLoading}
              onClick={this.useCreateDetailAddOn}
            >
              {intl.formatMessage({ id: 'add_product_foc' })}
            </Button>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

DetailFocTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showDetailsMain: PropTypes.func,
  showDetailsAddOn: PropTypes.func,
  setDocumentDetail: PropTypes.func,
  setDetailVisible: PropTypes.func,
  setDetailAddOnVisible: PropTypes.func,
  deleteDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentIsLoading: PropTypes.bool,
  documentMainDetails: PropTypes.arrayOf(PropTypes.object),
  documentAddOnDetails: PropTypes.arrayOf(PropTypes.object),
  initDocumentDetail: PropTypes.shape({}),
  initDocumentDetailFoc: PropTypes.shape({}),
  itemId: PropTypes.number
};

DetailFocTable.defaultProps = {
  intl: {},
  showDetailsMain() {},
  showDetailsAddOn() {},
  setDocumentDetail() {},
  setDetailVisible() {},
  setDetailAddOnVisible() {},
  deleteDetail() {},
  hdrId: 0,
  documentMainDetails: [],
  documentAddOnDetails: [],
  documentIsLoading: false,
  initDocumentDetail: {},
  initDocumentDetailFoc: {},
  itemId: 0
};

const mapStateToProps = state => ({
  timestamp: state.promoDetail.timestamp,
  hdrId: state.promoDetail.hdrId,
  documentMainDetails: state.promoDetail.documentMainDetails,
  documentAddOnDetails: state.promoDetail.documentAddOnDetails,
  initDocumentDetail: state.promoDetail.initDocumentDetail,
  initDocumentDetailFoc: state.promoDetail.initDocumentDetailFoc,
  documentIsLoading: state.promoDetail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  showDetailsMain: hdrId => dispatch(PromoDetailActions.promoDetailShowDetailsMain(hdrId)),
  showDetailsAddOn: hdrId => dispatch(PromoDetailActions.promoDetailShowDetailsAddOn(hdrId)),
  setDetailVisible: boolean => dispatch(PromoDetailActions.promoDetailSetDetailVisible(boolean)),
  setDetailAddOnVisible: boolean => dispatch(PromoDetailActions.promoDetailSetDetailAddOnVisible(boolean)),
  setDocumentDetail: documentDetail =>
    dispatch(PromoDetailActions.promoDetailSetDocumentDetail(documentDetail)),
  deleteDetail: (hdrId, documentDetail) =>
    dispatch(PromoDetailActions.promoDetailDeleteDetail(hdrId, documentDetail))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DetailFocTable));
