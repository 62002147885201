/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PromoDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const promoDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const promoDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false
});

export const promoDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const promoDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const promoDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails,
  detailIsVisible: false
});

export const promoDetailUpdateDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails,
  detailIsVisible: false
});

export const promoDetailUpdateDetailsSuccessMain = (state, { documentDetails }) => ({
  ...state,
  documentMainDetails: documentDetails,
  detailIsVisible: false
});

export const promoDetailUpdateDetailsSuccessAddOn = (state, { documentDetails }) => ({
  ...state,
  documentAddOnDetails: documentDetails,
  detailAddOnIsVisible: false
});

export const promoDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const promoDetailShowDetailsMainSuccess = (state, { documentDetails }) => ({
  ...state,
  documentMainDetails: documentDetails
});

export const promoDetailShowDetailsAddOnSuccess = (state, { documentDetails }) => ({
  ...state,
  documentAddOnDetails: documentDetails
});

export const promoDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const promoDetailSetDetailAddOnVisible = (state, { boolean }) => ({
  ...state,
  detailAddOnIsVisible: boolean
});

export const promoDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

// force refresh the formik form
export const promoDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const promoDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const promoDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const promoDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const promoDetailFetchBrandOptionLoading = (state, { boolean }) => ({
  ...state,
  brandIsLoading: boolean
});

export const promoDetailFetchCategoryOptionSuccess = (state, { options }) => ({
  ...state,
  categoryOptions: options
});

export const promoDetailFetchCategoryOptionLoading = (state, { boolean }) => ({
  ...state,
  categoryIsLoading: boolean
});

export const promoDetailFetchManufacturerOptionSuccess = (state, { options }) => ({
  ...state,
  manufacturerOptions: options
});

export const promoDetailFetchManufacturerOptionLoading = (state, { boolean }) => ({
  ...state,
  manufacturerIsLoading: boolean
});

export const promoDetailFetchBrandOptionSuccess = (state, { options }) => ({
  ...state,
  brandOptions: options
});

export const promoDetailFetchVariantTypeOptionLoading = (state, { boolean }) => ({
  ...state,
  variantTypeIsLoading: boolean
});

export const promoDetailFetchVariantTypeOptionSuccess = (state, { options }) => ({
  ...state,
  variantTypeOptions: options
});

export const promoDetailShowRulesSuccess = (state, { rules }) => ({
  ...state,
  rules,
});

export const promoDetailSetRuleVisible = (state, { boolean }) => ({
  ...state,
  ruleIsVisible: boolean
});

export const promoDetailSetRule = (state, { rule }) => ({
  ...state,
  rule,
  debtorOptions: rule.debtor_ids_select2
});

// force refresh the formik form
export const promoDetailUpdateRules = (state, { rules }) => ({
  ...state,
  rule: rules[0]
});

// force refresh the formik form
export const promoDetailCreateRule = (state, { rule }) => ({
  ...state,
  rule
});

export const promoDetailUpdateRule = (state, { rule }) => ({
  ...state,
  rule
});

export const promoDetailDeleteRule = (state, { rule }) => ({
  ...state,
  rule
});

export const promoDetailUpdateRuleSuccess = (state, { rules }) => ({
  ...state,
  rules,
  ruleIsVisible: false
});

export const promoDetailFetchRuleTypeOptionLoading = (state, { boolean }) => ({
  ...state,
  ruleTypeIsLoading: boolean
});

export const promoDetailFetchRuleTypeOptionSuccess = (state, { options }) => ({
  ...state,
  ruleTypeOptions: options
});

export const promoDetailFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const promoDetailFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const promoDetailFetchDebtorGroupOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorGroupIsLoading: boolean
});

export const promoDetailFetchDebtorGroupOptionSuccess = (state, { options }) => ({
  ...state,
  debtorGroupOptions: options
});

// export const promoDetailSetRecipientOption = (state, { option }) => ({
//   ...state,
//   broadcast: {
//     ...state.broadcast,
//     recipient_option: option
//   }
// })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PromoDetailTypes.PROMO_DETAIL_RESET_TIMESTAMP]: promoDetailResetTimestamp,
  [PromoDetailTypes.PROMO_DETAIL_SET_HDR_ID]: promoDetailSetHdrId,

  [PromoDetailTypes.PROMO_DETAIL_SHOW_HEADER_SUCCESS]: promoDetailShowHeaderSuccess,

  [PromoDetailTypes.PROMO_DETAIL_UPDATE_DOCUMENT_SUCCESS]: promoDetailUpdateDocumentSuccess,

  [PromoDetailTypes.PROMO_DETAIL_SHOW_DOCUMENT_LOADING]: promoDetailShowDocumentLoading,

  [PromoDetailTypes.PROMO_DETAIL_SHOW_DETAILS_SUCCESS]: promoDetailShowDetailsSuccess,
  [PromoDetailTypes.PROMO_DETAIL_SHOW_DETAILS_MAIN_SUCCESS]: promoDetailShowDetailsMainSuccess,
  [PromoDetailTypes.PROMO_DETAIL_SHOW_DETAILS_ADD_ON_SUCCESS]: promoDetailShowDetailsAddOnSuccess,

  [PromoDetailTypes.PROMO_DETAIL_SET_DETAIL_VISIBLE]: promoDetailSetDetailVisible,
  [PromoDetailTypes.PROMO_DETAIL_SET_DETAIL_ADD_ON_VISIBLE]: promoDetailSetDetailAddOnVisible,
  [PromoDetailTypes.PROMO_DETAIL_SET_DOCUMENT_DETAIL]: promoDetailSetDocumentDetail,

  [PromoDetailTypes.PROMO_DETAIL_UPDATE_DETAILS]: promoDetailUpdateDetails,
  [PromoDetailTypes.PROMO_DETAIL_CREATE_DETAIL]: promoDetailCreateDetail,

  [PromoDetailTypes.PROMO_DETAIL_UPDATE_DETAILS_SUCCESS]: promoDetailUpdateDetailsSuccess,
  [PromoDetailTypes.PROMO_DETAIL_UPDATE_DETAILS_SUCCESS_MAIN]: promoDetailUpdateDetailsSuccessMain,
  [PromoDetailTypes.PROMO_DETAIL_UPDATE_DETAILS_SUCCESS_ADD_ON]: promoDetailUpdateDetailsSuccessAddOn,

  [PromoDetailTypes.PROMO_DETAIL_FETCH_ITEM_OPTION_LOADING]: promoDetailFetchItemOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: promoDetailFetchItemOptionSuccess,

  [PromoDetailTypes.PROMO_DETAIL_FETCH_BRAND_OPTION_LOADING]: promoDetailFetchBrandOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_BRAND_OPTION_SUCCESS]: promoDetailFetchBrandOptionSuccess,

  [PromoDetailTypes.PROMO_DETAIL_FETCH_CATEGORY_OPTION_LOADING]: promoDetailFetchCategoryOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_CATEGORY_OPTION_SUCCESS]: promoDetailFetchCategoryOptionSuccess,

  [PromoDetailTypes.PROMO_DETAIL_FETCH_MANUFACTURER_OPTION_LOADING]: promoDetailFetchManufacturerOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_MANUFACTURER_OPTION_SUCCESS]: promoDetailFetchManufacturerOptionSuccess,

  [PromoDetailTypes.PROMO_DETAIL_FETCH_VARIANT_TYPE_OPTION_LOADING]: promoDetailFetchVariantTypeOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_VARIANT_TYPE_OPTION_SUCCESS]: promoDetailFetchVariantTypeOptionSuccess,
  
  [PromoDetailTypes.PROMO_DETAIL_SHOW_RULES_SUCCESS]: promoDetailShowRulesSuccess,

  [PromoDetailTypes.PROMO_DETAIL_SET_RULE_VISIBLE]: promoDetailSetRuleVisible,
  [PromoDetailTypes.PROMO_DETAIL_SET_RULE]: promoDetailSetRule,

  [PromoDetailTypes.PROMO_DETAIL_UPDATE_RULE]: promoDetailUpdateRule,
  [PromoDetailTypes.PROMO_DETAIL_CREATE_RULE]: promoDetailCreateRule,
  [PromoDetailTypes.PROMO_DETAIL_DELETE_RULE]: promoDetailDeleteRule,
  [PromoDetailTypes.PROMO_DETAIL_UPDATE_RULE_SUCCESS]: promoDetailUpdateRuleSuccess,

  [PromoDetailTypes.PROMO_DETAIL_FETCH_RULE_TYPE_OPTION_LOADING]: promoDetailFetchRuleTypeOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_RULE_TYPE_OPTION_SUCCESS]: promoDetailFetchRuleTypeOptionSuccess,
  
  [PromoDetailTypes.PROMO_DETAIL_FETCH_DEBTOR_OPTION_LOADING]: promoDetailFetchDebtorOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_DEBTOR_OPTION_SUCCESS]: promoDetailFetchDebtorOptionSuccess,
  
  [PromoDetailTypes.PROMO_DETAIL_FETCH_DEBTOR_GROUP_OPTION_LOADING]: promoDetailFetchDebtorGroupOptionLoading,
  [PromoDetailTypes.PROMO_DETAIL_FETCH_DEBTOR_GROUP_OPTION_SUCCESS]: promoDetailFetchDebtorGroupOptionSuccess,

  // [PromoDetailTypes.PROMO_DETAIL_SET_RECIPIENT_OPTION]: promoDetailSetRecipientOption,
});
