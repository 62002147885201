import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  reservedStockReportInitReservedStock: ['siteFlowId'],
  reservedStockReportInitReservedStockSuccess: ['criteria'],
  reservedStockReportReservedStock: ['formikBag', 'siteFlowId', 'criteria'],
  reservedStockReportReservedStockSuccess: ['criteria', 'reportData'],
  reservedStockReportReportLoading: ['boolean'],

  reservedStockReportFetchItemOptions: ['search'],
  reservedStockReportFetchItemOptionLoading: ['boolean'],
  reservedStockReportFetchItemOptionSuccess: ['options'],

  reservedStockReportFetchItemGroup01Options: ['search'],
  reservedStockReportFetchItemGroup01OptionLoading: ['boolean'],
  reservedStockReportFetchItemGroup01OptionSuccess: ['options'],

  reservedStockReportFetchItemGroup02Options: ['search'],
  reservedStockReportFetchItemGroup02OptionLoading: ['boolean'],
  reservedStockReportFetchItemGroup02OptionSuccess: ['options'],

  reservedStockReportFetchItemGroup03Options: ['search'],
  reservedStockReportFetchItemGroup03OptionLoading: ['boolean'],
  reservedStockReportFetchItemGroup03OptionSuccess: ['options'],

  reservedStockReportFetchStorageBinOptions: ['siteFlowId', 'search'],
  reservedStockReportFetchStorageBinOptionLoading: ['boolean'],
  reservedStockReportFetchStorageBinOptionSuccess: ['options'],

  reservedStockReportFetchStorageRowOptions: ['siteFlowId', 'search'],
  reservedStockReportFetchStorageRowOptionLoading: ['boolean'],
  reservedStockReportFetchStorageRowOptionSuccess: ['options'],

  reservedStockReportFetchStorageBayOptions: ['siteFlowId', 'search'],
  reservedStockReportFetchStorageBayOptionLoading: ['boolean'],
  reservedStockReportFetchStorageBayOptionSuccess: ['options'],

  reservedStockReportFetchLocationOptions: ['siteFlowId', 'search'],
  reservedStockReportFetchLocationOptionLoading: ['boolean'],
  reservedStockReportFetchLocationOptionSuccess: ['options']
});

export const ReservedStockReportTypes = Types;
export default Creators;
