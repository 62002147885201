import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip } from 'antd';

import PurchaseProcessActions from '../../Stores/PurchaseProcess/Actions';

import AdvShipSync01Form from '../AdvShipSync01/AdvShipSync01Form';
import InbOrd01Table from '../InbOrd01/InbOrd01Table';

const { TabPane } = Tabs;

class PurchaseProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchPurchaseProcess } = this.props;

    if (curDivisionId > 0) {
      fetchPurchaseProcess(curDivisionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchPurchaseProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchPurchaseProcess(curDivisionId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          {process.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                >
                  <Link
                    style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                    to={`${appPath}/purchaseProcess/index/${value.proc_type}`}
                  >
                    <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                  </Link>
                </Tooltip>
              }
              key={value.proc_type}
            />
          ))}
        </Tabs>

        {match.params.proc_type === 'ADV_SHIP_SYNC_01' && <AdvShipSync01Form match={match} />}
        {match.params.proc_type === 'INB_ORD_01' && <InbOrd01Table match={match} />}
      </Card>
    );
  }
}

PurchaseProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),

  curDivisionId: PropTypes.number,
  fetchPurchaseProcess: PropTypes.func
};

PurchaseProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],

  curDivisionId: 0,
  fetchPurchaseProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.purchaseProcess.process,
  processIsLoading: state.purchaseProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchPurchaseProcess: divisionId =>
    dispatch(PurchaseProcessActions.purchaseProcessFetchPurchaseProcess(divisionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PurchaseProcessScreen));
