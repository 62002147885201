import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  deliveryPointSetVisible: ['boolean'],
  deliveryPointSetDebtorId: ['debtorId'],
  deliveryPointInitDocumentDetail: ['debtorId'],
  deliveryPointSetDocumentDetail: ['documentDetail'],
  deliveryPointCreate: ['formikBag', 'debtorId', 'documentDetail'],
  deliveryPointUpdate: ['formikBag', 'documentDetail'],
  deliveryPointFetchStateOptions: ['search'],
  deliveryPointFetchStateOptionsLoading: ['boolean'],
  deliveryPointFetchStateOptionsSuccess: ['options'],
  deliveryPointFetchAreaOptions: ['stateId', 'search'],
  deliveryPointFetchAreaOptionsLoading: ['boolean'],
  deliveryPointFetchAreaOptionsSuccess: ['options'],
  deliveryPointChangeState: ['formikBag', 'stateId'],
  deliveryPointChangeArea: ['formikBag', 'areaId']
});

export const DeliveryPointTypes = Types;
export default Creators;
