import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  rtnRcptDetailResetTimestamp: null,
  rtnRcptDetailSetHdrId: ['hdrId', 'itemId'],

  rtnRcptDetailInitHeader: ['divisionId'],
  rtnRcptDetailShowHeader: ['hdrId'],
  rtnRcptDetailShowHeaderSuccess: ['documentHeader'],

  rtnRcptDetailUpdateHeader: ['formikBag', 'documentHeader'],
  rtnRcptDetailCreateHeader: ['formikBag', 'documentHeader'],
  rtnRcptDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  rtnRcptDetailShowDocumentLoading: ['boolean'],
  rtnRcptDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  rtnRcptDetailShowDetails: ['hdrId'],
  rtnRcptDetailShowDetailsSuccess: ['documentDetails'],

  rtnRcptDetailSetDetailVisible: ['boolean'],
  rtnRcptDetailSetDocumentDetail: ['documentDetail'],

  rtnRcptDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  rtnRcptDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  rtnRcptDetailDeleteDetail: ['hdrId', 'documentDetail'],

  rtnRcptDetailFetchSalesmanOptions: ['search'],
  rtnRcptDetailFetchSalesmanOptionLoading: ['boolean'],
  rtnRcptDetailFetchSalesmanOptionSuccess: ['options'],

  rtnRcptDetailFetchDeliveryPointOptions: ['search'],
  rtnRcptDetailFetchDeliveryPointOptionLoading: ['boolean'],
  rtnRcptDetailFetchDeliveryPointOptionSuccess: ['options'],
  rtnRcptDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  rtnRcptDetailFetchCreditTermOptions: ['search'],
  rtnRcptDetailFetchCreditTermOptionLoading: ['boolean'],
  rtnRcptDetailFetchCreditTermOptionSuccess: ['options'],

  rtnRcptDetailFetchCurrencyOptions: ['search'],
  rtnRcptDetailFetchCurrencyOptionLoading: ['boolean'],
  rtnRcptDetailFetchCurrencyOptionSuccess: ['options'],
  rtnRcptDetailChangeCurrency: ['formikBag', 'currencyId'],

  rtnRcptDetailFetchItemOptions: ['search'],
  rtnRcptDetailFetchItemOptionLoading: ['boolean'],
  rtnRcptDetailFetchItemOptionSuccess: ['options'],
  rtnRcptDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  rtnRcptDetailFetchUomOptions: ['itemId', 'search'],
  rtnRcptDetailFetchUomOptionLoading: ['boolean'],
  rtnRcptDetailFetchUomOptionSuccess: ['options'],
  rtnRcptDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId']
});

export const RtnRcptDetailTypes = Types;
export default Creators;
